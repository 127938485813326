import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';

import { AuthFailureGuard, AuthLogoutGuard } from '@evc/platform';
import type { ImportAsyncComponent } from '@shared/types/utils.type';

const DEBUG_DISABLE_GUARD_ON_DEV = environment.DEBUG_DISABLE_AUTH
  ? { canActivate: undefined }
  : {};

const importPageNotFoundComponent:ImportAsyncComponent = () => import(
  '@shared/components/page-not-found/page-not-found.component'
).then(m => m.PageNotFoundComponent);

const importProfilePageComponent:ImportAsyncComponent = () => import(
  './pages/profile/profile.component'
).then(m => m.ProfilePageComponent);

const importSecurityPrivacyPageComponent:ImportAsyncComponent = () => import(
  './pages/secu-privacy/secu-privacy.component'
).then(m => m.SecurityPrivacyPageComponent);

const importApplicationsPageComponent:ImportAsyncComponent = () => import(
  './pages/applications/applications.component'
).then(m => m.ApplicationsPageComponent);

export const appRoutes: Route[] = [
  {
    path: 'logout',
    canActivate: [AuthLogoutGuard],
    loadComponent: importPageNotFoundComponent,
    data: { logout: true },
  },
  {
    path: 'auth-fail',
    canActivate: [AuthFailureGuard],
    loadComponent: importPageNotFoundComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: importProfilePageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'security-privacy',
    loadComponent: importSecurityPrivacyPageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'applications',
    loadComponent: importApplicationsPageComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: '**',
    loadComponent: importPageNotFoundComponent,
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
];
