<div class="applications">
  <evc-frame>
    <div frame-title class="evc-app-title">
      <h1 class="evc-page-title">Applications</h1>
    </div>

    <div frame-content class="applications__table">
      <evc-table
        [displayedColumns]="appTableColumns()"
        [data]="data()"
        [columnMapping]="columnMapping()"
      ></evc-table>
    </div>
  </evc-frame>
</div>