<div class="app">
  <evc-platform
    class="app__platform"
    [inputAppIdentification]="appIdentification()"
    [inputLeftBar]="leftbarConfig()"
    [inputUserProfile]="mockUser()"
    [i18nAppService]="i18nService"
  >
    <main class="app__container">
      <router-outlet></router-outlet>
    </main>
  </evc-platform>
</div>
