/**
 * bundle config needed to setup in app.config.ts
 *
 * @example app.config.ts
 * ```ts
 * import { provideI18n } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideI18n(),
 *   ],
 * };
 * ```
 *
 * @example some.service.ts
 * ```ts
 * export class SomeService {
 *   constructor(
 *    private readonly _i18n: I18nService,
 *   ) {
 *      this._i18n.init();
 *   }
 * }
 * ```
 */
import { HttpClient } from '@angular/common/http';
import type { EnvironmentProviders } from '@angular/core';
import { importProvidersFrom } from '@angular/core';

import { TranslateHttpLoader, TranslateLoader, TranslatePipe } from './i18n.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient):TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function provideI18n():EnvironmentProviders {
  return importProvidersFrom(
    TranslatePipe.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  );
}
