/**
 * Generate default config for platform
 */
import type { Maybe, Menu, ModalService, ThemeService } from '@evc/web-components';
import { BtnActionEnum } from '@evc/web-components';

import type { Env, PlatformEnv } from '../../providers/env.type';
import type { ApiConfig, ApplicationURIs, I18nConfig, PlatformConfig } from './config.type';

export function defaultPlatformConfigFactory(
  environment:PlatformEnv,
  themeService: ThemeService,
  modalService: ModalService,
):Partial<PlatformConfig> {
  const helpMenu:Menu = {
    // TODO soon : remove title here - will be defined in menu component so unify all menus
    title: 'Information',
    items: [
      {
        // ? idea : add optional key `enabled` - if defined as false will skip this item
        text: 'About',
        key: 'about',
        action: {
          type: BtnActionEnum.CALLBACK,
          callbackOrUrl: (): void => {
            modalService.showCurrentModal('about');
          },
        },
      },
    ],
  };

  /** default setings will define theme management
   * - may be merge with extra menuItems (rendered before themes)
   * - handle item with key `advanced-settings` to be forced to be last (after themes)
   */
  const settingsMenu: Menu = {
    title: 'Quick Setting',
    items: [
      {
        text: 'Theme',
        key: 'theme',
        collapsible: [
          {
            text: 'Light',
            key: 'light',
            img: '/assets/theme/light.webp',
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: (): void => {
                themeService.theme = 'light';
              },
            },
          },
          {
            text: 'Dark',
            key: 'dark',
            img: '/assets/theme/dark.webp',
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: (): void => {
                themeService.theme = 'dark';
              },
            },
          },
          {
            text: 'Auto',
            key: 'auto',
            selected: true,
            img: '/assets/theme/auto.webp',
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: (): void => {
                themeService.theme = 'auto';
              },
            },
          },
        ],
      },
    ],
  };

  const leftbarMenus: Menu[] = [];

  const env = environment.ENV;

  return {
    env,
    /** application basic infos
     * used in metas, logo in navbar etc
     *
     * + IDEAS : favicon, keywords, description...
     */
    app: {
      name: 'evident-connect',
      title: 'Evident Connect',
      // TODO fix logo filename !
      logo: '/assets/apps/withe_icon_unis 1.svg#withe_icon_unis 1',
    },
    menus: {
      settings: settingsMenu,
      help: helpMenu,
      leftbar: leftbarMenus,
    },
    applications: [],
    uris: getUrisConfig(env),
    theme: 'auto',
  };
}

/** specific links for some specific buttons in topbar
 * - if not defined - will compute those per env (see config.service)
 * - if defined (input) will be overrided
 */
export function getUrisConfig(env:Env):ApplicationURIs {
  const perEnvUris = (() => {
    switch (env) {
      case 'local':
      case 'testing':
        return {
          user: 'http://localhost:4200',
          admin: 'http://localhost:4200',
          organization: 'https://organization.dev-evcnt.com',
        };
      case 'development':
        return {
          user: 'https://user.dev-evcnt.com',
          admin: 'https://admin.dev-evcnt.com',
          organization: 'https://organization.dev-evcnt.com',
        };
      case 'staging':
        return {
          user: 'https://user.stg-evcnt.com',
          admin: 'https://admin.stg-evcnt.com',
          organization: 'https://organization.stg-evcnt.com',
        };
      default:
        return {
          user: 'https://user.evidentscientific.com',
          admin: 'https://admin.evidentscientific.com',
          organization: 'https://organization.evidentscientific.com',
        };
    }
  })();

  return {
    root: window?.location.origin ?? '/',
    public: 'https://www.evidentscientific.com',
    logout: 'https://www.evidentscientific.com',
    marketplace: 'https://app.olympus-ims.com/marketplace/store',
    ...perEnvUris,
  };
}

/** compute clean config for api based on some .env config */
export function getApiConfig(environment:PlatformEnv):Maybe<ApiConfig> {
  if (!environment.GREENFIELD) return undefined;

  return {
    uri: environment.API_URI!,
    endpoints: {
      signupsignin: 'v1/idm/signupsignin',
      user: 'v1/user/me',
      organization: {
        create: 'v1/organization/create',
        list: 'v1/organization/user-organizations',
        current: 'v1/organization/current',
        exists: 'v1/organization/exists',
        listUsers: 'v1/organization/users',
      },
    },
  };
}

export function getI18nConfig(environment:PlatformEnv):I18nConfig {
  const { LANG, LOCALES, LOCALES_STORAGE_KEY } = environment;
  const locales = LOCALES ?? ['en', 'fr']; // defaults for tests - apps must provide env
  const storageKey = LOCALES_STORAGE_KEY!;
  const languages = Array.from(new Set(locales.map((locale: string) => locale.split('-')[0])));

  return {
    locales,
    storageKey,
    languages,
    lang: LANG,
    defaultLang: LANG ?? languages[0],
  };
}
