/**
 * dead simple loader
 *
 * wait for the platform to be ready
 * then remove itself
 * TODO : add animation ^^
 */
import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, ElementRef, inject, signal, untracked } from '@angular/core';

import { PlatformService } from '../../platform.service';

@Component({
  selector: 'evc-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  #platformService = inject(PlatformService);
  #host = inject(ElementRef<HTMLElement>);
  #destroyRef = inject(DestroyRef);

  #animationDone = signal(false);
  get loaded(): Signal<boolean> {
    return this.#platformService.ready;
  }

  get showLoader(): Signal<boolean> {
    return computed(() =>
      !this.loaded()
      || !this.#animationDone(),
    );
  }

  #triggerFadeOut = effect(() => {
    if (!this.loaded()) return;
    untracked(() => {
      this.#fadeOut();
    });
  });

  ngOnInit(): void {
    this.#destroyRef.onDestroy(() => {
      this.#triggerFadeOut.destroy();
    });
  }

  #fadeOut() {
    setTimeout(() => {
      this.#animationDone.set(true);
    }, 250);
  }
}
