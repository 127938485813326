import type { Signal } from '@angular/core';
import { computed, DestroyRef, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filter, firstValueFrom, map } from 'rxjs';

import type { Maybe } from '@evc/web-components';

import { PlatformConfigService } from '../config/config.service';
import type { I18nConfig } from '../config/config.type';

/** storing locale may require consent
 * TODO : implement consent check
 */
const TODO_CONSENT_STORAGE_LOCALE = false;

type LanguageSources = {
  // if setted directly here (setLanguage)
  current:Maybe<string>,
  // sure source of thruth (?lang, config)
  sure:Maybe<string>,
  // user pref locale - if need auth may wait for it
  user:string|'WAIT_API'|undefined,
  // potential source of thruth - but user pref would be better
  potential:Maybe<string>,
  // may fallback to unsafe sources - never if use auth (need wait user)
  fallback:Maybe<string>,
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  #config:I18nConfig = inject(PlatformConfigService).get('i18n');
  #destroyRef = inject(DestroyRef);

  /** may guess from many different sources - ordered by importance */
  #langSources = signal<LanguageSources>({
    current: undefined,
    sure: undefined,
    user: undefined,
    potential: undefined,
    fallback: undefined,
  });

  /** computed lang from source - please do not touch */
  #langToUse = computed(() => {
    const { current, user, sure, potential, fallback } = this.#langSources();
    const mustWaitUser = user === 'WAIT_API';
    const maybeFallbacks = mustWaitUser ? undefined : fallback;
    const maybeUser = mustWaitUser ? undefined : user;

    return current || sure || maybeUser || potential || maybeFallbacks;
  });

  get lang():Signal<Maybe<string>> {
    return this.#langToUse;
  }
  get otherLang():Maybe<string> {
    const ONLY_IF_2_LOCALES = (this.#config.languages.length === 2);

    return ONLY_IF_2_LOCALES
      ? this.#config.languages.filter((lang:string) => lang !== this.lang())[0]
      : undefined;
  }

  readonly lang$ = toObservable(this.lang)
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((lang) => !!lang),
      map((lang) => lang!),
    );

  #storeLangOnChange = effect(() => {
    const lang = this.lang();
    untracked(() => {
      this.#setLangInStorage(lang);
    });
  });

  constructor() {
    this.#destroyRef.onDestroy(() => {
      this.#storeLangOnChange.destroy();
    });
  }

  /**
   * @param withAuth - if true, may wait for user profile to be ready to use preferedLanguage
   */
  async init(withAuth=false): Promise<string> {
    this.#guessLang(withAuth);

    return firstValueFrom(this.lang$);
  }

  /** once defined - will override any other sources */
  setLanguage(lang?:string): void {
    this.#safelySetLanguage(lang!, 'current');
  }

  /** specific for user pref - may be overrided by better sources */
  setUserLanguage(lang?:string): void {
    const updated = this.#safelySetLanguage(lang!, 'user');
    if (!updated) {
      // stop waiting for user prefLang
      this.#langSources.update((guesses) => ({
        ...guesses,
        user: undefined,
      }));
    }
  }

  /* may switch lang - only if 2 locales - of please specify witch lang */
  changeLanguage(lang:Maybe<string>=this.otherLang): Maybe<string> {
    const ensuredLang = lang || this.otherLang;
    if (!ensuredLang) return undefined;
    this.setLanguage(ensuredLang);

    return ensuredLang;
  }

  #ensureLangIsValid(lang?:Maybe<string>): Maybe<string> {
    const ensuredLang = lang && lang !== '' ? lang.toLocaleLowerCase() : undefined;
    const isAvaible = () => this.#config.languages.includes(ensuredLang!);

    return isAvaible()
      ? ensuredLang!
      : undefined;
  }

  /** may update (async) our language
   * @returns change-succeed : boolean
  */
  #safelySetLanguage(lang:string, type:keyof LanguageSources): boolean {
    const current = this.#langSources()[type];
    const ensuredLang = this.#ensureLangIsValid(lang);

    if (!ensuredLang || ensuredLang === current) return false;

    this.#langSources.update((guesses) => ({
      ...guesses,
      [type]: ensuredLang,
    }));

    return true;
  }

  #guessLang(withAuth:boolean): Maybe<string> {
    const sources = {
      config: () => this.#config.lang,
      url: () => new URL(window.location.href).searchParams.get('lang') ?? undefined,
      storage: () => this.#getLangFromStorage() ?? undefined,
      browser: () => this.#getLangFromBrowser() ?? undefined,
      defaut: () => this.#config.defaultLang,
    };
    const sure = sources.config() || sources.url();
    const potential = sources.storage();
    const fallback = sources.browser() || sources.defaut();
    const user = withAuth ? 'WAIT_API' : undefined;

    this.#langSources.update((guesses) => ({
      ...guesses,
      user, sure, potential, fallback,
    }));

    return withAuth
      ? sure || potential
      : sure || potential || fallback;
  }

  #getLangFromBrowser(): string|null {
    const locale = navigator.languages
      ? navigator.languages[0]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      : (navigator.language || (navigator as any).userLanguage as string);

    return locale?.split('-')[0];
  }
  #getLangFromStorage(): string|null {
    const { storageKey } = this.#config;

    return localStorage.getItem(storageKey);
  }
  #setLangInStorage(lang:Maybe<string>): void {
    if (!TODO_CONSENT_STORAGE_LOCALE) return;

    const { storageKey } = this.#config;
    if (!lang) return localStorage.removeItem(storageKey);

    localStorage.setItem(storageKey, lang);
  }
}
