/**
 * Auth Service
 * bridge between the MSAL service and the application
 *
 * Note that you can use the MSAL service directly in your components, but it is recommended to use a service to encapsulate the MSAL service.
 *
 * TODO : everithing is private here
 * - may add final subject to redispatch some events
 * - or directly bridge a User and never exposing this flows
 */
import type { Signal } from '@angular/core';
import { DestroyRef, Inject, inject, Injectable, Optional, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import type { AccountInfo, AuthenticationResult, EventMessage, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import type { Observable } from 'rxjs';
import { catchError, filter, firstValueFrom, map, merge, of, switchMap, tap } from 'rxjs';

import { PlatformConfigService } from '../../services/config/config.service';
import type { AuthConfig } from '../../services/config/config.type';

let DEBUG = true; // will always be false if not development

// available actions requests (values in .env)
enum ACTIONS {
  SIGN_UP_SIGN_IN='signUpSignIn',
}

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  // TODO : investigate why our flows may not return tfp param in our idTokenClaim
  tfp?: string, // Trust framework policy
  acr?: string, // deprecated : Authentication context class reference
};
type IdTokenClaims = {organization_id:string} & Record<string, unknown>;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getMsalService():MsalService {
    return this.authService;
  }
  #destroyRef = inject(DestroyRef);

  #config!:AuthConfig;
  get config():AuthConfig {
    return this.#config;
  }
  get clientId(): string {
    return this.config?.clientId;
  }

  #connected = signal(false);
  get connected():Signal<boolean> {
    return this.#connected.asReadonly();
  }

  #accessToken = signal<string|undefined>(undefined);
  get accessToken():Signal<string|undefined> {
    return this.#accessToken.asReadonly();
  }
  accessToken$ = toObservable(this.#accessToken).pipe(
    filter((token?:string) => !!token),
    takeUntilDestroyed(this.#destroyRef),
  );

  /* complete after check for redirect org */
  #complete = signal(false);
  onComplete$ = toObservable(this.#complete)
      .pipe(
        filter((complete) => complete),
        takeUntilDestroyed(this.#destroyRef),
      );

  #idTokenClaims = signal<IdTokenClaims|undefined>(undefined);
  get idTokenClaims():Signal<IdTokenClaims|undefined> {
    return this.#idTokenClaims.asReadonly();
  }

  #forceLoginIfFailRetrieveToken = false;

  #language = signal<string|undefined>(undefined);
  set language(lang:string) {
    this.#language.set(lang);
  }
  #waitLang$:Observable<string> = toObservable(this.#language).pipe(
    takeUntilDestroyed(this.#destroyRef),
    filter((lang) => lang !== undefined && lang !== ''),
    map((lang?:string) => lang!),
  );

  constructor(
    @Optional() @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Optional() private authService: MsalService,
    @Optional() private msalBroadcastService: MsalBroadcastService,
    private platformConfigService: PlatformConfigService,
  ) {
    if (!platformConfigService.greenfield) return;

    this.#config = platformConfigService.get('auth')!;
    if (platformConfigService.get('env') !== 'development') DEBUG = false;
  }

  /** Start our init flow
   * - skiped if not greenfield
   * @param forceAuth - if true will force a login if no token found
   * @returns Promise<boolean> - if connected with valid token
   *
   * * do not forget to call this on app init !
   **/
  async init(forceAuth=false): Promise<boolean> {
    // eslint-disable-next-line no-console
    if (DEBUG) console.debug('[auth] init', this.#config, this);

    if (!this.#config) return false;

    // important because standalone
    this.authService.instance.enableAccountStorageEvents();

    const isConnected$ = of(this.connected() || new Error('auth: not connected'));
    const haveValidToken$ = merge(
      this.accessToken$,
      this.#redirectFail$().pipe(
        switchMap(() => of(new Error('auth: no token'))),
      ),
    );

    const flow = this.authService.handleRedirectObservable()
    .pipe(
      switchMap(() => isConnected$),
      switchMap(() => haveValidToken$),
      switchMap(() => of(true)),
      catchError(() => of(false)),
      takeUntilDestroyed(this.#destroyRef),
    );

    // flow to fetch current auth state on app init
    // (resolved before handleRedirectObservable resolve)
    this.#inProgress$().subscribe(this.#onInProgress.bind(this, forceAuth));

    // flows to handle AADB2C flows redirects
    this.#redirectSuccess$().subscribe(this.#onRedirectSuccess.bind(this));
    this.#redirectFail$().subscribe(this.#onRedirectFail.bind(this));

    return firstValueFrom(flow);
  }

  async login(userFlowRequest?: Partial<RedirectRequest>/* | PopupRequest*/, extra?: Partial<RedirectRequest>):Promise<void> {
    const { redirects } = this.#config;
    const payload:RedirectRequest = {
      scopes: [],
      redirectUri: redirects.success,
      ...(this.msalGuardConfig?.authRequest ?? {}),
      ...(userFlowRequest??{}),
      ...extra ?? {},
    };
    this.authService.loginRedirect(payload);
  }

  logout():void {
    this.authService.logoutRedirect();
  }

  /** request accesstoken routine
   * - first try to fetch localy + test if still valid for enough time
   * - if no try to use refreshToken
   * - if no use a login
   */
  requestAccessToken(scopes: string[] = [], extra?:Partial<SilentRequest>):Observable<
    void | AuthenticationResult | (AuthenticationResult & { account: AccountInfo; })
  > {
    const { b2cPolicies, redirects } = this.#config;
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      throw new Error('requestAccessToken : No active account found !');
    }

    let request: RedirectRequest = {
      authority: b2cPolicies.authorities[ACTIONS.SIGN_UP_SIGN_IN],
      scopes,
      account,
      redirectUri: redirects.success,
      tokenBodyParameters: {},
    };

    const orgId = this.idTokenClaims()?.organization_id;
    if (orgId) request.tokenBodyParameters!.toid = orgId;

    if (extra) {
      request = { ...request, ...extra };
    }

    return this.authService.acquireTokenSilent(request)
    .pipe(
      tap(() => {
        this.#forceLoginIfFailRetrieveToken = false;
      }),
      // if silent token acquisition fails, fallback to interactive method (prefer popup here)
      catchError((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.msalGuardConfig.interactionType === InteractionType.Popup
            ? this.authService.acquireTokenPopup(request)
            : this.authService.acquireTokenRedirect(request);
        }

        throw error;
      }),
      tap((response) => {
        if (DEBUG) {
          // eslint-disable-next-line no-console -- debug flow
          console.debug('auth: requestAccessToken - then', {
            response,
            request,
          });
        }
      }),
    );
    // ! DO NOT SUBSCRIBE HERE ! - handled by #onRedirectSuccess !
  }

  /** > ?redirect_uri to fail (public) route because need org */
  redirectToCreateOrganization(lang:string, redirectUri?:string):void {
    const { fail, organization } = this.#config.redirects;
    const uri = `${organization}?lang=${lang}&redirect_uri=${redirectUri ?? fail}`;
    window.location.href = uri;
  }

  /**
   * ! ---------------- FLOWS ----------------
   */

  /** On inProgress : fetch current login data
   * - after a successfull login, if you reload we may check if still loggued (localStorage)
   * ? - if ok, may request a new access-token to complete ?
   */
  #onInProgress(forceAuth=false) {
    this.#checkIfConnected();
    this.#checkAndSetActiveAccount();
    if (this.#connected() && !this.accessToken()) {
      const { clientId } = this.#config;
      this.#forceLoginIfFailRetrieveToken = forceAuth;
      this.requestAccessToken([clientId]);
    }
  }

  /** On redirect success - here have an updated token
   * #onRedirectSuccessFromSignupSignin will match login or aquireToken - and event register will pass through here
   *
   * * if no orgId within token claim => redirect org creation
   * * for now only support SIGN_UP_SIGN_IN policy
   */
  #onRedirectSuccess(result: EventMessage) {
    const payload = result.payload as AuthenticationResult;
    const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

    this.#idTokenClaims.set(idtoken);

    if (DEBUG) {
      // eslint-disable-next-line no-console -- debug flow
      console.debug('[auth] redirect success', result.eventType, { idtoken, result });
    }

    const mustCreateOrganization = !idtoken.organization_id
      && this.#config.forceOrganization;

    if (mustCreateOrganization) {
      this.#waitLang$.subscribe((lang:string) => {
        this.redirectToCreateOrganization(lang);
      });
    } else {
      this.#complete.set(true);
    }

    this.authService.instance.setActiveAccount(payload.account);
    this.#saveAccessToken(payload.accessToken);

    return result;
  }

  /** On redirect fail
   * - either a new login or a simple fetch access token
   * - may force login (if `init(forceAuth=true)`)
   */
  #onRedirectFail(result: EventMessage) {
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    const IS_FORGOT_PASSWORD_ERROR = result.error && result.error.message.indexOf('AADB2C90118') > -1;
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/7115
    const IS_AQUIRE_TOKEN_SILENT_EXPIRED_SESSION_ERROR = result.error && result.error.message.indexOf('AADB2C90077') > -1;

    if (DEBUG) {
      // eslint-disable-next-line no-console -- debug flow
      console.debug('[auth] #onRedirectFail :', {
        IS_FORGOT_PASSWORD_ERROR,
        IS_AQUIRE_TOKEN_SILENT_EXPIRED_SESSION_ERROR,
      }, result);
    }

    // bug in MSAL - expired session - may force login : `init(forceAuth=true)`
    if (IS_AQUIRE_TOKEN_SILENT_EXPIRED_SESSION_ERROR
      && this.#forceLoginIfFailRetrieveToken
    ) return this.login();

    this.#forceLoginIfFailRetrieveToken = false;

    return result;
  }

  /**
   * ! ---------------- PRIVATE ----------------
   */

  /** subtility : isConnected does not mean have access (expired token) / if not, cancel flow here */
  #checkIfConnected(): void {
    const isConnected = this.authService.instance.getAllAccounts().length > 0;
    this.#connected.set(isConnected);
  }

  #checkAndSetActiveAccount() {
    const { clientId } = this.#config;
    const accounts = this.authService.instance.getAllAccounts();
    let activeAccount = this.authService.instance.getActiveAccount() ?? undefined;

    if (!activeAccount && accounts.length > 0) {
      const matchAccount = accounts.find((account) => account.idTokenClaims?.aud === clientId) ?? accounts[0];
      activeAccount = matchAccount;
      this.authService.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }

  #saveAccessToken(accessToken?:string) {
    if (accessToken === this.#accessToken()
      || accessToken === ''
    ) return;
    this.#accessToken.set(accessToken);
  }

  /**
   * ! ---------------- OBSERVABLES ----------------
   */

  #inProgress$(): Observable<InteractionStatus> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.#destroyRef),
      );
  }

  #redirectSuccess$(): Observable<EventMessage> {
    return this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntilDestroyed(this.#destroyRef),
      );
  }

  #redirectFail$(): Observable<EventMessage> {
    return this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) =>
        msg.eventType === EventType.LOGIN_FAILURE
        || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      takeUntilDestroyed(this.#destroyRef),
    );
  }
}
