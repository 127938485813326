<ng-container [formGroup]="formGroup" >
  <div class="address-form address-form--{{parentComponentName}}">
    <div class="address-form--{{parentComponentName}}__form-address">
      <div class="address-form--{{parentComponentName}}__row-container">
        <evc-input
          formControlName="country"
          name="country"
          label="{{'personal_info.country' | translate }}"
          placeholder="{{'personal_info.country_placeholder' | translate }}"
          type="select"
          [options]="countries"
          [showError]="showError()"
        ></evc-input>
        <div class="address-form--{{parentComponentName}}__empty-space--md"></div>
      </div>

      <evc-input
        formControlName="street"
        name="street"
        label="{{'personal_info.street' | translate }}"
        placeholder="{{'personal_info.street_placeholder' | translate }}"
        [showError]="showError()"
      ></evc-input>
      <div class="address-form--{{parentComponentName}}__row-container">
        <evc-input
          formControlName="city"
          name="city"
          [label]="'personal_info.city'|translate"
          [placeholder]="'personal_info.city_placeholder'|translate"
          [showError]="showError()"
        ></evc-input>
        <evc-input
          formControlName="province"
          name="province"
          label="{{'personal_info.province' | translate }}"
          placeholder="{{'personal_info.province_placeholder' | translate }}"
          type="select"
          [options]="states()"
          [showError]="showError()"
        ></evc-input>
        </div>

      <evc-input
        formControlName="postalCode"
        name="postalCode"
        label="{{'personal_info.postal_code' | translate }}"
        placeholder="{{'personal_info.postal_code_placeholder' | translate }}"
        [showError]="showError()"
      ></evc-input>
    </div>
  </div>
</ng-container>
