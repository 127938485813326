import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FrameComponent } from '@evc/web-components';
import type { TableRow, TableRowArray } from '@shared/components/table/table.component';
import { TableComponent } from '@shared/components/table/table.component';
import { MOCK_APPLICATIONS } from '@shared/mock/mock-application-list';
import { I18nService, TranslatePipe } from '@shared/services/i18n/i18n.service';

@Component({
  standalone: true,
  selector: 'evc-applications',
  templateUrl: 'applications.component.html',
  styleUrls: ['applications.component.scss'],
  imports: [FrameComponent, TableComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationsPageComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #i18nService = inject(I18nService);
  appTableColumns = signal<string[]>([]);
  columnMapping = signal<{ [key: string]: string }>({});
  data = signal<TableRowArray>(MOCK_APPLICATIONS as TableRowArray);

  sortingDataAccessor = (item: TableRow, property: string):string => {
    const columnMapping = this.columnMapping();
    const originalKey = columnMapping[property] || property;

    return item[originalKey].value;
  };

  setDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }

  ngOnInit(): void {
    this.#i18nService.onReady$
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe(() => this.#setTableColumnsLabels());
  }

  #setTableColumnsLabels():void {
    const keys = ['app', 'licenseType', 'licensingModel', 'expiration'];
    const translatedKeys = keys.map(key => this.#i18nService.t(`PAGE_APPLICATIONS.table.${key}`) ?? key);

    this.appTableColumns.set(translatedKeys);

    const columnMapping = keys.reduce((acc, key, index) => {
      acc[translatedKeys[index]] = key;

      return acc;
    }, {} as { [key: string]: string });

    this.columnMapping.set(columnMapping);
  }
}
