import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';

import { AuthService, OrganizationsService, UserService } from '@evc/platform';
import {
  CloseOnClickOutsideDirective,
  FrameComponent,
  InfoBannerComponent,
  InputComponent,
  ModalComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

@Component({
  standalone: true,
  selector: 'evc-secu-privacy',
  templateUrl: 'secu-privacy.component.html',
  styleUrls: ['./secu-privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslatePipe,
    FrameComponent,
    InputComponent,
    NavButtonComponent,
    SvgIconComponent,
    InfoBannerComponent,
    ModalComponent,
    CloseOnClickOutsideDirective,
  ],
})
export class SecurityPrivacyPageComponent {
  public pricayPolicyLink = 'https://www.evidentscientific.com/en/privacy/';
  // TODO : dynamic date translation (french format vs english format)
  public lastUpdatedPassword = new Date('2024-03-17').toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  public showDeleteAccountModal = false;

  public canDeleteAccount = computed(() => {
    const profile = this._userService.profile();
    if (!profile) {
      return false;
    }

    return this._orgService.entries().length === 0;
  });

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _orgService: OrganizationsService,
  ) {}

  public changePasswordClick():void {
    // eslint-disable-next-line no-console
    console.log('Change password clicked');
  }

  public reviewPolicyClick():void {
    // eslint-disable-next-line no-console
    console.log('Review policy clicked');
  }

  public downloadAccountData():void {
    // eslint-disable-next-line no-console
    console.log('Download account data clicked');
  }

  public deleteAccount():void {
    this.showDeleteAccountModal = true;
  }

  public closeDeleteAccountModal():void {
    this.showDeleteAccountModal = false;
  }

  public confirmDeleteAccount():void {
    this._authService.logout();
    this.showDeleteAccountModal = false;
  }
}
