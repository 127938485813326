import type { WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import type { TableRowArray } from '@shared/components/table/table.component';
import { TableComponent } from '@shared/components/table/table.component';

import { FrameComponent } from '@evc/web-components';

@Component({
  standalone: true,
  selector: 'evc-applications',
  templateUrl: 'applications.component.html',
  styleUrls: ['applications.component.scss'],
  imports: [FrameComponent, TableComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationsPageComponent {
  appTableColumns: WritableSignal<string[]> = signal<string[]>(['Application', 'License type', 'Licensing model', 'Expiration']);
  columnMapping: WritableSignal<{ [key: string]: string; }> = signal<{ [key: string]: string }>({
    Application: 'app',
    'License type': 'licenseType',
    'Licensing model': 'licensingModel',
    Expiration: 'expiration',
  });
  data: WritableSignal<TableRowArray> = signal<TableRowArray>([
    {
      app: { value: 'Drive', iconLeft: { svgPath: '/assets/apps/drive.svg#drive' }, style: 'bold' },
      licenseType: { value: 'Standard' },
      licensingModel: { value: 'Base bundle', disabled: true },
      expiration: { value: '' },
    },
    {
      app: { value: 'IPM', iconLeft: { svgPath: '/assets/apps/ipm.svg#ipm' }, style: 'bold' },
      licenseType: { value: 'Standard', statusInfo: { text: 'Trial' } },
      licensingModel: { value: 'Single User' },
      expiration: { value: this.setDate('2024-03-17 00:00:00') },
    },
    {
      app: { value: 'Vanta', iconLeft: { svgPath: '/assets/apps/vanta.svg#vanta' }, style: 'bold' },
      licenseType: { value: 'Standard' },
      licensingModel: { value: 'Single User' },
      expiration: { value: this.setDate('2024-03-17 00:00:00') },
    },
    {
      app: { value: 'AeroView', iconLeft: { svgPath: '/assets/apps/aeroview.svg#aeroview' }, style: 'bold' },
      licenseType: { value: 'Standard', statusInfo: { text: 'Trial' } },
      licensingModel: { value: 'Node locked' },
      expiration: { value: this.setDate('2024-03-17 00:00:00') },
    },
    {
      app: { value: 'WindView', iconLeft: { svgPath: '/assets/apps/windviewapp.svg#windviewapp' }, style: 'bold' },
      licenseType: { value: 'Analysis' },
      licensingModel: { value: 'Perpetual', disabled: true },
      expiration: { value: this.setDate('2024-03-17 00:00:00') },
    },
    {
      app: { value: 'WelSight', iconLeft: { svgPath: '/assets/apps/weldsight.svg#weldsight' }, style: 'bold' },
      licenseType: { value: 'Standard', statusInfo: { text: 'Trial' } },
      licensingModel: { value: 'Single User' },
      expiration: { value: this.setDate('2024-03-17 00:00:00') },
    },
    {
      app: { value: 'ViSOL', iconLeft: { svgPath: '/assets/apps/visol.svg#visol' }, style: 'bold' },
      licenseType: { value: 'Default' },
      licensingModel: { value: 'Floating' },
      expiration: { value: '' },
    },
  ]);

  public setDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }
}
