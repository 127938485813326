<div class="menu">
  @if (showTitle && menuConfig?.title) {
    <div class="menu__title">
      {{ menuConfig?.title }}
    </div>
  }

  @if (!showOnlyBadgeItems) {
    @for (item of menuConfig?.items; track trackByKey) {
      <!-- @if (showDividers) {
        <div class="menu__divider"></div>
      } -->

      @if (!item.collapsible) {
        <evc-menu-item [item]="item"
          (itemClicked)="closeMenu()"
          [attr.data-key]="item.key"></evc-menu-item>
      } @else {
        <evc-collapsible-panel [content]="item" [attr.data-key]="item.key">
          @for (collapsibleItem of item.collapsible; track trackByKey) {
            <evc-menu-item
              [item]="collapsibleItem"
              [selected]="isItemSelected(collapsibleItem)"
              (click)="handleCollapsibleItemClick(item, collapsibleItem)"
              [attr.data-key]="collapsibleItem.key"
            ></evc-menu-item>
          }
        </evc-collapsible-panel>
      }
    }
  }

  @if (!showOnlyMenuItems && orgMenuConfig) {
    @for (badgeItem of orgMenuConfig(); track trackByKey) {
      <evc-menu-item [badgeItem]="badgeItem" [selected]="badgeItem.current ?? false" (organizationClicked)="handleOrganizationClick(badgeItem)"></evc-menu-item>
    }
  }


</div>
