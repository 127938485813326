import type { SharedEnv } from '@shared/services/config/config.type';

import type { PlatformEnv } from '@evc/platform';
import { ENVIRONMENT } from '@evc/platform';
import type { WebComponentsEnv } from '@evc/web-components';

export { ENVIRONMENT } from '@evc/platform';

export type AppEnv = SharedEnv & PlatformEnv & WebComponentsEnv

export const environment: AppEnv = {
  APP_NAME: 'user-account',
  ENV: ENVIRONMENT.production,
  GREENFIELD: true,
};
