import type { AfterViewInit, OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, ElementRef, inject, signal, ViewChild } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import type { ApiSaveUserPayload, UserProfile } from '@evc/platform';
import { UserService } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { FrameComponent, InputComponent, NavButtonComponent } from '@evc/web-components';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { EvcValidatorsService, patterns } from '@shared/reactive-form/validators/validator.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import type { FormDataProfile, FormModelProfile } from '@shared/types/profile-form.type';
import { UsrInfosComponent } from '@app/components/usr-infos/usr-infos.component';

import { LANGUAGES } from '../../mocks/user.mock';

@Component({
  standalone: true,
  selector: 'evc-home',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    FormsModule,
    InputComponent,
    FrameComponent,
    NavButtonComponent,
    UsrInfosComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class ProfilePageComponent implements OnInit, AfterViewInit {
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;
  @ViewChild('profileForm') profileFormElement!: ElementRef;

  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(FormBuilder);
  #userService = inject(UserService);
  #formService = inject(EvcFormService<FormDataProfile>);
  #validatorService = inject(EvcValidatorsService);

  canSave = false;
  userProfile: Signal<Maybe<UserProfile>>;
  newUserProfile : Maybe<UserProfile>;
  languages = LANGUAGES;
  form!: FormGroup<FormModelProfile>;
  submited = this.#formService.submited;
  displayAddress = signal(false);

  profileModel = signal<FormDataProfile>({
    givenName: '',
    surname: '',
    phone: '',
    address: undefined,
    preferredLanguage: 'En',
  });

  constructor() {
    this.userProfile = this.#userService.profile;
    effect(() => {
      this.form.patchValue(this.userProfile() as FormDataProfile);
      if (this.userProfile()?.address) {
        this.displayAddress.update(() => true);
      }
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    const control = this.#formService.controlFactory(this.#formBuilder.nonNullable, this.profileModel());

    this.form = this.#formBuilder.group<FormModelProfile>({
      givenName: control('givenName', [
        this.#validatorService.minLength(3),
        this.#validatorService.maxLength(25),
        this.#validatorService.pattern(patterns.alphanumericWithDash, 'noSpecialChars'),
      ]),
      surname: control('surname', [
        this.#validatorService.minLength(3),
        this.#validatorService.maxLength(25),
        this.#validatorService.pattern(patterns.alphanumericWithDash, 'noSpecialChars'),
      ]),
      preferredLanguage: control('preferredLanguage'),
      phone: control('phone', [this.#validatorService.phone()]),
    });

    this.#formService.onUpdate(
      (value) => {
        this.profileModel.update((profileModel) => ({ ...profileModel, ...value }));
      },
    );

    this.#formService.onSubmit(this.submitUserInfo.bind(this));
  }

  ngAfterViewInit(): void {
    this.#formService.bind(this.form, this.formDirective, this.#destroyRef);
  }

  submitUserInfo(value:Partial<FormDataProfile>, rawValue:FormDataProfile): void {
    const profile = this.userProfile();
    if (profile === undefined) return;

    const newUserProfile: ApiSaveUserPayload = {
      id: profile.id,
      preferredLanguage: rawValue.preferredLanguage,
      givenName: rawValue.givenName,
      surname: rawValue.surname,
      // TODO use lib to reformat phone on display
      phone: rawValue.phone.replace(/[^\d+]/g, ''),
      address: rawValue.address,
      displayName: profile.displayName ?? '',
    };

    this.#userService.saveUser(newUserProfile)
    .then(() => {
      this.form.get('province')?.enable();
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.#formService.submited.set(false);
    })
    .catch((error) => {
      console.error(error); // TODO handle the error
    });
  }

  submitForm(event: Event): void {
    event.preventDefault();
    if (this.form.value.address?.country === '') {
      this.form.value.address = undefined;
    }
    this.profileFormElement.nativeElement.dispatchEvent(new Event('submit'));
  }
}
