import type { Signal, WritableSignal } from '@angular/core';
import { Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

import type { AppIdentity, AppLinks, Organization } from '@evc/platform';
import { OrganizationsService, UserService } from '@evc/platform';
import type { Menu } from '@evc/web-components';
import { BtnActionEnum } from '@evc/web-components';

@Injectable({
  providedIn: 'root',
})
export class NavConfigService {
  private organizations!: WritableSignal<Organization[]>;
  private _leftbarConfig!: WritableSignal<Menu[]>;
  private _appIdentity!: WritableSignal<AppIdentity>;

  constructor(
    private readonly _router: Router,
    private readonly _orgService: OrganizationsService,
    private readonly _userService: UserService,
  ) {
    this.initConfig();

    this._router.events
      .pipe(
        takeUntilDestroyed(),
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateSelectedMenuItem();
        }
      });
  }

  private initConfig(): void {
    this.organizations = this._orgService.entries as WritableSignal<Organization[]>;
    this._leftbarConfig = signal<Menu[]>([
      {
        title: 'Account Settings',
        items: [
          {
            text: 'Profile',
            key: '/',
            icon: 'user',
            selected: false,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: () => this._router.navigateByUrl('/'),
            },
          },
          {
            text: 'Security & Privacy',
            key: '/security-privacy',
            icon: 'lock-keyhole',
            selected: false,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: () => this._router.navigateByUrl('/security-privacy'),
            },
          },
        ],
      },
      {
        title: 'Organization',
        badgeItems: this.organizations as Signal<Organization[]>,
        items: [
          {
            text: 'Applications',
            key: '/applications',
            icon: 'choco-menu',
            selected: false,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: () => this._router.navigateByUrl('/applications'),
            },
          },
        ],
      },
    ]);

    this._appIdentity = signal<AppIdentity>({
      title: 'USER ACCOUNT',
      logo: '../assets/icons/sprite/sprite.svg#circle-user',
      onTitleClick: () => this._router.navigateByUrl(''),
    });
  }

  private readonly _appLinks = signal<AppLinks>({
    marketplace: 'https://app.olympus-ims.com/marketplace/store',
    user: 'https://user.evidentscientific.com',
    admin: 'https://admin.evidentscientific.com',
    organization: 'https://organization.dev-evcnt.com',
  });

  public updateSelectedMenuItem(): void {
    if (!this._leftbarConfig) return;

    const currentUrl = this._router.url;

    this._leftbarConfig.update((config) =>
      config.map((section) => ({
        ...section,
        items: section.items?.map((item) => ({
          ...item,
          selected:
            item.key === currentUrl,
        })),
      })),
    );
  }

  public get leftbarConfig(): WritableSignal<Menu[]> {
    return this._leftbarConfig;
  }

  public set leftbarConfig(value: Menu[]) {
    this._leftbarConfig.set(value);
  }

  public get appIdentity(): WritableSignal<AppIdentity> {
    return this._appIdentity;
  }

  public get appLinks(): WritableSignal<AppLinks> {
    return this._appLinks;
  }
}
