import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { StatusIndicatorComponent, SvgIconComponent } from '@evc/web-components';

import { TableCell } from '../table.component';

@Component({
  standalone: true,
  selector: 'evc-cell',
  templateUrl: 'cell.component.html',
  styleUrls: ['cell.component.scss'],
  imports: [NgClass, MatTableModule, SvgIconComponent, StatusIndicatorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent {
  @Input() cellData: TableCell = { value: '', disabled: false, iconLeft: { svgPath: '' } };
}
