/**
 * Base Config Service
 * ============================
 *
 * We will use differents configServices
 * - web-components : some configs used in this lib
 * - platform : dsame interface but to focus on vars used in this lib
 * - shared : idem but for everithing shared between apps
 * - ? app related (ex: admin, organization, user-account...) : every apps can implement this for it's own config
 *
 * All our configServices will extends this same base class
 * - inject default config (typed)
 * - accept some extra config (not typed)
 *
 * So every instances of configService can ensure some config to pass to this extended class
 * - WebComponent simply inject default vars
 * - PlatformConfigService will inject default vars + env vars + auth config
 */
import type { Signal, WritableSignal } from '@angular/core';
import { computed, Inject, Injectable, Optional, signal } from '@angular/core';

import { deepMerge } from '../../utils/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService<T extends Record<string, unknown>> {
  protected _config: WritableSignal<T>;

  constructor(
    @Optional() @Inject('DEFAULT_CONFIG') defaultConfig: Partial<T>,
    /** any extra config - recommanded to pass merge of .env, auth etc in one object */
    @Optional() @Inject('EXTRA_CONFIG') extraConfig: Record<string, unknown>,
  ) {
    this._config = signal(deepMerge<T>(
      defaultConfig,
      (extraConfig ?? {}),
    ));
  }

  /** get the whole config object (signal) */
  public get config(): Signal<T> {
    return this._config.asReadonly();
  }

  /** get a config option (without update) */
  public get<K extends keyof T>(key: K): T[K] {
    return this.config()[key];
  }

  /** get a config option (with updates : signal) */
  public getComputed<K extends keyof T, D extends unknown | undefined | null>(key: K, defaultValue?:D): Signal<T[K]> {
    return computed(() => {
      const config = this.config();
      if (config[key] === undefined) return defaultValue as T[K];

      return this.config()[key];
    });
  }

  /** set a config option
   * simply set new value - may erase existant data
   */
  public set<K extends keyof T>(key: K, value: Partial<T[K]>): void {
    this._config.update(config => ({
      ...config,
      [key]: value,
    }));
  }

  /** merge a config option
   * use deepMerge so ensure safe update - may keep existant data + add new ones
   */
  public merge<K extends keyof T>(key: K, value: Partial<T[K]>): void {
    this._config.update(config => deepMerge(
      config,
      { [key]: value },
    ));
  }

  /** provide a whole config object (merge with config) */
  public provideConfig(payload: Partial<T>): void {
    this._config.update(config => deepMerge(
      config,
      payload,
    ));
  }
}
