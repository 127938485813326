import type { AppEnv } from './environment.default';
import { ENVIRONMENT, environment as defaultEnv } from './environment.default';

export const environment: AppEnv = {
  ...defaultEnv,
  ENV: ENVIRONMENT.development,
  AUTH_CLIENT_ID: '53227183-0a4c-40a4-8297-27b50e058a84',
  // TEMP - pascal will enjoy this link + cool for next demo
  DEBUG_ALWAYS_SHOW_ADMIN_APP: true,
};
