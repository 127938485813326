import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { Observable } from 'rxjs';

import { CopyrightComponent, ModalComponent, OptionalAsyncPipe, SvgIconComponent } from '@evc/web-components';

import { AppIdentity } from '../../types/app-identity.type';

@Component({
  selector: 'evc-about-modal',
  templateUrl: './about-modal.component.html',
  imports: [ModalComponent, SvgIconComponent, CopyrightComponent, OptionalAsyncPipe],
  standalone: true,
  styleUrls: ['./about-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutModalComponent {
  @Output() public readonly hideModal: EventEmitter<void> = new EventEmitter();

  @Input() public appIdentity?: AppIdentity;

  public closeModal(): void {
    this.hideModal.emit();
  }

  public getCopyrightText(): Observable<string> | string {
    return this.appIdentity?.copyright ?? '';
  }
}
