import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import type { IconListType } from '../../types/icon-list.type';
import { IconEnum } from '../../types/icon-list.type';
import { MenuItemType } from '../../types/menu-item.type';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'evc-collapsible-panel',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './collapsible-panel.component.html',
  styleUrls: ['./collapsible-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsiblePanelComponent {
  @Output() public readonly isOpenStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public content?: MenuItemType;

  public isOpen = false;

  public toggleCollapsible(event: MouseEvent): void {
    event.stopPropagation();
    this.isOpen = !this.isOpen;
    this.isOpenStateChange.emit(this.isOpen);
  }

  public getIcon(): IconListType {
    return this.isOpen ? IconEnum.AngleUp : IconEnum.AngleDown;
  }
}
