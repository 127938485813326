@if(item){
  <button
    class="item"
    [ngClass]="{ 'item--selected': selected }"
    [disabled]="item.isDisabled"
    (click)="handleItemClick(item)"
  >
    <div class="item__info">
      <div class="item__text-container">

        <div class="item__text-content">
          @if(item.img){
            <img class="item__img" [src]="item.img" alt="theme image" />
          }
          @if(item.icon){
            <evc-svg-icon size="md" [icon]="item.icon"></evc-svg-icon>
          }
          <span>{{ item.text | optionalAsync }}</span>
        </div>

        @if (aditionalText) {
          <span class="item__additional-text">{{ aditionalText }}</span>
        }
      </div>
    </div>

    @if(isActionURL(item)){
      <evc-svg-icon
        size="md"
        icon="arrow-up-right-from-square"
      ></evc-svg-icon>
    }
  </button>
}


@if(badgeItem){
  <button
    class="item item--organization"
    [ngClass]="{ 'item--selected': selected }"
    (click)="handleOrganizationClick(badgeItem)"
  >
    <div class="item__info">
      <evc-avatar
        size="sm"
        [avatar]="badgeItem.avatar"
      ></evc-avatar>
      <div class="item__text-container">
        <span>{{ badgeItem.name }}</span>
        @if (aditionalText) {
          <span class="item__additional-text">{{ aditionalText }}</span>
        }
      </div>
    </div>

  </button>
}

