export enum ENVIRONMENT {
  testing = 'testing',
  local = 'local',
  development = 'development',
  staging = 'staging',
  production = 'production'
}
export type Env = `${ENVIRONMENT}`

// locale = en-CA but international so keep `en`
export type AvailableLocales = 'en' | 'fr';
export type AvailableLanguages = 'en' | 'fr';
export type I18nEnv = {
  LANG?: AvailableLanguages
  LOCALES?: AvailableLocales[]
  LOCALES_STORAGE_KEY?: string
}

export type AuthEnv = {
  AUTH_CLIENT_ID?: string
  AUTH_FORCE_ORGANIZATION?: boolean
}

export type ApiEnv = {
  // must end with /
  API_URI?: string
}

export type AppIdentityEnv = {
  APP_NAME?: string
  APP_TITLE?: string
  APP_LOGO?: string
}

type PlatformEnvGreenfield = {
  GREENFIELD?: boolean
} & ApiEnv & AuthEnv

export type PlatformEnv = {
  ENV: Env
} & AppIdentityEnv
  & I18nEnv
  & PlatformEnvGreenfield
