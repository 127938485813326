<ng-container [formGroup]="formGroup" >
  <div class="address-form address-form--{{parentComponentName}}">
    <div class="address-form--{{parentComponentName}}__form-address">
      <div class="address-form--{{parentComponentName}}__row-container">
        <evc-input
          formControlName="country"
          name="country"
          label="{{'form_address.country.label' | translate }}"
          placeholder="{{'form_address.country.placeholder' | translate }}"
          type="select"
          [options]="countries()"
          [showError]="showError"
        ></evc-input>
        <div class="address-form--{{parentComponentName}}__empty-space--md"></div>
      </div>

      <evc-input
        formControlName="street"
        name="street"
        label="{{'form_address.street.label' | translate }}"
        placeholder="{{'form_address.street.placeholder' | translate }}"
        [showError]="showError"
      ></evc-input>

      <div class="address-form--{{parentComponentName}}__row-container">
        <evc-input
          formControlName="city"
          name="city"
          [label]="'form_address.city.label'|translate"
          [placeholder]="'form_address.city.placeholder'|translate"
          [showError]="showError"
        ></evc-input>

        @if (states() ; as states) {
          <evc-input formControlName="province"
            type="select"
            name="province"
            [label]="'form_address.'+states.type+'.label'|translate"
            [placeholder]="'form_address.'+states.type+'.placeholder'|translate"
            [showError]="showError"
            [options]="states.options"
          ></evc-input>
        }
      </div>

      <div class="address-form--{{parentComponentName}}__row-container">
        <evc-input
          formControlName="postalCode"
          name="postalCode"
          label="{{'form_address.'+zipOrPostalCodeLabel()+'.label' | translate }}"
          placeholder="{{'form_address.'+zipOrPostalCodeLabel()+'.placeholder' | translate }}"
          [showError]="showError"
        ></evc-input>

        <div class="address-form--{{parentComponentName}}__empty-space--md"></div>
      </div>

    </div>
  </div>
</ng-container>