import type { WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedConfigService } from '@shared/services/config/config.service';
import { I18nService } from '@shared/services/i18n/i18n.service';

import type { AppIdentity, AppLinks } from '@evc/platform';
import { OrganizationsService, PlatformComponent, UserService } from '@evc/platform';
import type { Menu } from '@evc/web-components';

import { NavConfigService } from './service/nav-config/nav-config.service';

@Component({
  standalone: true,
  imports: [RouterModule, PlatformComponent],
  selector: 'evc-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public leftbarConfig: WritableSignal<Menu[]>;
  public appIdentification: WritableSignal<AppIdentity>;
  public links: WritableSignal<AppLinks>;

  public i18nService = inject(I18nService);
  constructor(
    private readonly _config: SharedConfigService,
    private readonly _navConfig: NavConfigService,
    private readonly _userService: UserService,
    private readonly _orgService: OrganizationsService,
  ) {
    this.leftbarConfig = this._navConfig.leftbarConfig;
    this.appIdentification = this._navConfig.appIdentity;
    this.links = this._navConfig.appLinks;
  }
}
