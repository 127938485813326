<div class="info-banner info-banner--{{type}}">
  @if (icon) {
    <evc-svg-icon
      class="info-banner__icon"
      [icon]="icon"
      size="sm"
    ></evc-svg-icon>
  }
  <span>{{message}}</span>
  @if (closable) {
    <evc-button
      type="icon"
      rightIcon="xmark"
      variant="semi"
      size="sm"
      (click)="closeBanner()"
    ></evc-button>
  }
</div>