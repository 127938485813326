import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import type { IconType, StatusIndicatorType } from '@evc/web-components';
import { SvgIconComponent } from '@evc/web-components';

import { CellComponent } from './cell/cell.component';

export type TableCell = {
  value: string;
  disabled?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  statusInfo?: StatusIndicatorType;
  style?: 'bold' | 'italic' | 'normal';
  click?: () => void;
}

export type TableRow = {
  [key: string]: TableCell;
}

export type TableRowArray = TableRow[];

@Component({
  standalone: true,
  selector: 'evc-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  imports: [NgClass, MatTableModule, SvgIconComponent, CellComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {
  @Output() public readonly rowClicked: EventEmitter<TableRow> = new EventEmitter<TableRow>();

  @Input() displayedColumns: string[] = [];
  @Input() columnMapping: { [key: string ]: string } = {};
  @Input() data: TableRowArray = [];
  @Input() clickableRows = false;

  public onRowClick(row: TableRow): void {
    this.rowClicked.emit(row);
  }

  public onCellClick(cell: TableCell): void {
    if (cell.click) {
      cell.click();
    }
  }
}
