<div class="evc-platform">
  <div class="evc-platform__page-content-hider"></div>
  <evc-topbar [displayApplicationsIcon]="displayApplicationsIcon"
    (login)="onLogin()"
    (logout)="onLogout()"
  ></evc-topbar>

  <div class="evc-platform__page-content">
    @if(leftbarMenus()) {
      <evc-leftbar
        class="evc-platform__leftbar"
        [leftbarConfig]="leftbarMenus()"
        (isOpenChange)="onToggleLeftBar($event)"
      >
        <ng-content select="[leftbar-top-content]" top-cta></ng-content>
      </evc-leftbar>
    }

    <div class="evc-platform__app-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>