/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { firstValueFrom, switchMap } from 'rxjs';

import type { Avatar } from '@evc/web-components';

import type { ApiSaveUserPayload, UserApi, UserApiPossibilities, UserGreenfieldApi, UserProfile } from '../../core-client/user/user.type';
import { PlatformConfigService } from '../../services/config/config.service';
import { AuthService } from '../auth/auth.service';
import { MOCK_USER_CONFIG } from '../user/user.mock';

const DEBUG_MOCK_USER = true;

const DEFAULT_USER_COLOR = '#93C6F9';
const DEFAULT_USER_CHAR = '.';

export type OptionalProfile = UserProfile | undefined;
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _destroyRef = inject(DestroyRef);
  private _profile:WritableSignal<OptionalProfile> = signal(undefined);
  private _ready = signal(false);

  // ready after fetching orgs
  public get ready():Signal<boolean> {
    return this._ready.asReadonly();
  }
  public get profile():Signal<OptionalProfile> {
    return this._profile.asReadonly();
  }
  public get isAvatarImage():boolean {
    return this.profile()?.avatar?.type === 'image';
  }
  public get isAdmin():boolean {
    return this.profile()?.role === 'admin';
  }

  constructor(
    private _auth: AuthService,
    private _http: HttpClient,
    private _config: PlatformConfigService,
  ) { }

  public setUser(user: UserApi|undefined):void {
    const profile = user ? this.computeProfile(user) : undefined;
    this._profile.set(profile);
  }

  /** fetch user profile
   * * expected to call this when aquire token (cf platform/app.component) */
  public async init(): Promise<OptionalProfile> {
    await this.updateProfile();
    this._ready.set(true);

    return this.profile();
  }

  /** fetch user info
   * - remap data to fit our app model
   * - then update our profile
   */
  public async updateProfile(): Promise<UserProfile> {
    return firstValueFrom(this.fetchUser$())
    .then(userProfile => {
      this.setUser(userProfile);

      return this.profile()!;
    })
    .catch(error => {
      this.setUser(undefined);

      throw error;
    });
  }

  public saveUser(payload:ApiSaveUserPayload): Promise<UserProfile> {
    return firstValueFrom(this.saveUser$(payload))
    .then(() => {
      const updatedProfile = {
        ...this.profile(),
        ...payload,
      };

      this.setUser(updatedProfile);

      return this.profile() as UserProfile;
    })
    .catch(_error => {
      this.setUser(undefined);

      throw _error;
    });
  }

  public fetchUser$() :Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._config.get('api')!;
    const apiUri = `${uri}${endpoints.user}`;

    return this._auth.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap(() => this._http.get<UserGreenfieldApi>(apiUri)),
    );
  }

  private saveUser$(payload:ApiSaveUserPayload): Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._config.get('api')!;
    const apiUri = `${uri}${endpoints.user}`;

    return this._auth.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap(() => this._http.put<UserGreenfieldApi>(apiUri, payload)),
    );
  }

  private computeProfile(data: UserApi): UserProfile {
    const user = data as UserApiPossibilities;

    if (DEBUG_MOCK_USER) {
      Object.keys(user).forEach((key:string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const u = user as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const m = MOCK_USER_CONFIG as any;

        if (!u[key]) u[key] = m[key];
      });
    }

    const id = user.id ?? user.tenantId;
    const role = user.role ?? 'user';

    const fromDisplayName = (user.displayName && user.displayName !== 'unknown') && user.displayName;
    const fromFormalName = (user.givenName || user.surname) && [user.givenName, user.surname].join(' ');
    const displayName = fromDisplayName || fromFormalName
      || user.email?.split('@')[0]?.split(/[.,+-]/).join(' ') || id;
    const { email } = user;

    return {
      id,
      displayName,
      givenName: user.givenName ?? '',
      surname: user.surname ?? '',
      email,
      emailVerified: user.emailVerified ?? false,
      phone: user.phone ?? '',
      preferredLanguage: user.preferredLanguage,
      address: user.address,
      role,
      avatar: this.computeAvatar({ ...user, formalName: fromFormalName || displayName }),
    };
  }

  private computeAvatar(user: UserApiPossibilities): Avatar {
    const { formalName, userImage, color } = user;
    if (userImage) {
      return {
        type: 'image',
        src: userImage,
      };
    }

    const initials = this.computeInitials(formalName!);

    const colorDefaulted = color ?? DEFAULT_USER_COLOR;
    // TODO compute light/dark text
    const isLight = !!colorDefaulted;

    return {
      type: 'initials',
      color: colorDefaulted,
      light: isLight,
      initials,
    };
  }

  private computeInitials(formalName: string): string {
    const words = formalName.split(' ') ?? [];

    const firstWord = (words.length > 0 && words[0]) || DEFAULT_USER_CHAR;
    const lastWord = words.length > 1 && words[words.length - 1];

    const firstWordLetterOne = (firstWord.length > 0 && firstWord[0].toLocaleUpperCase()) || DEFAULT_USER_CHAR;
    const firstWordLetterTwo = (firstWord.length > 1 && firstWord[1].toLocaleLowerCase()) || DEFAULT_USER_CHAR;
    const lastWordInitial = (lastWord && lastWord[0].toLocaleUpperCase()) || firstWordLetterTwo;

    return [
      firstWordLetterOne,
      lastWordInitial,
    ].join('');
  }

  public openProfile():void {
    const uri = this._config.get('uris').user;
    if (!uri) return;
    window.open(uri, '_blank');
  }
}
