/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { catchError, firstValueFrom, of, switchMap } from 'rxjs';

import type { Avatar } from '@evc/web-components';

import type { ApiOrganizationPayload, Organization } from '../../core-client/organizations/organizations.type';
import { PlatformConfigService } from '../../services/config/config.service';
import { AuthService } from '../auth/auth.service';

const DEFAULT_ORG_COLOR = '#FFC107';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private _destroyRef = inject(DestroyRef);
  private _currentOrgId:WritableSignal<string|undefined> = signal(undefined);
  public get currentOrgId():Signal<string|undefined> {
    return this._currentOrgId.asReadonly();
  }

  private _entries:WritableSignal<Organization[]> = signal([]);
  public entries = computed<Organization[]>(() => {
    const current = this._currentOrgId();

    return this._entries().map((entry) => ({
      ...entry,
      current: entry.id === current,
    }));
  });

  // ready after fetching orgs
  private _ready = signal(false);

  public get ready():Signal<boolean> {
    return this._ready.asReadonly();
  }

  public get current():Signal<Organization|undefined> {
    return computed(() => {
      if (!this._currentOrgId()) return undefined;

      return this.entries().filter((entry) => entry.id === this._currentOrgId())?.[0];
    });
  }

  constructor(
    private _auth: AuthService,
    private _http: HttpClient,
    private _config: PlatformConfigService,
  ) { }

  /**
   * simply set current org ID > trigger computed so list always with {current:boolean}
   * @param followUpLogin - true only if need to retrigger auth login (new token) (eg on org click - if greenfield)
   */
  public setCurrent(id:string | undefined, followUpLogin=false):void {
    this._currentOrgId.set(id);
    if (this._config.greenfield && id && followUpLogin) {
      this._auth.login({
        extraQueryParameters: { toid: id },
      });
    }
  }

  public setEntries(organizations:Organization[] | ApiOrganizationPayload[]):Organization[] {
    const computedOrgs: Organization[] = organizations.map((organization) => this.computeOrganization(organization));
    this._entries.set(computedOrgs);
    const current = computedOrgs.find((org) => org.current)?.id;
    if (current) {
      this.setCurrent(current);
    } else {
      this.setCurrent(computedOrgs[0]?.id);
    }

    return computedOrgs;
  }

  public addEntry(organization:Organization | ApiOrganizationPayload): Organization {
    const computedOrg: Organization = this.computeOrganization(organization);
    this._entries.update((organizations) => [...organizations, computedOrg]);
    this.setCurrent(computedOrg.id);

    return computedOrg;
  }

  /** fetch organization list and set current one
   * * expected to call this when aquire token (cf platform/app.component) */
  async init(toid?:string|undefined):Promise<void> {
    this.setCurrent(toid);
    await this.updateOrganizations();
    this._ready.set(true);
  }

  public async updateOrganizations():Promise<Organization[]> {
    return firstValueFrom(this.fetchOrganizations$())
    .then(organizations => this.setEntries(organizations));
  }

  /** create a new org (call api)
   * then add it to our entries
   * ! may only be user by organization app where it will redirect on creation success
   */
  public async createOrganization(payload:ApiOrganizationPayload): Promise<Organization> {
    return firstValueFrom(this.createOrganization$(payload))
    .then(organization => this.addEntry(organization));
  }

  public fetchOrganizations$(): Observable<ApiOrganizationPayload[]> {
    const { uri, endpoints } = this._config.get('api')!;
    const apiUri = `${uri}${endpoints.organization.list}`;

    return this._auth.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap(() => this._http.get<ApiOrganizationPayload[]>(apiUri)),
      catchError(() => of([])),
    );
  }

  private createOrganization$(payload:ApiOrganizationPayload):Observable<ApiOrganizationPayload> {
    const { uri, endpoints } = this._config.get('api')!;
    const apiUri = `${uri}${endpoints.organization.create}`;

    return this._auth.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap(() => this._http.post<ApiOrganizationPayload>(apiUri, payload)),
    );
  }

  /** post api returns different format that the get one
   * here we ensure we have the same format
   */
  private computeOrganization(data: Partial<ApiOrganizationPayload & Organization>): Organization {
    const id = data.id || data.organizationId;
    const name = data.name ?? '';
    const logo = data.logo;

    if (!name) {
      throw new Error('Organization name is required');
    }

    if (!id) {
      throw new Error('Organization id is required');
    }

    return {
      id,
      name,
      current: data.current ?? false,
      avatar: data.avatar ?? this.generateOrgAvatar(name, logo),
    };
  }

  private generateOrgAvatar(name: string, logo?: string): Avatar {
    if (logo) {
      return {
        type: 'image',
        src: logo,
      };
    } else {
      return {
        type: 'initials',
        color: DEFAULT_ORG_COLOR,
        light: true,
        initials: this.computeOrgInitials(name),
      };
    }
  }

  private computeOrgInitials(name: string): string {
    return name.split(' ')
      .map((word) => word[0].toUpperCase())
      .join('')
      .slice(0, 2);
  }
}
