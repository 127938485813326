import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressFormComponent } from '@shared/components/address-form/address-form.component';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import type { FormDataAddress, FormModelProfile } from '@shared/types/profile-form.type';

import type { Address } from '@evc/platform';
import { UserService } from '@evc/platform';
import type {
  Avatar,
  IconListType,
  Maybe } from '@evc/web-components';
import {
  AvatarComponent,
  InputComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

import { COUNTRIES } from '../../mocks/user.mock';

@Component({
  standalone: true,
  selector: 'evc-usr-infos',
  templateUrl: './usr-infos.component.html',
  styleUrls: ['./usr-infos.component.scss'],
  imports: [
    AvatarComponent,
    AddressFormComponent,
    ReactiveFormsModule,
    TranslatePipe,
    FormsModule,
    NavButtonComponent,
    SvgIconComponent,
    InputComponent,
],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class UsrInfosComponent implements OnInit {
  @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent<FormGroup<FormModelProfile>> | undefined;

  @Input({ required: true }) formGroupParent!: FormGroup<FormModelProfile>;
  @Input({ required: true }) submited = signal(false);
  @Input() public isEditing = signal(false);

  private readonly _userService = inject(UserService);
  private readonly _destroyRef = inject(DestroyRef);

  public countries = COUNTRIES;
  public addressValue = signal<Partial<Address> | undefined>(undefined);
  public isEditingAddress = signal(false);
  public shouldDisplayAddress = computed(() => this.currentAddress() || (this.isEditing() || this.addressValue()));
  public addressCTA = computed((): {
    label: string,
    icon: IconListType,
    type: 'error-tertiary' | 'tertiary',
    action: (event: Event) => void,
  } => {
    if (this.addressValue()) {
      if (this.isEditing()) {
        return { label: 'cancel', icon: 'xmark', type: 'error-tertiary', action: (event: Event) => this.resetUserAddress(event) };
      } else {
        return { label: 'remove', icon: 'xmark', type: 'error-tertiary', action: (event: Event) => this.removeAddress(event) };
      }
    } else {
      if (this.currentAddress() && this.formGroupParent.dirty) {
        return { label: 'undo', icon: 'arrow-rotate-left', type: 'tertiary', action: (event: Event) => this.resetUserAddress(event) };
      } else {
        return { label: 'personal_info.cta_address', icon: 'plus', type: 'tertiary', action: (event: Event) => this.toggleAddress(event) };
      }
    }
  });
  public userAvatar = computed<Maybe<Avatar>>(() => this._userService.profile()?.avatar);

  public ngOnInit(): void {
    this.formGroupParent.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((changes) => {
      this.addressValue.set(changes.address);
    });
  }

  public get email(): Signal<string> {
    return computed(() => this._userService.profile()?.email ?? '');
  }

  public get currentAddress(): Signal<FormDataAddress|undefined> {
    return computed(() => this._userService.profile()?.address ?? undefined);
  }

  public toggleAddress(event: Event, force?:boolean): void {
    event.preventDefault();
    this.isEditing.update((value) => force === undefined ? !value : force);
  }

  public resetUserAddress(event: Event): void {
    event.preventDefault();
    this.addressFormComponent?.resetAddress();
    this.toggleAddress(event);
    this.formGroupParent.controls.address?.markAsPristine();
  }

  public removeAddress(event: Event): void {
    event.preventDefault();
    this.addressFormComponent?.removeAddress();
    this.toggleAddress(event);
  }

  public changeUserPicture(event: Event): void {
    event.preventDefault();
  }
}
