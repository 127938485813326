<div class="leftbar" [id]="id" [ngClass]="{ 'leftbar--collapsed': !isOpen }">
  <div class="leftbar__container">
    <ng-content select="[top-cta]"></ng-content>
    <div class="leftbar__tabs-container" evcIsScrollable [scrollPosition]="'left'">
      <div class="leftbar__tabs-content">
        @for (section of leftbarConfig; track sectionIndex; let sectionIndex = $index) {
          @if (section.title) {
            @if (sectionIndex > 0 && sectionIndex < getConfigLength()) {
              <div class="leftbar__divider"></div>
            }
            <div class="leftbar__section-title">{{ section.title }}</div>
          }
          @if (section.badgeItems) {
            @if (getCurrentOrganization(section.badgeItems)(); as currentOrganization){
              <evc-badge-button
                [currentText]="currentOrganization.name"
                [avatar]="currentOrganization.avatar"
                [role]="profile()?.role ?? ''"
                (click)="handleOrganizationBtnClick()"
              >
              <evc-svg-icon size="md" [icon]="showOrganizationsMenu ? 'angle-up' : 'angle-down'"></evc-svg-icon>
            </evc-badge-button>
            }

          }
          @for(item of section.items; track itemIndex; let itemIndex = $index) {
            <button
              class="leftbar__tab"
              [ngClass]="{ 'leftbar__tab--selected': isItemSelected(item) }"
              [disabled]="isTabDisabled(item.key)"
              [evcTooltipHover]="item.text"
              [evcTooltipVisible]="showTooltip"
              (click)="handleItemClick(item)"
            >
              <evc-svg-icon size="md" [icon]="getItemIcon(item)" />
              <span class="leftbar__tab-text" [ngClass]="{ 'leftbar__tab-text--collapsed': !isOpen }">
                {{ item.text }}
              </span>
            </button>
          }
        }
      </div>
    </div>
  </div>
  <ng-content select="[leftbar-bottom-content]"></ng-content>
  <div class="leftbar__state-icon">
    <evc-button type="icon" size="sm" variant="semi" (click)="toggleLeftBar()">
      <evc-svg-icon class="leftbar__state-icon" size="md" [icon]="getStateIcon()"></evc-svg-icon>
    </evc-button>
  </div>
</div>

@if (showOrganizationsMenu) {
    <evc-dropdown-menu
      class="leftbar__organizations-menu"
      evcCloseOnClickOutside
      [orgMenuConfig]="getOrganizationsMenuConfig()"
      [showOnlyBadgeItems]="true"
      [showTitle]="false"
      (clickedOutside)="handleHideOrgMenu()"
      (organizationClicked)="handleOrganizationClicked($event)"
    ></evc-dropdown-menu>
}
