export enum IconEnum {
  AngleDown = 'angle-down',
  AngleLeft = 'angle-left',
  AngleRight = 'angle-right',
  AngleUp = 'angle-up',
  Apps = 'apps',
  ArrowDownToBracket = 'arrow-down-to-bracket',
  ArrowDown = 'arrow-down',
  ArrowRightFromBracket = 'arrow-right-from-bracket',
  ArrowRotateLeft = 'arrow-rotate-left',
  ArrowRotateRight = 'arrow-rotate-right',
  ArrowUpRightFromSquare = 'arrow-up-right-from-square',
  ArrowUpToBracket = 'arrow-up-to-bracket',
  ArrowUp = 'arrow-up',
  ArrowsRotateReverse = 'arrows-rotate-reverse',
  ArrowsUpDownLeftRight = 'arrows-up-down-left-right',
  Ban = 'ban',
  BarsFilter = 'bars-filter',
  Bars = 'bars',
  BasketShoppingSimple = 'basket-shopping-simple',
  BasketShopping = 'basket-shopping',
  Bookmark = 'bookmark',
  BoxArchive = 'box-archive',
  CalendarLines = 'calendar-lines',
  CaretDown = 'caret-down',
  CaretUp = 'caret-up',
  CartShopping = 'cart-shopping',
  Certificate = 'certificate',
  Check = 'check',
  Check_solid = 'check_solid',
  ChocoMenu = 'choco-menu',
  CircleCheckSolid = 'circle-check-solid',
  CircleCheck = 'circle-check',
  CircleDot = 'circle-dot',
  CircleExclamationSolid = 'circle-exclamation-solid',
  CircleExclamation = 'circle-exclamation',
  CircleInfo = 'circle-info',
  CircleQuestion = 'circle-question',
  CircleSmall = 'circle-small',
  CircleThreeQuarters = 'circle-three-quarters',
  CircleUser = 'circle-user',
  Circle = 'circle',
  ClipboardList = 'clipboard-list',
  ClipboardUser = 'clipboard-user',
  ClockRotateLeft = 'clock-rotate-left',
  Copy = 'copy',
  Copyright = 'copyright',
  Ellipsis = 'ellipsis',
  EqualsVertical = 'equals-vertical',
  Equals = 'equals',
  Eye = 'eye',
  FileArrowUpSolid = 'file-arrow-up-solid',
  FileArrowUp = 'file-arrow-up',
  FileCsv = 'file-csv',
  FileDoc = 'file-doc',
  FileExport = 'file-export',
  FileJpg = 'file-jpg',
  FileMov = 'file-mov',
  FileMp4 = 'file-mp4',
  FileNde = 'file-nde',
  FilePdf = 'file-pdf',
  FilePng = 'file-png',
  FilePpt = 'file-ppt',
  FileSetup = 'file-setup',
  FileSvg = 'file-svg',
  FileXls = 'file-xls',
  FileZip = 'file-zip',
  File = 'file',
  Filter = 'filter',
  FloppyDisk = 'floppy-disk',
  FolderOpen = 'folder-open',
  FolderSolid = 'folder-solid',
  Folder = 'folder',
  Folders = 'folders',
  GearComplex = 'gear-complex',
  GridView = 'grid-view',
  HouseBlank = 'house-blank',
  Hyphen = 'hyphen',
  Images = 'images',
  InputNumeric = 'input-numeric',
  LaptopMobile = 'laptop-mobile',
  LinkSimple = 'link-simple',
  ListUl = 'list-ul',
  LockKeyhole = 'lock-keyhole',
  LockOpen = 'lock-open',
  Lock = 'lock',
  Lock_solid = 'lock_solid',
  MagnifyingGlassMinus = 'magnifying-glass-minus',
  MagnifyingGlassPlus = 'magnifying-glass-plus',
  MagnifyingGlass = 'magnifying-glass',
  MessageExclamation = 'message-exclamation',
  Message = 'message',
  Messages = 'messages',
  PaperPlaneTop = 'paper-plane-top',
  Pause = 'pause',
  PenCircle = 'pen-circle',
  PenLine = 'pen-line',
  PenSwirl = 'pen-swirl',
  PenToSquare = 'pen-to-square',
  Pen = 'pen',
  Percent = 'percent',
  Play = 'play',
  Plus = 'plus',
  Primary = 'primary',
  ShareFromSquare = 'share-from-square',
  ShareNodes = 'share-nodes',
  SignalStream = 'signal-stream',
  Sprite = 'sprite',
  SquareCheck = 'square-check',
  SquareMinus = 'square-minus',
  SquarePlus = 'square-plus',
  Square = 'square',
  Stamp = 'stamp',
  TrashCanList = 'trash-can-list',
  TrashCanUndo = 'trash-can-undo',
  TrashCan = 'trash-can',
  TriangleExclamation = 'triangle-exclamation',
  UserHelmetSafety = 'user-helmet-safety',
  UserPlus = 'user-plus',
  User = 'user',
  UsersMedical = 'users-medical',
  WaveformLines = 'waveform-lines',
  Xmark = 'xmark',
  Live = 'live',
  Blueprint = 'blueprint',
}

export type IconListType = `${IconEnum}`;
