import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  InputComponent,
  Maybe,
} from '@evc/web-components';

import { COUNTRIES } from '../../../assets/countries';
import { EvcFormService } from '../../reactive-form/reactive-form.service';
import type { FormGroupWithControls } from '../../reactive-form/types/reactive-form.type';
import { EvcValidatorsService, patterns } from '../../reactive-form/validators/validator.service';
import { TranslatePipe } from '../../services/i18n/i18n.module';
import type { FormDataAddress, FormModelAddress } from '../../types/profile-form.type';

export type StepAddressFormGroupWithControls = FormGroupWithControls<{
  address?: FormGroup<FormModelAddress>
}>

@Component({
  standalone: true,
  selector: 'evc-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    FormsModule,
    InputComponent,
],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class AddressFormComponent<TFormGroupParent extends StepAddressFormGroupWithControls> implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true }) submited = signal(false);
  @Input({ required: true }) formGroupParent!:TFormGroupParent;
  @Input() currentAddress: Maybe<FormDataAddress> = undefined;
  @Input() parentComponentName = '';
  @Input() addressValue: WritableSignal<Partial<FormDataAddress> | undefined> = signal(undefined);

  public model = signal<FormDataAddress>({
    country: '',
    province: '',
    street: '',
    postalCode: '',
    city: '',
  });

  public fields:string[] = [];
  public formGroup!: FormGroup<FormModelAddress>;
  public countries = COUNTRIES;
  public currentCountry:WritableSignal<Maybe<string>> = signal(undefined);
  public states = computed(() => {
    const country = this.currentCountry();
    if (!country) return [];

    return COUNTRIES.find(c => c.value === country)?.regions || [];
  });

  public showError = computed(() => this.submited() && !!this.addressValue());

  private _destroyRef = inject(DestroyRef);
  private _formBuilder = inject(FormBuilder);
  private _formService = inject(EvcFormService<FormDataAddress>);
  private _validatorService = inject(EvcValidatorsService);

  public get formgroup(): FormGroup<FormModelAddress> {
    return this.formGroup;
  }

  /** may create it's own formGroup and inject to main parent form
   * if already exist resuse it to keep current state
   */
  public ngOnInit(): void {
    this.fields = Object.keys(this.model);

    const currentAddressControls = this.formGroupParent.controls.address;
    if (currentAddressControls) {
      this.formGroup = currentAddressControls;

      return;
    }

    this.formGroup = this.createControls();
    this.formGroupParent.setControl('address', this.formGroup);

    if (this.currentAddress) {
      this.formGroup.patchValue(this.currentAddress);
    }

    this.formGroup.valueChanges.subscribe(() => {
      if (!this.formGroupParent.controls.address) {
        this.formGroupParent.setControl('address', this.formGroup);
      }
    });

    return;
  }

  public ngAfterViewInit(): void {
    this.syncCountryStates();
  }

  public resetAddress():void {
    if (this.currentAddress) {
      this.formGroupParent.setControl('address', this.formGroup);
      this.formGroup.patchValue(this.currentAddress);
    } else {
      this.formGroup.markAsPristine();
      this.formGroupParent.removeControl('address');
    }
  }

  public removeAddress():void {
    this.formGroup.setValue({
      country: '',
      province: '',
      street: '',
      postalCode: '',
      city: '',
    });
    this.formGroupParent.markAsDirty();
    this.formGroupParent.removeControl('address');
  }

  public ngOnDestroy():void {
    this.formGroupParent.removeControl('address');
  }

  private createControls(): FormGroup<FormModelAddress> {
    if (!this.model) {
      throw new Error('Model is required to create form controls');
    }
    const control = this._formService.controlFactory(this._formBuilder.nonNullable, this.model());

    return this._formBuilder.group<FormModelAddress>({
      country: control('country'),
      province: control('province'),
      street: control('street', [this._validatorService.pattern(patterns.alphanumericExtended)]),
      postalCode: control('postalCode', [this._validatorService.zipCode()]),
      city: control('city', [this._validatorService.pattern(patterns.alphanumericExtended)]),
    });
  }

  private syncCountryStates():void {
    const countryField = this.formGroup.get('country')!;
    const currentCountry = countryField.value;
    if (currentCountry) {
      this.currentCountry.set(currentCountry);
    }

    countryField.valueChanges
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe(value => {
      this.currentCountry.set(value ?? undefined);
      this.formGroup.get('province')!.setValue('');
    });
  }
}
