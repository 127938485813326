/**
 * Platform Config Service
 * ============================
 *
 * extends our baseConfig but focus only on Platform config here
 * - typed config
 * - inject defaults for platform
 * - inject env variables
 */
import type { Signal } from '@angular/core';
import { computed, Inject, Injectable, Optional } from '@angular/core';

import type { Menu, MenuItemType } from '@evc/web-components';
import { ConfigService, deepMerge, ENV_VARIABLES, ModalService, ThemeService } from '@evc/web-components';

import { AUTH_CONFIG } from '../../core-client/auth/auth.providers';
import { PlatformEnv } from '../../providers/env.type';
import type { AppIdentity } from '../../types/app-identity.type';
import type { ApplicationType } from '../../types/user-menu.type';
import { defaultPlatformConfigFactory, getApiConfig, getI18nConfig } from './config.default';
import type { PlatformConfig } from './config.type';
import { AuthConfig } from './config.type';

/** remap .env config in final config format */
function envToConfig(environment:Partial<PlatformEnv>):Partial<PlatformConfig> {
  const { ENV, APP_NAME, APP_TITLE, APP_LOGO, GREENFIELD } = environment;

  const appIdentity:AppIdentity = {
    name: APP_NAME,
    title: APP_TITLE || APP_NAME,
    logo: APP_LOGO,
  };

  return {
    env: ENV,
    greenfield: GREENFIELD,
    app: appIdentity,
  } as Partial<PlatformConfig>; // enforce because will be nice after merge
}

@Injectable({
  providedIn: 'root',
})
export class PlatformConfigService extends ConfigService<PlatformConfig> {
  constructor(
    @Optional() @Inject(ENV_VARIABLES) private envVars: PlatformEnv,
    @Optional() @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    themeService:ThemeService,
    modalService:ModalService,
  ) {
    const environment = envVars ?? { ENV: 'production' };

    const defaultPlatformConfig = defaultPlatformConfigFactory(environment, themeService, modalService);
    const auth = authConfig;
    const api = getApiConfig(environment);
    const i18n = getI18nConfig(environment);

    const config = deepMerge(
      defaultPlatformConfig,
      envToConfig(environment),
      {
        auth,
        api,
        i18n,
      },
    ) as PlatformConfig;

    super(config, environment);
  }
  /** may init flows diffently if greenfield or custom
   * - greenfield, init auth, users, organizations etc
   * - custom, we may just wait config to be provided by host app
   */
  get greenfield():boolean {
    return this.get('greenfield') === true;
  }

  /** we may extract `applications` from our usermodel vs platformComponent input
   * so this config handle this shortcut
   */
  get applications(): ApplicationType[] {
    return this.get('applications') ?? [];
  }

  get appIdentity(): Signal<AppIdentity> {
    return computed(() => {
      const { title, logo } = this.getComputed('app')();

      return { title, logo };
    });
  }

  getMenu(type:keyof PlatformConfig['menus']): Signal<Menu[]|Menu> {
    return computed(() => {
      const menus = this.getComputed('menus', [])();

      return menus[type];
    });
  }

  /** Merge menu items - override if same `key`
   * * expect change soon here to unify all menus - actualy those are the only one with default + group title+items
   */
  updateMenu(type:'settings'|'help', items:MenuItemType[]):void {
    const menus = { ...this.get('menus') };
    const menu = menus[type];
    if (!menu) return;

    // merge all items - override if same key
    const existingItems = menu.items || [];
    const itemMap: Map<string, MenuItemType> = new Map();
    existingItems.forEach(item => itemMap.set(item.key, item));
    items.forEach(item => itemMap.set(item.key, item));

    this._config.update((_config) => {
      const config = { ..._config };
      config.menus[type].items = Array.from(itemMap.values());

      return config;
    });
  }
}
