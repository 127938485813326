import { ENVIRONMENT } from '@evc/platform';
import type { AppEnv } from '@app/types/config.type';

export { ENVIRONMENT } from '@evc/platform';

export const environment: AppEnv = {
  APP_NAME: 'user-account',
  ENV: ENVIRONMENT.production,
  GREENFIELD: true,
};
