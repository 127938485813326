<aside class="user-menu">
  @if (userProfile(); as profile) {
    <header class="user-menu__general-info">
      <p class="user-menu__title"> {{ getTimeOfDay() }}, <span class="user-menu__title_nowrap">{{ profile.displayName }} !</span></p>
      <p class="user-menu__email">{{ profile.email }}</p>
      <div class="user-menu__user">
        <evc-button
          type="link"
          leftIcon="arrow-up-right-from-square"
          (click)="openUserProfile()">
          <p>User Account</p>
        </evc-button>
      </div>
    </header>

    <div class="user-menu__divider"></div>

    <section class="user-menu__organizations">
      <p class="user-menu__subtitle">Organizations</p>
      <div class="user-menu__organizations_list" [attr.data-scrollable]="organizations().length > MAX_ORGANIZATIONS_BEFORE_SCROLL">
        @for(organization of organizations(); track organization.id){
          <evc-menu-item
            [selected]="!!organization.current"
            [badgeItem]="organization"
            [aditionalText]="organization.roles?.join(', ')"
            (click)="onOrgClicked(organization)"
          ></evc-menu-item>
        }
        <evc-button class="user-menu__add-org" type="tertiary" (click)="addOrganization()">
          <div class="user-menu__add-org-content">
            <evc-svg-icon size="sm" icon="plus" class="user-menu__add-org-icon"></evc-svg-icon>
            <span class="user-menu__add-org-text">Add Organization</span>
          </div>
        </evc-button>
      </div>
    </section>

    <div class="user-menu__divider"></div>

    <footer class="user-menu__action">
      <evc-button type="tertiary"
        [leftIcon]="'arrow-right-from-bracket'"
        (click)="onLogout()">
        <span class="user-menu__btn-text">Log Out</span>
      </evc-button>
    </footer>
  }@else {
    <header class="user-menu__general-info">
      <p class="user-menu__title"> {{ getTimeOfDay() }} !</p>
    </header>
    <div class="user-menu__divider"></div>
    <footer class="user-menu__action">
      <evc-button type="tertiary"
        [leftIcon]="'arrow-right-from-bracket'"
        (click)="onLogin()">
        <span class="user-menu__btn-text">Log In</span>
      </evc-button>
    </footer>
  }
</aside>
