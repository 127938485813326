import { NgClass } from '@angular/common';
import type { Signal } from '@angular/core';
import { Component, computed, EventEmitter, Input, Output } from '@angular/core';

import type { Avatar, Maybe, Menu, MenuItemType } from '@evc/web-components';
import {
  AvatarComponent,
  CloseOnClickOutsideDirective,
  DropdownMenuComponent,
  GradientBorderMaskComponent,
  ModalService,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

import { AboutModalComponent } from '../../components/about-modal/about-modal.component';
import { AppsMenuComponent } from '../../components/apps-menu/apps-menu.component';
import { HeaderComponent } from '../../components/header/header.component';
import { SearchComponent } from '../../components/search/search.component';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import type { Organization } from '../../core-client/organizations/organizations.type';
import type { OptionalProfile } from '../../core-client/user/user.service';
import { UserService } from '../../core-client/user/user.service';
import { PlatformConfigService } from '../../services/config/config.service';
import { SearchService } from '../../services/search/search.service';
import type { SearchFn } from '../../services/search/search.type';
import type { AppIdentity } from '../../types/app-identity.type';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- investigate CLOUD-816
@Component({
  selector: 'evc-topbar',
  standalone: true,
  imports: [
    AvatarComponent,
    AboutModalComponent,
    DropdownMenuComponent,
    HeaderComponent,
    UserMenuComponent,
    CloseOnClickOutsideDirective,
    AppsMenuComponent,
    SvgIconComponent,
    GradientBorderMaskComponent,
    NavButtonComponent,
    SearchComponent,
    NgClass,
  ],
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @Output() public readonly login: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();

  @Input() public displayApplicationsIcon = true;
  public showSettingsMenu = false;
  public showHelpMenu = false;
  public showAppsMenu = false;
  public showUserMenu = false;
  public isLeftBarOpen = true;
  public showSearch = false;

  public userProfile: Signal<OptionalProfile>;
  public currentOrganization: Organization | undefined;
  public userAvatar = computed<Maybe<Avatar>>(() => this._userService.profile()?.avatar);

  public get appIdentity(): Signal<AppIdentity> {
    return this._platformConfigService.getComputed('app');
  }
  public get topTabs(): Signal<MenuItemType[] | undefined> {
    return this._platformConfigService.getComputed('topTabs', []);
  }
  public get settingsMenu(): Signal<Menu> {
    return this._platformConfigService.getMenu('settings');
  }
  public get helpMenu(): Signal<Menu> {
    return this._platformConfigService.getMenu('help');
  }
  public get search(): SearchFn | undefined {
    return this._searchService.search;
  }

  public get currentOrgLogo(): string | undefined {
    if (this.currentOrganization?.avatar.type === 'image') {
      return this.currentOrganization.avatar.src;
    }

    return undefined;
  }

  constructor(
    private readonly _organizationsService: OrganizationsService,
    private readonly _userService: UserService,
    private readonly _modalService: ModalService,
    private readonly _searchService: SearchService,
    private readonly _platformConfigService: PlatformConfigService,
  ) {
    this.userProfile = this._userService.profile;
  }

  public toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  public toggleMenu(menu: string): void {
    switch (menu) {
      case 'help-btn': {
        this.showHelpMenu = !this.showHelpMenu;
        this.showSettingsMenu = false;
        this.showAppsMenu = false;
        this.showUserMenu = false;
        break;
      }

      case 'setting-btn': {
        this.showSettingsMenu = !this.showSettingsMenu;
        this.showHelpMenu = false;
        this.showAppsMenu = false;
        this.showUserMenu = false;
        break;
      }

      case 'apps-btn': {
        this.showAppsMenu = !this.showAppsMenu;
        this.showSettingsMenu = false;
        this.showHelpMenu = false;
        this.showUserMenu = false;
        break;
      }

      case 'user-btn': {
        this.showUserMenu = !this.showUserMenu;
        this.showSettingsMenu = false;
        this.showHelpMenu = false;
        this.showAppsMenu = false;
        break;
      }

      default: {
        this.showSettingsMenu = false;
        this.showHelpMenu = false;
        this.showAppsMenu = false;
        this.showUserMenu = false;
        break;
      }
    }
  }

  public handleHideMenu(): void {
    this.showSettingsMenu = false;
    this.showHelpMenu = false;
    this.showAppsMenu = false;
    this.showUserMenu = false;
  }

  public handleLogin(): void {
    this.login.emit();
  }

  public handleLogout(): void {
    this.logout.emit();
  }

  public getShownModal(modalName: 'about' | 'trash'): boolean {
    return this._modalService.currentShownModal === modalName;
  }

  public hideModal(): void {
    this._modalService.closeCurrentModal();
  }

  public getCurrentOrganizationName(): string | undefined {
    return this._organizationsService?.current()?.name;
  }
}
