import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

import { ApplicationsPageComponent } from './applications/applications.component';
import { ProfilePageComponent } from './profile/profile.component';
import { SecurityPrivacyPageComponent } from './secu-privacy/secu-privacy.component';

export const appRoutes: Route[] = [
    {
      path: '',
      pathMatch: 'full',
      component: ProfilePageComponent,
      canActivate: [MsalGuard],
    },
    {
      path: 'security-privacy',
      component: SecurityPrivacyPageComponent,
      canActivate: [MsalGuard],
    },
    {
      path: 'applications',
      component: ApplicationsPageComponent,
      canActivate: [MsalGuard],
    },
    {
      path: '**',
      component: PageNotFoundComponent,
      canActivate: [MsalGuard],
    },
  ];
