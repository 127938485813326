export const COUNTRIES = [
    {
        label: 'Afghanistan',
        phoneCode: '+93',
        value: 'AF',
        countryFlag: '🇦🇫',
        regions: [
            {
                label: 'Badakhshan',
                value: 'BDS',
            },
            {
                label: 'Badghis',
                value: 'BDG',
            },
            {
                label: 'Baghlan',
                value: 'BGL',
            },
            {
                label: 'Balkh',
                value: 'BAL',
            },
            {
                label: 'Bamyan',
                value: 'BAM',
            },
            {
                label: 'Daykundi',
                value: 'DAY',
            },
            {
                label: 'Farah',
                value: 'FRA',
            },
            {
                label: 'Faryab',
                value: 'FYB',
            },
            {
                label: 'Ghazni',
                value: 'GHA',
            },
            {
                label: 'Ghor',
                value: 'GHO',
            },
            {
                label: 'Helmand',
                value: 'HEL',
            },
            {
                label: 'Herat',
                value: 'HER',
            },
            {
                label: 'Jowzjan',
                value: 'JOW',
            },
            {
                label: 'Kabul',
                value: 'KAB',
            },
            {
                label: 'Kandahar',
                value: 'KAN',
            },
            {
                label: 'Kapisa',
                value: 'KAP',
            },
            {
                label: 'Khost',
                value: 'KHO',
            },
            {
                label: 'Kunar',
                value: 'KNR',
            },
            {
                label: 'Kunduz',
                value: 'KDZ',
            },
            {
                label: 'Laghman',
                value: 'LAG',
            },
            {
                label: 'Logar',
                value: 'LOW',
            },
            {
                label: 'Maidan Wardak',
                value: 'WAR',
            },
            {
                label: 'Nangarhar',
                value: 'NAN',
            },
            {
                label: 'Nimruz',
                value: 'NIM',
            },
            {
                label: 'Nuristan',
                value: 'NUR',
            },
            {
                label: 'Paktia',
                value: 'PIA',
            },
            {
                label: 'Paktika',
                value: 'PKA',
            },
            {
                label: 'Panjshir',
                value: 'PAN',
            },
            {
                label: 'Parwan',
                value: 'PAR',
            },
            {
                label: 'Samangan',
                value: 'SAM',
            },
            {
                label: 'Sar-e Pol',
                value: 'SAR',
            },
            {
                label: 'Takhar',
                value: 'TAK',
            },
            {
                label: 'Urozgan',
                value: 'ORU',
            },
            {
                label: 'Zabul',
                value: 'ZAB',
            },
        ],
    },
    {
        label: 'Åland Islands',
        phoneCode: '+358',
        value: 'AX',
        countryFlag: '🇦🇽',
        regions: [
            {
                label: 'Brändö',
                value: 'BR',
            },
            {
                label: 'Eckerö',
                value: 'EC',
            },
            {
                label: 'Finström',
                value: 'FN',
            },
            {
                label: 'Föglö',
                value: 'FG',
            },
            {
                label: 'Geta',
                value: 'GT',
            },
            {
                label: 'Hammarland',
                value: 'HM',
            },
            {
                label: 'Jomala',
                value: 'JM',
            },
            {
                label: 'Kumlinge',
                value: 'KM',
            },
            {
                label: 'Kökar',
                value: 'KK',
            },
            {
                label: 'Lemland',
                value: 'LE',
            },
            {
                label: 'Lumparland',
                value: 'LU',
            },
            {
                label: 'Mariehamn',
                value: 'MH',
            },
            {
                label: 'Saltvik',
                value: 'SV',
            },
            {
                label: 'Sottunga',
                value: 'ST',
            },
            {
                label: 'Sund',
                value: 'SD',
            },
            {
                label: 'Vårdö',
                value: 'VR',
            },
        ],
    },
    {
        label: 'Albania',
        phoneCode: '+355',
        value: 'AL',
        countryFlag: '🇦🇱',
        regions: [
            {
                label: 'Berat',
                value: '01',
            },
            {
                label: 'Dibër',
                value: '09',
            },
            {
                label: 'Durrës',
                value: '02',
            },
            {
                label: 'Elbasan',
                value: '03',
            },
            {
                label: 'Fier',
                value: '04',
            },
            {
                label: 'Gjirokastër',
                value: '05',
            },
            {
                label: 'Korçë',
                value: '06',
            },
            {
                label: 'Kukës',
                value: '07',
            },
            {
                label: 'Lezhë',
                value: '08',
            },
            {
                label: 'Shkodër',
                value: '10',
            },
            {
                label: 'Tirana',
                value: '11',
            },
            {
                label: 'Vlorë',
                value: '12',
            },
        ],
    },
    {
        label: 'Algeria',
        phoneCode: '+213',
        value: 'DZ',
        countryFlag: '🇩🇿',
        regions: [
            {
                label: 'Adrar',
                value: '01',
            },
            {
                label: 'Aïn Defla',
                value: '44',
            },
            {
                label: 'Aïn Témouchent',
                value: '46',
            },
            {
                label: 'Algiers',
                value: '16',
            },
            {
                label: 'Annaba',
                value: '23',
            },
            {
                label: 'Batna',
                value: '05',
            },
            {
                label: 'Béchar',
                value: '08',
            },
            {
                label: 'Béjaïa',
                value: '06',
            },
            {
                label: 'Biskra',
                value: '07',
            },
            {
                label: 'Blida',
                value: '09',
            },
            {
                label: 'Bordj Bou Arréridj',
                value: '34',
            },
            {
                label: 'Bouïra',
                value: '10',
            },
            {
                label: 'Boumerdès',
                value: '35',
            },
            {
                label: 'Chlef',
                value: '02',
            },
            {
                label: 'Constantine',
                value: '25',
            },
            {
                label: 'Djelfa',
                value: '17',
            },
            {
                label: 'El Bayadh',
                value: '32',
            },
            {
                label: 'El Oued',
                value: '39',
            },
            {
                label: 'El Tarf',
                value: '36',
            },
            {
                label: 'Ghardaïa',
                value: '47',
            },
            {
                label: 'Guelma',
                value: '24',
            },
            {
                label: 'Illizi',
                value: '33',
            },
            {
                label: 'Jijel',
                value: '18',
            },
            {
                label: 'Khenchela',
                value: '40',
            },
            {
                label: 'Laghouat',
                value: '03',
            },
            {
                label: 'Mascara',
                value: '29',
            },
            {
                label: 'Médéa',
                value: '26',
            },
            {
                label: 'Mila',
                value: '43',
            },
            {
                label: 'Mostaganem',
                value: '27',
            },
            {
                label: 'Msila',
                value: '28',
            },
            {
                label: 'Naâma',
                value: '45',
            },
            {
                label: 'Oran',
                value: '31',
            },
            {
                label: 'Ouargla',
                value: '30',
            },
            {
                label: 'Oum el Bouaghi',
                value: '04',
            },
            {
                label: 'Relizane',
                value: '48',
            },
            {
                label: 'Saïda',
                value: '20',
            },
            {
                label: 'Sétif',
                value: '19',
            },
            {
                label: 'Sidi Bel Abbès',
                value: '22',
            },
            {
                label: 'Skikda',
                value: '21',
            },
            {
                label: 'Souk Ahras',
                value: '41',
            },
            {
                label: 'Tamanghasset',
                value: '11',
            },
            {
                label: 'Tébessa',
                value: '12',
            },
            {
                label: 'Tiaret',
                value: '14',
            },
            {
                label: 'Tindouf',
                value: '37',
            },
            {
                label: 'Tipaza',
                value: '42',
            },
            {
                label: 'Tissemsilt',
                value: '38',
            },
            {
                label: 'Tizi Ouzou',
                value: '15',
            },
            {
                label: 'Tlemcen',
                value: '13',
            },
        ],
    },
    {
        label: 'American Samoa',
        phoneCode: '+1684',
        value: 'AS',
        countryFlag: '🇦🇸',
        regions: [
            {
                label: 'Tutuila',
                value: '01',
            },
            {
                label: 'Aunu\'u',
                value: '02',
            },
            {
                label: 'Ta\'ū',
                value: '03',
            },
            {
                label: 'Ofu‑Olosega',
                value: '04',
            },
            {
                label: 'Rose Atoll',
                value: '21',
            },
            {
                label: 'Swains Island',
                value: '22',
            },
        ],
    },
    {
        label: 'Andorra',
        phoneCode: '+376',
        value: 'AD',
        countryFlag: '🇦🇩',
        regions: [
            {
                label: 'Andorra la Vella',
                value: '07',
            },
            {
                label: 'Canillo',
                value: '02',
            },
            {
                label: 'Encamp',
                value: '03',
            },
            {
                label: 'Escaldes-Engordany',
                value: '08',
            },
            {
                label: 'La Massana',
                value: '04',
            },
            {
                label: 'Ordino',
                value: '05',
            },
            {
                label: 'Sant Julià de Lòria',
                value: '06',
            },
        ],
    },
    {
        label: 'Angola',
        phoneCode: '+244',
        value: 'AO',
        countryFlag: '🇦🇴',
        regions: [
            {
                label: 'Bengo',
                value: 'BGO',
            },
            {
                label: 'Benguela',
                value: 'BGU',
            },
            {
                label: 'Bié',
                value: 'BIE',
            },
            {
                label: 'Cabinda',
                value: 'CAB',
            },
            {
                label: 'Cuando Cubango',
                value: 'CCU',
            },
            {
                label: 'Cuanza Norte',
                value: 'CNO',
            },
            {
                label: 'Cuanza Sul',
                value: 'CUS',
            },
            {
                label: 'Cunene',
                value: 'CNN',
            },
            {
                label: 'Huambo',
                value: 'HUA',
            },
            {
                label: 'Huíla',
                value: 'HUI',
            },
            {
                label: 'Luanda',
                value: 'LUA',
            },
            {
                label: 'Lunda Norte',
                value: 'LNO',
            },
            {
                label: 'Lunda Sul',
                value: 'LSU',
            },
            {
                label: 'Malanje',
                value: 'MAL',
            },
            {
                label: 'Moxico',
                value: 'MOX',
            },
            {
                label: 'Namibe',
                value: 'NAM',
            },
            {
                label: 'Uíge',
                value: 'UIG',
            },
            {
                label: 'Zaire',
                value: 'ZAI',
            },
        ],
    },
    {
        label: 'Anguilla',
        phoneCode: '+1264',
        value: 'AI',
        countryFlag: '🇦🇮',
        regions: [
            {
                label: 'Anguilla',
                value: '01',
            },
            {
                label: 'Anguillita Island',
                value: '02',
            },
            {
                label: 'Blowing Rock',
                value: '03',
            },
            {
                label: 'Cove Cay',
                value: '04',
            },
            {
                label: 'Crocus Cay',
                value: '05',
            },
            {
                label: 'Deadman\'s Cay',
                value: '06',
            },
            {
                label: 'Dog Island',
                value: '07',
            },
            {
                label: 'East Cay',
                value: '08',
            },
            {
                label: 'Little Island',
                value: '09',
            },
            {
                label: 'Little Scrub Island',
                value: '10',
            },
            {
                label: 'Mid Cay',
                value: '11',
            },
            {
                label: 'North Cay',
                value: '12',
            },
            {
                label: 'Prickly Pear Cays',
                value: '13',
            },
            {
                label: 'Rabbit Island',
                value: '14',
            },
            {
                label: 'Sandy Island/Sand Island',
                value: '15',
            },
            {
                label: 'Scilly Cay',
                value: '16',
            },
            {
                label: 'Scrub Island',
                value: '17',
            },
            {
                label: 'Seal Island',
                value: '18',
            },
            {
                label: 'Sombrero/Hat Island',
                value: '19',
            },
            {
                label: 'South Cay',
                value: '20',
            },
            {
                label: 'South Wager Island',
                value: '21',
            },
            {
                label: 'West Cay',
                value: '22',
            },
        ],
    },
    {
        label: 'Antarctica',
        phoneCode: '+672',
        value: 'AQ',
        countryFlag: '🇦🇶',
        regions: [
            {
                label: 'Antarctica',
                value: 'AQ',
            },
        ],
    },
    {
        label: 'Antigua and Barbuda',
        phoneCode: '+1268',
        value: 'AG',
        countryFlag: '🇦🇬',
        regions: [
            {
                label: 'Antigua Island',
                value: '01',
            },
            {
                label: 'Barbuda Island',
                value: '02',
            },
            {
                label: 'Bird Island',
                value: '04',
            },
            {
                label: 'Bishop Island',
                value: '05',
            },
            {
                label: 'Blake Island',
                value: '06',
            },
            {
                label: 'Crump Island',
                value: '09',
            },
            {
                label: 'Dulcina Island',
                value: '10',
            },
            {
                label: 'Exchange Island',
                value: '11',
            },
            {
                label: 'Five Islands',
                value: '12',
            },
            {
                label: 'Great Bird Island',
                value: '13',
            },
            {
                label: 'Green Island',
                value: '14',
            },
            {
                label: 'Guiana Island',
                value: '15',
            },
            {
                label: 'Hawes Island',
                value: '17',
            },
            {
                label: 'Hells Gate Island',
                value: '16',
            },
            {
                label: 'Henry Island',
                value: '18',
            },
            {
                label: 'Johnson Island',
                value: '19',
            },
            {
                label: 'Kid Island',
                value: '20',
            },
            {
                label: 'Lobster Island',
                value: '22',
            },
            {
                label: 'Maiden Island',
                value: '24',
            },
            {
                label: 'Moor Island',
                value: '25',
            },
            {
                label: 'Nanny Island',
                value: '26',
            },
            {
                label: 'Pelican Island',
                value: '27',
            },
            {
                label: 'Prickly Pear Island',
                value: '28',
            },
            {
                label: 'Rabbit Island',
                value: '29',
            },
            {
                label: 'Red Head Island',
                value: '31',
            },
            {
                label: 'Redonda Island',
                value: '03',
            },
            {
                label: 'Sandy Island',
                value: '32',
            },
            {
                label: 'Smith Island',
                value: '33',
            },
            {
                label: 'The Sisters',
                value: '34',
            },
            {
                label: 'Vernon Island',
                value: '35',
            },
            {
                label: 'Wicked Will Island',
                value: '36',
            },
            {
                label: 'York Island',
                value: '37',
            },
        ],
    },
    {
        label: 'Argentina',
        phoneCode: '+54',
        value: 'AR',
        countryFlag: '🇦🇷',
        regions: [
            {
                label: 'Buenos Aires',
                value: 'B',
            },
            {
                label: 'Capital Federal',
                value: 'C',
            },
            {
                label: 'Catamarca',
                value: 'K',
            },
            {
                label: 'Chaco',
                value: 'H',
            },
            {
                label: 'Chubut',
                value: 'U',
            },
            {
                label: 'Córdoba',
                value: 'X',
            },
            {
                label: 'Corrientes',
                value: 'W',
            },
            {
                label: 'Entre Ríos',
                value: 'E',
            },
            {
                label: 'Formosa',
                value: 'P',
            },
            {
                label: 'Jujuy',
                value: 'Y',
            },
            {
                label: 'La Pampa',
                value: 'L',
            },
            {
                label: 'La Rioja',
                value: 'F',
            },
            {
                label: 'Mendoza',
                value: 'M',
            },
            {
                label: 'Misiones',
                value: 'N',
            },
            {
                label: 'Neuquén',
                value: 'Q',
            },
            {
                label: 'Río Negro',
                value: 'R',
            },
            {
                label: 'Salta',
                value: 'A',
            },
            {
                label: 'San Juan',
                value: 'J',
            },
            {
                label: 'San Luis',
                value: 'D',
            },
            {
                label: 'Santa Cruz',
                value: 'Z',
            },
            {
                label: 'Santa Fe',
                value: 'S',
            },
            {
                label: 'Santiago del Estero',
                value: 'G',
            },
            {
                label: 'Tierra del Fuego',
                value: 'V',
            },
            {
                label: 'Tucumán',
                value: 'T',
            },
        ],
    },
    {
        label: 'Armenia',
        phoneCode: '+374',
        value: 'AM',
        countryFlag: '🇦🇲',
        regions: [
            {
                label: 'Aragatsotn',
                value: 'AG',
            },
            {
                label: 'Ararat',
                value: 'AR',
            },
            {
                label: 'Armavir',
                value: 'AV',
            },
            {
                label: 'Gegharkunik',
                value: 'GR',
            },
            {
                label: 'Kotayk',
                value: 'KT',
            },
            {
                label: 'Lori',
                value: 'LO',
            },
            {
                label: 'Shirak',
                value: 'SH',
            },
            {
                label: 'Syunik',
                value: 'SU',
            },
            {
                label: 'Tavush',
                value: 'TV',
            },
            {
                label: 'Vayots Dzor',
                value: 'VD',
            },
            {
                label: 'Yerevan',
                value: 'ER',
            },
        ],
    },
    {
        label: 'Aruba',
        phoneCode: '+297',
        value: 'AW',
        countryFlag: '🇦🇼',
        regions: [
            {
                label: 'Aruba',
                value: 'AW',
            },
        ],
    },
    {
        label: 'Australia',
        phoneCode: '+61',
        value: 'AU',
        countryFlag: '🇦🇺',
        regions: [
            {
                label: 'Australian Capital Territory',
                value: 'ACT',
            },
            {
                label: 'New South Wales',
                value: 'NSW',
            },
            {
                label: 'Northern Territory',
                value: 'NT',
            },
            {
                label: 'Queensland',
                value: 'QLD',
            },
            {
                label: 'South Australia',
                value: 'SA',
            },
            {
                label: 'Tasmania',
                value: 'TAS',
            },
            {
                label: 'Victoria',
                value: 'VIC',
            },
            {
                label: 'Western Australia',
                value: 'WA',
            },
        ],
    },
    {
        label: 'Austria',
        phoneCode: '+43',
        value: 'AT',
        countryFlag: '🇦🇹',
        regions: [
            {
                label: 'Burgenland',
                value: '1',
            },
            {
                label: 'Kärnten',
                value: '2',
            },
            {
                label: 'Niederösterreich',
                value: '3',
            },
            {
                label: 'Oberösterreich',
                value: '4',
            },
            {
                label: 'Salzburg',
                value: '5',
            },
            {
                label: 'Steiermark',
                value: '6',
            },
            {
                label: 'Tirol',
                value: '7',
            },
            {
                label: 'Vorarlberg',
                value: '8',
            },
            {
                label: 'Wien',
                value: '9',
            },
        ],
    },
    {
        label: 'Azerbaijan',
        phoneCode: '+994',
        value: 'AZ',
        countryFlag: '🇦🇿',
        regions: [
            {
                label: 'Abşeron',
                value: 'ABS',
            },
            {
                label: 'Ağcabədi',
                value: 'AGC',
            },
            {
                label: 'Ağdam',
                value: 'AGM',
            },
            {
                label: 'Ağdaş',
                value: 'AGS',
            },
            {
                label: 'Ağstafa',
                value: 'AGA',
            },
            {
                label: 'Ağsu',
                value: 'AGU',
            },
            {
                label: 'Astara',
                value: 'AST',
            },
            {
                label: 'Bakı',
                value: 'BAK',
            },
            {
                label: 'Babək',
                value: 'BAB',
            },
            {
                label: 'Balakən',
                value: 'BAL',
            },
            {
                label: 'Bərdə',
                value: 'BAR',
            },
            {
                label: 'Beyləqan',
                value: 'BEY',
            },
            {
                label: 'Biləsuvar',
                value: 'BIL',
            },
            {
                label: 'Cəbrayıl',
                value: 'CAB',
            },
            {
                label: 'Cəlilabad',
                value: 'CAL',
            },
            {
                label: 'Culfa',
                value: 'CUL',
            },
            {
                label: 'Daşkəsən',
                value: 'DAS',
            },
            {
                label: 'Füzuli',
                value: 'FUZ',
            },
            {
                label: 'Gədəbəy',
                value: 'GAD',
            },
            {
                label: 'Goranboy',
                value: 'GOR',
            },
            {
                label: 'Göyçay',
                value: 'GOY',
            },
            {
                label: 'Göygöl',
                value: 'GYG',
            },
            {
                label: 'Hacıqabul',
                value: 'HAC',
            },
            {
                label: 'İmişli',
                value: 'IMI',
            },
            {
                label: 'İsmayıllı',
                value: 'ISM',
            },
            {
                label: 'Kəlbəcər',
                value: 'KAL',
            },
            {
                label: 'Kǝngǝrli',
                value: 'KAN',
            },
            {
                label: 'Kürdəmir',
                value: 'KUR',
            },
            {
                label: 'Laçın',
                value: 'LAC',
            },
            {
                label: 'Lənkəran',
                value: 'LAN',
            },
            {
                label: 'Lerik',
                value: 'LER',
            },
            {
                label: 'Masallı',
                value: 'MAS',
            },
            {
                label: 'Neftçala',
                value: 'NEF',
            },
            {
                label: 'Oğuz',
                value: 'OGU',
            },
            {
                label: 'Ordubad',
                value: 'ORD',
            },
            {
                label: 'Qəbələ',
                value: 'QAB',
            },
            {
                label: 'Qax',
                value: 'QAX',
            },
            {
                label: 'Qazax',
                value: 'QAZ',
            },
            {
                label: 'Qobustan',
                value: 'QOB',
            },
            {
                label: 'Quba',
                value: 'QBA',
            },
            {
                label: 'Qubadli',
                value: 'QBI',
            },
            {
                label: 'Qusar',
                value: 'QUS',
            },
            {
                label: 'Saatlı',
                value: 'SAT',
            },
            {
                label: 'Sabirabad',
                value: 'SAB',
            },
            {
                label: 'Şabran',
                value: 'SBN',
            },
            {
                label: 'Sədərək',
                value: 'SAD',
            },
            {
                label: 'Şahbuz',
                value: 'SAH',
            },
            {
                label: 'Şəki',
                value: 'SAK',
            },
            {
                label: 'Salyan',
                value: 'SAL',
            },
            {
                label: 'Şamaxı',
                value: 'SMI',
            },
            {
                label: 'Şəmkir',
                value: 'SKR',
            },
            {
                label: 'Samux',
                value: 'SMX',
            },
            {
                label: 'Şərur',
                value: 'SAR',
            },
            {
                label: 'Siyəzən',
                value: 'SIY',
            },
            {
                label: 'Şuşa',
                value: 'SUS',
            },
            {
                label: 'Tərtər',
                value: 'TAR',
            },
            {
                label: 'Tovuz',
                value: 'TOV',
            },
            {
                label: 'Ucar',
                value: 'UCA',
            },
            {
                label: 'Xaçmaz',
                value: 'XAC',
            },
            {
                label: 'Xızı',
                value: 'XIZ',
            },
            {
                label: 'Xocalı',
                value: 'XCI',
            },
            {
                label: 'Xocavənd',
                value: 'XVD',
            },
            {
                label: 'Yardımlı',
                value: 'YAR',
            },
            {
                label: 'Yevlax',
                value: 'YEV',
            },
            {
                label: 'Zəngilan',
                value: 'ZAN',
            },
            {
                label: 'Zaqatala',
                value: 'ZAQ',
            },
            {
                label: 'Zərdab',
                value: 'ZAR',
            },
        ],
    },
    {
        label: 'Bahamas',
        phoneCode: '+1242',
        value: 'BS',
        countryFlag: '🇧🇸',
        regions: [
            {
                label: 'Acklins Island',
                value: '01',
            },
            {
                label: 'Berry Islands',
                value: '22',
            },
            {
                label: 'Bimini',
                value: '02',
            },
            {
                label: 'Black Point',
                value: '23',
            },
            {
                label: 'Cat Island',
                value: '03',
            },
            {
                label: 'Central Abaco',
                value: '24',
            },
            {
                label: 'Crooked Island and Long Cay',
                value: '28',
            },
            {
                label: 'East Grand Bahama',
                value: '29',
            },
            {
                label: 'Exuma',
                value: '04',
            },
            {
                label: 'Freeport',
                value: '05',
            },
            {
                label: 'Fresh Creek',
                value: '06',
            },
            {
                label: 'Governor\'s Harbour',
                value: '07',
            },
            {
                label: 'Green Turtle Cay',
                value: '08',
            },
            {
                label: 'Harbour Island',
                value: '09',
            },
            {
                label: 'High Rock',
                value: '10',
            },
            {
                label: 'Inagua',
                value: '11',
            },
            {
                label: 'Kemps Bay',
                value: '12',
            },
            {
                label: 'Long Island',
                value: '13',
            },
            {
                label: 'Marsh Harbour',
                value: '14',
            },
            {
                label: 'Mayaguana',
                value: '15',
            },
            {
                label: 'Moore’s Island',
                value: '40',
            },
            {
                label: 'New Providence',
                value: '16',
            },
            {
                label: 'Nichollstown and Berry Islands',
                value: '17',
            },
            {
                label: 'North Abaco',
                value: '42',
            },
            {
                label: 'North Andros',
                value: '41',
            },
            {
                label: 'North Eleuthera',
                value: '33',
            },
            {
                label: 'Ragged Island',
                value: '18',
            },
            {
                label: 'Rock Sound',
                value: '19',
            },
            {
                label: 'San Salvador and Rum Cay',
                value: '20',
            },
            {
                label: 'Sandy Point',
                value: '21',
            },
            {
                label: 'South Abaco',
                value: '35',
            },
            {
                label: 'South Andros',
                value: '36',
            },
            {
                label: 'South Eleuthera',
                value: '37',
            },
            {
                label: 'West Grand Bahama',
                value: '39',
            },
        ],
    },
    {
        label: 'Bahrain',
        phoneCode: '+973',
        value: 'BH',
        countryFlag: '🇧🇭',
        regions: [
            {
                label: 'Al Janūbīyah',
                value: '14',
            },
            {
                label: 'Al Manāmah',
                value: '13',
            },
            {
                label: 'Al Muḩarraq',
                value: '15',
            },
            {
                label: 'Al Wusţá',
                value: '16',
            },
            {
                label: 'Ash Shamālīyah',
                value: '17',
            },
        ],
    },
    {
        label: 'Bangladesh',
        phoneCode: '+880',
        value: 'BD',
        countryFlag: '🇧🇩',
        regions: [
            {
                label: 'Barisal',
                value: 'A',
            },
            {
                label: 'Chittagong',
                value: 'B',
            },
            {
                label: 'Dhaka',
                value: 'C',
            },
            {
                label: 'Khulna',
                value: 'D',
            },
            {
                label: 'Mymensingh',
                value: 'M',
            },
            {
                label: 'Rajshahi',
                value: 'E',
            },
            {
                label: 'Rangpur',
                value: 'F',
            },
            {
                label: 'Sylhet',
                value: 'G',
            },
        ],
    },
    {
        label: 'Barbados',
        phoneCode: '+1246',
        value: 'BB',
        countryFlag: '🇧🇧',
        regions: [
            {
                label: 'Christ Church',
                value: '01',
            },
            {
                label: 'Saint Andrew',
                value: '02',
            },
            {
                label: 'Saint George',
                value: '03',
            },
            {
                label: 'Saint James',
                value: '04',
            },
            {
                label: 'Saint John',
                value: '05',
            },
            {
                label: 'Saint Joseph',
                value: '06',
            },
            {
                label: 'Saint Lucy',
                value: '07',
            },
            {
                label: 'Saint Michael',
                value: '08',
            },
            {
                label: 'Saint Peter',
                value: '09',
            },
            {
                label: 'Saint Philip',
                value: '10',
            },
            {
                label: 'Saint Thomas',
                value: '11',
            },
        ],
    },
    {
        label: 'Belarus',
        phoneCode: '+375',
        value: 'BY',
        countryFlag: '🇧🇾',
        regions: [
            {
                label: 'Brest voblast',
                value: 'BR',
            },
            {
                label: 'Gorod Minsk',
                value: 'HM',
            },
            {
                label: 'Homiel voblast',
                value: 'HO',
            },
            {
                label: 'Hrodna voblast',
                value: 'HR',
            },
            {
                label: 'Mahilyow voblast',
                value: 'MA',
            },
            {
                label: 'Minsk voblast',
                value: 'MI',
            },
            {
                label: 'Vitsebsk voblast',
                value: 'VI',
            },
        ],
    },
    {
        label: 'Belgium',
        phoneCode: '+32',
        value: 'BE',
        countryFlag: '🇧🇪',
        regions: [
            {
                label: 'Brussels',
                value: 'BRU',
            },
            {
                label: 'Flanders',
                value: 'VLG',
            },
            {
                label: 'Wallonia',
                value: 'WAL',
            },
        ],
    },
    {
        label: 'Belize',
        phoneCode: '+501',
        value: 'BZ',
        countryFlag: '🇧🇿',
        regions: [
            {
                label: 'Belize District',
                value: 'BZ',
            },
            {
                label: 'Cayo District',
                value: 'CY',
            },
            {
                label: 'Corozal District',
                value: 'CZL',
            },
            {
                label: 'Orange Walk District',
                value: 'OW',
            },
            {
                label: 'Stann Creek District',
                value: 'SC',
            },
            {
                label: 'Toledo District',
                value: 'TOL',
            },
        ],
    },
    {
        label: 'Benin',
        phoneCode: '+229',
        value: 'BJ',
        countryFlag: '🇧🇯',
        regions: [
            {
                label: 'Alibori',
                value: 'AL',
            },
            {
                label: 'Atakora',
                value: 'AK',
            },
            {
                label: 'Atlantique',
                value: 'AQ',
            },
            {
                label: 'Borgou',
                value: 'BO',
            },
            {
                label: 'Collines Department',
                value: 'CO',
            },
            {
                label: 'Donga',
                value: 'DO',
            },
            {
                label: 'Kouffo',
                value: 'KO',
            },
            {
                label: 'Littoral Department',
                value: 'LI',
            },
            {
                label: 'Mono Department',
                value: 'MO',
            },
            {
                label: 'Ouémé',
                value: 'OU',
            },
            {
                label: 'Plateau',
                value: 'PL',
            },
            {
                label: 'Zou',
                value: 'ZO',
            },
        ],
    },
    {
        label: 'Bermuda',
        phoneCode: '+1441',
        value: 'BM',
        countryFlag: '🇧🇲',
        regions: [
            {
                label: 'City of Hamilton',
                value: '03',
            },
            {
                label: 'Devonshire Parish',
                value: '01',
            },
            {
                label: 'Hamilton Parish',
                value: '02',
            },
            {
                label: 'Paget Parish',
                value: '04',
            },
            {
                label: 'Pembroke Parish',
                value: '05',
            },
            {
                label: 'Sandys Parish',
                value: '08',
            },
            {
                label: 'Smith\'s Parish',
                value: '09',
            },
            {
                label: 'Southampton Parish',
                value: '10',
            },
            {
                label: 'St. George\'s Parish',
                value: '07',
            },
            {
                label: 'Town of St. George',
                value: '06',
            },
            {
                label: 'Warwick Parish',
                value: '11',
            },
        ],
    },
    {
        label: 'Bhutan',
        phoneCode: '+975',
        value: 'BT',
        countryFlag: '🇧🇹',
        regions: [
            {
                label: 'Bumthang',
                value: '33',
            },
            {
                label: 'Chhukha',
                value: '12',
            },
            {
                label: 'Dagana',
                value: '22',
            },
            {
                label: 'Gasa',
                value: 'GA',
            },
            {
                label: 'Haa',
                value: '13',
            },
            {
                label: 'Lhuntse',
                value: '44',
            },
            {
                label: 'Mongar',
                value: '42',
            },
            {
                label: 'Paro',
                value: '11',
            },
            {
                label: 'Pemagatshel',
                value: '43',
            },
            {
                label: 'Punakha',
                value: '23',
            },
            {
                label: 'Samdrup Jongkhar',
                value: '45',
            },
            {
                label: 'Samtse',
                value: '14',
            },
            {
                label: 'Sarpang',
                value: '31',
            },
            {
                label: 'Thimphu',
                value: '15',
            },
            {
                label: 'Trashigang',
                value: '41',
            },
            {
                label: 'Trashiyangtse',
                value: 'TY',
            },
            {
                label: 'Trongsa',
                value: '32',
            },
            {
                label: 'Tsirang',
                value: '21',
            },
            {
                label: 'Wangdue Phodrang',
                value: '24',
            },
            {
                label: 'Zhemgang',
                value: '34',
            },
        ],
    },
    {
        label: 'Bolivia',
        phoneCode: '+591',
        value: 'BO',
        countryFlag: '🇧🇴',
        regions: [
            {
                label: 'Beni',
                value: 'B',
            },
            {
                label: 'Chuquisaca',
                value: 'H',
            },
            {
                label: 'Cochabamba',
                value: 'C',
            },
            {
                label: 'La Paz',
                value: 'L',
            },
            {
                label: 'Oruro',
                value: 'O',
            },
            {
                label: 'Pando',
                value: 'N',
            },
            {
                label: 'Potosí',
                value: 'P',
            },
            {
                label: 'Santa Cruz',
                value: 'S',
            },
            {
                label: 'Tarija',
                value: 'T',
            },
        ],
    },
    {
        label: 'Bonaire, Sint Eustatius and Saba',
        phoneCode: '+599',
        value: 'BQ',
        countryFlag: '🇧🇶',
        regions: [
            {
                label: 'Bonaire',
                value: 'BO',
            },
            {
                label: 'Saba Isand',
                value: 'SA',
            },
            {
                label: 'Sint Eustatius',
                value: 'SE',
            },
        ],
    },
    {
        label: 'Bosnia and Herzegovina',
        phoneCode: '+387',
        value: 'BA',
        countryFlag: '🇧🇦',
        regions: [
            {
                label: 'Brčko Distrikt',
                value: 'BRC',
            },
            {
                label: 'Federacija Bosne i Hercegovine',
                value: 'BIH',
            },
            {
                label: 'Republika Srpska',
                value: 'SRP',
            },
        ],
    },
    {
        label: 'Botswana',
        phoneCode: '+267',
        value: 'BW',
        countryFlag: '🇧🇼',
        regions: [
            {
                label: 'Central',
                value: 'CE',
            },
            {
                label: 'Ghanzi',
                value: 'GH',
            },
            {
                label: 'Kgalagadi',
                value: 'KG',
            },
            {
                label: 'Kgatleng',
                value: 'KL',
            },
            {
                label: 'Kweneng',
                value: 'KW',
            },
            {
                label: 'North West',
                value: 'NW',
            },
            {
                label: 'North-East',
                value: 'NE',
            },
            {
                label: 'South East',
                value: 'SE',
            },
            {
                label: 'Southern',
                value: 'SO',
            },
        ],
    },
    {
        label: 'Bouvet Island',
        phoneCode: '+47',
        value: 'BV',
        countryFlag: '🇧🇻',
        regions: [
            {
                label: 'Bouvet Island',
                value: 'BV',
            },
        ],
    },
    {
        label: 'Brazil',
        phoneCode: '+55',
        value: 'BR',
        countryFlag: '🇧🇷',
        regions: [
            {
                label: 'Acre',
                value: 'AC',
            },
            {
                label: 'Alagoas',
                value: 'AL',
            },
            {
                label: 'Amapá',
                value: 'AP',
            },
            {
                label: 'Amazonas',
                value: 'AM',
            },
            {
                label: 'Bahia',
                value: 'BA',
            },
            {
                label: 'Ceará',
                value: 'CE',
            },
            {
                label: 'Distrito Federal',
                value: 'DF',
            },
            {
                label: 'Espírito Santo',
                value: 'ES',
            },
            {
                label: 'Goiás',
                value: 'GO',
            },
            {
                label: 'Maranhão',
                value: 'MA',
            },
            {
                label: 'Mato Grosso',
                value: 'MT',
            },
            {
                label: 'Mato Grosso do Sul',
                value: 'MS',
            },
            {
                label: 'Minas Gerais',
                value: 'MG',
            },
            {
                label: 'Pará',
                value: 'PA',
            },
            {
                label: 'Paraíba',
                value: 'PB',
            },
            {
                label: 'Paraná',
                value: 'PR',
            },
            {
                label: 'Pernambuco',
                value: 'PE',
            },
            {
                label: 'Piauí',
                value: 'PI',
            },
            {
                label: 'Rio de Janeiro',
                value: 'RJ',
            },
            {
                label: 'Rio Grande do Norte',
                value: 'RN',
            },
            {
                label: 'Rio Grande do Sul',
                value: 'RS',
            },
            {
                label: 'Rondônia',
                value: 'RO',
            },
            {
                label: 'Roraima',
                value: 'RR',
            },
            {
                label: 'Santa Catarina',
                value: 'SC',
            },
            {
                label: 'São Paulo',
                value: 'SP',
            },
            {
                label: 'Sergipe',
                value: 'SE',
            },
            {
                label: 'Tocantins',
                value: 'TO',
            },
        ],
    },
    {
        label: 'British Indian Ocean Territory',
        phoneCode: '+246',
        value: 'IO',
        countryFlag: '🇮🇴',
        regions: [
            {
                label: 'British Indian Ocean Territory',
                value: 'IO',
            },
        ],
    },
    {
        label: 'Brunei Darussalam',
        phoneCode: '+673',
        value: 'BN',
        countryFlag: '🇧🇳',
        regions: [
            {
                label: 'Belait',
                value: 'BE',
            },
            {
                label: 'Brunei Muara',
                value: 'BM',
            },
            {
                label: 'Temburong',
                value: 'TE',
            },
            {
                label: 'Tutong',
                value: 'TU',
            },
        ],
    },
    {
        label: 'Bulgaria',
        phoneCode: '+359',
        value: 'BG',
        countryFlag: '🇧🇬',
        regions: [
            {
                label: 'Blagoevgrad',
                value: '01',
            },
            {
                label: 'Burgas',
                value: '02',
            },
            {
                label: 'Dobrich',
                value: '08',
            },
            {
                label: 'Gabrovo',
                value: '07',
            },
            {
                label: 'Jambol',
                value: '28',
            },
            {
                label: 'Khaskovo',
                value: '26',
            },
            {
                label: 'Kjustendil',
                value: '10',
            },
            {
                label: 'Kurdzhali',
                value: '09',
            },
            {
                label: 'Lovech',
                value: '11',
            },
            {
                label: 'Montana',
                value: '12',
            },
            {
                label: 'Pazardzhik',
                value: '13',
            },
            {
                label: 'Pernik',
                value: '14',
            },
            {
                label: 'Pleven',
                value: '15',
            },
            {
                label: 'Plovdiv',
                value: '16',
            },
            {
                label: 'Razgrad',
                value: '17',
            },
            {
                label: 'Ruse',
                value: '18',
            },
            {
                label: 'Shumen',
                value: '27',
            },
            {
                label: 'Silistra',
                value: '19',
            },
            {
                label: 'Sliven',
                value: '20',
            },
            {
                label: 'Smoljan',
                value: '21',
            },
            {
                label: 'Sofija',
                value: '23',
            },
            {
                label: 'Sofija-Grad',
                value: '22',
            },
            {
                label: 'Stara Zagora',
                value: '24',
            },
            {
                label: 'Turgovishhe',
                value: '25',
            },
            {
                label: 'Varna',
                value: '03',
            },
            {
                label: 'Veliko Turnovo',
                value: '04',
            },
            {
                label: 'Vidin',
                value: '05',
            },
            {
                label: 'Vraca',
                value: '06',
            },
        ],
    },
    {
        label: 'Burkina Faso',
        phoneCode: '+226',
        value: 'BF',
        countryFlag: '🇧🇫',
        regions: [
            {
                label: 'Balé',
                value: 'BAL',
            },
            {
                label: 'Bam/Lake Bam',
                value: 'BAM',
            },
            {
                label: 'Banwa Province',
                value: 'BAN',
            },
            {
                label: 'Bazèga',
                value: 'BAZ',
            },
            {
                label: 'Bougouriba',
                value: 'BGR',
            },
            {
                label: 'Boulgou Province',
                value: 'BLG',
            },
            {
                label: 'Boulkiemdé',
                value: 'BLK',
            },
            {
                label: 'Comoé/Komoe',
                value: 'COM',
            },
            {
                label: 'Ganzourgou Province',
                value: 'GAN',
            },
            {
                label: 'Gnagna',
                value: 'GNA',
            },
            {
                label: 'Gourma Province',
                value: 'GOU',
            },
            {
                label: 'Houet',
                value: 'HOU',
            },
            {
                label: 'Ioba',
                value: 'IOB',
            },
            {
                label: 'Kadiogo',
                value: 'KAD',
            },
            {
                label: 'Kénédougou',
                value: 'KEN',
            },
            {
                label: 'Komondjari',
                value: 'KMD',
            },
            {
                label: 'Kompienga',
                value: 'KMP',
            },
            {
                label: 'Kossi Province',
                value: 'KOS',
            },
            {
                label: 'Koulpélogo',
                value: 'KOP',
            },
            {
                label: 'Kouritenga',
                value: 'KOT',
            },
            {
                label: 'Kourwéogo',
                value: 'KOW',
            },
            {
                label: 'Léraba',
                value: 'LER',
            },
            {
                label: 'Loroum',
                value: 'LOR',
            },
            {
                label: 'Mouhoun',
                value: 'MOU',
            },
            {
                label: 'labelntenga',
                value: 'NAM',
            },
            {
                label: 'Naouri/Nahouri',
                value: 'NAO',
            },
            {
                label: 'Nayala',
                value: 'NAY',
            },
            {
                label: 'Noumbiel',
                value: 'NOU',
            },
            {
                label: 'Oubritenga',
                value: 'OUB',
            },
            {
                label: 'Oudalan',
                value: 'OUD',
            },
            {
                label: 'Passoré',
                value: 'PAS',
            },
            {
                label: 'Poni',
                value: 'PON',
            },
            {
                label: 'Sanguié',
                value: 'SNG',
            },
            {
                label: 'Sanmatenga',
                value: 'SMT',
            },
            {
                label: 'Séno',
                value: 'SEN',
            },
            {
                label: 'Sissili',
                value: 'SIS',
            },
            {
                label: 'Soum',
                value: 'SOM',
            },
            {
                label: 'Sourou',
                value: 'SOR',
            },
            {
                label: 'Tapoa',
                value: 'TAP',
            },
            {
                label: 'Tui/Tuy',
                value: 'TUI',
            },
            {
                label: 'Yagha',
                value: 'YAG',
            },
            {
                label: 'Yatenga',
                value: 'YAT',
            },
            {
                label: 'Ziro',
                value: 'ZIR',
            },
            {
                label: 'Zondoma',
                value: 'ZON',
            },
            {
                label: 'Zoundwéogo',
                value: 'ZOU',
            },
        ],
    },
    {
        label: 'Burundi',
        phoneCode: '+257',
        value: 'BI',
        countryFlag: '🇧🇮',
        regions: [
            {
                label: 'Bubanza',
                value: 'BB',
            },
            {
                label: 'Bujumbura Mairie',
                value: 'BM',
            },
            {
                label: 'Bujumbura Rural',
                value: 'BL',
            },
            {
                label: 'Bururi',
                value: 'BR',
            },
            {
                label: 'Cankuzo',
                value: 'CA',
            },
            {
                label: 'Cibitoke',
                value: 'CI',
            },
            {
                label: 'Gitega',
                value: 'GI',
            },
            {
                label: 'Karuzi',
                value: 'KR',
            },
            {
                label: 'Kayanza',
                value: 'KY',
            },
            {
                label: 'Kirundo',
                value: 'KI',
            },
            {
                label: 'Makamba',
                value: 'MA',
            },
            {
                label: 'Muramvya',
                value: 'MU',
            },
            {
                label: 'Muyinga',
                value: 'MY',
            },
            {
                label: 'Mwaro',
                value: 'MW',
            },
            {
                label: 'Ngozi',
                value: 'NG',
            },
            {
                label: 'Rutana',
                value: 'RT',
            },
            {
                label: 'Ruyigi',
                value: 'RY',
            },
        ],
    },
    {
        label: 'Cambodia',
        phoneCode: '+855',
        value: 'KH',
        countryFlag: '🇰🇭',
        regions: [
            {
                label: 'Baat Dambang',
                value: '2',
            },
            {
                label: 'Banteay Mean Chey',
                value: '1',
            },
            {
                label: 'Kampong Chaam',
                value: '3',
            },
            {
                label: 'Kampong Chhnang',
                value: '4',
            },
            {
                label: 'Kampong Spueu',
                value: '5',
            },
            {
                label: 'Kampong Thum',
                value: '6',
            },
            {
                label: 'Kampot',
                value: '7',
            },
            {
                label: 'Kandaal',
                value: '8',
            },
            {
                label: 'Kaoh Kong',
                value: '9',
            },
            {
                label: 'Kracheh',
                value: '10',
            },
            {
                label: 'Krong Kaeb',
                value: '23',
            },
            {
                label: 'Krong Pailin',
                value: '24',
            },
            {
                label: 'Krong Preah Sihanouk',
                value: '18',
            },
            {
                label: 'Mondol Kiri',
                value: '11',
            },
            {
                label: 'Otdar Mean Chey',
                value: '22',
            },
            {
                label: 'Phnom Penh',
                value: '12',
            },
            {
                label: 'Pousaat',
                value: '15',
            },
            {
                label: 'Preah Vihear',
                value: '13',
            },
            {
                label: 'Prey Veaeng',
                value: '14',
            },
            {
                label: 'Rotanah Kiri',
                value: '16',
            },
            {
                label: 'Siem Reab',
                value: '17',
            },
            {
                label: 'Stueng Treng',
                value: '19',
            },
            {
                label: 'Svaay Rieng',
                value: '20',
            },
            {
                label: 'Taakaev',
                value: '21',
            },
            {
                label: 'Tbong Khmum',
                value: '25',
            },
        ],
    },
    {
        label: 'Cameroon',
        phoneCode: '+237',
        value: 'CM',
        countryFlag: '🇨🇲',
        regions: [
            {
                label: 'Adamaoua',
                value: 'AD',
            },
            {
                label: 'Centre',
                value: 'CE',
            },
            {
                label: 'Est',
                value: 'ES',
            },
            {
                label: 'Extrême-Nord',
                value: 'EN',
            },
            {
                label: 'Littoral',
                value: 'LT',
            },
            {
                label: 'Nord',
                value: 'NO',
            },
            {
                label: 'Nord-Ouest',
                value: 'NW',
            },
            {
                label: 'Ouest',
                value: 'OU',
            },
            {
                label: 'Sud',
                value: 'SU',
            },
            {
                label: 'Sud-Ouest',
                value: 'SW',
            },
        ],
    },
    {
        label: 'Canada',
        phoneCode: '+1',
        value: 'CA',
        countryFlag: '🇨🇦',
        regions: [
            {
                label: 'Alberta',
                value: 'AB',
            },
            {
                label: 'British Columbia',
                value: 'BC',
            },
            {
                label: 'Manitoba',
                value: 'MB',
            },
            {
                label: 'New Brunswick',
                value: 'NB',
            },
            {
                label: 'Newfoundland and Labrador',
                value: 'NL',
            },
            {
                label: 'Northwest Territories',
                value: 'NT',
            },
            {
                label: 'Nova Scotia',
                value: 'NS',
            },
            {
                label: 'Nunavut',
                value: 'NU',
            },
            {
                label: 'Ontario',
                value: 'ON',
            },
            {
                label: 'Prince Edward Island',
                value: 'PE',
            },
            {
                label: 'Quebec',
                value: 'QC',
            },
            {
                label: 'Saskatchewan',
                value: 'SK',
            },
            {
                label: 'Yukon',
                value: 'YT',
            },
        ],
    },
    {
        label: 'Cape Verde',
        phoneCode: '+238',
        value: 'CV',
        countryFlag: '🇨🇻',
        regions: [
            {
                label: 'Boa Vista',
                value: 'BV',
            },
            {
                label: 'Brava',
                value: 'BR',
            },
            {
                label: 'Calheta de São Miguel',
                value: 'CS',
            },
            {
                label: 'Maio',
                value: 'MA',
            },
            {
                label: 'Mosteiros',
                value: 'MO',
            },
            {
                label: 'Paúl',
                value: 'PA',
            },
            {
                label: 'Porto Novo',
                value: 'PN',
            },
            {
                label: 'Praia',
                value: 'PR',
            },
            {
                label: 'Ribeira Brava',
                value: 'RB',
            },
            {
                label: 'Ribeira Grande',
                value: 'RG',
            },
            {
                label: 'Sal',
                value: 'SL',
            },
            {
                label: 'Santa Catarina',
                value: 'CA',
            },
            {
                label: 'Santa Cruz',
                value: 'CR',
            },
            {
                label: 'São Domingos',
                value: 'SD',
            },
            {
                label: 'São Filipe',
                value: 'SF',
            },
            {
                label: 'São Nicolau',
                value: 'SN',
            },
            {
                label: 'São Vicente',
                value: 'SV',
            },
            {
                label: 'Tarrafal',
                value: 'TA',
            },
            {
                label: 'Tarrafal de São Nicolau',
                value: 'TS',
            },
        ],
    },
    {
        label: 'Cayman Islands',
        phoneCode: '+1345',
        value: 'KY',
        countryFlag: '🇰🇾',
        regions: [
            {
                label: 'Creek',
                value: null,
            },
            {
                label: 'Eastern',
                value: null,
            },
            {
                label: 'Midland',
                value: null,
            },
            {
                label: 'South Town',
                value: null,
            },
            {
                label: 'Spot Bay',
                value: null,
            },
            {
                label: 'Stake Bay',
                value: null,
            },
            {
                label: 'West End',
                value: null,
            },
            {
                label: 'Western',
                value: null,
            },
        ],
    },
    {
        label: 'Central African Republic',
        phoneCode: '+236',
        value: 'CF',
        countryFlag: '🇨🇫',
        regions: [
            {
                label: 'Bamingui-Bangoran',
                value: 'BB',
            },
            {
                label: 'Bangui',
                value: 'BGF',
            },
            {
                label: 'Basse-Kotto',
                value: 'BK',
            },
            {
                label: 'Haute-Kotto',
                value: 'HK',
            },
            {
                label: 'Haut-Mbomou',
                value: 'HM',
            },
            {
                label: 'Kémo',
                value: 'KG',
            },
            {
                label: 'Lobaye',
                value: 'LB',
            },
            {
                label: 'Mambéré-Kadéï',
                value: 'HS',
            },
            {
                label: 'Mbomou',
                value: 'MB',
            },
            {
                label: 'Nana-Grebizi',
                value: '10',
            },
            {
                label: 'Nana-Mambéré',
                value: 'NM',
            },
            {
                label: 'Ombella-M\'Poko',
                value: 'MP',
            },
            {
                label: 'Ouaka',
                value: 'UK',
            },
            {
                label: 'Ouham',
                value: 'AC',
            },
            {
                label: 'Ouham Péndé',
                value: 'OP',
            },
            {
                label: 'Sangha-Mbaéré',
                value: 'SE',
            },
            {
                label: 'Vakaga',
                value: 'VK',
            },
        ],
    },
    {
        label: 'Chad',
        phoneCode: '+235',
        value: 'TD',
        countryFlag: '🇹🇩',
        regions: [
            {
                label: 'Bahr el Ghazal',
                value: 'BG',
            },
            {
                label: 'Batha',
                value: 'BA',
            },
            {
                label: 'Borkou',
                value: 'BO',
            },
            {
                label: 'Chari-Baguirmi',
                value: 'CB',
            },
            {
                label: 'Ennedi-Est',
                value: 'EE',
            },
            {
                label: 'Ennedi-Ouest',
                value: 'EO',
            },
            {
                label: 'Guéra',
                value: 'GR',
            },
            {
                label: 'Hadjer Lamis',
                value: 'HL',
            },
            {
                label: 'Kanem',
                value: 'KA',
            },
            {
                label: 'Lac',
                value: 'LC',
            },
            {
                label: 'Logone Occidental',
                value: 'LO',
            },
            {
                label: 'Logone Oriental',
                value: 'LR',
            },
            {
                label: 'Mondoul',
                value: 'MA',
            },
            {
                label: 'Mayo-Kébbi-Est',
                value: 'ME',
            },
            {
                label: 'Moyen-Chari',
                value: 'MC',
            },
            {
                label: 'Ouaddai',
                value: 'OD',
            },
            {
                label: 'Salamat',
                value: 'SA',
            },
            {
                label: 'Sila',
                value: 'SI',
            },
            {
                label: 'Tandjilé',
                value: 'TA',
            },
            {
                label: 'Tibesti',
                value: 'TI',
            },
            {
                label: 'Ville de Ndjamena',
                value: 'ND',
            },
            {
                label: 'Wadi Fira',
                value: 'WF',
            },
        ],
    },
    {
        label: 'Chile',
        phoneCode: '+56',
        value: 'CL',
        countryFlag: '🇨🇱',
        regions: [
            {
                label: 'Aisén del General Carlos Ibáñez del Campo',
                value: 'AI',
            },
            {
                label: 'Antofagasta',
                value: 'AN',
            },
            {
                label: 'Araucanía',
                value: 'AR',
            },
            {
                label: 'Arica y Parinacota',
                value: 'AP',
            },
            {
                label: 'Atacama',
                value: 'AT',
            },
            {
                label: 'Bío-Bío',
                value: 'BI',
            },
            {
                label: 'Coquimbo',
                value: 'CO',
            },
            {
                label: 'Libertador General Bernardo O\'Higgins',
                value: 'LI',
            },
            {
                label: 'Los Lagos',
                value: 'LL',
            },
            {
                label: 'Los Ríos',
                value: 'LR',
            },
            {
                label: 'Magallanes y Antartica Chilena',
                value: 'MA',
            },
            {
                label: 'Marga-Marga',
                value: '',
            },
            {
                label: 'Maule',
                value: 'ML',
            },
            {
                label: 'Ñuble',
                value: 'NB',
            },
            {
                label: 'Región Metropolitana de Santiago',
                value: 'RM',
            },
            {
                label: 'Tarapacá',
                value: 'TA',
            },
            {
                label: 'Valparaíso',
                value: 'VS',
            },
        ],
    },
    {
        label: 'China',
        phoneCode: '+86',
        value: 'CN',
        countryFlag: '🇨🇳',
        regions: [
            {
                label: 'Anhui',
                value: '34',
            },
            {
                label: 'Beijing',
                value: '11',
            },
            {
                label: 'Chongqing',
                value: '50',
            },
            {
                label: 'Fujian',
                value: '35',
            },
            {
                label: 'Gansu',
                value: '62',
            },
            {
                label: 'Guangdong',
                value: '44',
            },
            {
                label: 'Guangxi',
                value: '45',
            },
            {
                label: 'Guizhou',
                value: '52',
            },
            {
                label: 'Hainan',
                value: '46',
            },
            {
                label: 'Hebei',
                value: '13',
            },
            {
                label: 'Heilongjiang',
                value: '23',
            },
            {
                label: 'Henan',
                value: '41',
            },
            {
                label: 'Hong Kong',
                value: '91',
            },
            {
                label: 'Hubei',
                value: '42',
            },
            {
                label: 'Hunan',
                value: '43',
            },
            {
                label: 'Inner Mongolia',
                value: '15',
            },
            {
                label: 'Jiangsu',
                value: '32',
            },
            {
                label: 'Jiangxi',
                value: '36',
            },
            {
                label: 'Jilin',
                value: '22',
            },
            {
                label: 'Liaoning',
                value: '21',
            },
            {
                label: 'Macau',
                value: '92',
            },
            {
                label: 'Ningxia',
                value: '64',
            },
            {
                label: 'Qinghai',
                value: '63',
            },
            {
                label: 'Shaanxi',
                value: '61',
            },
            {
                label: 'Shandong',
                value: '37',
            },
            {
                label: 'Shanghai',
                value: '31',
            },
            {
                label: 'Shanxi',
                value: '14',
            },
            {
                label: 'Sichuan',
                value: '51',
            },
            {
                label: 'Tianjin',
                value: '12',
            },
            {
                label: 'Tibet',
                value: '54',
            },
            {
                label: 'Xinjiang',
                value: '65',
            },
            {
                label: 'Yunnan',
                value: '53',
            },
            {
                label: 'Zhejiang',
                value: '33',
            },
        ],
    },
    {
        label: 'Christmas Island',
        phoneCode: '+61',
        value: 'CX',
        countryFlag: '🇨🇽',
        regions: [
            {
                label: 'Christmas Island',
                value: 'CX',
            },
        ],
    },
    {
        label: 'Cocos (Keeling) Islands',
        phoneCode: '+61',
        value: 'CC',
        countryFlag: '🇨🇨',
        regions: [
            {
                label: 'Direction Island',
                value: 'DI',
            },
            {
                label: 'Home Island',
                value: 'HM',
            },
            {
                label: 'Horsburgh Island',
                value: 'HR',
            },
            {
                label: 'North Keeling Island',
                value: 'NK',
            },
            {
                label: 'South Island',
                value: 'SI',
            },
            {
                label: 'West Island',
                value: 'WI',
            },
        ],
    },
    {
        label: 'Colombia',
        phoneCode: '+57',
        value: 'CO',
        countryFlag: '🇨🇴',
        regions: [
            {
                label: 'Amazonas',
                value: 'AMA',
            },
            {
                label: 'Antioquia',
                value: 'ANT',
            },
            {
                label: 'Arauca',
                value: 'ARA',
            },
            {
                label: 'Archipiélago de San Andrés',
                value: 'SAP',
            },
            {
                label: 'Atlántico',
                value: 'ATL',
            },
            {
                label: 'Bogotá D.C.',
                value: 'DC',
            },
            {
                label: 'Bolívar',
                value: 'BOL',
            },
            {
                label: 'Boyacá',
                value: 'BOY',
            },
            {
                label: 'Caldas',
                value: 'CAL',
            },
            {
                label: 'Caquetá',
                value: 'CAQ',
            },
            {
                label: 'Casanare',
                value: 'CAS',
            },
            {
                label: 'Cauca',
                value: 'CAU',
            },
            {
                label: 'Cesar',
                value: 'CES',
            },
            {
                label: 'Chocó',
                value: 'CHO',
            },
            {
                label: 'Córdoba',
                value: 'COR',
            },
            {
                label: 'Cundinamarca',
                value: 'CUN',
            },
            {
                label: 'Guainía',
                value: 'GUA',
            },
            {
                label: 'Guaviare',
                value: 'GUV',
            },
            {
                label: 'Huila',
                value: 'HUI',
            },
            {
                label: 'La Guajira',
                value: 'LAG',
            },
            {
                label: 'Magdalena',
                value: 'MAG',
            },
            {
                label: 'Meta',
                value: 'MET',
            },
            {
                label: 'Nariño',
                value: 'NAR',
            },
            {
                label: 'Norte de Santander',
                value: 'NSA',
            },
            {
                label: 'Putumayo',
                value: 'PUT',
            },
            {
                label: 'Quindío',
                value: 'QUI',
            },
            {
                label: 'Risaralda',
                value: 'RIS',
            },
            {
                label: 'Santander',
                value: 'SAN',
            },
            {
                label: 'Sucre',
                value: 'SUC',
            },
            {
                label: 'Tolima',
                value: 'TOL',
            },
            {
                label: 'Valle del Cauca',
                value: 'VAC',
            },
            {
                label: 'Vaupés',
                value: 'VAU',
            },
            {
                label: 'Vichada',
                value: 'VID',
            },
        ],
    },
    {
        label: 'Comoros',
        phoneCode: '+269',
        value: 'KM',
        countryFlag: '🇰🇲',
        regions: [
            {
                label: 'Andjazîdja',
                value: 'G',
            },
            {
                label: 'Andjouân',
                value: 'A',
            },
            {
                label: 'Moûhîlî',
                value: 'M',
            },
        ],
    },
    {
        label: 'Congo, Republic of the (Brazzaville)',
        phoneCode: '+242',
        value: 'CG',
        countryFlag: '🇨🇬',
        regions: [
            {
                label: 'Bouenza',
                value: '11',
            },
            {
                label: 'Brazzaville',
                value: 'BZV',
            },
            {
                label: 'Cuvette',
                value: '8',
            },
            {
                label: 'Cuvette-Ouest',
                value: '15',
            },
            {
                label: 'Kouilou',
                value: '5',
            },
            {
                label: 'Lékoumou',
                value: '2',
            },
            {
                label: 'Likouala',
                value: '7',
            },
            {
                label: 'Niari',
                value: '9',
            },
            {
                label: 'Plateaux',
                value: '14',
            },
            {
                label: 'Pointe-Noire',
                value: '16',
            },
            {
                label: 'Pool',
                value: '12',
            },
            {
                label: 'Sangha',
                value: '13',
            },
        ],
    },
    {
        label: 'Congo, the Democratic Republic of the (Kinshasa)',
        phoneCode: '+243',
        value: 'CD',
        countryFlag: '🇨🇩',
        regions: [
            {
                label: 'Bandundu',
                value: 'BN',
            },
            {
                label: 'Bas-Congo',
                value: 'BC',
            },
            {
                label: 'Équateur',
                value: 'EQ',
            },
            {
                label: 'Kasaï-Occidental',
                value: 'KE',
            },
            {
                label: 'Kasaï-Oriental',
                value: 'KW',
            },
            {
                label: 'Katanga',
                value: 'KA',
            },
            {
                label: 'Kinshasa',
                value: 'KN',
            },
            {
                label: 'Maniema',
                value: 'MA',
            },
            {
                label: 'Nord-Kivu',
                value: 'NK',
            },
            {
                label: 'Orientale',
                value: 'OR',
            },
            {
                label: 'Sud-Kivu',
                value: 'SK',
            },
        ],
    },
    {
        label: 'Cook Islands',
        phoneCode: '+682',
        value: 'CK',
        countryFlag: '🇨🇰',
        regions: [
            {
                label: 'Aitutaki',
                value: null,
            },
            {
                label: 'Atiu',
                value: null,
            },
            {
                label: 'Avarua',
                value: null,
            },
            {
                label: 'Mangaia',
                value: null,
            },
            {
                label: 'Manihiki',
                value: null,
            },
            {
                label: 'Ma\'uke',
                value: null,
            },
            {
                label: 'Mitiaro',
                value: null,
            },
            {
                label: 'Nassau',
                value: null,
            },
            {
                label: 'Palmerston',
                value: null,
            },
            {
                label: 'Penrhyn',
                value: null,
            },
            {
                label: 'Pukapuka',
                value: null,
            },
            {
                label: 'Rakahanga',
                value: null,
            },
        ],
    },
    {
        label: 'Costa Rica',
        phoneCode: '+506',
        value: 'CR',
        countryFlag: '🇨🇷',
        regions: [
            {
                label: 'Alajuela',
                value: '2',
            },
            {
                label: 'Cartago',
                value: '3',
            },
            {
                label: 'Guanacaste',
                value: '5',
            },
            {
                label: 'Heredia',
                value: '4',
            },
            {
                label: 'Limón',
                value: '7',
            },
            {
                label: 'Puntarenas',
                value: '6',
            },
            {
                label: 'San José',
                value: '1',
            },
        ],
    },
    {
        label: 'Côte d\'Ivoire, Republic of',
        phoneCode: '+225',
        value: 'CI',
        countryFlag: '🇨🇮',
        regions: [
            {
                label: 'Agnéby',
                value: '16',
            },
            {
                label: 'Bafing',
                value: '17',
            },
            {
                label: 'Bas-Sassandra',
                value: '09',
            },
            {
                label: 'Denguélé',
                value: '10',
            },
            {
                label: 'Dix-Huit Montagnes',
                value: '06',
            },
            {
                label: 'Fromager',
                value: '18',
            },
            {
                label: 'Haut-Sassandra',
                value: '02',
            },
            {
                label: 'Lacs',
                value: '07',
            },
            {
                label: 'Lagunes',
                value: '01',
            },
            {
                label: 'Marahoué',
                value: '12',
            },
            {
                label: 'Moyen-Cavally',
                value: '19',
            },
            {
                label: 'Moyen-Comoé',
                value: '05',
            },
            {
                label: 'N\'zi-Comoé',
                value: '11',
            },
            {
                label: 'Savanes',
                value: '03',
            },
            {
                label: 'Sud-Bandama',
                value: '15',
            },
            {
                label: 'Sud-Comoé',
                value: '13',
            },
            {
                label: 'Vallée du Bandama',
                value: '04',
            },
            {
                label: 'Worodougou',
                value: '14',
            },
            {
                label: 'Zanzan',
                value: '08',
            },
        ],
    },
    {
        label: 'Croatia',
        phoneCode: '+385',
        value: 'HR',
        countryFlag: '🇭🇷',
        regions: [
            {
                label: 'Bjelovarsko-Bilogorska Županija',
                value: '07',
            },
            {
                label: 'Brodsko-Posavska Županija',
                value: '12',
            },
            {
                label: 'Dubrovačko-Neretvanska Županija',
                value: '19',
            },
            {
                label: 'Grad Zagreb',
                value: '21',
            },
            {
                label: 'Istarska Županija',
                value: '18',
            },
            {
                label: 'Karlovačka Županija',
                value: '04',
            },
            {
                label: 'Koprivničko-Krizevačka Županija',
                value: '06',
            },
            {
                label: 'Krapinsko-Zagorska Županija',
                value: '02',
            },
            {
                label: 'Ličko-Senjska Županija',
                value: '09',
            },
            {
                label: 'Međimurska Županija',
                value: '20',
            },
            {
                label: 'Osječko-Baranjska Županija',
                value: '14',
            },
            {
                label: 'Požeško-Slavonska Županija',
                value: '11',
            },
            {
                label: 'Primorsko-Goranska Županija',
                value: '08',
            },
            {
                label: 'Sisačko-Moslavačka Županija',
                value: '03',
            },
            {
                label: 'Splitsko-Dalmatinska Županija',
                value: '17',
            },
            {
                label: 'Sibensko-Kninska Županija',
                value: '15',
            },
            {
                label: 'Varaždinska Županija',
                value: '05',
            },
            {
                label: 'Virovitičko-Podravska Županija',
                value: '10',
            },
            {
                label: 'Vukovarsko-Srijemska Županija',
                value: '16',
            },
            {
                label: 'Zadarska Županija',
                value: '13',
            },
            {
                label: 'Zagrebacka Zupanija',
                value: '01',
            },
        ],
    },
    {
        label: 'Cuba',
        phoneCode: '+53',
        value: 'CU',
        countryFlag: '🇨🇺',
        regions: [
            {
                label: 'Artemisa',
                value: '15',
            },
            {
                label: 'Camagüey',
                value: '09',
            },
            {
                label: 'Ciego de Ávila',
                value: '08',
            },
            {
                label: 'Cienfuegos',
                value: '06',
            },
            {
                label: 'Granma',
                value: '12',
            },
            {
                label: 'Guantánamo',
                value: '14',
            },
            {
                label: 'Holguín',
                value: '11',
            },
            {
                label: 'Isla de la Juventud',
                value: '99',
            },
            {
                label: 'La Habana',
                value: '03',
            },
            {
                label: 'Las Tunas',
                value: '10',
            },
            {
                label: 'Matanzas',
                value: '04',
            },
            {
                label: 'Mayabeque',
                value: '16',
            },
            {
                label: 'Pinar del Río',
                value: '01',
            },
            {
                label: 'Sancti Spíritus',
                value: '07',
            },
            {
                label: 'Santiago de Cuba',
                value: '13',
            },
            {
                label: 'Villa Clara',
                value: '05',
            },
        ],
    },
    {
        label: 'Curaçao',
        phoneCode: '+599',
        value: 'CW',
        countryFlag: '🇨🇼',
        regions: [
            {
                label: 'Curaçao',
                value: 'CW',
            },
        ],
    },
    {
        label: 'Cyprus',
        phoneCode: '+357',
        value: 'CY',
        countryFlag: '🇨🇾',
        regions: [
            {
                label: 'Ammochostos',
                value: '04',
            },
            {
                label: 'Keryneia',
                value: '05',
            },
            {
                label: 'Larnaka',
                value: '03',
            },
            {
                label: 'Lefkosia',
                value: '01',
            },
            {
                label: 'Lemesos',
                value: '02',
            },
            {
                label: 'Pafos',
                value: '05',
            },
        ],
    },
    {
        label: 'Czech Republic',
        phoneCode: '+420',
        value: 'CZ',
        countryFlag: '🇨🇿',
        regions: [
            {
                label: 'Hlavní město Praha',
                value: 'PR',
            },
            {
                label: 'Jihočeský kraj',
                value: 'JC',
            },
            {
                label: 'Jihomoravský kraj',
                value: 'JM',
            },
            {
                label: 'Karlovarský kraj',
                value: 'KA',
            },
            {
                label: 'Královéhradecký kraj',
                value: 'KR',
            },
            {
                label: 'Liberecký kraj',
                value: 'LI',
            },
            {
                label: 'Moravskoslezský kraj',
                value: 'MO',
            },
            {
                label: 'Olomoucký kraj',
                value: 'OL',
            },
            {
                label: 'Pardubický kraj',
                value: 'PA',
            },
            {
                label: 'Plzeňský kraj',
                value: 'PL',
            },
            {
                label: 'Středočeský kraj',
                value: 'ST',
            },
            {
                label: 'Ústecký kraj',
                value: 'US',
            },
            {
                label: 'Vysočina',
                value: 'VY',
            },
            {
                label: 'Zlínský kraj',
                value: 'ZL',
            },
        ],
    },
    {
        label: 'Denmark',
        phoneCode: '+45',
        value: 'DK',
        countryFlag: '🇩🇰',
        regions: [
            {
                label: 'Hovedstaden',
                value: '84',
            },
            {
                label: 'Kujalleq',
                value: 'GL-KU',
            },
            {
                label: 'Midtjylland',
                value: '82',
            },
            {
                label: 'Norderøerne',
                value: 'FO-01',
            },
            {
                label: 'Nordjylland',
                value: '81',
            },
            {
                label: 'Østerø',
                value: 'FO-06',
            },
            {
                label: 'Qaasuitsup',
                value: 'GL-QA',
            },
            {
                label: 'Qeqqata',
                value: 'GL-QE',
            },
            {
                label: 'Sandø',
                value: 'FO-02',
            },
            {
                label: 'Sermersooq',
                value: 'GL-SM',
            },
            {
                label: 'Sjælland',
                value: '85',
            },
            {
                label: 'Strømø',
                value: 'FO-03',
            },
            {
                label: 'Suderø',
                value: 'FO-04',
            },
            {
                label: 'Syddanmark',
                value: '83',
            },
            {
                label: 'Vågø',
                value: 'FO-05',
            },
        ],
    },
    {
        label: 'Djibouti',
        phoneCode: '+253',
        value: 'DJ',
        countryFlag: '🇩🇯',
        regions: [
            {
                label: 'Ali Sabieh',
                value: 'AS',
            },
            {
                label: 'Arta',
                value: 'AR',
            },
            {
                label: 'Dikhil',
                value: 'DI',
            },
            {
                label: 'Obock',
                value: 'OB',
            },
            {
                label: 'Tadjourah',
                value: 'TA',
            },
        ],
    },
    {
        label: 'Dominica',
        phoneCode: '+1767',
        value: 'DM',
        countryFlag: '🇩🇲',
        regions: [
            {
                label: 'Saint Andrew Parish',
                value: '02',
            },
            {
                label: 'Saint David Parish',
                value: '03',
            },
            {
                label: 'Saint George Parish',
                value: '04',
            },
            {
                label: 'Saint John Parish',
                value: '05',
            },
            {
                label: 'Saint Joseph Parish',
                value: '06',
            },
            {
                label: 'Saint Luke Parish',
                value: '07',
            },
            {
                label: 'Saint Mark Parish',
                value: '08',
            },
            {
                label: 'Saint Patrick Parish',
                value: '09',
            },
            {
                label: 'Saint Paul Parish',
                value: '10',
            },
            {
                label: 'Saint Peter Parish',
                value: '11',
            },
        ],
    },
    {
        label: 'Dominican Republic',
        phoneCode: '+1849',
        value: 'DO',
        countryFlag: '🇩🇴',
        regions: [
            {
                label: 'Cibao Central',
                value: '02',
            },
            {
                label: 'Del Valle',
                value: '37',
            },
            {
                label: 'Distrito Nacional',
                value: '01',
            },
            {
                label: 'Enriquillo',
                value: '38',
            },
            {
                label: 'Norcentral',
                value: '04',
            },
            {
                label: 'Nordeste',
                value: '34',
            },
            {
                label: 'Noroeste',
                value: '34',
            },
            {
                label: 'Norte',
                value: '35',
            },
            {
                label: 'Valdesia',
                value: '42',
            },
        ],
    },
    {
        label: 'Ecuador',
        phoneCode: '+593',
        value: 'EC',
        countryFlag: '🇪🇨',
        regions: [
            {
                label: 'Azuay',
                value: 'A',
            },
            {
                label: 'Bolívar',
                value: 'B',
            },
            {
                label: 'Cañar',
                value: 'F',
            },
            {
                label: 'Carchi',
                value: 'C',
            },
            {
                label: 'Chimborazo',
                value: 'H',
            },
            {
                label: 'Cotopaxi',
                value: 'X',
            },
            {
                label: 'El Oro',
                value: 'O',
            },
            {
                label: 'Esmeraldas',
                value: 'E',
            },
            {
                label: 'Galápagos',
                value: 'W',
            },
            {
                label: 'Guayas',
                value: 'G',
            },
            {
                label: 'Imbabura',
                value: 'I',
            },
            {
                label: 'Loja',
                value: 'L',
            },
            {
                label: 'Los Ríos',
                value: 'R',
            },
            {
                label: 'Manabí',
                value: 'M',
            },
            {
                label: 'Morona-Santiago',
                value: 'S',
            },
            {
                label: 'Napo',
                value: 'N',
            },
            {
                label: 'Orellana',
                value: 'D',
            },
            {
                label: 'Pastaza',
                value: 'Y',
            },
            {
                label: 'Pichincha',
                value: 'P',
            },
            {
                label: 'Santa Elena',
                value: 'SE',
            },
            {
                label: 'Santo Domingo de los Tsáchilas',
                value: 'SD',
            },
            {
                label: 'Sucumbíos',
                value: 'U',
            },
            {
                label: 'Tungurahua',
                value: 'T',
            },
            {
                label: 'Zamora-Chinchipe',
                value: 'Z',
            },
        ],
    },
    {
        label: 'Egypt',
        phoneCode: '+20',
        value: 'EG',
        countryFlag: '🇪🇬',
        regions: [
            {
                label: 'Alexandria',
                value: 'ALX',
            },
            {
                label: 'Aswan',
                value: 'ASN',
            },
            {
                label: 'Asyout',
                value: 'AST',
            },
            {
                label: 'Bani Sueif',
                value: 'BNS',
            },
            {
                label: 'Beheira',
                value: 'BH',
            },
            {
                label: 'Cairo',
                value: 'C',
            },
            {
                label: 'Daqahlia',
                value: 'DK',
            },
            {
                label: 'Dumiat',
                value: 'DT',
            },
            {
                label: 'El Bahr El Ahmar',
                value: 'BA',
            },
            {
                label: 'El Ismailia',
                value: 'IS',
            },
            {
                label: 'El Suez',
                value: 'SUZ',
            },
            {
                label: 'El Wadi El Gedeed',
                value: 'WAD',
            },
            {
                label: 'Fayoum',
                value: 'FYM',
            },
            {
                label: 'Gharbia',
                value: 'GH',
            },
            {
                label: 'Giza',
                value: 'SUZ',
            },
            {
                label: 'Helwan',
                value: 'HU',
            },
            {
                label: 'Kafr El Sheikh',
                value: 'KFS',
            },
            {
                label: 'Luxor',
                value: 'LX',
            },
            {
                label: 'Matrouh',
                value: 'MT',
            },
            {
                label: 'Menia',
                value: 'MN',
            },
            {
                label: 'Menofia',
                value: 'MNF',
            },
            {
                label: 'North Sinai',
                value: 'SIN',
            },
            {
                label: 'Port Said',
                value: 'PTS',
            },
            {
                label: 'Qalubia',
                value: 'KB',
            },
            {
                label: 'Qena',
                value: 'KN',
            },
            {
                label: 'Sharqia',
                value: 'SHR',
            },
            {
                label: 'Sixth of October',
                value: 'SU',
            },
            {
                label: 'Sohag',
                value: 'SHG',
            },
            {
                label: 'South Sinai',
                value: 'JS',
            },
        ],
    },
    {
        label: 'El Salvador',
        phoneCode: '+503',
        value: 'SV',
        countryFlag: '🇸🇻',
        regions: [
            {
                label: 'Ahuachapán',
                value: 'AH',
            },
            {
                label: 'Cabañas',
                value: 'CA',
            },
            {
                label: 'Cuscatlán',
                value: 'CU',
            },
            {
                label: 'Chalatenango',
                value: 'CH',
            },
            {
                label: 'La Libertad',
                value: 'LI',
            },
            {
                label: 'La Paz',
                value: 'PA',
            },
            {
                label: 'La Unión',
                value: 'UN',
            },
            {
                label: 'Morazán',
                value: 'MO',
            },
            {
                label: 'San Miguel',
                value: 'SM',
            },
            {
                label: 'San Salvador',
                value: 'SS',
            },
            {
                label: 'Santa Ana',
                value: 'SA',
            },
            {
                label: 'San Vicente',
                value: 'SV',
            },
            {
                label: 'Sonsonate',
                value: 'SO',
            },
            {
                label: 'Usulután',
                value: 'US',
            },
        ],
    },
    {
        label: 'Equatorial Guinea',
        phoneCode: '+240',
        value: 'GQ',
        countryFlag: '🇬🇶',
        regions: [
            {
                label: 'Annobón',
                value: 'AN',
            },
            {
                label: 'Bioko Norte',
                value: 'BN',
            },
            {
                label: 'Bioko Sur',
                value: 'BS',
            },
            {
                label: 'Centro Sur',
                value: 'CS',
            },
            {
                label: 'Kié-Ntem',
                value: 'KN',
            },
            {
                label: 'Litoral',
                value: 'LI',
            },
            {
                label: 'Wele-Nzas',
                value: 'WN',
            },
        ],
    },
    {
        label: 'Eritrea',
        phoneCode: '+291',
        value: 'ER',
        countryFlag: '🇪🇷',
        regions: [
            {
                label: 'Anseba',
                value: 'AN',
            },
            {
                label: 'Debub',
                value: 'DU',
            },
            {
                label: 'Debub-Keih-Bahri',
                value: 'DK',
            },
            {
                label: 'Gash-Barka',
                value: 'GB',
            },
            {
                label: 'Maekel',
                value: 'MA',
            },
            {
                label: 'Semien-Keih-Bahri',
                value: 'SK',
            },
        ],
    },
    {
        label: 'Estonia',
        phoneCode: '+372',
        value: 'EE',
        countryFlag: '🇪🇪',
        regions: [
            {
                label: 'Harjumaa (Tallinn)',
                value: '37',
            },
            {
                label: 'Hiiumaa (Kardla)',
                value: '39',
            },
            {
                label: 'Ida-Virumaa (Johvi)',
                value: '44',
            },
            {
                label: 'Järvamaa (Paide)',
                value: '41',
            },
            {
                label: 'Jõgevamaa (Jogeva)',
                value: '49',
            },
            {
                label: 'Läänemaa',
                value: '57',
            },
            {
                label: 'Lääne-Virumaa (Rakvere)',
                value: '59',
            },
            {
                label: 'Pärnumaa (Parnu)',
                value: '67',
            },
            {
                label: 'Põlvamaa (Polva)',
                value: '65',
            },
            {
                label: 'Raplamaa (Rapla)',
                value: '70',
            },
            {
                label: 'Saaremaa (Kuessaare)',
                value: '74',
            },
            {
                label: 'Tartumaa (Tartu)',
                value: '78',
            },
            {
                label: 'Valgamaa (Valga)',
                value: '82',
            },
            {
                label: 'Viljandimaa (Viljandi)',
                value: '84',
            },
            {
                label: 'Võrumaa (Voru)',
                value: '86',
            },
        ],
    },
    {
        label: 'Ethiopia',
        phoneCode: '+251',
        value: 'ET',
        countryFlag: '🇪🇹',
        regions: [
            {
                label: 'Addis Ababa',
                value: 'AA',
            },
            {
                label: 'Afar',
                value: 'AF',
            },
            {
                label: 'Amhara',
                value: 'AM',
            },
            {
                label: 'Benshangul-Gumaz',
                value: 'BE',
            },
            {
                label: 'Dire Dawa',
                value: 'DD',
            },
            {
                label: 'Gambela',
                value: 'GA',
            },
            {
                label: 'Harari',
                value: 'HA',
            },
            {
                label: 'Oromia',
                value: 'OR',
            },
            {
                label: 'Somali',
                value: 'SO',
            },
            {
                label: 'Southern Nations Nationalities and People\'s Region',
                value: 'SN',
            },
            {
                label: 'Tigray',
                value: 'TI',
            },
        ],
    },
    {
        label: 'Falkland Islands (Islas Malvinas)',
        phoneCode: '+500',
        value: 'FK',
        countryFlag: '🇫🇰',
        regions: [
            {
                label: 'Falkland Islands (Islas Malvinas)',
                value: null,
            },
        ],
    },
    {
        label: 'Faroe Islands',
        phoneCode: '+298',
        value: 'FO',
        countryFlag: '🇫🇴',
        regions: [
            {
                label: 'Bordoy',
                value: null,
            },
            {
                label: 'Eysturoy',
                value: null,
            },
            {
                label: 'Mykines',
                value: null,
            },
            {
                label: 'Sandoy',
                value: null,
            },
            {
                label: 'Skuvoy',
                value: null,
            },
            {
                label: 'Streymoy',
                value: null,
            },
            {
                label: 'Suduroy',
                value: null,
            },
            {
                label: 'Tvoroyri',
                value: null,
            },
            {
                label: 'Vagar',
                value: null,
            },
        ],
    },
    {
        label: 'Fiji',
        phoneCode: '+679',
        value: 'FJ',
        countryFlag: '🇫🇯',
        regions: [
            {
                label: 'Ba',
                value: '01',
            },
            {
                label: 'Bua',
                value: '01',
            },
            {
                label: 'Cakaudrove',
                value: '03',
            },
            {
                label: 'Kadavu',
                value: '04',
            },
            {
                label: 'Lau',
                value: '05',
            },
            {
                label: 'Lomaiviti',
                value: '06',
            },
            {
                label: 'Macuata',
                value: '07',
            },
            {
                label: 'Nadroga and Navosa',
                value: '08',
            },
            {
                label: 'Naitasiri',
                value: '09',
            },
            {
                label: 'Namosi',
                value: '10',
            },
            {
                label: 'Ra',
                value: '011',
            },
            {
                label: 'Rewa',
                value: '12',
            },
            {
                label: 'Rotuma',
                value: 'R',
            },
            {
                label: 'Serua',
                value: '12',
            },
            {
                label: 'Tailevu',
                value: '14',
            },
        ],
    },
    {
        label: 'Finland',
        phoneCode: '+358',
        value: 'FI',
        countryFlag: '🇫🇮',
        regions: [
            {
                label: 'Ahvenanmaan lääni',
                value: 'AL',
            },
            {
                label: 'Etelä-Suomen lääni',
                value: 'ES',
            },
            {
                label: 'Itä-Suomen lääni',
                value: 'IS',
            },
            {
                label: 'Länsi-Suomen lääni',
                value: 'LS',
            },
            {
                label: 'Lapin lääni',
                value: 'LL',
            },
            {
                label: 'Oulun lääni',
                value: 'OL',
            },
        ],
    },
    {
        label: 'France',
        phoneCode: '+33',
        value: 'FR',
        countryFlag: '🇫🇷',
        regions: [
            {
                label: 'Auvergne-Rhône-Alpes',
                value: 'ARA',
            },
            {
                label: 'Bourgogne-Franche-Comté',
                value: 'BFC',
            },
            {
                label: 'Bretagne',
                value: 'BRE',
            },
            {
                label: 'Centre-Val de Loire',
                value: 'CVL',
            },
            {
                label: 'Corse',
                value: 'COR',
            },
            {
                label: 'Grand Est',
                value: 'GES',
            },
            {
                label: 'Hauts-de-France',
                value: 'HDF',
            },
            {
                label: 'Île-de-France',
                value: 'IDF',
            },
            {
                label: 'Normandie',
                value: 'NOR',
            },
            {
                label: 'Nouvelle-Aquitaine',
                value: 'NAQ',
            },
            {
                label: 'Occitanie',
                value: 'OCC',
            },
            {
                label: 'Pays de la Loire',
                value: 'PDL',
            },
            {
                label: 'Provence-Alpes-Côte d\'Azur',
                value: 'PAC',
            },
            {
                label: 'Clipperton',
                value: 'CP',
            },
            {
                label: 'Guadeloupe',
                value: 'GP',
            },
            {
                label: 'Guyane',
                value: 'GF',
            },
            {
                label: 'Martinique',
                value: 'MQ',
            },
            {
                label: 'Mayotte',
                value: 'YT',
            },
            {
                label: 'Nouvelle Calédonie',
                value: 'NC',
            },
            {
                label: 'Polynésie',
                value: 'PF',
            },
            {
                label: 'Saint-Pierre-et-Miquelon',
                value: 'PM',
            },
            {
                label: 'Saint Barthélemy',
                value: 'BL',
            },
            {
                label: 'Saint Martin',
                value: 'MF',
            },
            {
                label: 'Réunion',
                value: 'RE',
            },
            {
                label: 'Terres Australes Françaises',
                value: 'TF',
            },
            {
                label: 'Wallis-et-Futuna',
                value: 'WF',
            },
        ],
    },
    {
        label: 'French Guiana',
        phoneCode: '+594',
        value: 'GF',
        countryFlag: '🇬🇫',
        regions: [
            {
                label: 'French Guiana',
                value: null,
            },
        ],
    },
    {
        label: 'French Polynesia',
        phoneCode: '+689',
        value: 'PF',
        countryFlag: '🇵🇫',
        regions: [
            {
                label: 'Archipel des Marquises',
                value: null,
            },
            {
                label: 'Archipel des Tuamotu',
                value: null,
            },
            {
                label: 'Archipel des Tubuai',
                value: null,
            },
            {
                label: 'Iles du Vent',
                value: null,
            },
            {
                label: 'Iles Sous-le-Vent',
                value: null,
            },
        ],
    },
    {
        label: 'French Southern and Antarctic Lands',
        phoneCode: '+262',
        value: 'TF',
        countryFlag: '🇹🇫',
        regions: [
            {
                label: 'Adelie Land',
                value: null,
            },
            {
                label: 'Ile Crozet',
                value: null,
            },
            {
                label: 'Iles Kerguelen',
                value: null,
            },
            {
                label: 'Iles Saint-Paul et Amsterdam',
                value: null,
            },
        ],
    },
    {
        label: 'Gabon',
        phoneCode: '+241',
        value: 'GA',
        countryFlag: '🇬🇦',
        regions: [
            {
                label: 'Estuaire',
                value: '1',
            },
            {
                label: 'Haut-Ogooué',
                value: '2',
            },
            {
                label: 'Moyen-Ogooué',
                value: '3',
            },
            {
                label: 'Ngounié',
                value: '4',
            },
            {
                label: 'Nyanga',
                value: '5',
            },
            {
                label: 'Ogooué-Ivindo',
                value: '6',
            },
            {
                label: 'Ogooué-Lolo',
                value: '7',
            },
            {
                label: 'Ogooué-Maritime',
                value: '8',
            },
            {
                label: 'Woleu-Ntem',
                value: '9',
            },
        ],
    },
    {
        label: 'Gambia, The',
        phoneCode: '+220',
        value: 'GM',
        countryFlag: '🇬🇲',
        regions: [
            {
                label: 'Banjul',
                value: 'B',
            },
            {
                label: 'Central River',
                value: 'M',
            },
            {
                label: 'Lower River',
                value: 'L',
            },
            {
                label: 'North Bank',
                value: 'N',
            },
            {
                label: 'Upper River',
                value: 'U',
            },
            {
                label: 'Western',
                value: 'W',
            },
        ],
    },
    {
        label: 'Georgia',
        phoneCode: '+995',
        value: 'GE',
        countryFlag: '🇬🇪',
        regions: [
            {
                label: 'Abkhazia (Sokhumi)',
                value: 'AB',
            },
            {
                label: 'Ajaria (Bat\'umi)',
                value: 'AJ',
            },
            {
                label: 'Guria',
                value: 'GU',
            },
            {
                label: 'Imereti',
                value: 'IM',
            },
            {
                label: 'K\'akheti',
                value: 'KA',
            },
            {
                label: 'Kvemo Kartli',
                value: 'KK',
            },
            {
                label: 'Mtshkheta-Mtianeti',
                value: 'MM',
            },
            {
                label: 'Rach\'a-Lexhkumi-KvemoSvaneti',
                value: 'RL',
            },
            {
                label: 'Samegrelo-Zemo Svaneti',
                value: 'SZ',
            },
            {
                label: 'Samtskhe-Javakheti',
                value: 'SJ',
            },
            {
                label: 'Shida Kartli',
                value: 'SK',
            },
            {
                label: 'Tbilisi',
                value: 'TB',
            },
        ],
    },
    {
        label: 'Germany',
        phoneCode: '+49',
        value: 'DE',
        countryFlag: '🇩🇪',
        regions: [
            {
                label: 'Baden-Württemberg',
                value: 'BW',
            },
            {
                label: 'Bayern',
                value: 'BY',
            },
            {
                label: 'Berlin',
                value: 'BE',
            },
            {
                label: 'Brandenburg',
                value: 'BB',
            },
            {
                label: 'Bremen',
                value: 'HB',
            },
            {
                label: 'Hamburg',
                value: 'HH',
            },
            {
                label: 'Hessen',
                value: 'HE',
            },
            {
                label: 'Mecklenburg-Vorpommern',
                value: 'MV',
            },
            {
                label: 'Niedersachsen',
                value: 'NI',
            },
            {
                label: 'Nordrhein-Westfalen',
                value: 'NW',
            },
            {
                label: 'Rheinland-Pfalz',
                value: 'RP',
            },
            {
                label: 'Saarland',
                value: 'SL',
            },
            {
                label: 'Sachsen',
                value: 'SN',
            },
            {
                label: 'Sachsen-Anhalt',
                value: 'ST',
            },
            {
                label: 'Schleswig-Holstein',
                value: 'SH',
            },
            {
                label: 'Thüringen',
                value: 'TH',
            },
        ],
    },
    {
        label: 'Ghana',
        phoneCode: '+233',
        value: 'GH',
        countryFlag: '🇬🇭',
        regions: [
            {
                label: 'Ahafo',
                value: null,
            },
            {
                label: 'Ashanti',
                value: 'AH',
            },
            {
                label: 'Bono',
                value: null,
            },
            {
                label: 'Bono East',
                value: null,
            },
            {
                label: 'Central',
                value: 'CP',
            },
            {
                label: 'Eastern',
                value: 'EP',
            },
            {
                label: 'Greater Accra',
                value: 'AA',
            },
            {
                label: 'Northern',
                value: 'NP',
            },
            {
                label: 'North East',
                value: null,
            },
            {
                label: 'Oti',
                value: null,
            },
            {
                label: 'Savannah',
                value: null,
            },
            {
                label: 'Upper East',
                value: 'UE',
            },
            {
                label: 'Upper West',
                value: 'UW',
            },
            {
                label: 'Volta',
                value: 'TV',
            },
            {
                label: 'Western',
                value: 'WP',
            },
            {
                label: 'Western North',
                value: null,
            },
        ],
    },
    {
        label: 'Gibraltar',
        phoneCode: '+350',
        value: 'GI',
        countryFlag: '🇬🇮',
        regions: [
            {
                label: 'Gibraltar',
                value: null,
            },
        ],
    },
    {
        label: 'Greece',
        phoneCode: '+30',
        value: 'GR',
        countryFlag: '🇬🇷',
        regions: [
            {
                label: 'Anatolikí Makedonía kai Thráki',
                value: 'A',
            },
            {
                label: 'Attikḯ',
                value: 'I',
            },
            {
                label: 'Dytikí Elláda',
                value: 'G',
            },
            {
                label: 'Dytikí Makedonía',
                value: 'C',
            },
            {
                label: 'Ionía Nísia',
                value: 'F',
            },
            {
                label: 'Kentrikí Makedonía',
                value: 'B',
            },
            {
                label: 'Krítí',
                value: 'M',
            },
            {
                label: 'Notío Aigaío',
                value: 'L',
            },
            {
                label: 'Peloponnísos',
                value: 'J',
            },
            {
                label: 'Stereá Elláda',
                value: 'H',
            },
            {
                label: 'Thessalía',
                value: 'E',
            },
            {
                label: 'Voreío Aigaío',
                value: 'K',
            },
            {
                label: 'Ípeiros',
                value: 'D',
            },
            {
                label: 'Ágion Óros',
                value: '69',
            },
        ],
    },
    {
        label: 'Greenland',
        phoneCode: '+299',
        value: 'GL',
        countryFlag: '🇬🇱',
        regions: [
            {
                label: 'Kommune Kujalleq',
                value: 'KU',
            },
            {
                label: 'Kommuneqarfik Sermersooq',
                value: 'SM',
            },
            {
                label: 'Qaasuitsup Kommunia',
                value: 'QA',
            },
            {
                label: 'Qeqqata Kommunia',
                value: 'QE',
            },
        ],
    },
    {
        label: 'Grenada',
        phoneCode: '+1473',
        value: 'GD',
        countryFlag: '🇬🇩',
        regions: [
            {
                label: 'Saint Andrew',
                value: '01',
            },
            {
                label: 'Saint David',
                value: '02',
            },
            {
                label: 'Saint George',
                value: '03',
            },
            {
                label: 'Saint John',
                value: '04',
            },
            {
                label: 'Saint Mark',
                value: '05',
            },
            {
                label: 'Saint Patrick',
                value: '06',
            },
            {
                label: 'Southern Grenadine Islands',
                value: '10',
            },
        ],
    },
    {
        label: 'Guadeloupe',
        phoneCode: '+590',
        value: 'GP',
        countryFlag: '🇬🇵',
        regions: [
            {
                label: 'Guadeloupe',
                value: null,
            },
        ],
    },
    {
        label: 'Guam',
        phoneCode: '+1671',
        value: 'GU',
        countryFlag: '🇬🇺',
        regions: [
            {
                label: 'Guam',
                value: null,
            },
        ],
    },
    {
        label: 'Guatemala',
        phoneCode: '+502',
        value: 'GT',
        countryFlag: '🇬🇹',
        regions: [
            {
                label: 'Alta Verapaz',
                value: 'AV',
            },
            {
                label: 'Baja Verapaz',
                value: 'BV',
            },
            {
                label: 'Chimaltenango',
                value: 'CM',
            },
            {
                label: 'Chiquimula',
                value: 'CQ',
            },
            {
                label: 'El Progreso',
                value: 'PR',
            },
            {
                label: 'Escuintla',
                value: 'ES',
            },
            {
                label: 'Guatemala',
                value: 'GU',
            },
            {
                label: 'Huehuetenango',
                value: 'HU',
            },
            {
                label: 'Izabal',
                value: 'IZ',
            },
            {
                label: 'Jalapa',
                value: 'JA',
            },
            {
                label: 'Jutiapa',
                value: 'JU',
            },
            {
                label: 'Petén',
                value: 'PE',
            },
            {
                label: 'Quetzaltenango',
                value: 'QZ',
            },
            {
                label: 'Quiché',
                value: 'QC',
            },
            {
                label: 'Retalhuleu',
                value: 'Re',
            },
            {
                label: 'Sacatepéquez',
                value: 'SA',
            },
            {
                label: 'San Marcos',
                value: 'SM',
            },
            {
                label: 'Santa Rosa',
                value: 'SR',
            },
            {
                label: 'Sololá',
                value: 'SO',
            },
            {
                label: 'Suchitepéquez',
                value: 'SU',
            },
            {
                label: 'Totonicapán',
                value: 'TO',
            },
            {
                label: 'Zacapa',
                value: 'ZA',
            },
        ],
    },
    {
        label: 'Guernsey',
        phoneCode: '+44',
        value: 'GG',
        countryFlag: '🇬🇬',
        regions: [
            {
                label: 'Castel',
                value: null,
            },
            {
                label: 'Forest',
                value: null,
            },
            {
                label: 'St. Andrew',
                value: null,
            },
            {
                label: 'St. Martin',
                value: null,
            },
            {
                label: 'St. Peter Port',
                value: null,
            },
            {
                label: 'St. Pierre du Bois',
                value: null,
            },
            {
                label: 'St. Sampson',
                value: null,
            },
            {
                label: 'St. Saviour',
                value: null,
            },
            {
                label: 'Torteval',
                value: null,
            },
            {
                label: 'Vale',
                value: null,
            },
        ],
    },
    {
        label: 'Guinea',
        phoneCode: '+224',
        value: 'GN',
        countryFlag: '🇬🇳',
        regions: [
            {
                label: 'Boké',
                value: 'B',
            },
            {
                label: 'Conakry',
                value: 'C',
            },
            {
                label: 'Faranah',
                value: 'F',
            },
            {
                label: 'Kankan',
                value: 'K',
            },
            {
                label: 'Kindia',
                value: 'D',
            },
            {
                label: 'Labé',
                value: 'L',
            },
            {
                label: 'Mamou',
                value: 'M',
            },
            {
                label: 'Nzérékoré',
                value: 'N',
            },
        ],
    },
    {
        label: 'Guinea-Bissau',
        phoneCode: '+245',
        value: 'GW',
        countryFlag: '🇬🇼',
        regions: [
            {
                label: 'Bafatá',
                value: 'BA',
            },
            {
                label: 'Biombo',
                value: 'BM',
            },
            {
                label: 'Bissau',
                value: 'BS',
            },
            {
                label: 'Bolama-Bijagos',
                value: 'BL',
            },
            {
                label: 'Cacheu',
                value: 'CA',
            },
            {
                label: 'Gabú',
                value: 'GA',
            },
            {
                label: 'Oio',
                value: 'OI',
            },
            {
                label: 'Quinara',
                value: 'QU',
            },
            {
                label: 'Tombali',
                value: 'TO',
            },
        ],
    },
    {
        label: 'Guyana',
        phoneCode: '+592',
        value: 'GY',
        countryFlag: '🇬🇾',
        regions: [
            {
                label: 'Barima-Waini',
                value: 'BA',
            },
            {
                label: 'Cuyuni-Mazaruni',
                value: 'CU',
            },
            {
                label: 'Demerara-Mahaica',
                value: 'DE',
            },
            {
                label: 'East Berbice-Corentyne',
                value: 'EB',
            },
            {
                label: 'Essequibo Islands-West Demerara',
                value: 'ES',
            },
            {
                label: 'Mahaica-Berbice',
                value: 'MA',
            },
            {
                label: 'Pomeroon-Supenaam',
                value: 'PM',
            },
            {
                label: 'Potaro-Siparuni',
                value: 'PT',
            },
            {
                label: 'Upper Demerara-Berbice',
                value: 'UD',
            },
            {
                label: 'Upper Takutu-Upper Essequibo',
                value: 'UT',
            },
        ],
    },
    {
        label: 'Haiti',
        phoneCode: '+509',
        value: 'HT',
        countryFlag: '🇭🇹',
        regions: [
            {
                label: 'Artibonite',
                value: 'AR',
            },
            {
                label: 'Centre',
                value: 'CE',
            },
            {
                label: 'Grand\'Anse',
                value: 'GA',
            },
            {
                label: 'Nippes',
                value: 'NI',
            },
            {
                label: 'Nord',
                value: 'ND',
            },
            {
                label: 'Nord-Est',
                value: 'NE',
            },
            {
                label: 'Nord-Ouest',
                value: 'NO',
            },
            {
                label: 'Ouest',
                value: 'OU',
            },
            {
                label: 'Sud',
                value: 'SD',
            },
            {
                label: 'Sud-Est',
                value: 'SE',
            },
        ],
    },
    {
        label: 'Heard Island and McDonald Islands',
        phoneCode: '+672',
        value: 'HM',
        countryFlag: '🇭🇲',
        regions: [
            {
                label: 'Heard Island and McDonald Islands',
                value: null,
            },
        ],
    },
    {
        label: 'Holy See (Vatican City)',
        phoneCode: '+379',
        value: 'VA',
        countryFlag: '🇻🇦',
        regions: [
            {
                label: 'Holy See (Vatican City)',
                value: '01',
            },
        ],
    },
    {
        label: 'Honduras',
        phoneCode: '+504',
        value: 'HN',
        countryFlag: '🇭🇳',
        regions: [
            {
                label: 'Atlántida',
                value: 'AT',
            },
            {
                label: 'Choluteca',
                value: 'CH',
            },
            {
                label: 'Colón',
                value: 'CL',
            },
            {
                label: 'Comayagua',
                value: 'CM',
            },
            {
                label: 'Copán',
                value: 'CP',
            },
            {
                label: 'Cortés',
                value: 'CR',
            },
            {
                label: 'El Paraíso',
                value: 'EP',
            },
            {
                label: 'Francisco Morazan',
                value: 'FM',
            },
            {
                label: 'Gracias a Dios',
                value: 'GD',
            },
            {
                label: 'Intibucá',
                value: 'IN',
            },
            {
                label: 'Islas de la Bahía',
                value: 'IB',
            },
            {
                label: 'La Paz',
                value: 'LP',
            },
            {
                label: 'Lempira',
                value: 'LE',
            },
            {
                label: 'Ocotepeque',
                value: 'OC',
            },
            {
                label: 'Olancho',
                value: 'OL',
            },
            {
                label: 'Santa Bárbara',
                value: 'SB',
            },
            {
                label: 'Valle',
                value: 'VA',
            },
            {
                label: 'Yoro',
                value: 'YO',
            },
        ],
    },
    {
        label: 'Hong Kong',
        phoneCode: '+852',
        value: 'HK',
        countryFlag: '🇭🇰',
        regions: [
            {
                label: 'Hong Kong',
                value: 'HK',
            },
        ],
    },
    {
        label: 'Hungary',
        phoneCode: '+36',
        value: 'HU',
        countryFlag: '🇭🇺',
        regions: [
            {
                label: 'Bács-Kiskun',
                value: 'BK',
            },
            {
                label: 'Baranya',
                value: 'BA',
            },
            {
                label: 'Békés',
                value: 'BE',
            },
            {
                label: 'Békéscsaba',
                value: 'BC',
            },
            {
                label: 'Borsod-Abauj-Zemplen',
                value: 'BZ',
            },
            {
                label: 'Budapest',
                value: 'BU',
            },
            {
                label: 'Csongrád',
                value: 'CS',
            },
            {
                label: 'Debrecen',
                value: 'DE',
            },
            {
                label: 'Dunaújváros',
                value: 'DU',
            },
            {
                label: 'Eger',
                value: 'EG',
            },
            {
                label: 'Érd',
                value: 'ER',
            },
            {
                label: 'Fejér',
                value: 'FE',
            },
            {
                label: 'Győr',
                value: 'GY',
            },
            {
                label: 'Győr-Moson-Sopron',
                value: 'GS',
            },
            {
                label: 'Hajdú-Bihar',
                value: 'HB',
            },
            {
                label: 'Heves',
                value: 'HE',
            },
            {
                label: 'Hódmezővásárhely',
                value: 'HV',
            },
            {
                label: 'Jász-Nagykun-Szolnok',
                value: 'N',
            },
            {
                label: 'Kaposvár',
                value: 'KV',
            },
            {
                label: 'Kecskemét',
                value: 'KM',
            },
            {
                label: 'Komárom-Esztergom',
                value: 'KE',
            },
            {
                label: 'Miskolc',
                value: 'MI',
            },
            {
                label: 'Nagykanizsa',
                value: 'NK',
            },
            {
                label: 'Nógrád',
                value: 'NO',
            },
            {
                label: 'Nyíregyháza',
                value: 'NY',
            },
            {
                label: 'Pécs',
                value: 'PS',
            },
            {
                label: 'Pest',
                value: 'PE',
            },
            {
                label: 'Salgótarján',
                value: 'ST',
            },
            {
                label: 'Somogy',
                value: 'SO',
            },
            {
                label: 'Sopron',
                value: 'SN',
            },
            {
                label: 'Szabolcs-á-Bereg',
                value: 'SZ',
            },
            {
                label: 'Szeged',
                value: 'SD',
            },
            {
                label: 'Székesfehérvár',
                value: 'SF',
            },
            {
                label: 'Szekszárd',
                value: 'SS',
            },
            {
                label: 'Szolnok',
                value: 'SK',
            },
            {
                label: 'Szombathely',
                value: 'SH',
            },
            {
                label: 'Tatabánya',
                value: 'TB',
            },
            {
                label: 'Tolna',
                value: 'TO',
            },
            {
                label: 'Vas',
                value: 'VA',
            },
            {
                label: 'Veszprém',
                value: 'VE',
            },
            {
                label: 'Veszprém (City)',
                value: 'VM',
            },
            {
                label: 'Zala',
                value: 'ZA',
            },
            {
                label: 'Zalaegerszeg',
                value: 'ZE',
            },
        ],
    },
    {
        label: 'Iceland',
        phoneCode: '+354',
        value: 'IS',
        countryFlag: '🇮🇸',
        regions: [
            {
                label: 'Austurland',
                value: '7',
            },
            {
                label: 'Höfuðborgarsvæði utan Reykjavíkur',
                value: '1',
            },
            {
                label: 'Norðurland eystra',
                value: '6',
            },
            {
                label: 'Norðurland vestra',
                value: '5',
            },
            {
                label: 'Suðurland',
                value: '8',
            },
            {
                label: 'Suðurnes',
                value: '2',
            },
            {
                label: 'Vestfirðir',
                value: '4',
            },
            {
                label: 'Vesturland',
                value: '3',
            },
        ],
    },
    {
        label: 'India',
        phoneCode: '+91',
        value: 'IN',
        countryFlag: '🇮🇳',
        regions: [
            {
                label: 'Andaman and Nicobar Islands',
                value: 'AN',
            },
            {
                label: 'Andhra Pradesh',
                value: 'AP',
            },
            {
                label: 'Arunachal Pradesh',
                value: 'AR',
            },
            {
                label: 'Assam',
                value: 'AS',
            },
            {
                label: 'Bihar',
                value: 'BR',
            },
            {
                label: 'Chandigarh',
                value: 'CH',
            },
            {
                label: 'Chhattisgarh',
                value: 'CT',
            },
            {
                label: 'Dadra and Nagar Haveli',
                value: 'DN',
            },
            {
                label: 'Daman and Diu',
                value: 'DD',
            },
            {
                label: 'Delhi',
                value: 'DL',
            },
            {
                label: 'Goa',
                value: 'GA',
            },
            {
                label: 'Gujarat',
                value: 'GJ',
            },
            {
                label: 'Haryana',
                value: 'HR',
            },
            {
                label: 'Himachal Pradesh',
                value: 'HP',
            },
            {
                label: 'Jammu and Kashmir',
                value: 'JK',
            },
            {
                label: 'Jharkhand',
                value: 'JH',
            },
            {
                label: 'Karnataka',
                value: 'KA',
            },
            {
                label: 'Kerala',
                value: 'KL',
            },
            {
                label: 'Lakshadweep',
                value: 'LD',
            },
            {
                label: 'Madhya Pradesh',
                value: 'MP',
            },
            {
                label: 'Maharashtra',
                value: 'MH',
            },
            {
                label: 'Manipur',
                value: 'MN',
            },
            {
                label: 'Meghalaya',
                value: 'ML',
            },
            {
                label: 'Mizoram',
                value: 'MZ',
            },
            {
                label: 'Nagaland',
                value: 'NL',
            },
            {
                label: 'Odisha',
                value: 'OR',
            },
            {
                label: 'Puducherry',
                value: 'PY',
            },
            {
                label: 'Punjab',
                value: 'PB',
            },
            {
                label: 'Rajasthan',
                value: 'RJ',
            },
            {
                label: 'Sikkim',
                value: 'WK',
            },
            {
                label: 'Tamil Nadu',
                value: 'TN',
            },
            {
                label: 'Telangana',
                value: 'TG',
            },
            {
                label: 'Tripura',
                value: 'TR',
            },
            {
                label: 'Uttarakhand',
                value: 'UT',
            },
            {
                label: 'Uttar Pradesh',
                value: 'UP',
            },
            {
                label: 'West Bengal',
                value: 'WB',
            },
        ],
    },
    {
        label: 'Indonesia',
        phoneCode: '+62',
        value: 'ID',
        countryFlag: '🇮🇩',
        regions: [
            {
                label: 'Aceh',
                value: 'AC',
            },
            {
                label: 'Bali',
                value: 'BA',
            },
            {
                label: 'Bangka Belitung',
                value: 'BB',
            },
            {
                label: 'Banten',
                value: 'BT',
            },
            {
                label: 'Bengkulu',
                value: 'BE',
            },
            {
                label: 'Gorontalo',
                value: 'GO',
            },
            {
                label: 'Jakarta Raya',
                value: 'JK',
            },
            {
                label: 'Jambi',
                value: 'JA',
            },
            {
                label: 'Jawa Barat',
                value: 'JB',
            },
            {
                label: 'Jawa Tengah',
                value: 'JT',
            },
            {
                label: 'Jawa Timur',
                value: 'JI',
            },
            {
                label: 'Kalimantan Barat',
                value: 'KB',
            },
            {
                label: 'Kalimantan Selatan',
                value: 'KS',
            },
            {
                label: 'Kalimantan Tengah',
                value: 'KT',
            },
            {
                label: 'Kalimantan Timur',
                value: 'KI',
            },
            {
                label: 'Kalimantan Utara',
                value: 'KU',
            },
            {
                label: 'Kepulauan Riau',
                value: 'KR',
            },
            {
                label: 'Lampung',
                value: 'LA',
            },
            {
                label: 'Maluku',
                value: 'MA',
            },
            {
                label: 'Maluku Utara',
                value: 'MU',
            },
            {
                label: 'Nusa Tenggara Barat',
                value: 'NB',
            },
            {
                label: 'Nusa Tenggara Timur',
                value: 'NT',
            },
            {
                label: 'Papua',
                value: 'PA',
            },
            {
                label: 'Papua Barat',
                value: 'PB',
            },
            {
                label: 'Riau',
                value: 'RI',
            },
            {
                label: 'Sulawesi Selatan',
                value: 'SR',
            },
            {
                label: 'Sulawesi Tengah',
                value: 'ST',
            },
            {
                label: 'Sulawesi Tenggara',
                value: 'SG',
            },
            {
                label: 'Sulawesi Utara',
                value: 'SA',
            },
            {
                label: 'Sumatera Barat',
                value: 'SB',
            },
            {
                label: 'Sumatera Selatan',
                value: 'SS',
            },
            {
                label: 'Sumatera Utara',
                value: 'SU',
            },
            {
                label: 'Yogyakarta',
                value: 'YO',
            },
        ],
    },
    {
        label: 'Iran, Islamic Republic of',
        phoneCode: '+98',
        value: 'IR',
        countryFlag: '🇮🇷',
        regions: [
            {
                label: 'Alborz',
                value: '32',
            },
            {
                label: 'Ardabīl',
                value: '03',
            },
            {
                label: 'Āz̄arbāyjān-e Gharbī',
                value: '02',
            },
            {
                label: 'Āz̄arbāyjān-e Sharqī',
                value: '01',
            },
            {
                label: 'Būshehr',
                value: '06',
            },
            {
                label: 'Chahār Maḩāl va Bakhtīārī',
                value: '08',
            },
            {
                label: 'Eşfahān',
                value: '04',
            },
            {
                label: 'Fārs',
                value: '14',
            },
            {
                label: 'Gīlān',
                value: '19',
            },
            {
                label: 'Golestān',
                value: '27',
            },
            {
                label: 'Hamadān',
                value: '24',
            },
            {
                label: 'Hormozgān',
                value: '23',
            },
            {
                label: 'Īlām',
                value: '05',
            },
            {
                label: 'Kermān',
                value: '15',
            },
            {
                label: 'Kermānshāh',
                value: '17',
            },
            {
                label: 'Khorāsān-e Jonūbī',
                value: '29',
            },
            {
                label: 'Khorāsān-e Raẕavī',
                value: '30',
            },
            {
                label: 'Khorāsān-e Shomālī',
                value: '61',
            },
            {
                label: 'Khūzestān',
                value: '10',
            },
            {
                label: 'Kohgīlūyeh va Bowyer Aḩmad',
                value: '18',
            },
            {
                label: 'Kordestān',
                value: '16',
            },
            {
                label: 'Lorestān',
                value: '20',
            },
            {
                label: 'Markazi',
                value: '22',
            },
            {
                label: 'Māzandarān',
                value: '21',
            },
            {
                label: 'Qazvīn',
                value: '28',
            },
            {
                label: 'Qom',
                value: '26',
            },
            {
                label: 'Semnān',
                value: '12',
            },
            {
                label: 'Sīstān va Balūchestān',
                value: '13',
            },
            {
                label: 'Tehrān',
                value: '07',
            },
            {
                label: 'Yazd',
                value: '25',
            },
            {
                label: 'Zanjān',
                value: '11',
            },
        ],
    },
    {
        label: 'Iraq',
        phoneCode: '+964',
        value: 'IQ',
        countryFlag: '🇮🇶',
        regions: [
            {
                label: 'Al Anbār',
                value: 'AN',
            },
            {
                label: 'Al Başrah',
                value: 'BA',
            },
            {
                label: 'Al Muthanná',
                value: 'MU',
            },
            {
                label: 'Al Qādisīyah',
                value: 'QA',
            },
            {
                label: 'An Najaf',
                value: 'NA',
            },
            {
                label: 'Arbīl',
                value: 'AR',
            },
            {
                label: 'As Sulaymānīyah',
                value: 'SU',
            },
            {
                label: 'Bābil',
                value: 'BB',
            },
            {
                label: 'Baghdād',
                value: 'BG',
            },
            {
                label: 'Dohuk',
                value: 'DA',
            },
            {
                label: 'Dhī Qār',
                value: 'DQ',
            },
            {
                label: 'Diyālá',
                value: 'DI',
            },
            {
                label: 'Karbalā\'',
                value: 'KA',
            },
            {
                label: 'Kirkuk',
                value: 'KI',
            },
            {
                label: 'Maysān',
                value: 'MA',
            },
            {
                label: 'Nīnawá',
                value: 'NI',
            },
            {
                label: 'Şalāḩ ad Dīn',
                value: 'SD',
            },
            {
                label: 'Wāsiţ',
                value: 'WA',
            },
        ],
    },
    {
        label: 'Ireland',
        phoneCode: '+353',
        value: 'IE',
        countryFlag: '🇮🇪',
        regions: [
            {
                label: 'Carlow',
                value: 'CW',
            },
            {
                label: 'Cavan',
                value: 'CN',
            },
            {
                label: 'Clare',
                value: 'CE',
            },
            {
                label: 'Cork',
                value: 'CO',
            },
            {
                label: 'Donegal',
                value: 'DL',
            },
            {
                label: 'Dublin',
                value: 'D',
            },
            {
                label: 'Galway',
                value: 'G',
            },
            {
                label: 'Kerry',
                value: 'KY',
            },
            {
                label: 'Kildare',
                value: 'KE',
            },
            {
                label: 'Kilkenny',
                value: 'KK',
            },
            {
                label: 'Laois',
                value: 'LS',
            },
            {
                label: 'Leitrim',
                value: 'LM',
            },
            {
                label: 'Limerick',
                value: 'LK',
            },
            {
                label: 'Longford',
                value: 'LD',
            },
            {
                label: 'Louth',
                value: 'LH',
            },
            {
                label: 'Mayo',
                value: 'MO',
            },
            {
                label: 'Meath',
                value: 'MH',
            },
            {
                label: 'Monaghan',
                value: 'MN',
            },
            {
                label: 'Offaly',
                value: 'OY',
            },
            {
                label: 'Roscommon',
                value: 'RN',
            },
            {
                label: 'Sligo',
                value: 'SO',
            },
            {
                label: 'Tipperary',
                value: 'TA',
            },
            {
                label: 'Waterford',
                value: 'WD',
            },
            {
                label: 'Westmeath',
                value: 'WH',
            },
            {
                label: 'Wexford',
                value: 'WX',
            },
            {
                label: 'Wicklow',
                value: 'WW',
            },
        ],
    },
    {
        label: 'Isle of Man',
        phoneCode: '+44',
        value: 'IM',
        countryFlag: '🇮🇲',
        regions: [
            {
                label: 'Isle of Man',
                value: null,
            },
        ],
    },
    {
        label: 'Israel',
        phoneCode: '+972',
        value: 'IL',
        countryFlag: '🇮🇱',
        regions: [
            {
                label: 'HaDarom',
                value: 'D',
            },
            {
                label: 'HaMerkaz',
                value: 'M',
            },
            {
                label: 'HaTsafon',
                value: 'Z',
            },
            {
                label: 'H̱efa',
                value: 'HA',
            },
            {
                label: 'Tel-Aviv',
                value: 'TA',
            },
            {
                label: 'Yerushalayim',
                value: 'JM',
            },
        ],
    },
    {
        label: 'Italy',
        phoneCode: '+39',
        value: 'IT',
        countryFlag: '🇮🇹',
        regions: [
            {
                label: 'Abruzzo',
                value: '65',
            },
            {
                label: 'Basilicata',
                value: '77',
            },
            {
                label: 'Calabria',
                value: '78',
            },
            {
                label: 'Campania',
                value: '72',
            },
            {
                label: 'Emilia-Romagna',
                value: '45',
            },
            {
                label: 'Friuli-Venezia Giulia',
                value: '36',
            },
            {
                label: 'Lazio',
                value: '62',
            },
            {
                label: 'Liguria',
                value: '42',
            },
            {
                label: 'Lombardia',
                value: '25',
            },
            {
                label: 'Marche',
                value: '57',
            },
            {
                label: 'Molise',
                value: '67',
            },
            {
                label: 'Piemonte',
                value: '21',
            },
            {
                label: 'Puglia',
                value: '75',
            },
            {
                label: 'Sardegna',
                value: '88',
            },
            {
                label: 'Sicilia',
                value: '82',
            },
            {
                label: 'Toscana',
                value: '52',
            },
            {
                label: 'Trentino-Alto Adige',
                value: '32',
            },
            {
                label: 'Umbria',
                value: '55',
            },
            {
                label: 'Valle d\'Aosta',
                value: '23',
            },
            {
                label: 'Veneto',
                value: '34',
            },
        ],
    },
    {
        label: 'Jamaica',
        phoneCode: '+1876',
        value: 'JM',
        countryFlag: '🇯🇲',
        regions: [
            {
                label: 'Clarendon',
                value: '13',
            },
            {
                label: 'Hanover',
                value: '09',
            },
            {
                label: 'Kingston',
                value: '01',
            },
            {
                label: 'Manchester',
                value: '12',
            },
            {
                label: 'Portland',
                value: '04',
            },
            {
                label: 'Saint Andrew',
                value: '02',
            },
            {
                label: 'Saint Ann',
                value: '06',
            },
            {
                label: 'Saint Catherine',
                value: '14',
            },
            {
                label: 'Saint Elizabeth',
                value: '11',
            },
            {
                label: 'Saint James',
                value: '08',
            },
            {
                label: 'Saint Mary',
                value: '05',
            },
            {
                label: 'Saint Thomas',
                value: '03',
            },
            {
                label: 'Trelawny',
                value: '07',
            },
            {
                label: 'Westmoreland',
                value: '10',
            },
        ],
    },
    {
        label: 'Japan',
        phoneCode: '+81',
        value: 'JP',
        countryFlag: '🇯🇵',
        regions: [
            {
                label: 'Aichi',
                value: '23',
            },
            {
                label: 'Akita',
                value: '05',
            },
            {
                label: 'Aomori',
                value: '02',
            },
            {
                label: 'Chiba',
                value: '12',
            },
            {
                label: 'Ehime',
                value: '38',
            },
            {
                label: 'Fukui',
                value: '18',
            },
            {
                label: 'Fukuoka',
                value: '40',
            },
            {
                label: 'Fukushima',
                value: '07',
            },
            {
                label: 'Gifu',
                value: '21',
            },
            {
                label: 'Gunma',
                value: '10',
            },
            {
                label: 'Hiroshima',
                value: '34',
            },
            {
                label: 'Hokkaido',
                value: '01',
            },
            {
                label: 'Hyogo',
                value: '28',
            },
            {
                label: 'Ibaraki',
                value: '08',
            },
            {
                label: 'Ishikawa',
                value: '17',
            },
            {
                label: 'Iwate',
                value: '03',
            },
            {
                label: 'Kagawa',
                value: '37',
            },
            {
                label: 'Kagoshima',
                value: '46',
            },
            {
                label: 'Kanagawa',
                value: '14',
            },
            {
                label: 'Kochi',
                value: '39',
            },
            {
                label: 'Kumamoto',
                value: '43',
            },
            {
                label: 'Kyoto',
                value: '26',
            },
            {
                label: 'Mie',
                value: '24',
            },
            {
                label: 'Miyagi',
                value: '04',
            },
            {
                label: 'Miyazaki',
                value: '45',
            },
            {
                label: 'Nagano',
                value: '20',
            },
            {
                label: 'Nagasaki',
                value: '42',
            },
            {
                label: 'Nara',
                value: '29',
            },
            {
                label: 'Niigata',
                value: '15',
            },
            {
                label: 'Oita',
                value: '44',
            },
            {
                label: 'Okayama',
                value: '33',
            },
            {
                label: 'Okinawa',
                value: '47',
            },
            {
                label: 'Osaka',
                value: '27',
            },
            {
                label: 'Saga',
                value: '41',
            },
            {
                label: 'Saitama',
                value: '11',
            },
            {
                label: 'Shiga',
                value: '25',
            },
            {
                label: 'Shimane',
                value: '32',
            },
            {
                label: 'Shizuoka',
                value: '22',
            },
            {
                label: 'Tochigi',
                value: '09',
            },
            {
                label: 'Tokushima',
                value: '36',
            },
            {
                label: 'Tokyo',
                value: '13',
            },
            {
                label: 'Tottori',
                value: '31',
            },
            {
                label: 'Toyama',
                value: '16',
            },
            {
                label: 'Wakayama',
                value: '30',
            },
            {
                label: 'Yamagata',
                value: '06',
            },
            {
                label: 'Yamaguchi',
                value: '35',
            },
            {
                label: 'Yamanashi',
                value: '19',
            },
        ],
    },
    {
        label: 'Jersey',
        phoneCode: '+44',
        value: 'JE',
        countryFlag: '🇯🇪',
        regions: [
            {
                label: 'Jersey',
                value: null,
            },
        ],
    },
    {
        label: 'Jordan',
        phoneCode: '+962',
        value: 'JO',
        countryFlag: '🇯🇴',
        regions: [
            {
                label: '‘Ajlūn',
                value: 'AJ',
            },
            {
                label: 'Al \'Aqabah',
                value: 'AQ',
            },
            {
                label: 'Al Balqā’',
                value: 'BA',
            },
            {
                label: 'Al Karak',
                value: 'KA',
            },
            {
                label: 'Al Mafraq',
                value: 'MA',
            },
            {
                label: 'Al ‘A̅şimah',
                value: 'AM',
            },
            {
                label: 'Aţ Ţafīlah',
                value: 'AT',
            },
            {
                label: 'Az Zarqā’',
                value: 'AZ',
            },
            {
                label: 'Irbid',
                value: 'IR',
            },
            {
                label: 'Jarash',
                value: 'JA',
            },
            {
                label: 'Ma‘ān',
                value: 'MN',
            },
            {
                label: 'Mādabā',
                value: 'MD',
            },
        ],
    },
    {
        label: 'Kazakhstan',
        phoneCode: '+77',
        value: 'KZ',
        countryFlag: '🇰🇿',
        regions: [
            {
                label: 'Almaty',
                value: 'ALA',
            },
            {
                label: 'Aqmola',
                value: 'AKM',
            },
            {
                label: 'Aqtobe',
                value: 'AKT',
            },
            {
                label: 'Astana',
                value: 'AST',
            },
            {
                label: 'Atyrau',
                value: 'ATY',
            },
            {
                label: 'Batys Qazaqstan',
                value: 'ZAP',
            },
            {
                label: 'Bayqongyr',
                value: null,
            },
            {
                label: 'Mangghystau',
                value: 'MAN',
            },
            {
                label: 'Ongtustik Qazaqstan',
                value: 'YUZ',
            },
            {
                label: 'Pavlodar',
                value: 'PAV',
            },
            {
                label: 'Qaraghandy',
                value: 'KAR',
            },
            {
                label: 'Qostanay',
                value: 'KUS',
            },
            {
                label: 'Qyzylorda',
                value: 'KZY',
            },
            {
                label: 'Shyghys Qazaqstan',
                value: 'VOS',
            },
            {
                label: 'Soltustik Qazaqstan',
                value: 'SEV',
            },
            {
                label: 'Zhambyl',
                value: 'ZHA',
            },
        ],
    },
    {
        label: 'Kenya',
        phoneCode: '+254',
        value: 'KE',
        countryFlag: '🇰🇪',
        regions: [
            {
                label: 'Baringo',
                value: '01',
            },
            {
                label: 'Bomet',
                value: '02',
            },
            {
                label: 'Bungoma',
                value: '03',
            },
            {
                label: 'Busia',
                value: '04',
            },
            {
                label: 'Eleyo/Marakwet',
                value: '05',
            },
            {
                label: 'Embu',
                value: '06',
            },
            {
                label: 'Garissa',
                value: '07',
            },
            {
                label: 'Homa Bay',
                value: '08',
            },
            {
                label: 'Isiolo',
                value: '09',
            },
            {
                label: 'Kajiado',
                value: '10',
            },
            {
                label: 'Kakamega',
                value: '11',
            },
            {
                label: 'Kericho',
                value: '12',
            },
            {
                label: 'Kiambu',
                value: '13',
            },
            {
                label: 'Kilifi',
                value: '14',
            },
            {
                label: 'Kirinyaga',
                value: '15',
            },
            {
                label: 'Kisii',
                value: '16',
            },
            {
                label: 'Kisumu',
                value: '17',
            },
            {
                label: 'Kitui',
                value: '18',
            },
            {
                label: 'Kwale',
                value: '19',
            },
            {
                label: 'Laikipia',
                value: '20',
            },
            {
                label: 'Lamu',
                value: '21',
            },
            {
                label: 'Machakos',
                value: '22',
            },
            {
                label: 'Makueni',
                value: '23',
            },
            {
                label: 'Mandera',
                value: '24',
            },
            {
                label: 'Marsabit',
                value: '25',
            },
            {
                label: 'Meru',
                value: '26',
            },
            {
                label: 'Migori',
                value: '27',
            },
            {
                label: 'Mombasa',
                value: '28',
            },
            {
                label: 'Murang\'a',
                value: '29',
            },
            {
                label: 'Nairobi City',
                value: '30',
            },
            {
                label: 'Nakuru',
                value: '31',
            },
            {
                label: 'Nandi',
                value: '32',
            },
            {
                label: 'Narok',
                value: '33',
            },
            {
                label: 'Nyamira',
                value: '34',
            },
            {
                label: 'Nyandarua',
                value: '35',
            },
            {
                label: 'Nyeri',
                value: '36',
            },
            {
                label: 'Samburu',
                value: '37',
            },
            {
                label: 'Siaya',
                value: '38',
            },
            {
                label: 'Taita/Taveta',
                value: '39',
            },
            {
                label: 'Tana River',
                value: '40',
            },
            {
                label: 'Tharaka-Nithi',
                value: '41',
            },
            {
                label: 'Trans Nzoia',
                value: '42',
            },
            {
                label: 'Turkana',
                value: '43',
            },
            {
                label: 'Uasin Gishu',
                value: '44',
            },
            {
                label: 'Vihiga',
                value: '45',
            },
            {
                label: 'Wajir',
                value: '46',
            },
            {
                label: 'West Pokot',
                value: '47',
            },
        ],
    },
    {
        label: 'Kiribati',
        phoneCode: '+686',
        value: 'KI',
        countryFlag: '🇰🇮',
        regions: [
            {
                label: 'Abaiang',
                value: null,
            },
            {
                label: 'Abemama',
                value: null,
            },
            {
                label: 'Aranuka',
                value: null,
            },
            {
                label: 'Arorae',
                value: null,
            },
            {
                label: 'Banaba',
                value: null,
            },
            {
                label: 'Beru',
                value: null,
            },
            {
                label: 'Butaritari',
                value: null,
            },
            {
                label: 'Central Gilberts',
                value: null,
            },
            {
                label: 'Gilbert Islands',
                value: 'G',
            },
            {
                label: 'Kanton',
                value: null,
            },
            {
                label: 'Kiritimati',
                value: null,
            },
            {
                label: 'Kuria',
                value: null,
            },
            {
                label: 'Line Islands',
                value: 'L',
            },
            {
                label: 'Maiana',
                value: null,
            },
            {
                label: 'Makin',
                value: null,
            },
            {
                label: 'Marakei',
                value: null,
            },
            {
                label: 'Nikunau',
                value: null,
            },
            {
                label: 'Nonouti',
                value: null,
            },
            {
                label: 'Northern Gilberts',
                value: null,
            },
            {
                label: 'Onotoa',
                value: null,
            },
            {
                label: 'Phoenix Islands',
                value: 'P',
            },
            {
                label: 'Southern Gilberts',
                value: null,
            },
            {
                label: 'Tabiteuea',
                value: null,
            },
            {
                label: 'Tabuaeran',
                value: null,
            },
            {
                label: 'Tamana',
                value: null,
            },
            {
                label: 'Tarawa',
                value: null,
            },
            {
                label: 'Teraina',
                value: null,
            },
        ],
    },
    {
        label: 'Korea, Democratic People\'s Republic of',
        phoneCode: '+850',
        value: 'KP',
        countryFlag: '🇰🇵',
        regions: [
            {
                label: 'Chagang-do (Chagang Province)',
                value: '04',
            },
            {
                label: 'Hamgyong-bukto (North Hamgyong Province)',
                value: '09',
            },
            {
                label: 'Hamgyong-namdo (South Hamgyong Province)',
                value: '08',
            },
            {
                label: 'Hwanghae-bukto (North Hwanghae Province)',
                value: '06',
            },
            {
                label: 'Hwanghae-namdo (South Hwanghae Province)',
                value: '05',
            },
            {
                label: 'Kangwon-do (Kangwon Province)',
                value: '07',
            },
            {
                label: 'Nasŏn (Najin-Sŏnbong)',
                value: '13',
            },
            {
                label: 'P\'yongan-bukto (North P\'yongan Province)',
                value: '03',
            },
            {
                label: 'P\'yongan-namdo (South P\'yongan Province)',
                value: '02',
            },
            {
                label: 'P\'yongyang-si (P\'yongyang City)',
                value: '01',
            },
            {
                label: 'Yanggang-do (Yanggang Province)',
                value: '10',
            },
        ],
    },
    {
        label: 'Korea, Republic of',
        phoneCode: '+82',
        value: 'KR',
        countryFlag: '🇰🇷',
        regions: [
            {
                label: 'Ch\'ungch\'ongbuk-do',
                value: '43',
            },
            {
                label: 'Ch\'ungch\'ongnam-do',
                value: '44',
            },
            {
                label: 'Cheju-do',
                value: '49',
            },
            {
                label: 'Chollabuk-do',
                value: '45',
            },
            {
                label: 'Chollanam-do',
                value: '46',
            },
            {
                label: 'Inch\'on-Kwangyokhi',
                value: '28',
            },
            {
                label: 'Kang-won-do',
                value: '42',
            },
            {
                label: 'Kwangju-Kwangyokshi',
                value: '28',
            },
            {
                label: 'Kyonggi-do',
                value: '41',
            },
            {
                label: 'Kyongsangbuk-do',
                value: '47',
            },
            {
                label: 'Kyongsangnam-do',
                value: '48',
            },
            {
                label: 'Pusan-Kwangyokshi',
                value: '26',
            },
            {
                label: 'Seoul-T\'ukpyolshi',
                value: '11',
            },
            {
                label: 'Sejong',
                value: '50',
            },
            {
                label: 'Taegu-Kwangyokshi',
                value: '27',
            },
            {
                label: 'Taejon-Kwangyokshi',
                value: '30',
            },
            {
                label: 'Ulsan-Kwangyokshi',
                value: '31',
            },
        ],
    },
    {
        label: 'Kuwait',
        phoneCode: '+965',
        value: 'KW',
        countryFlag: '🇰🇼',
        regions: [
            {
                label: 'Al Aḩmadi',
                value: 'AH',
            },
            {
                label: 'Al Farwānīyah',
                value: 'FA',
            },
            {
                label: 'Al Jahrā’',
                value: 'JA',
            },
            {
                label: 'Al ‘Āşimah',
                value: 'KU',
            },
            {
                label: 'Ḩawallī',
                value: 'HA',
            },
            {
                label: 'Mubārak al Kabir',
                value: 'MU',
            },
        ],
    },
    {
        label: 'Kyrgyzstan',
        phoneCode: '+996',
        value: 'KG',
        countryFlag: '🇰🇬',
        regions: [
            {
                label: 'Batken Oblasty',
                value: 'B',
            },
            {
                label: 'Bishkek Shaary',
                value: 'GB',
            },
            {
                label: 'Chuy Oblasty (Bishkek)',
                value: 'C',
            },
            {
                label: 'Jalal-Abad Oblasty',
                value: 'J',
            },
            {
                label: 'Naryn Oblasty',
                value: 'N',
            },
            {
                label: 'Osh Oblasty',
                value: 'O',
            },
            {
                label: 'Talas Oblasty',
                value: 'T',
            },
            {
                label: 'Ysyk-Kol Oblasty (Karakol)',
                value: 'Y',
            },
        ],
    },
    {
        label: 'Laos',
        phoneCode: '+856',
        value: 'LA',
        countryFlag: '🇱🇦',
        regions: [
            {
                label: 'Attapu',
                value: 'AT',
            },
            {
                label: 'Bokèo',
                value: 'BK',
            },
            {
                label: 'Bolikhamxai',
                value: 'BL',
            },
            {
                label: 'Champasak',
                value: 'CH',
            },
            {
                label: 'Houaphan',
                value: 'HO',
            },
            {
                label: 'Khammouan',
                value: 'KH',
            },
            {
                label: 'Louang Namtha',
                value: 'LM',
            },
            {
                label: 'Louangphabang',
                value: 'LP',
            },
            {
                label: 'Oudômxai',
                value: 'OU',
            },
            {
                label: 'Phôngsali',
                value: 'PH',
            },
            {
                label: 'Salavan',
                value: 'SL',
            },
            {
                label: 'Savannakhét',
                value: 'SV',
            },
            {
                label: 'Vientiane',
                value: 'VI',
            },
            {
                label: 'Xaignabouli',
                value: 'XA',
            },
            {
                label: 'Xékong',
                value: 'XE',
            },
            {
                label: 'Xaisomboun',
                value: 'XS',
            },
            {
                label: 'Xiangkhouang',
                value: 'XI',
            },
        ],
    },
    {
        label: 'Latvia',
        phoneCode: '+371',
        value: 'LV',
        countryFlag: '🇱🇻',
        regions: [
            {
                label: 'Aglona',
                value: '001',
            },
            {
                label: 'Aizkraukle',
                value: '002',
            },
            {
                label: 'Aizpute',
                value: '003',
            },
            {
                label: 'Aknīste',
                value: '004',
            },
            {
                label: 'Aloja',
                value: '005',
            },
            {
                label: 'Alsunga',
                value: '06',
            },
            {
                label: 'Alūksne',
                value: '007',
            },
            {
                label: 'Amata',
                value: '008',
            },
            {
                label: 'Ape',
                value: '009',
            },
            {
                label: 'Auce',
                value: '010',
            },
            {
                label: 'Ādaži',
                value: '011',
            },
            {
                label: 'Babīte',
                value: '012',
            },
            {
                label: 'Baldone',
                value: '013',
            },
            {
                label: 'Baltinava',
                value: '014',
            },
            {
                label: 'Balvi',
                value: '015',
            },
            {
                label: 'Bauska',
                value: '016',
            },
            {
                label: 'Beverīna',
                value: '017',
            },
            {
                label: 'Brocēni',
                value: '018',
            },
            {
                label: 'Burtnieki',
                value: '019',
            },
            {
                label: 'Carnikava',
                value: '020',
            },
            {
                label: 'Cesvaine',
                value: '021',
            },
            {
                label: 'Cēsis',
                value: '022',
            },
            {
                label: 'Cibla',
                value: '023',
            },
            {
                label: 'Dagda',
                value: '024',
            },
            {
                label: 'Daugavpils',
                value: '025',
            },
            {
                label: 'Daugavpils (City)',
                value: 'DGV',
            },
            {
                label: 'Dobele',
                value: '026',
            },
            {
                label: 'Dundaga',
                value: '027',
            },
            {
                label: 'Durbe',
                value: '028',
            },
            {
                label: 'Engure',
                value: '029',
            },
            {
                label: 'Ērgļi',
                value: '030',
            },
            {
                label: 'Garkalne',
                value: '031',
            },
            {
                label: 'Grobiņa',
                value: '032',
            },
            {
                label: 'Gulbene',
                value: '033',
            },
            {
                label: 'Iecava',
                value: '034',
            },
            {
                label: 'Ikšķile',
                value: '035',
            },
            {
                label: 'Ilūkste',
                value: '036',
            },
            {
                label: 'Inčukalns',
                value: '037',
            },
            {
                label: 'Jaunjelgava',
                value: '038',
            },
            {
                label: 'Jaunpiebalga',
                value: '039',
            },
            {
                label: 'Jaunpils',
                value: '040',
            },
            {
                label: 'Jelgava',
                value: '041',
            },
            {
                label: 'Jelgava (City)',
                value: 'JEL',
            },
            {
                label: 'Jēkabpils',
                value: '042',
            },
            {
                label: 'Jēkabpils (City)',
                value: 'JKB',
            },
            {
                label: 'Jūrmala (City)',
                value: 'JUR',
            },
            {
                label: 'Kandava',
                value: '043',
            },
            {
                label: 'Kārsava',
                value: '044',
            },
            {
                label: 'Kocēni',
                value: '045',
            },
            {
                label: 'Koknese',
                value: '046',
            },
            {
                label: 'Krāslava',
                value: '047',
            },
            {
                label: 'Krimulda',
                value: '048',
            },
            {
                label: 'Krustpils',
                value: '049',
            },
            {
                label: 'Kuldīga',
                value: '050',
            },
            {
                label: 'Ķegums',
                value: '051',
            },
            {
                label: 'Ķekava',
                value: '052',
            },
            {
                label: 'Lielvārde',
                value: '053',
            },
            {
                label: 'Liepāja',
                value: 'LPX',
            },
            {
                label: 'Limbaži',
                value: '054',
            },
            {
                label: 'Līgatne',
                value: '055',
            },
            {
                label: 'Līvāni',
                value: '056',
            },
            {
                label: 'Lubāna',
                value: '057',
            },
            {
                label: 'Ludza',
                value: '058',
            },
            {
                label: 'Madona',
                value: '059',
            },
            {
                label: 'Mazsalaca',
                value: '060',
            },
            {
                label: 'Mālpils',
                value: '061',
            },
            {
                label: 'Mārupe',
                value: '062',
            },
            {
                label: 'Mērsrags',
                value: '063',
            },
            {
                label: 'Naukšēni',
                value: '064',
            },
            {
                label: 'Nereta',
                value: '065',
            },
            {
                label: 'Nīca',
                value: '066',
            },
            {
                label: 'Ogre',
                value: '067',
            },
            {
                label: 'Olaine',
                value: '068',
            },
            {
                label: 'Ozolnieki',
                value: '069',
            },
            {
                label: 'Pārgauja',
                value: '070',
            },
            {
                label: 'Pāvilosta',
                value: '071',
            },
            {
                label: 'Pļaviņas',
                value: '072',
            },
            {
                label: 'Preiļi',
                value: '073',
            },
            {
                label: 'Priekule',
                value: '074',
            },
            {
                label: 'Priekuļi',
                value: '075',
            },
            {
                label: 'Rauna',
                value: '076',
            },
            {
                label: 'Rēzekne',
                value: '077',
            },
            {
                label: 'Rēzekne (City)',
                value: 'REZ',
            },
            {
                label: 'Riebiņi',
                value: '078',
            },
            {
                label: 'Rīga',
                value: 'RIX',
            },
            {
                label: 'Roja',
                value: '079',
            },
            {
                label: 'Ropaži',
                value: '080',
            },
            {
                label: 'Rucava',
                value: '081',
            },
            {
                label: 'Rugāji',
                value: '082',
            },
            {
                label: 'Rundāle',
                value: '083',
            },
            {
                label: 'Rūjiena',
                value: '084',
            },
            {
                label: 'Sala',
                value: '085',
            },
            {
                label: 'Salacgrīva',
                value: '086',
            },
            {
                label: 'Salaspils',
                value: '087',
            },
            {
                label: 'Saldus',
                value: '088',
            },
            {
                label: 'Saulkrasti',
                value: '089',
            },
            {
                label: 'Sēja',
                value: '090',
            },
            {
                label: 'Sigulda',
                value: '091',
            },
            {
                label: 'Skrīveri',
                value: '092',
            },
            {
                label: 'Skrunda',
                value: '093',
            },
            {
                label: 'Smiltene',
                value: '094',
            },
            {
                label: 'Stopiņi',
                value: '095',
            },
            {
                label: 'Strenči',
                value: '096',
            },
            {
                label: 'Talsi',
                value: '097',
            },
            {
                label: 'Tērvete',
                value: '098',
            },
            {
                label: 'Tukums',
                value: '099',
            },
            {
                label: 'Vaiņode',
                value: '100',
            },
            {
                label: 'Valka',
                value: '101',
            },
            {
                label: 'Valmiera',
                value: 'VMR',
            },
            {
                label: 'Varakļāni',
                value: '102',
            },
            {
                label: 'Vārkava',
                value: '103',
            },
            {
                label: 'Vecpiebalga',
                value: '104',
            },
            {
                label: 'Vecumnieki',
                value: '105',
            },
            {
                label: 'Ventspils',
                value: '106',
            },
            {
                label: 'Ventspils (City)',
                value: 'VEN',
            },
            {
                label: 'Viesīte',
                value: '107',
            },
            {
                label: 'Viļaka',
                value: '108',
            },
            {
                label: 'Viļāni',
                value: '109',
            },
            {
                label: 'Zilupe',
                value: '110',
            },
        ],
    },
    {
        label: 'Lebanon',
        phoneCode: '+961',
        value: 'LB',
        countryFlag: '🇱🇧',
        regions: [
            {
                label: 'Aakkâr',
                value: 'AK',
            },
            {
                label: 'Baalbelk-Hermel',
                value: 'BH',
            },
            {
                label: 'Béqaa',
                value: 'BI',
            },
            {
                label: 'Beyrouth',
                value: 'BA',
            },
            {
                label: 'Liban-Nord',
                value: 'AS',
            },
            {
                label: 'Liban-Sud',
                value: 'JA',
            },
            {
                label: 'Mont-Liban',
                value: 'JL',
            },
            {
                label: 'Nabatîyé',
                value: 'NA',
            },
        ],
    },
    {
        label: 'Lesotho',
        phoneCode: '+266',
        value: 'LS',
        countryFlag: '🇱🇸',
        regions: [
            {
                label: 'Berea',
                value: 'D',
            },
            {
                label: 'Butha-Buthe',
                value: 'B',
            },
            {
                label: 'Leribe',
                value: 'C',
            },
            {
                label: 'Mafeteng',
                value: 'E',
            },
            {
                label: 'Maseru',
                value: 'A',
            },
            {
                label: 'Mohales Hoek',
                value: 'F',
            },
            {
                label: 'Mokhotlong',
                value: 'J',
            },
            {
                label: 'Qacha\'s Nek',
                value: 'H',
            },
            {
                label: 'Quthing',
                value: 'G',
            },
            {
                label: 'Thaba-Tseka',
                value: 'K',
            },
        ],
    },
    {
        label: 'Liberia',
        phoneCode: '+231',
        value: 'LR',
        countryFlag: '🇱🇷',
        regions: [
            {
                label: 'Bomi',
                value: 'BM',
            },
            {
                label: 'Bong',
                value: 'BG',
            },
            {
                label: 'Gbarpolu',
                value: 'GP',
            },
            {
                label: 'Grand Bassa',
                value: 'GB',
            },
            {
                label: 'Grand Cape Mount',
                value: 'CM',
            },
            {
                label: 'Grand Gedeh',
                value: 'GG',
            },
            {
                label: 'Grand Kru',
                value: 'GK',
            },
            {
                label: 'Lofa',
                value: 'LO',
            },
            {
                label: 'Margibi',
                value: 'MG',
            },
            {
                label: 'Maryland',
                value: 'MY',
            },
            {
                label: 'Montserrado',
                value: 'MO',
            },
            {
                label: 'Nimba',
                value: 'NI',
            },
            {
                label: 'River Cess',
                value: 'RI',
            },
            {
                label: 'River Geee',
                value: 'RG',
            },
            {
                label: 'Sinoe',
                value: 'SI',
            },
        ],
    },
    {
        label: 'Libya',
        phoneCode: '+218',
        value: 'LY',
        countryFlag: '🇱🇾',
        regions: [
            {
                label: 'Al Buţnān',
                value: 'BU',
            },
            {
                label: 'Al Jabal al Akhḑar',
                value: 'JA',
            },
            {
                label: 'Al Jabal al Gharbī',
                value: 'JG',
            },
            {
                label: 'Al Jafārah',
                value: 'JA',
            },
            {
                label: 'Al Jufrah',
                value: 'JU',
            },
            {
                label: 'Al Kufrah',
                value: 'FK',
            },
            {
                label: 'Al Marj',
                value: 'MJ',
            },
            {
                label: 'Al Marquab',
                value: 'MB',
            },
            {
                label: 'Al Wāḩāt',
                value: 'WA',
            },
            {
                label: 'An Nuqaţ al Khams',
                value: 'NQ',
            },
            {
                label: 'Az Zāwiyah',
                value: 'ZA',
            },
            {
                label: 'Banghāzī',
                value: 'BA',
            },
            {
                label: 'Darnah',
                value: 'DR',
            },
            {
                label: 'Ghāt',
                value: 'GH',
            },
            {
                label: 'Mişrātah',
                value: 'MI',
            },
            {
                label: 'Murzuq',
                value: 'MQ',
            },
            {
                label: 'Nālūt',
                value: 'NL',
            },
            {
                label: 'Sabhā',
                value: 'SB',
            },
            {
                label: 'Surt',
                value: 'SR',
            },
            {
                label: 'Ţarābulus',
                value: 'TB',
            },
            {
                label: 'Yafran',
                value: 'WD',
            },
            {
                label: 'Wādī ash Shāţiʾ',
                value: 'WS',
            },
        ],
    },
    {
        label: 'Liechtenstein',
        phoneCode: '+423',
        value: 'LI',
        countryFlag: '🇱🇮',
        regions: [
            {
                label: 'Balzers',
                value: '01',
            },
            {
                label: 'Eschen',
                value: '02',
            },
            {
                label: 'Gamprin',
                value: '03',
            },
            {
                label: 'Mauren',
                value: '04',
            },
            {
                label: 'Planken',
                value: '05',
            },
            {
                label: 'Ruggell',
                value: '06',
            },
            {
                label: 'Schaan',
                value: '07',
            },
            {
                label: 'Schellenberg',
                value: '08',
            },
            {
                label: 'Triesen',
                value: '09',
            },
            {
                label: 'Triesenberg',
                value: '10',
            },
            {
                label: 'Vaduz',
                value: '11',
            },
        ],
    },
    {
        label: 'Lithuania',
        phoneCode: '+370',
        value: 'LT',
        countryFlag: '🇱🇹',
        regions: [
            {
                label: 'Alytaus',
                value: 'AL',
            },
            {
                label: 'Kauno',
                value: 'KU',
            },
            {
                label: 'Klaipėdos',
                value: 'KL',
            },
            {
                label: 'Marijampolės',
                value: 'MR',
            },
            {
                label: 'Panevėžio',
                value: 'PN',
            },
            {
                label: 'Šiaulių',
                value: 'SA',
            },
            {
                label: 'Tauragės',
                value: 'TA',
            },
            {
                label: 'Telšių',
                value: 'TE',
            },
            {
                label: 'Utenos',
                value: 'UT',
            },
            {
                label: 'Vilniaus',
                value: 'VL',
            },
        ],
    },
    {
        label: 'Luxembourg',
        phoneCode: '+352',
        value: 'LU',
        countryFlag: '🇱🇺',
        regions: [
            {
                label: 'Capellen',
                value: 'CA',
            },
            {
                label: 'Clevaux',
                value: 'CL',
            },
            {
                label: 'Diekirch',
                value: 'DI',
            },
            {
                label: 'Echternach',
                value: 'EC',
            },
            {
                label: 'Esch-sur-Alzette',
                value: 'ES',
            },
            {
                label: 'Grevenmacher',
                value: 'GR',
            },
            {
                label: 'Luxembourg',
                value: 'LU',
            },
            {
                label: 'Mersch',
                value: 'ME',
            },
            {
                label: 'Redange',
                value: 'RD',
            },
            {
                label: 'Remich',
                value: 'RM',
            },
            {
                label: 'Vianden',
                value: 'VD',
            },
            {
                label: 'Wiltz',
                value: 'WI',
            },
        ],
    },
    {
        label: 'Macao',
        phoneCode: '+853',
        value: 'MO',
        countryFlag: '🇲🇴',
        regions: [
            {
                label: 'Macao',
                value: null,
            },
        ],
    },
    {
        label: 'Macedonia, Republic of',
        phoneCode: '+389',
        value: 'MK',
        countryFlag: '🇲🇰',
        regions: [
            {
                label: 'Aračinovo',
                value: '02',
            },
            {
                label: 'Berovo',
                value: '03',
            },
            {
                label: 'Bitola',
                value: '04',
            },
            {
                label: 'Bogdanci',
                value: '05',
            },
            {
                label: 'Bogovinje',
                value: '06',
            },
            {
                label: 'Bosilovo',
                value: '07',
            },
            {
                label: 'Brvenica',
                value: '08',
            },
            {
                label: 'Centar Župa',
                value: '78',
            },
            {
                label: 'Čaška',
                value: '08',
            },
            {
                label: 'Češinovo-Obleševo',
                value: '81',
            },
            {
                label: 'Čučer Sandevo',
                value: '82',
            },
            {
                label: 'Debar',
                value: '21',
            },
            {
                label: 'Debarca',
                value: '22',
            },
            {
                label: 'Delčevo',
                value: '23',
            },
            {
                label: 'Demir Hisar',
                value: '25',
            },
            {
                label: 'Demir Kapija',
                value: '24',
            },
            {
                label: 'Doran',
                value: '26',
            },
            {
                label: 'Dolneni',
                value: '27',
            },
            {
                label: 'Gevgelija',
                value: '18',
            },
            {
                label: 'Gostivar',
                value: '19',
            },
            {
                label: 'Gradsko',
                value: '20',
            },
            {
                label: 'Ilinden',
                value: '34',
            },
            {
                label: 'Jegunovce',
                value: '35',
            },
            {
                label: 'Karbinci',
                value: '37',
            },
            {
                label: 'Kavadarci',
                value: '36',
            },
            {
                label: 'Kičevo',
                value: '40',
            },
            {
                label: 'Kočani',
                value: '42',
            },
            {
                label: 'Konče',
                value: '41',
            },
            {
                label: 'Kratovo',
                value: '43',
            },
            {
                label: 'Kriva Palanka',
                value: '44',
            },
            {
                label: 'Krivogaštani',
                value: '45',
            },
            {
                label: 'Kruševo',
                value: '46',
            },
            {
                label: 'Kumanovo',
                value: '47',
            },
            {
                label: 'Lipkovo',
                value: '48',
            },
            {
                label: 'Lozovo',
                value: '49',
            },
            {
                label: 'Makedonska Kamenica',
                value: '51',
            },
            {
                label: 'Makedonski Brod',
                value: '52',
            },
            {
                label: 'Mavrovo i Rostuša',
                value: '50',
            },
            {
                label: 'Mogila',
                value: '53',
            },
            {
                label: 'Negotino',
                value: '54',
            },
            {
                label: 'Novaci',
                value: '55',
            },
            {
                label: 'Novo Selo',
                value: '56',
            },
            {
                label: 'Ohrid',
                value: '58',
            },
            {
                label: 'Pehčevo',
                value: '60',
            },
            {
                label: 'Petrovec',
                value: '59',
            },
            {
                label: 'Plasnica',
                value: '61',
            },
            {
                label: 'Prilep',
                value: '62',
            },
            {
                label: 'Probištip',
                value: '63',
            },
            {
                label: 'Radoviš',
                value: '',
            },
            {
                label: 'Rankovce',
                value: '65',
            },
            {
                label: 'Resen',
                value: '66',
            },
            {
                label: 'Rosoman',
                value: '67',
            },
            {
                label: 'Skopje',
                value: '85',
            },
            {
                label: 'Sopište',
                value: '70',
            },
            {
                label: 'Staro Nagoričane',
                value: '71',
            },
            {
                label: 'Struga',
                value: '72',
            },
            {
                label: 'Strumica',
                value: '73',
            },
            {
                label: 'Studeničani',
                value: '74',
            },
            {
                label: 'Sveti Nikole',
                value: '69',
            },
            {
                label: 'Štip',
                value: '83',
            },
            {
                label: 'Tearce',
                value: '75',
            },
            {
                label: 'Tetovo',
                value: '76',
            },
            {
                label: 'Valandovo',
                value: '10',
            },
            {
                label: 'Vasilevo',
                value: '11',
            },
            {
                label: 'Veles',
                value: '13',
            },
            {
                label: 'Vevčani',
                value: '12',
            },
            {
                label: 'Vinica',
                value: '14',
            },
            {
                label: 'Vrapčište',
                value: '16',
            },
            {
                label: 'Zelenikovo',
                value: '32',
            },
            {
                label: 'Zrnovci',
                value: '33',
            },
            {
                label: 'Želino',
                value: '30',
            },
        ],
    },
    {
        label: 'Madagascar',
        phoneCode: '+261',
        value: 'MG',
        countryFlag: '🇲🇬',
        regions: [
            {
                label: 'Antananarivo',
                value: 'T',
            },
            {
                label: 'Antsiranana',
                value: 'D',
            },
            {
                label: 'Fianarantsoa',
                value: 'F',
            },
            {
                label: 'Mahajanga',
                value: 'M',
            },
            {
                label: 'Toamasina',
                value: 'A',
            },
            {
                label: 'Toliara',
                value: 'U',
            },
        ],
    },
    {
        label: 'Malawi',
        phoneCode: '+265',
        value: 'MW',
        countryFlag: '🇲🇼',
        regions: [
            {
                label: 'Balaka',
                value: 'BA',
            },
            {
                label: 'Blantyre',
                value: 'BL',
            },
            {
                label: 'Chikwawa',
                value: 'CK',
            },
            {
                label: 'Chiradzulu',
                value: 'CR',
            },
            {
                label: 'Chitipa',
                value: 'CT',
            },
            {
                label: 'Dedza',
                value: 'DE',
            },
            {
                label: 'Dowa',
                value: 'DO',
            },
            {
                label: 'Karonga',
                value: 'KR',
            },
            {
                label: 'Kasungu',
                value: 'KS',
            },
            {
                label: 'Likoma',
                value: 'LK',
            },
            {
                label: 'Lilongwe',
                value: 'LI',
            },
            {
                label: 'Machinga',
                value: 'MH',
            },
            {
                label: 'Mangochi',
                value: 'MG',
            },
            {
                label: 'Mchinji',
                value: 'MC',
            },
            {
                label: 'Mulanje',
                value: 'MU',
            },
            {
                label: 'Mwanza',
                value: 'MW',
            },
            {
                label: 'Mzimba',
                value: 'MZ',
            },
            {
                label: 'Nkhata Bay',
                value: 'NE',
            },
            {
                label: 'Nkhotakota',
                value: 'NB',
            },
            {
                label: 'Nsanje',
                value: 'NS',
            },
            {
                label: 'Ntcheu',
                value: 'NU',
            },
            {
                label: 'Ntchisi',
                value: 'NI',
            },
            {
                label: 'Phalombe',
                value: 'PH',
            },
            {
                label: 'Rumphi',
                value: 'RU',
            },
            {
                label: 'Salima',
                value: 'SA',
            },
            {
                label: 'Thyolo',
                value: 'TH',
            },
            {
                label: 'Zomba',
                value: 'ZO',
            },
        ],
    },
    {
        label: 'Malaysia',
        phoneCode: '+60',
        value: 'MY',
        countryFlag: '🇲🇾',
        regions: [
            {
                label: 'Johor',
                value: '01',
            },
            {
                label: 'Kedah',
                value: '02',
            },
            {
                label: 'Kelantan',
                value: '03',
            },
            {
                label: 'Melaka',
                value: '04',
            },
            {
                label: 'Negeri Sembilan',
                value: '05',
            },
            {
                label: 'Pahang',
                value: '06',
            },
            {
                label: 'Perak',
                value: '08',
            },
            {
                label: 'Perlis',
                value: '09',
            },
            {
                label: 'Pulau Pinang',
                value: '07',
            },
            {
                label: 'Sabah',
                value: '12',
            },
            {
                label: 'Sarawak',
                value: '13',
            },
            {
                label: 'Selangor',
                value: '10',
            },
            {
                label: 'Terengganu',
                value: '11',
            },
            {
                label: 'Wilayah Persekutuan (Kuala Lumpur)',
                value: '14',
            },
            {
                label: 'Wilayah Persekutuan (Labuan)',
                value: '15',
            },
            {
                label: 'Wilayah Persekutuan (Putrajaya)',
                value: '16',
            },
        ],
    },
    {
        label: 'Maldives',
        phoneCode: '+960',
        value: 'MV',
        countryFlag: '🇲🇻',
        regions: [
            {
                label: 'Alifu Alifu',
                value: '02',
            },
            {
                label: 'Alifu Dhaalu',
                value: '00',
            },
            {
                label: 'Baa',
                value: '20',
            },
            {
                label: 'Dhaalu',
                value: '17',
            },
            {
                label: 'Faafu',
                value: '14',
            },
            {
                label: 'Gaafu Alifu',
                value: '27',
            },
            {
                label: 'Gaafu Dhaalu',
                value: '28',
            },
            {
                label: 'Gnaviyani',
                value: '29',
            },
            {
                label: 'Haa Alifu',
                value: '07',
            },
            {
                label: 'Haa Dhaalu',
                value: '23',
            },
            {
                label: 'Kaafu',
                value: '29',
            },
            {
                label: 'Laamu',
                value: '05',
            },
            {
                label: 'Lhaviyani',
                value: '03',
            },
            {
                label: 'Malé',
                value: 'MLE',
            },
            {
                label: 'Meemu',
                value: '12',
            },
            {
                label: 'Noonu',
                value: '25',
            },
            {
                label: 'Raa',
                value: '13',
            },
            {
                label: 'Seenu',
                value: '01',
            },
            {
                label: 'Shaviyani',
                value: '24',
            },
            {
                label: 'Thaa',
                value: '08',
            },
            {
                label: 'Vaavu',
                value: '04',
            },
        ],
    },
    {
        label: 'Mali',
        phoneCode: '+223',
        value: 'ML',
        countryFlag: '🇲🇱',
        regions: [
            {
                label: 'Bamako',
                value: 'BKO',
            },
            {
                label: 'Gao',
                value: '7',
            },
            {
                label: 'Kayes',
                value: '1',
            },
            {
                label: 'Kidal',
                value: '8',
            },
            {
                label: 'Koulikoro',
                value: '2',
            },
            {
                label: 'Mopti',
                value: '5',
            },
            {
                label: 'Segou',
                value: '4',
            },
            {
                label: 'Sikasso',
                value: '3',
            },
            {
                label: 'Tombouctou',
                value: '6',
            },
        ],
    },
    {
        label: 'Malta',
        phoneCode: '+356',
        value: 'MT',
        countryFlag: '🇲🇹',
        regions: [
            {
                label: 'Attard',
                value: '01',
            },
            {
                label: 'Balzan',
                value: '02',
            },
            {
                label: 'Birgu',
                value: '03',
            },
            {
                label: 'Birkirkara',
                value: '04',
            },
            {
                label: 'Birżebbuġa',
                value: '05',
            },
            {
                label: 'Bormla',
                value: '06',
            },
            {
                label: 'Dingli',
                value: '07',
            },
            {
                label: 'Fgura',
                value: '08',
            },
            {
                label: 'Floriana',
                value: '09',
            },
            {
                label: 'Fontana',
                value: '10',
            },
            {
                label: 'Guda',
                value: '11',
            },
            {
                label: 'Gżira',
                value: '12',
            },
            {
                label: 'Għajnsielem',
                value: '13',
            },
            {
                label: 'Għarb',
                value: '14',
            },
            {
                label: 'Għargħur',
                value: '15',
            },
            {
                label: 'Għasri',
                value: '16',
            },
            {
                label: 'Għaxaq',
                value: '17',
            },
            {
                label: 'Ħamrun',
                value: '18',
            },
            {
                label: 'Iklin',
                value: '19',
            },
            {
                label: 'Isla',
                value: '20',
            },
            {
                label: 'Kalkara',
                value: '21',
            },
            {
                label: 'Kerċem',
                value: '22',
            },
            {
                label: 'Kirkop',
                value: '23',
            },
            {
                label: 'Lija',
                value: '24',
            },
            {
                label: 'Luqa',
                value: '25',
            },
            {
                label: 'Marsa',
                value: '26',
            },
            {
                label: 'Marsaskala',
                value: '27',
            },
            {
                label: 'Marsaxlokk',
                value: '28',
            },
            {
                label: 'Mdina',
                value: '29',
            },
            {
                label: 'Mellieħa',
                value: '30',
            },
            {
                label: 'Mġarr',
                value: '31',
            },
            {
                label: 'Mosta',
                value: '32',
            },
            {
                label: 'Mqabba',
                value: '33',
            },
            {
                label: 'Msida',
                value: '34',
            },
            {
                label: 'Mtarfa',
                value: '35',
            },
            {
                label: 'Munxar',
                value: '36',
            },
            {
                label: 'Nadur',
                value: '37',
            },
            {
                label: 'Naxxar',
                value: '38',
            },
            {
                label: 'Paola',
                value: '39',
            },
            {
                label: 'Pembroke',
                value: '40',
            },
            {
                label: 'Pietà',
                value: '41',
            },
            {
                label: 'Qala',
                value: '42',
            },
            {
                label: 'Qormi',
                value: '43',
            },
            {
                label: 'Qrendi',
                value: '44',
            },
            {
                label: 'Rabat Għawdex',
                value: '45',
            },
            {
                label: 'Rabat Malta',
                value: '46',
            },
            {
                label: 'Safi',
                value: '47',
            },
            {
                label: 'San Ġiljan',
                value: '48',
            },
            {
                label: 'San Ġwann',
                value: '49',
            },
            {
                label: 'San Lawrenz',
                value: '50',
            },
            {
                label: 'San Pawl il-Baħar',
                value: '51',
            },
            {
                label: 'Sannat',
                value: '52',
            },
            {
                label: 'Santa Luċija',
                value: '53',
            },
            {
                label: 'Santa Venera',
                value: '54',
            },
            {
                label: 'Siġġiewi',
                value: '55',
            },
            {
                label: 'Sliema',
                value: '56',
            },
            {
                label: 'Swieqi',
                value: '57',
            },
            {
                label: 'Tai Xbiex',
                value: '58',
            },
            {
                label: 'Tarzien',
                value: '59',
            },
            {
                label: 'Valletta',
                value: '60',
            },
            {
                label: 'Xagħra',
                value: '61',
            },
            {
                label: 'Xewkija',
                value: '62',
            },
            {
                label: 'Xgħajra',
                value: '63',
            },
            {
                label: 'Żabbar',
                value: '64',
            },
            {
                label: 'Żebbuġ Għawde',
                value: '65',
            },
            {
                label: 'Żebbuġ Malta',
                value: '66',
            },
            {
                label: 'Żejtun',
                value: '67',
            },
            {
                label: 'Żurrieq',
                value: '68',
            },
        ],
    },
    {
        label: 'Marshall Islands',
        phoneCode: '+692',
        value: 'MH',
        countryFlag: '🇲🇭',
        regions: [
            {
                label: 'Ailinglaplap',
                value: 'ALL',
            },
            {
                label: 'Ailuk',
                value: 'ALK',
            },
            {
                label: 'Arno',
                value: 'ARN',
            },
            {
                label: 'Aur',
                value: 'AUR',
            },
            {
                label: 'Bikini and Kili',
                value: 'KIL',
            },
            {
                label: 'Ebon',
                value: 'EBO',
            },
            {
                label: 'Jabat',
                value: 'JAB',
            },
            {
                label: 'Jaluit',
                value: 'JAL',
            },
            {
                label: 'Kwajalein',
                value: 'KWA',
            },
            {
                label: 'Lae',
                value: 'LAE',
            },
            {
                label: 'Lib',
                value: 'LIB',
            },
            {
                label: 'Likiep',
                value: 'LIK',
            },
            {
                label: 'Majuro',
                value: 'MAJ',
            },
            {
                label: 'Maloelap',
                value: 'MAL',
            },
            {
                label: 'Mejit',
                value: 'MEJ',
            },
            {
                label: 'Namdrik',
                value: 'NMK',
            },
            {
                label: 'Namu',
                value: 'NMU',
            },
            {
                label: 'Rongelap',
                value: 'RON',
            },
            {
                label: 'Ujae',
                value: 'UJA',
            },
            {
                label: 'Utrik',
                value: 'UTI',
            },
            {
                label: 'Wotho',
                value: 'WTH',
            },
            {
                label: 'Wotje',
                value: 'WTJ',
            },
        ],
    },
    {
        label: 'Martinique',
        phoneCode: '+596',
        value: 'MQ',
        countryFlag: '🇲🇶',
        regions: [
            {
                label: 'Martinique',
                value: null,
            },
        ],
    },
    {
        label: 'Mauritania',
        phoneCode: '+222',
        value: 'MR',
        countryFlag: '🇲🇷',
        regions: [
            {
                label: 'Adrar',
                value: '07',
            },
            {
                label: 'Assaba',
                value: '03',
            },
            {
                label: 'Brakna',
                value: '05',
            },
            {
                label: 'Dakhlet Nouadhibou',
                value: '08',
            },
            {
                label: 'Gorgol',
                value: '04',
            },
            {
                label: 'Guidimaka',
                value: '10',
            },
            {
                label: 'Hodh Ech Chargui',
                value: '01',
            },
            {
                label: 'Hodh El Gharbi',
                value: '02',
            },
            {
                label: 'Inchiri',
                value: '12',
            },
            {
                label: 'Nouakchott Nord',
                value: '14',
            },
            {
                label: 'Nouakchott Ouest',
                value: '13',
            },
            {
                label: 'Nouakchott Sud',
                value: '15',
            },
            {
                label: 'Tagant',
                value: '09',
            },
            {
                label: 'Tiris Zemmour',
                value: '11',
            },
            {
                label: 'Trarza',
                value: '06',
            },
        ],
    },
    {
        label: 'Mauritius',
        phoneCode: '+230',
        value: 'MU',
        countryFlag: '🇲🇺',
        regions: [
            {
                label: 'Agalega Islands',
                value: 'AG',
            },
            {
                label: 'Beau Bassin-Rose Hill',
                value: 'BR',
            },
            {
                label: 'Black River',
                value: 'BL',
            },
            {
                label: 'Cargados Carajos Shoals',
                value: 'CC',
            },
            {
                label: 'Curepipe',
                value: 'CU',
            },
            {
                label: 'Flacq',
                value: 'FL',
            },
            {
                label: 'Grand Port',
                value: 'GP',
            },
            {
                label: 'Moka',
                value: 'MO',
            },
            {
                label: 'Pamplemousses',
                value: 'PA',
            },
            {
                label: 'Plaines Wilhems',
                value: 'PW',
            },
            {
                label: 'Port Louis (City)',
                value: 'PU',
            },
            {
                label: 'Port Louis',
                value: 'PL',
            },
            {
                label: 'Riviere du Rempart',
                value: 'RR',
            },
            {
                label: 'Rodrigues Island',
                value: 'RO',
            },
            {
                label: 'Savanne',
                value: 'SA',
            },
            {
                label: 'Vacoas-Phoenix',
                value: 'CP',
            },
        ],
    },
    {
        label: 'Mayotte',
        phoneCode: '+262',
        value: 'YT',
        countryFlag: '🇾🇹',
        regions: [
            {
                label: 'Dzaoudzi',
                value: '01',
            },
            {
                label: 'Pamandzi',
                value: '02',
            },
            {
                label: 'Mamoudzou',
                value: '03',
            },
            {
                label: 'Dembeni',
                value: '04',
            },
            {
                label: 'Bandrélé',
                value: '05',
            },
            {
                label: 'Kani-Kéli',
                value: '06',
            },
            {
                label: 'Bouéni',
                value: '07',
            },
            {
                label: 'Chirongui',
                value: '08',
            },
            {
                label: 'Sada',
                value: '09',
            },
            {
                label: 'Ouangani',
                value: '10',
            },
            {
                label: 'Chiconi',
                value: '11',
            },
            {
                label: 'Tsingoni',
                value: '12',
            },
            {
                label: 'M\'Tsangamouji',
                value: '13',
            },
            {
                label: 'Acoua',
                value: '14',
            },
            {
                label: 'Mtsamboro',
                value: '15',
            },
            {
                label: 'Bandraboua',
                value: '16',
            },
            {
                label: 'Koungou',
                value: '17',
            },
        ],
    },
    {
        label: 'Mexico',
        phoneCode: '+52',
        value: 'MX',
        countryFlag: '🇲🇽',
        regions: [
            {
                label: 'Aguascalientes',
                value: 'AGU',
            },
            {
                label: 'Baja California',
                value: 'BCN',
            },
            {
                label: 'Baja California Sur',
                value: 'BCS',
            },
            {
                label: 'Campeche',
                value: 'CAM',
            },
            {
                label: 'Ciudad de México',
                value: 'DIF',
            },
            {
                label: 'Chiapas',
                value: 'CHP',
            },
            {
                label: 'Chihuahua',
                value: 'CHH',
            },
            {
                label: 'Coahuila de Zaragoza',
                value: 'COA',
            },
            {
                label: 'Colima',
                value: 'COL',
            },
            {
                label: 'Durango',
                value: 'DUR',
            },
            {
                label: 'Estado de México',
                value: 'MEX',
            },
            {
                label: 'Guanajuato',
                value: 'GUA',
            },
            {
                label: 'Guerrero',
                value: 'GRO',
            },
            {
                label: 'Hidalgo',
                value: 'HID',
            },
            {
                label: 'Jalisco',
                value: 'JAL',
            },
            {
                label: 'Michoacán de Ocampo',
                value: 'MIC',
            },
            {
                label: 'Morelos',
                value: 'MOR',
            },
            {
                label: 'Nayarit',
                value: 'NAY',
            },
            {
                label: 'Nuevo León',
                value: 'NLE',
            },
            {
                label: 'Oaxaca',
                value: 'OAX',
            },
            {
                label: 'Puebla',
                value: 'PUE',
            },
            {
                label: 'Querétaro de Arteaga',
                value: 'QUE',
            },
            {
                label: 'Quintana Roo',
                value: 'ROO',
            },
            {
                label: 'San Luis Potosí',
                value: 'SLP',
            },
            {
                label: 'Sinaloa',
                value: 'SIN',
            },
            {
                label: 'Sonora',
                value: 'SON',
            },
            {
                label: 'Tabasco',
                value: 'TAB',
            },
            {
                label: 'Tamaulipas',
                value: 'TAM',
            },
            {
                label: 'Tlaxcala',
                value: 'TLA',
            },
            {
                label: 'Veracruz',
                value: 'VER',
            },
            {
                label: 'Yucatán',
                value: 'YUC',
            },
            {
                label: 'Zacatecas',
                value: 'ZAC',
            },
        ],
    },
    {
        label: 'Micronesia, Federated States of',
        phoneCode: '+691',
        value: 'FM',
        countryFlag: '🇫🇲',
        regions: [
            {
                label: 'Chuuk (Truk)',
                value: 'TRK',
            },
            {
                label: 'Kosrae',
                value: 'KSA',
            },
            {
                label: 'Pohnpei',
                value: 'PNI',
            },
            {
                label: 'Yap',
                value: 'YAP',
            },
        ],
    },
    {
        label: 'Moldova',
        phoneCode: '+373',
        value: 'MD',
        countryFlag: '🇲🇩',
        regions: [
            {
                label: 'Aenii Noi',
                value: 'AN',
            },
            {
                label: 'Basarabeasca',
                value: 'BS',
            },
            {
                label: 'Bălți',
                value: 'BA',
            },
            {
                label: 'Bender',
                value: 'BD',
            },
            {
                label: 'Briceni',
                value: 'BR',
            },
            {
                label: 'Cahul',
                value: 'CA',
            },
            {
                label: 'Cantemir',
                value: 'CT',
            },
            {
                label: 'Călărași',
                value: 'CL',
            },
            {
                label: 'Căușeni',
                value: 'CS',
            },
            {
                label: 'Chișinău',
                value: 'CU',
            },
            {
                label: 'Cimișlia',
                value: 'CM',
            },
            {
                label: 'Criuleni',
                value: 'CR',
            },
            {
                label: 'Dondușeni',
                value: 'DO',
            },
            {
                label: 'Drochia',
                value: 'DR',
            },
            {
                label: 'Dubăsari',
                value: 'DU',
            },
            {
                label: 'Edineț',
                value: 'ED',
            },
            {
                label: 'Fălești',
                value: 'FA',
            },
            {
                label: 'Florești',
                value: 'FL',
            },
            {
                label: 'Găgăuzia',
                value: 'GA',
            },
            {
                label: 'Glodeni',
                value: 'GL',
            },
            {
                label: 'Hîncești',
                value: 'HI',
            },
            {
                label: 'Ialoveni',
                value: 'IA',
            },
            {
                label: 'Leova',
                value: 'LE',
            },
            {
                label: 'Nisporeni',
                value: 'NI',
            },
            {
                label: 'Ocnița',
                value: 'OC',
            },
            {
                label: 'Orhei',
                value: 'OR',
            },
            {
                label: 'Rezina',
                value: 'RE',
            },
            {
                label: 'Rîșcani',
                value: 'RI',
            },
            {
                label: 'Sîngerei',
                value: 'SI',
            },
            {
                label: 'Soroca',
                value: 'SO',
            },
            {
                label: 'Stînga Nistrului',
                value: 'SN',
            },
            {
                label: 'Strășeni',
                value: 'ST',
            },
            {
                label: 'Șoldănești',
                value: 'SD',
            },
            {
                label: 'Ștefan Vodă',
                value: 'SV',
            },
            {
                label: 'Taraclia',
                value: 'TA',
            },
            {
                label: 'Telenești',
                value: 'TE',
            },
            {
                label: 'Ungheni',
                value: 'UN',
            },
        ],
    },
    {
        label: 'Monaco',
        phoneCode: '+377',
        value: 'MC',
        countryFlag: '🇲🇨',
        regions: [
            {
                label: 'Colle',
                value: 'CL',
            },
            {
                label: 'Condamine',
                value: 'CO',
            },
            {
                label: 'Fontvieille',
                value: 'FO',
            },
            {
                label: 'Gare',
                value: 'GA',
            },
            {
                label: 'Jardin Exotique',
                value: 'JE',
            },
            {
                label: 'Larvotto',
                value: 'LA',
            },
            {
                label: 'Malbousquet',
                value: 'MA',
            },
            {
                label: 'Monaco-Ville',
                value: 'MO',
            },
            {
                label: 'Moneghetti',
                value: 'MG',
            },
            {
                label: 'Monte-Carlo',
                value: 'MC',
            },
            {
                label: 'Moulins',
                value: 'MU',
            },
            {
                label: 'Port-Hercule',
                value: 'PH',
            },
            {
                label: 'Saint-Roman',
                value: 'SR',
            },
            {
                label: 'Sainte-Dévote',
                value: 'SD',
            },
            {
                label: 'Source',
                value: 'SO',
            },
            {
                label: 'Spélugues',
                value: 'SP',
            },
            {
                label: 'Vallon de la Rousse',
                value: 'VR',
            },
        ],
    },
    {
        label: 'Mongolia',
        phoneCode: '+976',
        value: 'MN',
        countryFlag: '🇲🇳',
        regions: [
            {
                label: 'Arhangay',
                value: '073',
            },
            {
                label: 'Bayan-Olgiy',
                value: '071',
            },
            {
                label: 'Bayanhongor',
                value: '069',
            },
            {
                label: 'Bulgan',
                value: '067',
            },
            {
                label: 'Darhan',
                value: '037',
            },
            {
                label: 'Dornod',
                value: '061',
            },
            {
                label: 'Dornogovi',
                value: '063',
            },
            {
                label: 'Dundgovi',
                value: '059',
            },
            {
                label: 'Dzavhan',
                value: '065',
            },
            {
                label: 'Govi-Altay',
                value: '065',
            },
            {
                label: 'Govi-Sumber',
                value: '064',
            },
            {
                label: 'Hovd',
                value: '043',
            },
            {
                label: 'Hovsgol',
                value: '041',
            },
            {
                label: 'Omnogovi',
                value: '053',
            },
            {
                label: 'Ovorhangay',
                value: '055',
            },
            {
                label: 'Selenge',
                value: '049',
            },
            {
                label: 'Suhbaatar',
                value: '051',
            },
            {
                label: 'Tov',
                value: '047',
            },
            {
                label: 'Ulaanbaatar',
                value: '1',
            },
            {
                label: 'Uvs',
                value: '046',
            },
        ],
    },
    {
        label: 'Montenegro',
        phoneCode: '+382',
        value: 'ME',
        countryFlag: '🇲🇪',
        regions: [
            {
                label: 'Andrijevica',
                value: '01',
            },
            {
                label: 'Bar',
                value: '02',
            },
            {
                label: 'Berane',
                value: '03',
            },
            {
                label: 'Bijelo Polje',
                value: '04',
            },
            {
                label: 'Budva',
                value: '05',
            },
            {
                label: 'Cetinje',
                value: '06',
            },
            {
                label: 'Danilovgrad',
                value: '07',
            },
            {
                label: 'Gusinje',
                value: '22',
            },
            {
                label: 'Herceg Novi',
                value: '08',
            },
            {
                label: 'Kolašin',
                value: '09',
            },
            {
                label: 'Kotor',
                value: '10',
            },
            {
                label: 'Mojkovac',
                value: '11',
            },
            {
                label: 'Nikšić',
                value: '12',
            },
            {
                label: 'Petnica',
                value: '23',
            },
            {
                label: 'Plav',
                value: '13',
            },
            {
                label: 'Plužine',
                value: '14',
            },
            {
                label: 'Pljevlja',
                value: '15',
            },
            {
                label: 'Podgorica',
                value: '16',
            },
            {
                label: 'Rožaje',
                value: '17',
            },
            {
                label: 'Šavnik',
                value: '18',
            },
            {
                label: 'Tivat',
                value: '19',
            },
            {
                label: 'Ulcinj',
                value: '20',
            },
            {
                label: 'Žabljak',
                value: '21',
            },
        ],
    },
    {
        label: 'Montserrat',
        phoneCode: '+1664',
        value: 'MS',
        countryFlag: '🇲🇸',
        regions: [
            {
                label: 'Saint Anthony',
                value: null,
            },
            {
                label: 'Saint Georges',
                value: null,
            },
            {
                label: 'Saint Peter\'s',
                value: null,
            },
        ],
    },
    {
        label: 'Morocco',
        phoneCode: '+212',
        value: 'MA',
        countryFlag: '🇲🇦',
        regions: [
            {
                label: 'Chaouia-Ouardigha',
                value: '09',
            },
            {
                label: 'Doukhala-Abda',
                value: '10',
            },
            {
                label: 'Fès-Boulemane',
                value: '05',
            },
            {
                label: 'Gharb-Chrarda-Beni Hssen',
                value: '02',
            },
            {
                label: 'Grand Casablanca',
                value: '08',
            },
            {
                label: 'Guelmim-Es Semara',
                value: '14',
            },
            {
                label: 'Laâyoune-Boujdour-Sakia el Hamra',
                value: '15',
            },
            {
                label: 'Marrakech-Tensift-Al Haouz',
                value: '11',
            },
            {
                label: 'Meknès-Tafilalet',
                value: '06',
            },
            {
                label: 'Oriental',
                value: '04',
            },
            {
                label: 'Oued ed Dahab-Lagouira',
                value: '16',
            },
            {
                label: 'Souss-Massa-Drâa',
                value: '13',
            },
            {
                label: 'Tadla-Azilal',
                value: '12',
            },
            {
                label: 'Tanger-Tétouan',
                value: '01',
            },
            {
                label: 'Taza-Al Hoceima-Taounate',
                value: '03',
            },
        ],
    },
    {
        label: 'Mozambique',
        phoneCode: '+258',
        value: 'MZ',
        countryFlag: '🇲🇿',
        regions: [
            {
                label: 'Cabo Delgado',
                value: 'P',
            },
            {
                label: 'Gaza',
                value: 'G',
            },
            {
                label: 'Inhambane',
                value: 'I',
            },
            {
                label: 'Manica',
                value: 'B',
            },
            {
                label: 'Maputo',
                value: 'L',
            },
            {
                label: 'Maputo (City)',
                value: 'MPM',
            },
            {
                label: 'Nampula',
                value: 'N',
            },
            {
                label: 'Niassa',
                value: 'A',
            },
            {
                label: 'Sofala',
                value: 'S',
            },
            {
                label: 'Tete',
                value: 'T',
            },
            {
                label: 'Zambezia',
                value: 'Q',
            },
        ],
    },
    {
        label: 'Myanmar',
        phoneCode: '+95',
        value: 'MM',
        countryFlag: '🇲🇲',
        regions: [
            {
                label: 'Ayeyarwady',
                value: '07',
            },
            {
                label: 'Bago',
                value: '02',
            },
            {
                label: 'Chin',
                value: '14',
            },
            {
                label: 'Kachin',
                value: '11',
            },
            {
                label: 'Kayah',
                value: '12',
            },
            {
                label: 'Kayin',
                value: '13',
            },
            {
                label: 'Magway',
                value: '03',
            },
            {
                label: 'Mandalay',
                value: '04',
            },
            {
                label: 'Mon',
                value: '15',
            },
            {
                label: 'Nay Pyi Taw',
                value: '18',
            },
            {
                label: 'Rakhine',
                value: '16',
            },
            {
                label: 'Sagaing',
                value: '01',
            },
            {
                label: 'Shan',
                value: '17',
            },
            {
                label: 'Tanintharyi',
                value: '05',
            },
            {
                label: 'Yangon',
                value: '06',
            },
        ],
    },
    {
        label: 'Namibia',
        phoneCode: '+264',
        value: 'NA',
        countryFlag: '🇳🇦',
        regions: [
            {
                label: 'Erongo',
                value: 'ER',
            },
            {
                label: 'Hardap',
                value: 'HA',
            },
            {
                label: 'Kavango East',
                value: 'KE',
            },
            {
                label: 'Kavango West',
                value: 'KW',
            },
            {
                label: 'Karas',
                value: 'KA',
            },
            {
                label: 'Khomas',
                value: 'KH',
            },
            {
                label: 'Kunene',
                value: 'KU',
            },
            {
                label: 'Ohangwena',
                value: 'OW',
            },
            {
                label: 'Omaheke',
                value: 'OH',
            },
            {
                label: 'Omusati',
                value: 'OS',
            },
            {
                label: 'Oshana',
                value: 'ON',
            },
            {
                label: 'Oshikoto',
                value: 'OT',
            },
            {
                label: 'Otjozondjupa',
                value: 'OD',
            },
            {
                label: 'Zambezi',
                value: 'CA',
            },
        ],
    },
    {
        label: 'Nauru',
        phoneCode: '+674',
        value: 'NR',
        countryFlag: '🇳🇷',
        regions: [
            {
                label: 'Aiwo',
                value: '01',
            },
            {
                label: 'Anabar',
                value: '02',
            },
            {
                label: 'Anetan',
                value: '03',
            },
            {
                label: 'Anibare',
                value: '04',
            },
            {
                label: 'Baiti',
                value: '05',
            },
            {
                label: 'Boe',
                value: '06',
            },
            {
                label: 'Buada',
                value: '07',
            },
            {
                label: 'Denigomodu',
                value: '08',
            },
            {
                label: 'Ewa',
                value: '09',
            },
            {
                label: 'Ijuw',
                value: '10',
            },
            {
                label: 'Meneng',
                value: '11',
            },
            {
                label: 'Nibok',
                value: '12',
            },
            {
                label: 'Uaboe',
                value: '13',
            },
            {
                label: 'Yaren',
                value: '14',
            },
        ],
    },
    {
        label: 'Nepal',
        phoneCode: '+977',
        value: 'NP',
        countryFlag: '🇳🇵',
        regions: [
            {
                label: 'Bagmati',
                value: 'BA',
            },
            {
                label: 'Bheri',
                value: 'BH',
            },
            {
                label: 'Dhawalagiri',
                value: 'DH',
            },
            {
                label: 'Gandaki',
                value: 'GA',
            },
            {
                label: 'Janakpur',
                value: 'JA',
            },
            {
                label: 'Karnali',
                value: 'KA',
            },
            {
                label: 'Kosi',
                value: 'KO',
            },
            {
                label: 'Lumbini',
                value: 'LU',
            },
            {
                label: 'Mahakali',
                value: 'MA',
            },
            {
                label: 'Mechi',
                value: 'ME',
            },
            {
                label: 'Narayani',
                value: 'NA',
            },
            {
                label: 'Rapti',
                value: 'RA',
            },
            {
                label: 'Sagarmatha',
                value: 'SA',
            },
            {
                label: 'Seti',
                value: 'SE',
            },
        ],
    },
    {
        label: 'Netherlands',
        phoneCode: '+31',
        value: 'NL',
        countryFlag: '🇳🇱',
        regions: [
            {
                label: 'Drenthe',
                value: 'DR',
            },
            {
                label: 'Flevoland',
                value: 'FL',
            },
            {
                label: 'Friesland',
                value: 'FR',
            },
            {
                label: 'Gelderland',
                value: 'GE',
            },
            {
                label: 'Groningen',
                value: 'GR',
            },
            {
                label: 'Limburg',
                value: 'LI',
            },
            {
                label: 'Noord-Brabant',
                value: 'NB',
            },
            {
                label: 'Noord-Holland',
                value: 'NH',
            },
            {
                label: 'Overijssel',
                value: 'OV',
            },
            {
                label: 'Utrecht',
                value: 'UT',
            },
            {
                label: 'Zeeland',
                value: 'ZE',
            },
            {
                label: 'Zuid-Holland',
                value: 'ZH',
            },
        ],
    },
    {
        label: 'New Caledonia',
        phoneCode: '+687',
        value: 'NC',
        countryFlag: '🇳🇨',
        regions: [
            {
                label: 'Iles Loyaute',
                value: null,
            },
            {
                label: 'Nord',
                value: null,
            },
            {
                label: 'Sud',
                value: null,
            },
        ],
    },
    {
        label: 'New Zealand',
        phoneCode: '+64',
        value: 'NZ',
        countryFlag: '🇳🇿',
        regions: [
            {
                label: 'Auckland',
                value: 'AUK',
            },
            {
                label: 'Bay of Plenty',
                value: 'BOP',
            },
            {
                label: 'Canterbury',
                value: 'CAN',
            },
            {
                label: 'Gisborne',
                value: 'GIS',
            },
            {
                label: 'Hawke\'s Bay',
                value: 'HKB',
            },
            {
                label: 'Marlborough',
                value: 'MBH',
            },
            {
                label: 'Manawatu-Wanganui',
                value: 'MWT',
            },
            {
                label: 'Northland',
                value: 'NTL',
            },
            {
                label: 'Nelson',
                value: 'NSN',
            },
            {
                label: 'Otago',
                value: 'OTA',
            },
            {
                label: 'Southland',
                value: 'STL',
            },
            {
                label: 'Taranaki',
                value: 'TKI',
            },
            {
                label: 'Tasman',
                value: 'TAS',
            },
            {
                label: 'Waikato',
                value: 'WKO',
            },
            {
                label: 'Wellington',
                value: 'WGN',
            },
            {
                label: 'West Coast',
                value: 'WTC',
            },
            {
                label: 'Chatham Islands Territory',
                value: 'CIT',
            },
        ],
    },
    {
        label: 'Nicaragua',
        phoneCode: '+505',
        value: 'NI',
        countryFlag: '🇳🇮',
        regions: [
            {
                label: 'Boaco',
                value: 'BO',
            },
            {
                label: 'Carazo',
                value: 'CA',
            },
            {
                label: 'Chinandega',
                value: 'CI',
            },
            {
                label: 'Chontales',
                value: 'CO',
            },
            {
                label: 'Estelí',
                value: 'ES',
            },
            {
                label: 'Granada',
                value: 'GR',
            },
            {
                label: 'Jinotega',
                value: 'JI',
            },
            {
                label: 'León',
                value: 'LE',
            },
            {
                label: 'Madriz',
                value: 'MD',
            },
            {
                label: 'Managua',
                value: 'MN',
            },
            {
                label: 'Masaya',
                value: 'MS',
            },
            {
                label: 'Matagalpa',
                value: 'MT',
            },
            {
                label: 'Nueva Segovia',
                value: 'NS',
            },
            {
                label: 'Río San Juan',
                value: 'SJ',
            },
            {
                label: 'Rivas',
                value: 'RI',
            },
            {
                label: 'Atlántico Norte',
                value: 'AN',
            },
            {
                label: 'Atlántico Sur',
                value: 'AS',
            },
        ],
    },
    {
        label: 'Niger',
        phoneCode: '+227',
        value: 'NE',
        countryFlag: '🇳🇪',
        regions: [
            {
                label: 'Agadez',
                value: '1',
            },
            {
                label: 'Diffa',
                value: '2',
            },
            {
                label: 'Dosso',
                value: '3',
            },
            {
                label: 'Maradi',
                value: '4',
            },
            {
                label: 'Niamey',
                value: '8',
            },
            {
                label: 'Tahoua',
                value: '5',
            },
            {
                label: 'Tillabéri',
                value: '6',
            },
            {
                label: 'Zinder',
                value: '7',
            },
        ],
    },
    {
        label: 'Nigeria',
        phoneCode: '+234',
        value: 'NG',
        countryFlag: '🇳🇬',
        regions: [
            {
                label: 'Abia',
                value: 'AB',
            },
            {
                label: 'Abuja Federal Capital Territory',
                value: 'FC',
            },
            {
                label: 'Adamawa',
                value: 'AD',
            },
            {
                label: 'Akwa Ibom',
                value: 'AK',
            },
            {
                label: 'Anambra',
                value: 'AN',
            },
            {
                label: 'Bauchi',
                value: 'BA',
            },
            {
                label: 'Bayelsa',
                value: 'BY',
            },
            {
                label: 'Benue',
                value: 'BE',
            },
            {
                label: 'Borno',
                value: 'BO',
            },
            {
                label: 'Cross River',
                value: 'CR',
            },
            {
                label: 'Delta',
                value: 'DE',
            },
            {
                label: 'Ebonyi',
                value: 'EB',
            },
            {
                label: 'Edo',
                value: 'ED',
            },
            {
                label: 'Ekiti',
                value: 'EK',
            },
            {
                label: 'Enugu',
                value: 'EN',
            },
            {
                label: 'Gombe',
                value: 'GO',
            },
            {
                label: 'Imo',
                value: 'IM',
            },
            {
                label: 'Jigawa',
                value: 'JI',
            },
            {
                label: 'Kaduna',
                value: 'KD',
            },
            {
                label: 'Kano',
                value: 'KN',
            },
            {
                label: 'Katsina',
                value: 'KT',
            },
            {
                label: 'Kebbi',
                value: 'KE',
            },
            {
                label: 'Kogi',
                value: 'KO',
            },
            {
                label: 'Kwara',
                value: 'KW',
            },
            {
                label: 'Lagos',
                value: 'LA',
            },
            {
                label: 'Nassarawa',
                value: 'NA',
            },
            {
                label: 'Niger',
                value: 'NI',
            },
            {
                label: 'Ogun',
                value: 'OG',
            },
            {
                label: 'Ondo',
                value: 'ON',
            },
            {
                label: 'Osun',
                value: 'OS',
            },
            {
                label: 'Oyo',
                value: 'OY',
            },
            {
                label: 'Plateau',
                value: 'PL',
            },
            {
                label: 'Rivers',
                value: 'RI',
            },
            {
                label: 'Sokoto',
                value: 'SO',
            },
            {
                label: 'Taraba',
                value: 'TA',
            },
            {
                label: 'Yobe',
                value: 'YO',
            },
            {
                label: 'Zamfara',
                value: 'ZA',
            },
        ],
    },
    {
        label: 'Niue',
        phoneCode: '+683',
        value: 'NU',
        countryFlag: '🇳🇺',
        regions: [
            {
                label: 'Niue',
                value: null,
            },
        ],
    },
    {
        label: 'Norfolk Island',
        phoneCode: '+672',
        value: 'NF',
        countryFlag: '🇳🇫',
        regions: [
            {
                label: 'Norfolk Island',
                value: null,
            },
        ],
    },
    {
        label: 'Northern Mariana Islands',
        phoneCode: '+1670',
        value: 'MP',
        countryFlag: '🇲🇵',
        regions: [
            {
                label: 'Northern Islands',
                value: null,
            },
            {
                label: 'Rota',
                value: null,
            },
            {
                label: 'Saipan',
                value: null,
            },
            {
                label: 'Tinian',
                value: null,
            },
        ],
    },
    {
        label: 'Norway',
        phoneCode: '+47',
        value: 'NO',
        countryFlag: '🇳🇴',
        regions: [
            {
                label: 'Akershus',
                value: '02',
            },
            {
                label: 'Aust-Agder',
                value: '09',
            },
            {
                label: 'Buskerud',
                value: '06',
            },
            {
                label: 'Finnmark',
                value: '20',
            },
            {
                label: 'Hedmark',
                value: '04',
            },
            {
                label: 'Hordaland',
                value: '12',
            },
            {
                label: 'Møre og Romsdal',
                value: '15',
            },
            {
                label: 'Nordland',
                value: '18',
            },
            {
                label: 'Nord-Trøndelag',
                value: '17',
            },
            {
                label: 'Oppland',
                value: '05',
            },
            {
                label: 'Oslo',
                value: '03',
            },
            {
                label: 'Rogaland',
                value: '11',
            },
            {
                label: 'Sogn og Fjordane',
                value: '14',
            },
            {
                label: 'Sør-Trøndelag',
                value: '16',
            },
            {
                label: 'Telemark',
                value: '08',
            },
            {
                label: 'Troms',
                value: '19',
            },
            {
                label: 'Vest-Agder',
                value: '10',
            },
            {
                label: 'Vestfold',
                value: '07',
            },
            {
                label: 'Østfold',
                value: '01',
            },
            {
                label: 'Jan Mayen',
                value: '22',
            },
            {
                label: 'Svalbard',
                value: '21',
            },
        ],
    },
    {
        label: 'Oman',
        phoneCode: '+968',
        value: 'OM',
        countryFlag: '🇴🇲',
        regions: [
            {
                label: 'Ad Dakhiliyah',
                value: 'DA',
            },
            {
                label: 'Al Buraymi',
                value: 'BU',
            },
            {
                label: 'Al Wusta',
                value: 'WU',
            },
            {
                label: 'Az Zahirah',
                value: 'ZA',
            },
            {
                label: 'Janub al Batinah',
                value: 'BS',
            },
            {
                label: 'Janub ash Sharqiyah',
                value: 'SS',
            },
            {
                label: 'Masqat',
                value: 'MA',
            },
            {
                label: 'Musandam',
                value: 'MU',
            },
            {
                label: 'Shamal al Batinah',
                value: 'BJ',
            },
            {
                label: 'Shamal ash Sharqiyah',
                value: 'SJ',
            },
            {
                label: 'Zufar',
                value: 'ZU',
            },
        ],
    },
    {
        label: 'Pakistan',
        phoneCode: '+92',
        value: 'PK',
        countryFlag: '🇵🇰',
        regions: [
            {
                label: 'Āzād Kashmīr',
                value: 'JK',
            },
            {
                label: 'Balōchistān',
                value: 'BA',
            },
            {
                label: 'Gilgit-Baltistān',
                value: 'GB',
            },
            {
                label: 'Islāmābād',
                value: 'IS',
            },
            {
                label: 'Khaībar Pakhtūnkhwās',
                value: 'KP',
            },
            {
                label: 'Punjāb',
                value: 'PB',
            },
            {
                label: 'Sindh',
                value: 'SD',
            },
            {
                label: 'Federally Administered Tribal Areas',
                value: 'TA',
            },
        ],
    },
    {
        label: 'Palau',
        phoneCode: '+680',
        value: 'PW',
        countryFlag: '🇵🇼',
        regions: [
            {
                label: 'Aimeliik',
                value: '002',
            },
            {
                label: 'Airai',
                value: '004',
            },
            {
                label: 'Angaur',
                value: '010',
            },
            {
                label: 'Hatobohei',
                value: '050',
            },
            {
                label: 'Kayangel',
                value: '100',
            },
            {
                label: 'Koror',
                value: '150',
            },
            {
                label: 'Melekeok',
                value: '212',
            },
            {
                label: 'Ngaraard',
                value: '214',
            },
            {
                label: 'Ngarchelong',
                value: '218',
            },
            {
                label: 'Ngardmau',
                value: '222',
            },
            {
                label: 'Ngatpang',
                value: '224',
            },
            {
                label: 'Ngchesar',
                value: '226',
            },
            {
                label: 'Ngeremlengui',
                value: '227',
            },
            {
                label: 'Ngiwal',
                value: '228',
            },
            {
                label: 'Peleliu',
                value: '350',
            },
            {
                label: 'Sonsoral',
                value: '350',
            },
        ],
    },
    {
        label: 'Palestine, State of',
        phoneCode: '+970',
        value: 'PS',
        countryFlag: '🇵🇸',
        regions: [
            {
                label: 'Ak Khalīl',
                value: 'HBN',
            },
            {
                label: 'Al Quds',
                value: 'JEM',
            },
            {
                label: 'Arīḩā wal Aghwār',
                value: 'JRH',
            },
            {
                label: 'Bayt Laḩm',
                value: 'BTH',
            },
            {
                label: 'Dayr al Balaḩ',
                value: 'DEB',
            },
            {
                label: 'Ghazzah',
                value: 'GZA',
            },
            {
                label: 'Janīn',
                value: 'JEN',
            },
            {
                label: 'Khān Yūnis',
                value: 'KYS',
            },
            {
                label: 'Nāblus',
                value: 'NBS',
            },
            {
                label: 'Qalqīyah',
                value: 'QQA',
            },
            {
                label: 'Rafaḩ',
                value: 'RFH',
            },
            {
                label: 'Rām Allāh wal Bīrah',
                value: 'RBH',
            },
            {
                label: 'Salfīt',
                value: 'SLT',
            },
            {
                label: 'Shamāl Ghazzah',
                value: 'NGZ',
            },
            {
                label: 'Ţūbās',
                value: 'TBS',
            },
            {
                label: 'Ţūlkarm',
                value: 'TKM',
            },
        ],
    },
    {
        label: 'Panama',
        phoneCode: '+507',
        value: 'PA',
        countryFlag: '🇵🇦',
        regions: [
            {
                label: 'Bocas del Toro',
                value: '1',
            },
            {
                label: 'Chiriquí',
                value: '4',
            },
            {
                label: 'Coclé',
                value: '2',
            },
            {
                label: 'Colón',
                value: '3',
            },
            {
                label: 'Darién',
                value: '5',
            },
            {
                label: 'Emberá',
                value: 'EM',
            },
            {
                label: 'Herrera',
                value: '6',
            },
            {
                label: 'Kuna Yala',
                value: 'KY',
            },
            {
                label: 'Los Santos',
                value: '7',
            },
            {
                label: 'Ngäbe-Buglé',
                value: 'NB',
            },
            {
                label: 'Panamá',
                value: '8',
            },
            {
                label: 'Panamá Oeste',
                value: '10',
            },
            {
                label: 'Veraguas',
                value: '9',
            },
        ],
    },
    {
        label: 'Papua New Guinea',
        phoneCode: '+675',
        value: 'PG',
        countryFlag: '🇵🇬',
        regions: [
            {
                label: 'Bougainville',
                value: 'NSB',
            },
            {
                label: 'Central',
                value: 'CPM',
            },
            {
                label: 'Chimbu',
                value: 'CPK',
            },
            {
                label: 'East New Britain',
                value: 'EBR',
            },
            {
                label: 'East Sepik',
                value: 'ESW',
            },
            {
                label: 'Eastern Highlands',
                value: 'EHG',
            },
            {
                label: 'Enga',
                value: 'EPW',
            },
            {
                label: 'Gulf',
                value: 'GPK',
            },
            {
                label: 'Hela',
                value: 'HLA',
            },
            {
                label: 'Jiwaka',
                value: 'JWK',
            },
            {
                label: 'Madang',
                value: 'MOM',
            },
            {
                label: 'Manus',
                value: 'MRL',
            },
            {
                label: 'Milne Bay',
                value: 'MBA',
            },
            {
                label: 'Morobe',
                value: 'MPL',
            },
            {
                label: 'Port Moresby',
                value: 'NCD',
            },
            {
                label: 'New Ireland',
                value: 'NIK',
            },
            {
                label: 'Northern',
                value: 'NPP',
            },
            {
                label: 'Southern Highlands',
                value: 'SHM',
            },
            {
                label: 'West New Britain',
                value: 'WBK',
            },
            {
                label: 'West Sepik',
                value: 'SAN',
            },
            {
                label: 'Western',
                value: 'WPD',
            },
            {
                label: 'Western Highlands',
                value: 'WHM',
            },
        ],
    },
    {
        label: 'Paraguay',
        phoneCode: '+595',
        value: 'PY',
        countryFlag: '🇵🇾',
        regions: [
            {
                label: 'Alto Paraguay',
                value: '16',
            },
            {
                label: 'Alto Parana',
                value: '10',
            },
            {
                label: 'Amambay',
                value: '13',
            },
            {
                label: 'Asuncion',
                value: 'ASU',
            },
            {
                label: 'Caaguazu',
                value: '5',
            },
            {
                label: 'Caazapa',
                value: '6',
            },
            {
                label: 'Canindeyu',
                value: '14',
            },
            {
                label: 'Central',
                value: '11',
            },
            {
                label: 'Concepcion',
                value: '1',
            },
            {
                label: 'Cordillera',
                value: '3',
            },
            {
                label: 'Guaira',
                value: '4',
            },
            {
                label: 'Itapua',
                value: '7',
            },
            {
                label: 'Misiones',
                value: '8',
            },
            {
                label: 'Neembucu',
                value: '12',
            },
            {
                label: 'Paraguari',
                value: '9',
            },
            {
                label: 'Presidente Hayes',
                value: '15',
            },
            {
                label: 'San Pedro',
                value: '2',
            },
        ],
    },
    {
        label: 'Peru',
        phoneCode: '+51',
        value: 'PE',
        countryFlag: '🇵🇪',
        regions: [
            {
                label: 'Amazonas',
                value: 'AMA',
            },
            {
                label: 'Ancash',
                value: 'ANC',
            },
            {
                label: 'Apurimac',
                value: 'APU',
            },
            {
                label: 'Arequipa',
                value: 'ARE',
            },
            {
                label: 'Ayacucho',
                value: 'AYA',
            },
            {
                label: 'Cajamarca',
                value: 'CAJ',
            },
            {
                label: 'Callao',
                value: 'CAL',
            },
            {
                label: 'Cusco',
                value: 'CUS',
            },
            {
                label: 'Huancavelica',
                value: 'HUV',
            },
            {
                label: 'Huanuco',
                value: 'HUC',
            },
            {
                label: 'Ica',
                value: 'ICA',
            },
            {
                label: 'Junin',
                value: 'JUN',
            },
            {
                label: 'La Libertad',
                value: 'LAL',
            },
            {
                label: 'Lambayeque',
                value: 'LAM',
            },
            {
                label: 'Lima',
                value: 'LIM',
            },
            {
                label: 'Loreto',
                value: 'LOR',
            },
            {
                label: 'Madre de Dios',
                value: 'MDD',
            },
            {
                label: 'Moquegua',
                value: 'MOQ',
            },
            {
                label: 'Municipalidad Metropolitana de Lima',
                value: 'LMA',
            },
            {
                label: 'Pasco',
                value: 'PAS',
            },
            {
                label: 'Piura',
                value: 'PIU',
            },
            {
                label: 'Puno',
                value: 'PUN',
            },
            {
                label: 'San Martin',
                value: 'SAM',
            },
            {
                label: 'Tacna',
                value: 'TAC',
            },
            {
                label: 'Tumbes',
                value: 'TUM',
            },
            {
                label: 'Ucayali',
                value: 'UCA',
            },
        ],
    },
    {
        label: 'Philippines',
        phoneCode: '+63',
        value: 'PH',
        countryFlag: '🇵🇭',
        regions: [
            {
                label: 'Abra',
                value: 'ABR',
            },
            {
                label: 'Agusan del Norte',
                value: 'AGN',
            },
            {
                label: 'Agusan del Sur',
                value: 'AGS',
            },
            {
                label: 'Aklan',
                value: 'AKL',
            },
            {
                label: 'Albay',
                value: 'ALB',
            },
            {
                label: 'Antique',
                value: 'ANT',
            },
            {
                label: 'Apayao',
                value: 'APA',
            },
            {
                label: 'Aurora',
                value: 'AUR',
            },
            {
                label: 'Basilan',
                value: 'BAS',
            },
            {
                label: 'Bataan',
                value: 'BAN',
            },
            {
                label: 'Batanes',
                value: 'BTN',
            },
            {
                label: 'Batangas',
                value: 'BTG',
            },
            {
                label: 'Benguet',
                value: 'BEN',
            },
            {
                label: 'Biliran',
                value: 'BIL',
            },
            {
                label: 'Bohol',
                value: 'BOH',
            },
            {
                label: 'Bukidnon',
                value: 'BUK',
            },
            {
                label: 'Bulacan',
                value: 'BUL',
            },
            {
                label: 'Cagayan',
                value: 'CAG',
            },
            {
                label: 'Camarines Norte',
                value: 'CAN',
            },
            {
                label: 'Camarines Sur',
                value: 'CAS',
            },
            {
                label: 'Camiguin',
                value: 'CAM',
            },
            {
                label: 'Capiz',
                value: 'CAP',
            },
            {
                label: 'Catanduanes',
                value: 'CAT',
            },
            {
                label: 'Cavite',
                value: 'CAV',
            },
            {
                label: 'Cebu',
                value: 'CEB',
            },
            {
                label: 'Compostela',
                value: 'COM',
            },
            {
                label: 'Cotabato',
                value: 'NCO',
            },
            {
                label: 'Davao del Norte',
                value: 'DAV',
            },
            {
                label: 'Davao del Sur',
                value: 'DAS',
            },
            {
                label: 'Davao Occidental',
                value: 'DVO',
            },
            {
                label: 'Davao Oriental',
                value: 'DAO',
            },
            {
                label: 'Dinagat Islands',
                value: 'DIN',
            },
            {
                label: 'Eastern Samar',
                value: 'EAS',
            },
            {
                label: 'Guimaras',
                value: 'GUI',
            },
            {
                label: 'Ifugao',
                value: 'IFU',
            },
            {
                label: 'Ilocos Norte',
                value: 'ILN',
            },
            {
                label: 'Ilocos Sur',
                value: 'ILS',
            },
            {
                label: 'Iloilo',
                value: 'ILI',
            },
            {
                label: 'Isabela',
                value: 'ISA',
            },
            {
                label: 'Kalinga',
                value: 'KAL',
            },
            {
                label: 'La Union',
                value: 'LUN',
            },
            {
                label: 'Laguna',
                value: 'LAG',
            },
            {
                label: 'Lanao del Norte',
                value: 'LAN',
            },
            {
                label: 'Lanao del Sur',
                value: 'LAS',
            },
            {
                label: 'Leyte',
                value: 'LEY',
            },
            {
                label: 'Maguindanao',
                value: 'MAG',
            },
            {
                label: 'Masbate',
                value: 'MAS',
            },
            {
                label: 'Metro Manila',
                value: '00',
            },
            {
                label: 'Mindoro Occidental',
                value: 'MDC',
            },
            {
                label: 'Mindoro Oriental',
                value: 'MDR',
            },
            {
                label: 'Misamis Occidental',
                value: 'MSC',
            },
            {
                label: 'Misamis Oriental',
                value: 'MSR',
            },
            {
                label: 'Mountain Province',
                value: 'MOU',
            },
            {
                label: 'Negros Occidental',
                value: 'NEC',
            },
            {
                label: 'Negros Oriental',
                value: 'NER',
            },
            {
                label: 'Northern Samar',
                value: 'NSA',
            },
            {
                label: 'Nueva Ecija',
                value: 'NUE',
            },
            {
                label: 'Nueva Vizcaya',
                value: 'NUV',
            },
            {
                label: 'Palawan',
                value: 'PLW',
            },
            {
                label: 'Pampanga',
                value: 'PAM',
            },
            {
                label: 'Pangasinan',
                value: 'PAN',
            },
            {
                label: 'Quezon',
                value: 'QUE',
            },
            {
                label: 'Quirino',
                value: 'QUI',
            },
            {
                label: 'Rizal',
                value: 'RIZ',
            },
            {
                label: 'Romblon',
                value: 'ROM',
            },
            {
                label: 'Samar',
                value: 'WSA',
            },
            {
                label: 'Sarangani',
                value: 'SAR',
            },
            {
                label: 'Siquijor',
                value: 'SIG',
            },
            {
                label: 'Sorsogon',
                value: 'SOR',
            },
            {
                label: 'Southern Leyte',
                value: 'SLE',
            },
            {
                label: 'Sultan Kudarat',
                value: 'AUK',
            },
            {
                label: 'Sulu',
                value: 'SLU',
            },
            {
                label: 'Surigao del Norte',
                value: 'SUN',
            },
            {
                label: 'Surigao del Sur',
                value: 'SUR',
            },
            {
                label: 'Tarlac',
                value: 'TAR',
            },
            {
                label: 'Tawi-Tawi',
                value: 'TAW',
            },
            {
                label: 'Zambales',
                value: 'ZMB',
            },
            {
                label: 'Zamboanga del Norte',
                value: 'ZAN',
            },
            {
                label: 'Zamboanga del Sur',
                value: 'ZAS',
            },
            {
                label: 'Zamboanga Sibugay',
                value: 'ZSI',
            },
        ],
    },
    {
        label: 'Pitcairn',
        phoneCode: '+872',
        value: 'PN',
        countryFlag: '🇵🇳',
        regions: [
            {
                label: 'Pitcairn Islands',
                value: null,
            },
        ],
    },
    {
        label: 'Poland',
        phoneCode: '+48',
        value: 'PL',
        countryFlag: '🇵🇱',
        regions: [
            {
                label: 'Dolnośląskie',
                value: 'DS',
            },
            {
                label: 'Kujawsko-pomorskie',
                value: 'KP',
            },
            {
                label: 'Łódzkie',
                value: 'LD',
            },
            {
                label: 'Lubelskie',
                value: 'LU',
            },
            {
                label: 'Lubuskie',
                value: 'LB',
            },
            {
                label: 'Małopolskie',
                value: 'MA',
            },
            {
                label: 'Mazowieckie',
                value: 'MZ',
            },
            {
                label: 'Opolskie',
                value: 'OP',
            },
            {
                label: 'Podkarpackie',
                value: 'PK',
            },
            {
                label: 'Podlaskie',
                value: 'PD',
            },
            {
                label: 'Pomorskie',
                value: 'PM',
            },
            {
                label: 'Śląskie',
                value: 'SL',
            },
            {
                label: 'Świętokrzyskie',
                value: 'SK',
            },
            {
                label: 'Warmińsko-mazurskie',
                value: 'WN',
            },
            {
                label: 'Wielkopolskie',
                value: 'WP',
            },
            {
                label: 'Zachodniopomorskie',
                value: 'ZP',
            },
        ],
    },
    {
        label: 'Portugal',
        phoneCode: '+351',
        value: 'PT',
        countryFlag: '🇵🇹',
        regions: [
            {
                label: 'Açores',
                value: '20',
            },
            {
                label: 'Aveiro',
                value: '01',
            },
            {
                label: 'Beja',
                value: '02',
            },
            {
                label: 'Braga',
                value: '03',
            },
            {
                label: 'Bragança',
                value: '04',
            },
            {
                label: 'Castelo Branco',
                value: '05',
            },
            {
                label: 'Coimbra',
                value: '06',
            },
            {
                label: 'Évora',
                value: '07',
            },
            {
                label: 'Faro',
                value: '08',
            },
            {
                label: 'Guarda',
                value: '09',
            },
            {
                label: 'Leiria',
                value: '10',
            },
            {
                label: 'Lisboa',
                value: '11',
            },
            {
                label: 'Madeira',
                value: '30',
            },
            {
                label: 'Portalegre',
                value: '12',
            },
            {
                label: 'Porto',
                value: '13',
            },
            {
                label: 'Santarém',
                value: '14',
            },
            {
                label: 'Setúbal',
                value: '15',
            },
            {
                label: 'Viana do Castelo',
                value: '16',
            },
            {
                label: 'Vila Real',
                value: '17',
            },
            {
                label: 'Viseu',
                value: '18',
            },
        ],
    },
    {
        label: 'Puerto Rico',
        phoneCode: '+1939',
        value: 'PR',
        countryFlag: '🇵🇷',
        regions: [
            {
                label: 'Adjuntas',
                value: null,
            },
            {
                label: 'Aguada',
                value: null,
            },
            {
                label: 'Aguadilla',
                value: null,
            },
            {
                label: 'Aguas Buenas',
                value: null,
            },
            {
                label: 'Aibonito',
                value: null,
            },
            {
                label: 'Anasco',
                value: null,
            },
            {
                label: 'Arecibo',
                value: null,
            },
            {
                label: 'Arroyo',
                value: null,
            },
            {
                label: 'Barceloneta',
                value: null,
            },
            {
                label: 'Barranquitas',
                value: null,
            },
            {
                label: 'Bayamon',
                value: null,
            },
            {
                label: 'Cabo Rojo',
                value: null,
            },
            {
                label: 'Caguas',
                value: null,
            },
            {
                label: 'Camuy',
                value: null,
            },
            {
                label: 'Canovanas',
                value: null,
            },
            {
                label: 'Carolina',
                value: null,
            },
            {
                label: 'Cat',
                value: null,
            },
            {
                label: 'Ceiba',
                value: null,
            },
            {
                label: 'Ciales',
                value: null,
            },
            {
                label: 'Cidra',
                value: null,
            },
            {
                label: 'Coamo',
                value: null,
            },
            {
                label: 'Comerio',
                value: null,
            },
            {
                label: 'Corozal',
                value: null,
            },
            {
                label: 'Culebra',
                value: null,
            },
            {
                label: 'Dorado',
                value: null,
            },
            {
                label: 'Fajardo',
                value: null,
            },
            {
                label: 'Florida',
                value: null,
            },
            {
                label: 'Guanica',
                value: null,
            },
            {
                label: 'Guayama',
                value: null,
            },
            {
                label: 'Guayanilla',
                value: null,
            },
            {
                label: 'Guaynabo',
                value: null,
            },
            {
                label: 'Gurabo',
                value: null,
            },
            {
                label: 'Hatillo',
                value: null,
            },
            {
                label: 'Hormigueros',
                value: null,
            },
            {
                label: 'Humacao',
                value: null,
            },
            {
                label: 'Isabe',
                value: null,
            },
            {
                label: 'Juana Diaz',
                value: null,
            },
            {
                label: 'Juncos',
                value: null,
            },
            {
                label: 'Lajas',
                value: null,
            },
            {
                label: 'Lares',
                value: null,
            },
            {
                label: 'Las Marias',
                value: null,
            },
            {
                label: 'Las oiza',
                value: null,
            },
            {
                label: 'Luquillo',
                value: null,
            },
            {
                label: 'Manati',
                value: null,
            },
            {
                label: 'Maricao',
                value: null,
            },
            {
                label: 'Maunabo',
                value: null,
            },
            {
                label: 'Mayaguez',
                value: null,
            },
            {
                label: 'Moca',
                value: null,
            },
            {
                label: 'Morovis',
                value: null,
            },
            {
                label: 'Naguabo',
                value: null,
            },
            {
                label: 'Naranjito',
                value: null,
            },
            {
                label: 'Orocovis',
                value: null,
            },
            {
                label: 'Patillas',
                value: null,
            },
            {
                label: 'Penuelas',
                value: null,
            },
            {
                label: 'Ponce',
                value: null,
            },
            {
                label: 'Quebradillas',
                value: null,
            },
            {
                label: 'Rincon',
                value: null,
            },
            {
                label: 'Rio Grande',
                value: null,
            },
            {
                label: 'Sabana linas',
                value: null,
            },
            {
                label: 'San German',
                value: null,
            },
            {
                label: 'San Juan',
                value: null,
            },
            {
                label: 'San Lorenzo',
                value: null,
            },
            {
                label: 'San Sebastian',
                value: null,
            },
            {
                label: 'Santa Isabel',
                value: null,
            },
            {
                label: 'Toa Alta',
                value: null,
            },
            {
                label: 'Toa Baja',
                value: null,
            },
            {
                label: 'Trujillo Alto',
                value: null,
            },
            {
                label: 'Utuado',
                value: null,
            },
            {
                label: 'Vega Alta',
                value: null,
            },
            {
                label: 'Vega ues',
                value: null,
            },
            {
                label: 'Villalba',
                value: null,
            },
            {
                label: 'Yabucoa',
                value: null,
            },
            {
                label: 'Yauco',
                value: null,
            },
        ],
    },
    {
        label: 'Qatar',
        phoneCode: '+974',
        value: 'QA',
        countryFlag: '🇶🇦',
        regions: [
            {
                label: 'Ad Dawḩah',
                value: 'DA',
            },
            {
                label: 'Al Khawr wa adh Dhakhīrah',
                value: 'KH',
            },
            {
                label: 'Al Wakrah',
                value: 'WA',
            },
            {
                label: 'Ar Rayyān',
                value: 'RA',
            },
            {
                label: 'Ash Shamāl',
                value: 'MS',
            },
            {
                label: 'Az̧ Za̧`āyin',
                value: 'ZA',
            },
            {
                label: 'Umm Şalāl',
                value: 'US',
            },
        ],
    },
    {
        label: 'Réunion',
        phoneCode: '+262',
        value: 'RE',
        countryFlag: '🇷🇪',
        regions: [
            {
                label: 'Réunion',
                value: null,
            },
        ],
    },
    {
        label: 'Romania',
        phoneCode: '+40',
        value: 'RO',
        countryFlag: '🇷🇴',
        regions: [
            {
                label: 'Alba',
                value: 'AB',
            },
            {
                label: 'Arad',
                value: 'AR',
            },
            {
                label: 'Arges',
                value: 'AG',
            },
            {
                label: 'Bacau',
                value: 'BC',
            },
            {
                label: 'Bihor',
                value: 'BH',
            },
            {
                label: 'Bistrita-Nasaud',
                value: 'BN',
            },
            {
                label: 'Botosani',
                value: 'BT',
            },
            {
                label: 'Braila',
                value: 'BR',
            },
            {
                label: 'Brasov',
                value: 'BV',
            },
            {
                label: 'Bucuresti',
                value: 'B',
            },
            {
                label: 'Buzau',
                value: 'BZ',
            },
            {
                label: 'Calarasi',
                value: 'CL',
            },
            {
                label: 'Caras-Severin',
                value: 'CS',
            },
            {
                label: 'Cluj',
                value: 'CJ',
            },
            {
                label: 'Constanta',
                value: 'CT',
            },
            {
                label: 'Covasna',
                value: 'CV',
            },
            {
                label: 'Dambovita',
                value: 'DB',
            },
            {
                label: 'Dolj',
                value: 'DJ',
            },
            {
                label: 'Galati',
                value: 'GL',
            },
            {
                label: 'Giurgiu',
                value: 'GR',
            },
            {
                label: 'Gorj',
                value: 'GJ',
            },
            {
                label: 'Harghita',
                value: 'HR',
            },
            {
                label: 'Hunedoara',
                value: 'HD',
            },
            {
                label: 'Ialomita',
                value: 'IL',
            },
            {
                label: 'Iasi',
                value: 'IS',
            },
            {
                label: 'Maramures',
                value: 'MM',
            },
            {
                label: 'Mehedinti',
                value: 'MH',
            },
            {
                label: 'Mures',
                value: 'MS',
            },
            {
                label: 'Neamt',
                value: 'NT',
            },
            {
                label: 'Olt',
                value: 'OT',
            },
            {
                label: 'Prahova',
                value: 'PH',
            },
            {
                label: 'Salaj',
                value: 'SJ',
            },
            {
                label: 'Satu Mare',
                value: 'SM',
            },
            {
                label: 'Sibiu',
                value: 'SB',
            },
            {
                label: 'Suceava',
                value: 'SV',
            },
            {
                label: 'Teleorman',
                value: 'TR',
            },
            {
                label: 'Timis',
                value: 'TM',
            },
            {
                label: 'Tulcea',
                value: 'TL',
            },
            {
                label: 'Valcea',
                value: 'VL',
            },
            {
                label: 'Vaslui',
                value: 'VS',
            },
            {
                label: 'Vrancea',
                value: 'VN',
            },
        ],
    },
    {
        label: 'Russian Federation',
        phoneCode: '+7',
        value: 'RU',
        countryFlag: '🇷🇺',
        regions: [
            {
                label: 'Republic of Adygea',
                value: 'AD',
            },
            {
                label: 'Republic of Altai (Gorno-Altaysk)',
                value: 'AL',
            },
            {
                label: 'Altai Krai',
                value: 'ALT',
            },
            {
                label: 'Amur Oblast',
                value: 'AMU',
            },
            {
                label: 'Arkhangelsk Oblast',
                value: 'ARK',
            },
            {
                label: 'Astrakhan Oblast',
                value: 'AST',
            },
            {
                label: 'Republic of Bashkortostan',
                value: 'BA',
            },
            {
                label: 'Belgorod Oblast',
                value: 'BEL',
            },
            {
                label: 'Bryansk Oblast',
                value: 'BRY',
            },
            {
                label: 'Republic of Buryatia',
                value: 'BU',
            },
            {
                label: 'Chechen Republic',
                value: 'CE',
            },
            {
                label: 'Chelyabinsk Oblast',
                value: 'CHE',
            },
            {
                label: 'Chukotka Autonomous Okrug',
                value: 'CHU',
            },
            {
                label: 'Chuvash Republic',
                value: 'CU',
            },
            {
                label: 'Republic of Dagestan',
                value: 'DA',
            },
            {
                label: 'Republic of Ingushetia',
                value: 'IN',
            },
            {
                label: 'Irkutsk Oblast',
                value: 'IRK',
            },
            {
                label: 'Ivanovo Oblast',
                value: 'IVA',
            },
            {
                label: 'Jewish Autonomous Oblast',
                value: 'JEW',
            },
            {
                label: 'Kabardino-Balkar Republic',
                value: 'KB',
            },
            {
                label: 'Kaliningrad Oblast',
                value: 'KLN',
            },
            {
                label: 'Republic of Kalmykia',
                value: 'KL',
            },
            {
                label: 'Kaluga Oblast',
                value: 'KLU',
            },
            {
                label: 'Kamchatka Krai',
                value: 'KAM',
            },
            {
                label: 'Karachay-Cherkess Republic',
                value: 'KC',
            },
            {
                label: 'Republic of Karelia',
                value: 'KR',
            },
            {
                label: 'Khabarovsk Krai',
                value: 'KHA',
            },
            {
                label: 'Republic of Khakassia',
                value: 'KK',
            },
            {
                label: 'Khanty-Mansi Autonomous Okrug - Yugra',
                value: 'KHM',
            },
            {
                label: 'Kemerovo Oblast',
                value: 'KEM',
            },
            {
                label: 'Kirov Oblast',
                value: 'KIR',
            },
            {
                label: 'Komi Republic',
                value: 'KO',
            },
            {
                label: 'Kostroma Oblast',
                value: 'KOS',
            },
            {
                label: 'Krasnodar Krai',
                value: 'KDA',
            },
            {
                label: 'Krasnoyarsk Krai',
                value: 'KYA',
            },
            {
                label: 'Kurgan Oblast',
                value: 'KGN',
            },
            {
                label: 'Kursk Oblast',
                value: 'KRS',
            },
            {
                label: 'Leningrad Oblast',
                value: 'LEN',
            },
            {
                label: 'Lipetsk Oblast',
                value: 'LIP',
            },
            {
                label: 'Magadan Oblast',
                value: 'MAG',
            },
            {
                label: 'Mari El Republic',
                value: 'ME',
            },
            {
                label: 'Republic of Mordovia',
                value: 'MO',
            },
            {
                label: 'Moscow Oblast',
                value: 'MOS',
            },
            {
                label: 'Moscow',
                value: 'MOW',
            },
            {
                label: 'Murmansk Oblast',
                value: 'MU',
            },
            {
                label: 'Nenets Autonomous Okrug',
                value: 'NEN',
            },
            {
                label: 'Nizhny Novgorod Oblast',
                value: 'NIZ',
            },
            {
                label: 'Novgorod Oblast',
                value: 'NGR',
            },
            {
                label: 'Novosibirsk Oblast',
                value: 'NVS',
            },
            {
                label: 'Omsk Oblast',
                value: 'OMS',
            },
            {
                label: 'Orenburg Oblast',
                value: 'ORE',
            },
            {
                label: 'Oryol Oblast',
                value: 'ORL',
            },
            {
                label: 'Penza Oblast',
                value: 'PNZ',
            },
            {
                label: 'Perm Krai',
                value: 'PER',
            },
            {
                label: 'Primorsky Krai',
                value: 'PRI',
            },
            {
                label: 'Pskov Oblast',
                value: 'PSK',
            },
            {
                label: 'Rostov Oblast',
                value: 'ROS',
            },
            {
                label: 'Ryazan Oblast',
                value: 'RYA',
            },
            {
                label: 'Saint Petersburg',
                value: 'SPE',
            },
            {
                label: 'Sakha (Yakutia) Republic',
                value: 'SA',
            },
            {
                label: 'Sakhalin Oblast',
                value: 'SAK',
            },
            {
                label: 'Samara Oblast',
                value: 'SAM',
            },
            {
                label: 'Saratov Oblast',
                value: 'SAR',
            },
            {
                label: 'Republic of North Ossetia-Alania',
                value: 'NOA',
            },
            {
                label: 'Smolensk Oblast',
                value: 'SMO',
            },
            {
                label: 'Stavropol Krai',
                value: 'STA',
            },
            {
                label: 'Sverdlovsk Oblast',
                value: 'SVE',
            },
            {
                label: 'Tambov Oblast',
                value: 'TAM',
            },
            {
                label: 'Republic of Tatarstan',
                value: 'TA',
            },
            {
                label: 'Tomsk Oblast',
                value: 'TOM',
            },
            {
                label: 'Tuva Republic',
                value: 'TU',
            },
            {
                label: 'Tula Oblast',
                value: 'TUL',
            },
            {
                label: 'Tver Oblast',
                value: 'TVE',
            },
            {
                label: 'Tyumen Oblast',
                value: 'TYU',
            },
            {
                label: 'Udmurt Republic',
                value: 'UD',
            },
            {
                label: 'Ulyanovsk Oblast',
                value: 'ULY',
            },
            {
                label: 'Vladimir Oblast',
                value: 'VLA',
            },
            {
                label: 'Volgograd Oblast',
                value: 'VGG',
            },
            {
                label: 'Vologda Oblast',
                value: 'VLG',
            },
            {
                label: 'Voronezh Oblast',
                value: 'VOR',
            },
            {
                label: 'Yamalo-Nenets Autonomous Okrug',
                value: 'YAN',
            },
            {
                label: 'Yaroslavl Oblast',
                value: 'YAR',
            },
            {
                label: 'Zabaykalsky Krai',
                value: 'ZAB',
            },
        ],
    },
    {
        label: 'Rwanda',
        phoneCode: '+250',
        value: 'RW',
        countryFlag: '🇷🇼',
        regions: [
            {
                label: 'Kigali',
                value: '01',
            },
            {
                label: 'Eastern',
                value: '02',
            },
            {
                label: 'Northern',
                value: '03',
            },
            {
                label: 'Western',
                value: '04',
            },
            {
                label: 'Southern',
                value: '05',
            },
        ],
    },
    {
        label: 'Saint Barthélemy',
        phoneCode: '+590',
        value: 'BL',
        countryFlag: '🇧🇱',
        regions: [
            {
                label: 'Au Vent',
                value: '02',
            },
            {
                label: 'Sous le Vent',
                value: '01',
            },
        ],
    },
    {
        label: 'Saint Helena, Ascension and Tristan da Cunha',
        phoneCode: '+290',
        value: 'SH',
        countryFlag: '🇸🇭',
        regions: [
            {
                label: 'Ascension',
                value: 'AC',
            },
            {
                label: 'Saint Helena',
                value: 'HL',
            },
            {
                label: 'Tristan da Cunha',
                value: 'TA',
            },
        ],
    },
    {
        label: 'Saint Kitts and Nevis',
        phoneCode: '+1869',
        value: 'KN',
        countryFlag: '🇰🇳',
        regions: [
            {
                label: 'Saint Kitts',
                value: 'K',
            },
            {
                label: 'Nevis',
                value: 'N',
            },
        ],
    },
    {
        label: 'Saint Lucia',
        phoneCode: '+1758',
        value: 'LC',
        countryFlag: '🇱🇨',
        regions: [
            {
                label: 'Anse-la-Raye',
                value: '01',
            },
            {
                label: 'Canaries',
                value: '12',
            },
            {
                label: 'Castries',
                value: '02',
            },
            {
                label: 'Choiseul',
                value: '03',
            },
            {
                label: 'Dennery',
                value: '05',
            },
            {
                label: 'Gros Islet',
                value: '06',
            },
            {
                label: 'Laborie',
                value: '07',
            },
            {
                label: 'Micoud',
                value: '08',
            },
            {
                label: 'Soufriere',
                value: '10',
            },
            {
                label: 'Vieux Fort',
                value: '11',
            },
        ],
    },
    {
        label: 'Saint Martin',
        phoneCode: '+590',
        value: 'MF',
        countryFlag: '🇲🇫',
        regions: [
            {
                label: 'Saint Martin',
                value: null,
            },
        ],
    },
    {
        label: 'Saint Pierre and Miquelon',
        phoneCode: '+508',
        value: 'PM',
        countryFlag: '🇵🇲',
        regions: [
            {
                label: 'Miquelon',
                value: null,
            },
            {
                label: 'Saint Pierre',
                value: null,
            },
        ],
    },
    {
        label: 'Saint Vincent and the Grenadines',
        phoneCode: '+1784',
        value: 'VC',
        countryFlag: '🇻🇨',
        regions: [
            {
                label: 'Charlotte',
                value: '01',
            },
            {
                label: 'Grenadines',
                value: '06',
            },
            {
                label: 'Saint Andrew',
                value: '02',
            },
            {
                label: 'Saint David',
                value: '03',
            },
            {
                label: 'Saint George',
                value: '04',
            },
            {
                label: 'Saint Patrick',
                value: '05',
            },
        ],
    },
    {
        label: 'Samoa',
        phoneCode: '+685',
        value: 'WS',
        countryFlag: '🇼🇸',
        regions: [
            {
                label: 'A\'ana',
                value: 'AA',
            },
            {
                label: 'Aiga-i-le-Tai',
                value: 'AL',
            },
            {
                label: 'Atua',
                value: 'AT',
            },
            {
                label: 'Fa\'asaleleaga',
                value: 'FA',
            },
            {
                label: 'Gaga\'emauga',
                value: 'GE',
            },
            {
                label: 'Gagaifomauga',
                value: 'GI',
            },
            {
                label: 'Palauli',
                value: 'PA',
            },
            {
                label: 'Satupa\'itea',
                value: 'SA',
            },
            {
                label: 'Tuamasaga',
                value: 'TU',
            },
            {
                label: 'Va\'a-o-Fonoti',
                value: 'VF',
            },
            {
                label: 'Vaisigano',
                value: 'VS',
            },
        ],
    },
    {
        label: 'San Marino',
        phoneCode: '+378',
        value: 'SM',
        countryFlag: '🇸🇲',
        regions: [
            {
                label: 'Acquaviva',
                value: '01',
            },
            {
                label: 'Borgo Maggiore',
                value: '06',
            },
            {
                label: 'Chiesanuova',
                value: '02',
            },
            {
                label: 'Domagnano',
                value: '03',
            },
            {
                label: 'Faetano',
                value: '04',
            },
            {
                label: 'Fiorentino',
                value: '05',
            },
            {
                label: 'Montegiardino',
                value: '08',
            },
            {
                label: 'San Marino',
                value: '07',
            },
            {
                label: 'Serravalle',
                value: '09',
            },
        ],
    },
    {
        label: 'Sao Tome and Principe',
        phoneCode: '+239',
        value: 'ST',
        countryFlag: '🇸🇹',
        regions: [
            {
                label: 'Principe',
                value: 'P',
            },
            {
                label: 'Sao Tome',
                value: 'S',
            },
        ],
    },
    {
        label: 'Saudi Arabia',
        phoneCode: '+966',
        value: 'SA',
        countryFlag: '🇸🇦',
        regions: [
            {
                label: '\'Asir',
                value: '14',
            },
            {
                label: 'Al Bahah',
                value: '11',
            },
            {
                label: 'Al Hudud ash Shamaliyah',
                value: '08',
            },
            {
                label: 'Al Jawf',
                value: '12',
            },
            {
                label: 'Al Madinah al Munawwarah',
                value: '03',
            },
            {
                label: 'Al Qasim',
                value: '05',
            },
            {
                label: 'Ar Riyad',
                value: '01',
            },
            {
                label: 'Ash Sharqiyah',
                value: '04',
            },
            {
                label: 'Ha\'il',
                value: '06',
            },
            {
                label: 'Jazan',
                value: '09',
            },
            {
                label: 'Makkah al Mukarramah',
                value: '02',
            },
            {
                label: 'Najran',
                value: '10',
            },
            {
                label: 'Tabuk',
                value: '07',
            },
        ],
    },
    {
        label: 'Senegal',
        phoneCode: '+221',
        value: 'SN',
        countryFlag: '🇸🇳',
        regions: [
            {
                label: 'Dakar',
                value: 'DK',
            },
            {
                label: 'Diourbel',
                value: 'DB',
            },
            {
                label: 'Fatick',
                value: 'FK',
            },
            {
                label: 'Kaffrine',
                value: 'KA',
            },
            {
                label: 'Kaolack',
                value: 'KL',
            },
            {
                label: 'Kedougou',
                value: 'KE',
            },
            {
                label: 'Kolda',
                value: 'KD',
            },
            {
                label: 'Louga',
                value: 'LG',
            },
            {
                label: 'Matam',
                value: 'MT',
            },
            {
                label: 'Saint-Louis',
                value: 'SL',
            },
            {
                label: 'Sedhiou',
                value: 'SE',
            },
            {
                label: 'Tambacounda',
                value: 'TC',
            },
            {
                label: 'Thies',
                value: 'TH',
            },
            {
                label: 'Ziguinchor',
                value: 'ZG',
            },
        ],
    },
    {
        label: 'Serbia',
        phoneCode: '+381',
        value: 'RS',
        countryFlag: '🇷🇸',
        regions: [
            {
                label: 'Beograd (Belgrade)',
                value: '00',
            },
            {
                label: 'Borski',
                value: '14',
            },
            {
                label: 'Braničevski',
                value: '11',
            },
            {
                label: 'Jablanički',
                value: '23',
            },
            {
                label: 'Južnobački',
                value: '06',
            },
            {
                label: 'Južnobanatski',
                value: '04',
            },
            {
                label: 'Kolubarski',
                value: '09',
            },
            {
                label: 'Kosovski',
                value: '25',
            },
            {
                label: 'Kosovsko-Mitrovački',
                value: '28',
            },
            {
                label: 'Kosovsko-Pomoravski',
                value: '29',
            },
            {
                label: 'Mačvanski',
                value: '08',
            },
            {
                label: 'Moravički',
                value: '17',
            },
            {
                label: 'Nišavski',
                value: '20',
            },
            {
                label: 'Pčinjski',
                value: '24',
            },
            {
                label: 'Pećki',
                value: '26',
            },
            {
                label: 'Pirotski',
                value: '22',
            },
            {
                label: 'Podunavski',
                value: '10',
            },
            {
                label: 'Pomoravski',
                value: '13',
            },
            {
                label: 'Prizrenski',
                value: '27',
            },
            {
                label: 'Rasinski',
                value: '19',
            },
            {
                label: 'Raški',
                value: '18',
            },
            {
                label: 'Severnobački',
                value: '01',
            },
            {
                label: 'Severnobanatski',
                value: '03',
            },
            {
                label: 'Srednjebanatski',
                value: '02',
            },
            {
                label: 'Sremski',
                value: '07',
            },
            {
                label: 'Šumadijski',
                value: '12',
            },
            {
                label: 'Toplički',
                value: '21',
            },
            {
                label: 'Zaječarski',
                value: '15',
            },
            {
                label: 'Zapadnobački',
                value: '05',
            },
            {
                label: 'Zlatiborski',
                value: '16',
            },
        ],
    },
    {
        label: 'Seychelles',
        phoneCode: '+248',
        value: 'SC',
        countryFlag: '🇸🇨',
        regions: [
            {
                label: 'Anse aux Pins',
                value: '01',
            },
            {
                label: 'Anse Boileau',
                value: '02',
            },
            {
                label: 'Anse Etoile',
                value: '03',
            },
            {
                label: 'Anse Royale',
                value: '05',
            },
            {
                label: 'Anu Cap',
                value: '04',
            },
            {
                label: 'Baie Lazare',
                value: '06',
            },
            {
                label: 'Baie Sainte Anne',
                value: '07',
            },
            {
                label: 'Beau Vallon',
                value: '08',
            },
            {
                label: 'Bel Air',
                value: '09',
            },
            {
                label: 'Bel Ombre',
                value: '10',
            },
            {
                label: 'Cascade',
                value: '11',
            },
            {
                label: 'Glacis',
                value: '12',
            },
            {
                label: 'Grand\'Anse Mahe',
                value: '13',
            },
            {
                label: 'Grand\'Anse Praslin',
                value: '14',
            },
            {
                label: 'La Digue',
                value: '15',
            },
            {
                label: 'La Riviere Anglaise',
                value: '16',
            },
            {
                label: 'Les Mamelles',
                value: '24',
            },
            {
                label: 'Mont Buxton',
                value: '17',
            },
            {
                label: 'Mont Fleuri',
                value: '18',
            },
            {
                label: 'Plaisance',
                value: '19',
            },
            {
                label: 'Pointe La Rue',
                value: '20',
            },
            {
                label: 'Port Glaud',
                value: '21',
            },
            {
                label: 'Roche Caiman',
                value: '25',
            },
            {
                label: 'Saint Louis',
                value: '22',
            },
            {
                label: 'Takamaka',
                value: '23',
            },
        ],
    },
    {
        label: 'Sierra Leone',
        phoneCode: '+232',
        value: 'SL',
        countryFlag: '🇸🇱',
        regions: [
            {
                label: 'Eastern',
                value: 'E',
            },
            {
                label: 'Northern',
                value: 'N',
            },
            {
                label: 'Southern',
                value: 'S',
            },
            {
                label: 'Western',
                value: 'W',
            },
        ],
    },
    {
        label: 'Singapore',
        phoneCode: '+65',
        value: 'SG',
        countryFlag: '🇸🇬',
        regions: [
            {
                label: 'Central Singapore',
                value: '01',
            },
            {
                label: 'North East',
                value: '02',
            },
            {
                label: 'North West',
                value: '03',
            },
            {
                label: 'South East',
                value: '04',
            },
            {
                label: 'South West',
                value: '05',
            },
        ],
    },
    {
        label: 'Sint Maarten (Dutch part)',
        phoneCode: '+1721',
        value: 'SX',
        countryFlag: '🇸🇽',
        regions: [
            {
                label: 'Sint Maarten',
                value: null,
            },
        ],
    },
    {
        label: 'Slovakia',
        phoneCode: '+421',
        value: 'SK',
        countryFlag: '🇸🇰',
        regions: [
            {
                label: 'Banskobystricky',
                value: 'BC',
            },
            {
                label: 'Bratislavsky',
                value: 'BL',
            },
            {
                label: 'Kosicky',
                value: 'KI',
            },
            {
                label: 'Nitriansky',
                value: 'NI',
            },
            {
                label: 'Presovsky',
                value: 'PV',
            },
            {
                label: 'Trenciansky',
                value: 'TC',
            },
            {
                label: 'Trnavsky',
                value: 'TA',
            },
            {
                label: 'Zilinsky',
                value: 'ZI',
            },
        ],
    },
    {
        label: 'Slovenia',
        phoneCode: '+386',
        value: 'SI',
        countryFlag: '🇸🇮',
        regions: [
            {
                label: 'Ajdovscina',
                value: '001',
            },
            {
                label: 'Apace',
                value: '195',
            },
            {
                label: 'Beltinci',
                value: '002',
            },
            {
                label: 'Benedikt',
                value: '148',
            },
            {
                label: 'Bistrica ob Sotli',
                value: '149',
            },
            {
                label: 'Bled',
                value: '003',
            },
            {
                label: 'Bloke',
                value: '150',
            },
            {
                label: 'Bohinj',
                value: '004',
            },
            {
                label: 'Borovnica',
                value: '005',
            },
            {
                label: 'Bovec',
                value: '006',
            },
            {
                label: 'Braslovce',
                value: '151',
            },
            {
                label: 'Brda',
                value: '007',
            },
            {
                label: 'Brezice',
                value: '009',
            },
            {
                label: 'Brezovica',
                value: '008',
            },
            {
                label: 'Cankova',
                value: '152',
            },
            {
                label: 'Celje',
                value: '011',
            },
            {
                label: 'Cerklje na Gorenjskem',
                value: '012',
            },
            {
                label: 'Cerknica',
                value: '013',
            },
            {
                label: 'Cerkno',
                value: '014',
            },
            {
                label: 'Cerkvenjak',
                value: '153',
            },
            {
                label: 'Cirkulane',
                value: '196',
            },
            {
                label: 'Crensovci',
                value: '015',
            },
            {
                label: 'Crna na Koroskem',
                value: '016',
            },
            {
                label: 'Crnomelj',
                value: '017',
            },
            {
                label: 'Destrnik',
                value: '018',
            },
            {
                label: 'Divaca',
                value: '019',
            },
            {
                label: 'Dobje',
                value: '154',
            },
            {
                label: 'Dobrepolje',
                value: '020',
            },
            {
                label: 'Dobrna',
                value: '155',
            },
            {
                label: 'Dobrova-Polhov Gradec',
                value: '021',
            },
            {
                label: 'Dobrovnik',
                value: '156',
            },
            {
                label: 'Dol pri Ljubljani',
                value: '022',
            },
            {
                label: 'Dolenjske Toplice',
                value: '157',
            },
            {
                label: 'Domzale',
                value: '023',
            },
            {
                label: 'Dornava',
                value: '024',
            },
            {
                label: 'Dravograd',
                value: '025',
            },
            {
                label: 'Duplek',
                value: '026',
            },
            {
                label: 'Gorenja Vas-Poljane',
                value: '027',
            },
            {
                label: 'Gorisnica',
                value: '028',
            },
            {
                label: 'Gorje',
                value: '207',
            },
            {
                label: 'Gornja Radgona',
                value: '029',
            },
            {
                label: 'Gornji Grad',
                value: '030',
            },
            {
                label: 'Gornji Petrovci',
                value: '031',
            },
            {
                label: 'Grad',
                value: '158',
            },
            {
                label: 'Grosuplje',
                value: '032',
            },
            {
                label: 'Hajdina',
                value: '159',
            },
            {
                label: 'Hoce-Slivnica',
                value: '160',
            },
            {
                label: 'Hodos',
                value: '161',
            },
            {
                label: 'Horjul',
                value: '162',
            },
            {
                label: 'Hrastnik',
                value: '034',
            },
            {
                label: 'Hrpelje-Kozina',
                value: '035',
            },
            {
                label: 'Idrija',
                value: '036',
            },
            {
                label: 'Ig',
                value: '037',
            },
            {
                label: 'Ilirska Bistrica',
                value: '038',
            },
            {
                label: 'Ivancna Gorica',
                value: '039',
            },
            {
                label: 'Izola',
                value: '040s',
            },
            {
                label: 'Jesenice',
                value: '041',
            },
            {
                label: 'Jursinci',
                value: '042',
            },
            {
                label: 'Kamnik',
                value: '043',
            },
            {
                label: 'Kanal',
                value: '044',
            },
            {
                label: 'Kidricevo',
                value: '045',
            },
            {
                label: 'Kobarid',
                value: '046',
            },
            {
                label: 'Kobilje',
                value: '047',
            },
            {
                label: 'Kocevje',
                value: '048',
            },
            {
                label: 'Komen',
                value: '049',
            },
            {
                label: 'Komenda',
                value: '164',
            },
            {
                label: 'Koper',
                value: '050',
            },
            {
                label: 'Kodanjevica na Krki',
                value: '197',
            },
            {
                label: 'Kostel',
                value: '165',
            },
            {
                label: 'Kozje',
                value: '051',
            },
            {
                label: 'Kranj',
                value: '052',
            },
            {
                label: 'Kranjska Gora',
                value: '053',
            },
            {
                label: 'Krizevci',
                value: '166',
            },
            {
                label: 'Krsko',
                value: '054',
            },
            {
                label: 'Kungota',
                value: '055',
            },
            {
                label: 'Kuzma',
                value: '056',
            },
            {
                label: 'Lasko',
                value: '057',
            },
            {
                label: 'Lenart',
                value: '058',
            },
            {
                label: 'Lendava',
                value: '059',
            },
            {
                label: 'Litija',
                value: '068',
            },
            {
                label: 'Ljubljana',
                value: '061',
            },
            {
                label: 'Ljubno',
                value: '062',
            },
            {
                label: 'Ljutomer',
                value: '063',
            },
            {
                label: 'Log-Dragomer',
                value: '208',
            },
            {
                label: 'Logatec',
                value: '064',
            },
            {
                label: 'Loska Dolina',
                value: '065',
            },
            {
                label: 'Loski Potok',
                value: '066',
            },
            {
                label: 'Lovrenc na Pohorju',
                value: '167',
            },
            {
                label: 'Lukovica',
                value: '068',
            },
            {
                label: 'Luce',
                value: '067',
            },
            {
                label: 'Majsperk',
                value: '069',
            },
            {
                label: 'Makole',
                value: '198',
            },
            {
                label: 'Maribor',
                value: '070',
            },
            {
                label: 'Markovci',
                value: '168',
            },
            {
                label: 'Medvode',
                value: '071',
            },
            {
                label: 'Menges',
                value: '072',
            },
            {
                label: 'Metlika',
                value: '073',
            },
            {
                label: 'Mezica',
                value: '074',
            },
            {
                label: 'Miklavz na Dravskem Polju',
                value: '169',
            },
            {
                label: 'Miren-Kostanjevica',
                value: '075',
            },
            {
                label: 'Mirna',
                value: '212',
            },
            {
                label: 'Mirna Pec',
                value: '170',
            },
            {
                label: 'Mislinja',
                value: '076',
            },
            {
                label: 'Mokronog-Trebelno',
                value: '199',
            },
            {
                label: 'Moravce',
                value: '077',
            },
            {
                label: 'Moravske Toplice',
                value: '078',
            },
            {
                label: 'Mozirje',
                value: '079',
            },
            {
                label: 'Murska Sobota',
                value: '080',
            },
            {
                label: 'Naklo',
                value: '082',
            },
            {
                label: 'Nazarje',
                value: '083',
            },
            {
                label: 'Nova Gorica',
                value: '084',
            },
            {
                label: 'Novo Mesto',
                value: '085',
            },
            {
                label: 'Odranci',
                value: '086',
            },
            {
                label: 'Ormoz',
                value: '087',
            },
            {
                label: 'Osilnica',
                value: '088',
            },
            {
                label: 'Pesnica',
                value: '089',
            },
            {
                label: 'Piran',
                value: '090',
            },
            {
                label: 'Pivka',
                value: '091',
            },
            {
                label: 'Podcetrtek',
                value: '092',
            },
            {
                label: 'Podlehnik',
                value: '172',
            },
            {
                label: 'Podvelka',
                value: '093',
            },
            {
                label: 'Poljcane',
                value: '200',
            },
            {
                label: 'Postojna',
                value: '094',
            },
            {
                label: 'Prebold',
                value: '174',
            },
            {
                label: 'Preddvor',
                value: '095',
            },
            {
                label: 'Prevalje',
                value: '175',
            },
            {
                label: 'Ptuj',
                value: '096',
            },
            {
                label: 'Race-Fram',
                value: '098',
            },
            {
                label: 'Radece',
                value: '099',
            },
            {
                label: 'Radenci',
                value: '100',
            },
            {
                label: 'Radlje ob Dravi',
                value: '101',
            },
            {
                label: 'Radovljica',
                value: '102',
            },
            {
                label: 'Ravne na Koroskem',
                value: '103',
            },
            {
                label: 'Razkrizje',
                value: '176',
            },
            {
                label: 'Recica ob Savinji',
                value: '209',
            },
            {
                label: 'Rence-Vogrsko',
                value: '201',
            },
            {
                label: 'Ribnica',
                value: '104',
            },
            {
                label: 'Ribnica na Poboriu',
                value: '177',
            },
            {
                label: 'Rogaska Slatina',
                value: '106',
            },
            {
                label: 'Rogasovci',
                value: '105',
            },
            {
                label: 'Rogatec',
                value: '107',
            },
            {
                label: 'Ruse',
                value: '108',
            },
            {
                label: 'Salovci',
                value: '033',
            },
            {
                label: 'Selnica ob Dravi',
                value: '178',
            },
            {
                label: 'Semic',
                value: '109',
            },
            {
                label: 'Sempeter-Vrtojba',
                value: '183',
            },
            {
                label: 'Sencur',
                value: '117',
            },
            {
                label: 'Sentilj',
                value: '118',
            },
            {
                label: 'Sentjernej',
                value: '119',
            },
            {
                label: 'Sentjur',
                value: '120',
            },
            {
                label: 'Sentrupert',
                value: '211',
            },
            {
                label: 'Sevnica',
                value: '110',
            },
            {
                label: 'Sezana',
                value: '111',
            },
            {
                label: 'Skocjan',
                value: '121',
            },
            {
                label: 'Skofja Loka',
                value: '122',
            },
            {
                label: 'Skofljica',
                value: '123',
            },
            {
                label: 'Slovenj Gradec',
                value: '112',
            },
            {
                label: 'Slovenska Bistrica',
                value: '113',
            },
            {
                label: 'Slovenske Konjice',
                value: '114',
            },
            {
                label: 'Smarje pri elsah',
                value: '124',
            },
            {
                label: 'Smarjeske Toplice',
                value: '206',
            },
            {
                label: 'Smartno ob Paki',
                value: '125',
            },
            {
                label: 'Smartno pri Litiji',
                value: '194',
            },
            {
                label: 'Sodrazica',
                value: '179',
            },
            {
                label: 'Solcava',
                value: '180',
            },
            {
                label: 'Sostanj',
                value: '126',
            },
            {
                label: 'Sredisce ob Dravi',
                value: '202',
            },
            {
                label: 'Starse',
                value: '115',
            },
            {
                label: 'Store',
                value: '127',
            },
            {
                label: 'Straza',
                value: '203',
            },
            {
                label: 'Sveta Ana',
                value: '181',
            },
            {
                label: 'Sveta Trojica v Slovenskih Goricah',
                value: '204',
            },
            {
                label: 'Sveta Andraz v Slovenskih Goricah',
                value: '182',
            },
            {
                label: 'Sveti Jurij',
                value: '116',
            },
            {
                label: 'Sveti Jurij v Slovenskih Goricah',
                value: '210',
            },
            {
                label: 'Sveti Tomaz',
                value: '205',
            },
            {
                label: 'Tabor',
                value: '184',
            },
            {
                label: 'Tisina',
                value: '128',
            },
            {
                label: 'Tolmin',
                value: '128',
            },
            {
                label: 'Trbovlje',
                value: '129',
            },
            {
                label: 'Trebnje',
                value: '130',
            },
            {
                label: 'Trnovska Vas',
                value: '185',
            },
            {
                label: 'Trzin',
                value: '186',
            },
            {
                label: 'Trzic',
                value: '131',
            },
            {
                label: 'Turnisce',
                value: '132',
            },
            {
                label: 'Velenje',
                value: '133',
            },
            {
                label: 'Velika Polana',
                value: '187',
            },
            {
                label: 'Velike Lasce',
                value: '134',
            },
            {
                label: 'Verzej',
                value: '188',
            },
            {
                label: 'Videm',
                value: '135',
            },
            {
                label: 'Vipava',
                value: '136',
            },
            {
                label: 'Vitanje',
                value: '137',
            },
            {
                label: 'Vodice',
                value: '138',
            },
            {
                label: 'Vojnik',
                value: '139',
            },
            {
                label: 'Vransko',
                value: '189',
            },
            {
                label: 'Vrhnika',
                value: '140',
            },
            {
                label: 'Vuzenica',
                value: '141',
            },
            {
                label: 'Zagorje ob Savi',
                value: '142',
            },
            {
                label: 'Zavrc',
                value: '143',
            },
            {
                label: 'Zrece',
                value: '144',
            },
            {
                label: 'Zalec',
                value: '190',
            },
            {
                label: 'Zelezniki',
                value: '146',
            },
            {
                label: 'Zetale',
                value: '191',
            },
            {
                label: 'Ziri',
                value: '147',
            },
            {
                label: 'Zirovnica',
                value: '192',
            },
            {
                label: 'Zuzemberk',
                value: '193',
            },
        ],
    },
    {
        label: 'Solomon Islands',
        phoneCode: '+677',
        value: 'SB',
        countryFlag: '🇸🇧',
        regions: [
            {
                label: 'Central',
                value: 'CE',
            },
            {
                label: 'Choiseul',
                value: 'CH',
            },
            {
                label: 'Guadalcanal',
                value: 'GU',
            },
            {
                label: 'Honiara',
                value: 'CT',
            },
            {
                label: 'Isabel',
                value: 'IS',
            },
            {
                label: 'Makira-Ulawa',
                value: 'MK',
            },
            {
                label: 'Malaita',
                value: 'ML',
            },
            {
                label: 'Rennell and Bellona',
                value: 'RB',
            },
            {
                label: 'Temotu',
                value: 'TE',
            },
            {
                label: 'Western',
                value: 'WE',
            },
        ],
    },
    {
        label: 'Somalia',
        phoneCode: '+252',
        value: 'SO',
        countryFlag: '🇸🇴',
        regions: [
            {
                label: 'Awdal',
                value: 'AW',
            },
            {
                label: 'Bakool',
                value: 'BK',
            },
            {
                label: 'Banaadir',
                value: 'BN',
            },
            {
                label: 'Bari',
                value: 'BR',
            },
            {
                label: 'Bay',
                value: 'BY',
            },
            {
                label: 'Galguduud',
                value: 'GA',
            },
            {
                label: 'Gedo',
                value: 'GE',
            },
            {
                label: 'Hiiraan',
                value: 'HI',
            },
            {
                label: 'Jubbada Dhexe',
                value: 'JD',
            },
            {
                label: 'Jubbada Hoose',
                value: 'JH',
            },
            {
                label: 'Mudug',
                value: 'MU',
            },
            {
                label: 'Nugaal',
                value: 'NU',
            },
            {
                label: 'Sanaag',
                value: 'SA',
            },
            {
                label: 'Shabeellaha Dhexe',
                value: 'SD',
            },
            {
                label: 'Shabeellaha Hoose',
                value: 'SH',
            },
            {
                label: 'Sool',
                value: 'SO',
            },
            {
                label: 'Togdheer',
                value: 'TO',
            },
            {
                label: 'Woqooyi Galbeed',
                value: 'WO',
            },
        ],
    },
    {
        label: 'South Africa',
        phoneCode: '+27',
        value: 'ZA',
        countryFlag: '🇿🇦',
        regions: [
            {
                label: 'Eastern Cape',
                value: 'EC',
            },
            {
                label: 'Free State',
                value: 'FS',
            },
            {
                label: 'Gauteng',
                value: 'GT',
            },
            {
                label: 'KwaZulu-Natal',
                value: 'NL',
            },
            {
                label: 'Limpopo',
                value: 'LP',
            },
            {
                label: 'Mpumalanga',
                value: 'MP',
            },
            {
                label: 'Northern Cape',
                value: 'NC',
            },
            {
                label: 'North West',
                value: 'NW',
            },
            {
                label: 'Western Cape',
                value: 'WC',
            },
        ],
    },
    {
        label: 'South Georgia and South Sandwich Islands',
        phoneCode: '+500',
        value: 'GS',
        countryFlag: '🇬🇸',
        regions: [
            {
                label: 'Bird Island',
                value: null,
            },
            {
                label: 'Bristol Island',
                value: null,
            },
            {
                label: 'Clerke Rocks',
                value: null,
            },
            {
                label: 'Montagu Island',
                value: null,
            },
            {
                label: 'Saunders Island',
                value: null,
            },
            {
                label: 'South Georgia',
                value: null,
            },
            {
                label: 'Southern Thule',
                value: null,
            },
            {
                label: 'Traversay Islands',
                value: null,
            },
        ],
    },
    {
        label: 'South Sudan',
        phoneCode: '+211',
        value: 'SS',
        countryFlag: '🇸🇸',
        regions: [
            {
                label: 'Central Equatoria',
                value: 'CE',
            },
            {
                label: 'Eastern Equatoria',
                value: 'EE',
            },
            {
                label: 'Jonglei',
                value: 'JG',
            },
            {
                label: 'Lakes',
                value: 'LK',
            },
            {
                label: 'Northern Bahr el Ghazal',
                value: 'BN',
            },
            {
                label: 'Unity',
                value: 'UY',
            },
            {
                label: 'Upper Nile',
                value: 'NU',
            },
            {
                label: 'Warrap',
                value: 'WR',
            },
            {
                label: 'Western Bahr el Ghazal',
                value: 'BW',
            },
            {
                label: 'Western Equatoria',
                value: 'EW',
            },
        ],
    },
    {
        label: 'Spain',
        phoneCode: '+34',
        value: 'ES',
        countryFlag: '🇪🇸',
        regions: [
            {
                label: 'Albacete',
                value: 'CM',
            },
            {
                label: 'Alicante',
                value: 'VC',
            },
            {
                label: 'Almería',
                value: 'AN',
            },
            {
                label: 'Araba/Álava',
                value: 'VI',
            },
            {
                label: 'Asturias',
                value: 'O',
            },
            {
                label: 'Ávila',
                value: 'AV',
            },
            {
                label: 'Badajoz',
                value: 'BA',
            },
            {
                label: 'Barcelona',
                value: 'B',
            },
            {
                label: 'Bizkaia',
                value: 'BI',
            },
            {
                label: 'Burgos',
                value: 'BU',
            },
            {
                label: 'Cáceres',
                value: 'CC',
            },
            {
                label: 'Cádiz',
                value: 'CA',
            },
            {
                label: 'Cantabria',
                value: 'S',
            },
            {
                label: 'Castellón',
                value: 'CS',
            },
            {
                label: 'Cueta',
                value: 'CU',
            },
            {
                label: 'Ciudad Real',
                value: 'CR',
            },
            {
                label: 'Córdoba',
                value: 'CO',
            },
            {
                label: 'A Coruña',
                value: 'C',
            },
            {
                label: 'Cuenca',
                value: 'CU',
            },
            {
                label: 'Gipuzkoa',
                value: 'SS',
            },
            {
                label: 'Girona',
                value: 'GI',
            },
            {
                label: 'Granada',
                value: 'GR',
            },
            {
                label: 'Guadalajara',
                value: 'GU',
            },
            {
                label: 'Huelva',
                value: 'H',
            },
            {
                label: 'Huesca',
                value: 'HU',
            },
            {
                label: 'Illes Balears',
                value: 'PM',
            },
            {
                label: 'Jaén',
                value: 'J',
            },
            {
                label: 'León',
                value: 'LE',
            },
            {
                label: 'Lleida',
                value: 'L',
            },
            {
                label: 'Lugo',
                value: 'LU',
            },
            {
                label: 'Madrid',
                value: 'M',
            },
            {
                label: 'Málaga',
                value: 'MA',
            },
            {
                label: 'Melilla',
                value: 'ML',
            },
            {
                label: 'Murcia',
                value: 'MU',
            },
            {
                label: 'Navarre',
                value: 'NA',
            },
            {
                label: 'Ourense',
                value: 'OR',
            },
            {
                label: 'Palencia',
                value: 'P',
            },
            {
                label: 'Las Palmas',
                value: 'GC',
            },
            {
                label: 'Pontevedra',
                value: 'PO',
            },
            {
                label: 'La Rioja',
                value: 'LO',
            },
            {
                label: 'Salamanca',
                value: 'SA',
            },
            {
                label: 'Santa Cruz de Tenerife',
                value: 'TF',
            },
            {
                label: 'Segovia',
                value: 'SG',
            },
            {
                label: 'Sevilla',
                value: 'SE',
            },
            {
                label: 'Soria',
                value: 'SO',
            },
            {
                label: 'Tarragona',
                value: 'T',
            },
            {
                label: 'Teruel',
                value: 'TE',
            },
            {
                label: 'Toledo',
                value: 'TO',
            },
            {
                label: 'Valencia',
                value: 'V',
            },
            {
                label: 'Valladolid',
                value: 'VA',
            },
            {
                label: 'Zamora',
                value: 'ZA',
            },
            {
                label: 'Zaragoza',
                value: 'Z',
            },
        ],
    },
    {
        label: 'Sri Lanka',
        phoneCode: '+94',
        value: 'LK',
        countryFlag: '🇱🇰',
        regions: [
            {
                label: 'Basnahira',
                value: '1',
            },
            {
                label: 'Dakunu',
                value: '3',
            },
            {
                label: 'Madhyama',
                value: '2',
            },
            {
                label: 'Naegenahira',
                value: '5',
            },
            {
                label: 'Sabaragamuwa',
                value: '9',
            },
            {
                label: 'Uturu',
                value: '4',
            },
            {
                label: 'Uturumaeda',
                value: '7',
            },
            {
                label: 'Vayamba',
                value: '6',
            },
            {
                label: 'Uva',
                value: '8',
            },
        ],
    },
    {
        label: 'Sudan',
        phoneCode: '+249',
        value: 'SD',
        countryFlag: '🇸🇩',
        regions: [
            {
                label: 'Al Bahr al Ahmar',
                value: 'RS',
            },
            {
                label: 'Al Jazirah',
                value: 'GZ',
            },
            {
                label: 'Al Khartum',
                value: 'KH',
            },
            {
                label: 'Al Qadarif',
                value: 'GD',
            },
            {
                label: 'An Nil al Abyad',
                value: 'NW',
            },
            {
                label: 'An Nil al Azraq',
                value: 'NB',
            },
            {
                label: 'Ash Shamaliyah',
                value: 'NO',
            },
            {
                label: 'Gharb Darfur',
                value: 'DW',
            },
            {
                label: 'Gharb Kurdufan',
                value: 'GK',
            },
            {
                label: 'Janub Darfur',
                value: 'DS',
            },
            {
                label: 'Janub Kurdufan',
                value: 'KS',
            },
            {
                label: 'Kassala',
                value: 'KA',
            },
            {
                label: 'Nahr an Nil',
                value: 'NR',
            },
            {
                label: 'Shamal Darfur',
                value: 'DN',
            },
            {
                label: 'Sharq Darfur',
                value: 'DE',
            },
            {
                label: 'Shiamal Kurdufan',
                value: 'KN',
            },
            {
                label: 'Sinnar',
                value: 'SI',
            },
            {
                label: 'Wasat Darfur Zalinjay',
                value: 'DC',
            },
        ],
    },
    {
        label: 'Surilabel',
        phoneCode: '+597',
        value: 'SR',
        countryFlag: '🇸🇷',
        regions: [
            {
                label: 'Brokopondo',
                value: 'BR',
            },
            {
                label: 'Commewijne',
                value: 'CM',
            },
            {
                label: 'Coronie',
                value: 'CR',
            },
            {
                label: 'Marowijne',
                value: 'MA',
            },
            {
                label: 'Nickerie',
                value: 'NI',
            },
            {
                label: 'Para',
                value: 'PR',
            },
            {
                label: 'Paramaribo',
                value: 'PM',
            },
            {
                label: 'Saramacca',
                value: 'SA',
            },
            {
                label: 'Sipaliwini',
                value: 'SI',
            },
            {
                label: 'Wanica',
                value: 'WA',
            },
        ],
    },
    {
        label: 'Swaziland',
        phoneCode: '+268',
        value: 'SZ',
        countryFlag: '🇸🇿',
        regions: [
            {
                label: 'Hhohho',
                value: 'HH',
            },
            {
                label: 'Lubombo',
                value: 'LU',
            },
            {
                label: 'Manzini',
                value: 'MA',
            },
            {
                label: 'Shiselweni',
                value: 'SH',
            },
        ],
    },
    {
        label: 'Sweden',
        phoneCode: '+46',
        value: 'SE',
        countryFlag: '🇸🇪',
        regions: [
            {
                label: 'Blekinge',
                value: 'K',
            },
            {
                label: 'Dalarna',
                value: 'W',
            },
            {
                label: 'Gävleborg',
                value: 'X',
            },
            {
                label: 'Gotland',
                value: 'I',
            },
            {
                label: 'Halland',
                value: 'N',
            },
            {
                label: 'Jämtland',
                value: 'Z',
            },
            {
                label: 'Jönköping',
                value: 'F',
            },
            {
                label: 'Kalmar',
                value: 'H',
            },
            {
                label: 'Kronoberg',
                value: 'G',
            },
            {
                label: 'Norrbotten',
                value: 'BD',
            },
            {
                label: 'Örebro',
                value: 'T',
            },
            {
                label: 'Östergötland',
                value: 'E',
            },
            {
                label: 'Skåne',
                value: 'M',
            },
            {
                label: 'Södermanland',
                value: 'D',
            },
            {
                label: 'Stockholm',
                value: 'AB',
            },
            {
                label: 'Uppsala',
                value: 'C',
            },
            {
                label: 'Värmland',
                value: 'S',
            },
            {
                label: 'Västerbotten',
                value: 'AC',
            },
            {
                label: 'Västernorrland',
                value: 'Y',
            },
            {
                label: 'Västmanland',
                value: 'U',
            },
            {
                label: 'Västra Götaland',
                value: 'O',
            },
        ],
    },
    {
        label: 'Switzerland',
        phoneCode: '+41',
        value: 'CH',
        countryFlag: '🇨🇭',
        regions: [
            {
                label: 'Aargau',
                value: 'AG',
            },
            {
                label: 'Appenzell Ausserrhoden',
                value: 'AR',
            },
            {
                label: 'Appenzell Innerhoden',
                value: 'AI',
            },
            {
                label: 'Basel-Landschaft',
                value: 'BL',
            },
            {
                label: 'Basel-Stadt',
                value: 'BS',
            },
            {
                label: 'Bern',
                value: 'BE',
            },
            {
                label: 'Fribourg',
                value: 'FR',
            },
            {
                label: 'Genève',
                value: 'GE',
            },
            {
                label: 'Glarus',
                value: 'GL',
            },
            {
                label: 'Graubünden',
                value: 'GR',
            },
            {
                label: 'Jura',
                value: 'JU',
            },
            {
                label: 'Luzern',
                value: 'LU',
            },
            {
                label: 'Neuchâtel',
                value: 'NE',
            },
            {
                label: 'Nidwalden',
                value: 'NW',
            },
            {
                label: 'Obwalden',
                value: 'OW',
            },
            {
                label: 'Sankt Gallen',
                value: 'SG',
            },
            {
                label: 'Schaffhausen',
                value: 'SH',
            },
            {
                label: 'Schwyz',
                value: 'SZ',
            },
            {
                label: 'Solothurn',
                value: 'SO',
            },
            {
                label: 'Thurgau',
                value: 'TG',
            },
            {
                label: 'Ticino',
                value: 'TI',
            },
            {
                label: 'Uri',
                value: 'UR',
            },
            {
                label: 'Valais',
                value: 'VS',
            },
            {
                label: 'Vaud',
                value: 'VD',
            },
            {
                label: 'Zug',
                value: 'ZG',
            },
            {
                label: 'Zürich',
                value: 'ZH',
            },
        ],
    },
    {
        label: 'Syrian Arab Republic',
        phoneCode: '+963',
        value: 'SY',
        countryFlag: '🇸🇾',
        regions: [
            {
                label: 'Al Hasakah',
                value: 'HA',
            },
            {
                label: 'Al Ladhiqiyah',
                value: 'LA',
            },
            {
                label: 'Al Qunaytirah',
                value: 'QU',
            },
            {
                label: 'Ar Raqqah',
                value: 'RA',
            },
            {
                label: 'As Suwayda\'',
                value: 'SU',
            },
            {
                label: 'Dar\'a',
                value: 'DR',
            },
            {
                label: 'Dayr az Zawr',
                value: 'DY',
            },
            {
                label: 'Dimashq',
                value: 'DI',
            },
            {
                label: 'Halab',
                value: 'HL',
            },
            {
                label: 'Hamah',
                value: 'HM',
            },
            {
                label: 'Hims',
                value: 'HI',
            },
            {
                label: 'Idlib',
                value: 'ID',
            },
            {
                label: 'Rif Dimashq',
                value: 'RD',
            },
            {
                label: 'Tartus',
                value: 'TA',
            },
        ],
    },
    {
        label: 'Taiwan',
        phoneCode: '+886',
        value: 'TW',
        countryFlag: '🇹🇼',
        regions: [
            {
                label: 'Chang-hua',
                value: 'CHA',
            },
            {
                label: 'Chia-i',
                value: 'CYQ',
            },
            {
                label: 'Hsin-chu',
                value: 'HSQ',
            },
            {
                label: 'Hua-lien',
                value: 'HUA',
            },
            {
                label: 'Kao-hsiung',
                value: 'KHH',
            },
            {
                label: 'Keelung',
                value: 'KEE',
            },
            {
                label: 'Kinmen',
                value: 'KIN',
            },
            {
                label: 'Lienchiang',
                value: 'LIE',
            },
            {
                label: 'Miao-li',
                value: 'MIA',
            },
            {
                label: 'Nan-t\'ou',
                value: 'NAN',
            },
            {
                label: 'P\'eng-hu',
                value: 'PEN',
            },
            {
                label: 'New Taipei',
                value: 'NWT',
            },
            {
                label: 'P\'ing-chung',
                value: 'PIF',
            },
            {
                label: 'T\'ai-chung',
                value: 'TXG',
            },
            {
                label: 'T\'ai-nan',
                value: 'TNN',
            },
            {
                label: 'T\'ai-pei',
                value: 'TPE',
            },
            {
                label: 'T\'ai-tung',
                value: 'TTT',
            },
            {
                label: 'T\'ao-yuan',
                value: 'TAO',
            },
            {
                label: 'Yi-lan',
                value: 'ILA',
            },
            {
                label: 'Yun-lin',
                value: 'YUN',
            },
        ],
    },
    {
        label: 'Tajikistan',
        phoneCode: '+992',
        value: 'TJ',
        countryFlag: '🇹🇯',
        regions: [
            {
                label: 'Dushanbe',
                value: 'DU',
            },
            {
                label: 'Kŭhistoni Badakhshon',
                value: 'GB',
            },
            {
                label: 'Khatlon',
                value: 'KT',
            },
            {
                label: 'Sughd',
                value: 'SU',
            },
        ],
    },
    {
        label: 'Tanzania, United Republic of',
        phoneCode: '+255',
        value: 'TZ',
        countryFlag: '🇹🇿',
        regions: [
            {
                label: 'Arusha',
                value: '01',
            },
            {
                label: 'Coast',
                value: '19',
            },
            {
                label: 'Dar es Salaam',
                value: '02',
            },
            {
                label: 'Dodoma',
                value: '03',
            },
            {
                label: 'Iringa',
                value: '04',
            },
            {
                label: 'Kagera',
                value: '05',
            },
            {
                label: 'Kigoma',
                value: '08',
            },
            {
                label: 'Kilimanjaro',
                value: '09',
            },
            {
                label: 'Lindi',
                value: '12',
            },
            {
                label: 'Manyara',
                value: '26',
            },
            {
                label: 'Mara',
                value: '13',
            },
            {
                label: 'Mbeya',
                value: '14',
            },
            {
                label: 'Morogoro',
                value: '16',
            },
            {
                label: 'Mtwara',
                value: '17',
            },
            {
                label: 'Mwanza',
                value: '18',
            },
            {
                label: 'Pemba North',
                value: '06',
            },
            {
                label: 'Pemba South',
                value: '10',
            },
            {
                label: 'Rukwa',
                value: '20',
            },
            {
                label: 'Ruvuma',
                value: '21',
            },
            {
                label: 'Shinyanga',
                value: '22',
            },
            {
                label: 'Singida',
                value: '23',
            },
            {
                label: 'Tabora',
                value: '24',
            },
            {
                label: 'Tanga',
                value: '25',
            },
            {
                label: 'Zanzibar North',
                value: '07',
            },
            {
                label: 'Zanzibar Central/South',
                value: '11',
            },
            {
                label: 'Zanzibar Urban/West',
                value: '15',
            },
        ],
    },
    {
        label: 'Thailand',
        phoneCode: '+66',
        value: 'TH',
        countryFlag: '🇹🇭',
        regions: [
            {
                label: 'Amnat Charoen',
                value: '37',
            },
            {
                label: 'Ang Thong',
                value: '15',
            },
            {
                label: 'Bueng Kan',
                value: '38',
            },
            {
                label: 'Buri Ram',
                value: '31',
            },
            {
                label: 'Chachoengsao',
                value: '24',
            },
            {
                label: 'Chai Nat',
                value: '18',
            },
            {
                label: 'Chaiyaphum',
                value: '36',
            },
            {
                label: 'Chanthaburi',
                value: '22',
            },
            {
                label: 'Chiang Mai',
                value: '50',
            },
            {
                label: 'Chiang Rai',
                value: '57',
            },
            {
                label: 'Chon Buri',
                value: '20',
            },
            {
                label: 'Chumphon',
                value: '86',
            },
            {
                label: 'Kalasin',
                value: '46',
            },
            {
                label: 'Kamphaeng Phet',
                value: '62',
            },
            {
                label: 'Kanchanaburi',
                value: '71',
            },
            {
                label: 'Khon Kaen',
                value: '40',
            },
            {
                label: 'Krabi',
                value: '81',
            },
            {
                label: 'Krung Thep Mahanakhon (Bangkok)',
                value: '10',
            },
            {
                label: 'Lampang',
                value: '52',
            },
            {
                label: 'Lamphun',
                value: '51',
            },
            {
                label: 'Loei',
                value: '42',
            },
            {
                label: 'Lop Buri',
                value: '16',
            },
            {
                label: 'Mae Hong Son',
                value: '58',
            },
            {
                label: 'Maha Sarakham',
                value: '44',
            },
            {
                label: 'Mukdahan',
                value: '49',
            },
            {
                label: 'Nakhon Nayok',
                value: '26',
            },
            {
                label: 'Nakhon Phathom',
                value: '73',
            },
            {
                label: 'Nakhon Phanom',
                value: '48',
            },
            {
                label: 'Nakhon Ratchasima',
                value: '30',
            },
            {
                label: 'Nakhon Sawan',
                value: '60',
            },
            {
                label: 'Nakhon Si Thammarat',
                value: '80',
            },
            {
                label: 'Nan',
                value: '55',
            },
            {
                label: 'Narathiwat',
                value: '96',
            },
            {
                label: 'Nong Bua Lam Phu',
                value: '39',
            },
            {
                label: 'Nong Khai',
                value: '43',
            },
            {
                label: 'Nonthaburi',
                value: '12',
            },
            {
                label: 'Pathum Thani',
                value: '13',
            },
            {
                label: 'Pattani',
                value: '94',
            },
            {
                label: 'Phangnga',
                value: '82',
            },
            {
                label: 'Phatthalung',
                value: '93',
            },
            {
                label: 'Phayao',
                value: '56',
            },
            {
                label: 'Phetchabun',
                value: '76',
            },
            {
                label: 'Phetchaburi',
                value: '76',
            },
            {
                label: 'Phichit',
                value: '66',
            },
            {
                label: 'Phitsanulok',
                value: '65',
            },
            {
                label: 'Phra Nakhon Si Ayutthaya',
                value: '14',
            },
            {
                label: 'Phrae',
                value: '54',
            },
            {
                label: 'Phuket',
                value: '83',
            },
            {
                label: 'Prachin Buri',
                value: '25',
            },
            {
                label: 'Prachuap Khiri Khan',
                value: '77',
            },
            {
                label: 'Ranong',
                value: '85',
            },
            {
                label: 'Ratchaburi',
                value: '70',
            },
            {
                label: 'Rayong',
                value: '21',
            },
            {
                label: 'Roi Et',
                value: '45',
            },
            {
                label: 'Sa Kaeo',
                value: '27',
            },
            {
                label: 'Sakon Nakhon',
                value: '47',
            },
            {
                label: 'Samut Prakan',
                value: '11',
            },
            {
                label: 'Samut Sakhon',
                value: '74',
            },
            {
                label: 'Samut Songkhram',
                value: '75',
            },
            {
                label: 'Saraburi',
                value: '19',
            },
            {
                label: 'Satun',
                value: '91',
            },
            {
                label: 'Sing Buri',
                value: '17',
            },
            {
                label: 'Si Sa ket',
                value: '33',
            },
            {
                label: 'Songkhla',
                value: '90',
            },
            {
                label: 'Sukhothai',
                value: '64',
            },
            {
                label: 'Suphan Buri',
                value: '72',
            },
            {
                label: 'Surat Thani',
                value: '84',
            },
            {
                label: 'Surin',
                value: '32',
            },
            {
                label: 'Tak',
                value: '63',
            },
            {
                label: 'Trang',
                value: '92',
            },
            {
                label: 'Trat',
                value: '23',
            },
            {
                label: 'Ubon Ratchathani',
                value: '34',
            },
            {
                label: 'Udon Thani',
                value: '41',
            },
            {
                label: 'Uthai Thani',
                value: '61',
            },
            {
                label: 'Uttaradit',
                value: '53',
            },
            {
                label: 'Yala',
                value: '95',
            },
            {
                label: 'Yasothon',
                value: '35',
            },
        ],
    },
    {
        label: 'Timor-Leste',
        phoneCode: '+670',
        value: 'TL',
        countryFlag: '🇹🇱',
        regions: [
            {
                label: 'Aileu',
                value: 'AL',
            },
            {
                label: 'Ainaro',
                value: 'AN',
            },
            {
                label: 'Baucau',
                value: 'BA',
            },
            {
                label: 'Bobonaro',
                value: 'BO',
            },
            {
                label: 'Cova Lima',
                value: 'CO',
            },
            {
                label: 'Dili',
                value: 'DI',
            },
            {
                label: 'Ermera',
                value: 'ER',
            },
            {
                label: 'Lautem',
                value: 'LA',
            },
            {
                label: 'Liquica',
                value: 'LI',
            },
            {
                label: 'Manatuto',
                value: 'MT',
            },
            {
                label: 'Manufahi',
                value: 'MF',
            },
            {
                label: 'Oecussi',
                value: 'OE',
            },
            {
                label: 'Viqueque',
                value: 'VI',
            },
        ],
    },
    {
        label: 'Togo',
        phoneCode: '+228',
        value: 'TG',
        countryFlag: '🇹🇬',
        regions: [
            {
                label: 'Centre',
                value: 'C',
            },
            {
                label: 'Kara',
                value: 'K',
            },
            {
                label: 'Maritime',
                value: 'M',
            },
            {
                label: 'Plateaux',
                value: 'P',
            },
            {
                label: 'Savannes',
                value: 'S',
            },
        ],
    },
    {
        label: 'Tokelau',
        phoneCode: '+690',
        value: 'TK',
        countryFlag: '🇹🇰',
        regions: [
            {
                label: 'Atafu',
                value: null,
            },
            {
                label: 'Fakaofo',
                value: null,
            },
            {
                label: 'Nukunonu',
                value: null,
            },
        ],
    },
    {
        label: 'Tonga',
        phoneCode: '+676',
        value: 'TO',
        countryFlag: '🇹🇴',
        regions: [
            {
                label: '\'Eua',
                value: '01',
            },
            {
                label: 'Ha\'apai',
                value: '02',
            },
            {
                label: 'Niuas',
                value: '03',
            },
            {
                label: 'Tongatapu',
                value: '04',
            },
            {
                label: 'Vava\'u',
                value: '05',
            },
        ],
    },
    {
        label: 'Trinidad and Tobago',
        phoneCode: '+1868',
        value: 'TT',
        countryFlag: '🇹🇹',
        regions: [
            {
                label: 'Arima',
                value: 'ARI',
            },
            {
                label: 'Chaguanas',
                value: 'CHA',
            },
            {
                label: 'Couva-Tabaquite-Talparo',
                value: 'CTT',
            },
            {
                label: 'Diefo Martin',
                value: 'DMN',
            },
            {
                label: 'Mayaro-Rio Claro',
                value: 'MRC',
            },
            {
                label: 'Penal-Debe',
                value: 'PED',
            },
            {
                label: 'Point Fortin',
                value: 'PTF',
            },
            {
                label: 'Port-of-Spain',
                value: 'POS',
            },
            {
                label: 'Princes Town',
                value: 'PRT',
            },
            {
                label: 'San Fernando',
                value: 'SFO',
            },
            {
                label: 'San Juan-Laventille',
                value: 'SJL',
            },
            {
                label: 'Sangre Grande',
                value: 'SGE',
            },
            {
                label: 'Siparia',
                value: 'SIP',
            },
            {
                label: 'Tobago',
                value: 'TOB',
            },
            {
                label: 'Tunapuna-Piarco',
                value: 'TUP',
            },
        ],
    },
    {
        label: 'Tunisia',
        phoneCode: '+216',
        value: 'TN',
        countryFlag: '🇹🇳',
        regions: [
            {
                label: 'Ariana',
                value: '12',
            },
            {
                label: 'Beja',
                value: '31',
            },
            {
                label: 'Ben Arous',
                value: '13',
            },
            {
                label: 'Bizerte',
                value: '23',
            },
            {
                label: 'Gabes',
                value: '81',
            },
            {
                label: 'Gafsa',
                value: '71',
            },
            {
                label: 'Jendouba',
                value: '32',
            },
            {
                label: 'Kairouan',
                value: '41',
            },
            {
                label: 'Kasserine',
                value: '42',
            },
            {
                label: 'Kebili',
                value: '73',
            },
            {
                label: 'Kef',
                value: '33',
            },
            {
                label: 'Mahdia',
                value: '53',
            },
            {
                label: 'Medenine',
                value: '82',
            },
            {
                label: 'Monastir',
                value: '52',
            },
            {
                label: 'Nabeul',
                value: '21',
            },
            {
                label: 'Sfax',
                value: '61',
            },
            {
                label: 'Sidi Bouzid',
                value: '43',
            },
            {
                label: 'Siliana',
                value: '34',
            },
            {
                label: 'Sousse',
                value: '51',
            },
            {
                label: 'Tataouine',
                value: '83',
            },
            {
                label: 'Tozeur',
                value: '72',
            },
            {
                label: 'Tunis',
                value: '11',
            },
            {
                label: 'Zaghouan',
                value: '22',
            },
        ],
    },
    {
        label: 'Turkey',
        phoneCode: '+90',
        value: 'TR',
        countryFlag: '🇹🇷',
        regions: [
            {
                label: 'Adana',
                value: '01',
            },
            {
                label: 'Adiyaman',
                value: '02',
            },
            {
                label: 'Afyonkarahisar',
                value: '03',
            },
            {
                label: 'Agri',
                value: '04',
            },
            {
                label: 'Aksaray',
                value: '68',
            },
            {
                label: 'Amasya',
                value: '05',
            },
            {
                label: 'Ankara',
                value: '06',
            },
            {
                label: 'Antalya',
                value: '07',
            },
            {
                label: 'Ardahan',
                value: '75',
            },
            {
                label: 'Artvin',
                value: '08',
            },
            {
                label: 'Aydin',
                value: '09',
            },
            {
                label: 'Balikesir',
                value: '10',
            },
            {
                label: 'Bartin',
                value: '74',
            },
            {
                label: 'Batman',
                value: '72',
            },
            {
                label: 'Bayburt',
                value: '69',
            },
            {
                label: 'Bilecik',
                value: '11',
            },
            {
                label: 'Bingol',
                value: '12',
            },
            {
                label: 'Bitlis',
                value: '13',
            },
            {
                label: 'Bolu',
                value: '14',
            },
            {
                label: 'Burdur',
                value: '15',
            },
            {
                label: 'Bursa',
                value: '16',
            },
            {
                label: 'Canakkale',
                value: '17',
            },
            {
                label: 'Cankiri',
                value: '18',
            },
            {
                label: 'Corum',
                value: '19',
            },
            {
                label: 'Denizli',
                value: '20',
            },
            {
                label: 'Diyarbakir',
                value: '21',
            },
            {
                label: 'Duzce',
                value: '81',
            },
            {
                label: 'Edirne',
                value: '22',
            },
            {
                label: 'Elazig',
                value: '23',
            },
            {
                label: 'Erzincan',
                value: '24',
            },
            {
                label: 'Erzurum',
                value: '25',
            },
            {
                label: 'Eskisehir',
                value: '26',
            },
            {
                label: 'Gaziantep',
                value: '27',
            },
            {
                label: 'Giresun',
                value: '28',
            },
            {
                label: 'Gumushane',
                value: '29',
            },
            {
                label: 'Hakkari',
                value: '30',
            },
            {
                label: 'Hatay',
                value: '31',
            },
            {
                label: 'Igdir',
                value: '76',
            },
            {
                label: 'Isparta',
                value: '32',
            },
            {
                label: 'Istanbul',
                value: '34',
            },
            {
                label: 'Izmir',
                value: '35',
            },
            {
                label: 'Kahramanmaras',
                value: '46',
            },
            {
                label: 'Karabuk',
                value: '78',
            },
            {
                label: 'Karaman',
                value: '70',
            },
            {
                label: 'Kars',
                value: '36',
            },
            {
                label: 'Kastamonu',
                value: '37',
            },
            {
                label: 'Kayseri',
                value: '38',
            },
            {
                label: 'Kilis',
                value: '79',
            },
            {
                label: 'Kirikkale',
                value: '71',
            },
            {
                label: 'Kirklareli',
                value: '39',
            },
            {
                label: 'Kirsehir',
                value: '40',
            },
            {
                label: 'Kocaeli',
                value: '41',
            },
            {
                label: 'Konya',
                value: '42',
            },
            {
                label: 'Kutahya',
                value: '43',
            },
            {
                label: 'Malatya',
                value: '44',
            },
            {
                label: 'Manisa',
                value: '45',
            },
            {
                label: 'Mardin',
                value: '47',
            },
            {
                label: 'Mersin',
                value: '33',
            },
            {
                label: 'Mugla',
                value: '48',
            },
            {
                label: 'Mus',
                value: '49',
            },
            {
                label: 'Nevsehir',
                value: '50',
            },
            {
                label: 'Nigde',
                value: '51',
            },
            {
                label: 'Ordu',
                value: '52',
            },
            {
                label: 'Osmaniye',
                value: '80',
            },
            {
                label: 'Rize',
                value: '53',
            },
            {
                label: 'Sakarya',
                value: '54',
            },
            {
                label: 'Samsun',
                value: '55',
            },
            {
                label: 'Sanliurfa',
                value: '63',
            },
            {
                label: 'Siirt',
                value: '56',
            },
            {
                label: 'Sinop',
                value: '57',
            },
            {
                label: 'Sirnak',
                value: '73',
            },
            {
                label: 'Sivas',
                value: '58',
            },
            {
                label: 'Tekirdag',
                value: '59',
            },
            {
                label: 'Tokat',
                value: '60',
            },
            {
                label: 'Trabzon',
                value: '61',
            },
            {
                label: 'Tunceli',
                value: '62',
            },
            {
                label: 'Usak',
                value: '64',
            },
            {
                label: 'Van',
                value: '65',
            },
            {
                label: 'Yalova',
                value: '77',
            },
            {
                label: 'Yozgat',
                value: '66',
            },
            {
                label: 'Zonguldak',
                value: '67',
            },
        ],
    },
    {
        label: 'Turkmenistan',
        phoneCode: '+993',
        value: 'TM',
        countryFlag: '🇹🇲',
        regions: [
            {
                label: 'Ahal',
                value: 'A',
            },
            {
                label: 'Asgabat',
                value: 'S',
            },
            {
                label: 'Balkan',
                value: 'B',
            },
            {
                label: 'Dashoguz',
                value: 'D',
            },
            {
                label: 'Lebap',
                value: 'L',
            },
            {
                label: 'Mary',
                value: 'M',
            },
        ],
    },
    {
        label: 'Turks and Caicos Islands',
        phoneCode: '+1649',
        value: 'TC',
        countryFlag: '🇹🇨',
        regions: [
            {
                label: 'Turks and Caicos Islands',
                value: null,
            },
        ],
    },
    {
        label: 'Tuvalu',
        phoneCode: '+688',
        value: 'TV',
        countryFlag: '🇹🇻',
        regions: [
            {
                label: 'Funafuti',
                value: 'FUN',
            },
            {
                label: 'Nanumanga',
                value: 'NMG',
            },
            {
                label: 'Nanumea',
                value: 'NMA',
            },
            {
                label: 'Niutao',
                value: 'NIT',
            },
            {
                label: 'Nui',
                value: 'NUI',
            },
            {
                label: 'Nukufetau',
                value: 'NKF',
            },
            {
                label: 'Nukulaelae',
                value: 'NKL',
            },
            {
                label: 'Vaitupu',
                value: 'VAU',
            },
        ],
    },
    {
        label: 'Uganda',
        phoneCode: '+256',
        value: 'UG',
        countryFlag: '🇺🇬',
        regions: [
            {
                label: 'Abim',
                value: '317',
            },
            {
                label: 'Adjumani',
                value: '301',
            },
            {
                label: 'Amolatar',
                value: '314',
            },
            {
                label: 'Amuria',
                value: '216',
            },
            {
                label: 'Amuru',
                value: '319',
            },
            {
                label: 'Apac',
                value: '302',
            },
            {
                label: 'Arua',
                value: '303',
            },
            {
                label: 'Budaka',
                value: '217',
            },
            {
                label: 'Bududa',
                value: '223',
            },
            {
                label: 'Bugiri',
                value: '201',
            },
            {
                label: 'Bukedea',
                value: '224',
            },
            {
                label: 'Bukwa',
                value: '218',
            },
            {
                label: 'Buliisa',
                value: '419',
            },
            {
                label: 'Bundibugyo',
                value: '401',
            },
            {
                label: 'Bushenyi',
                value: '402',
            },
            {
                label: 'Busia',
                value: '202',
            },
            {
                label: 'Butaleja',
                value: '219',
            },
            {
                label: 'Dokolo',
                value: '318',
            },
            {
                label: 'Gulu',
                value: '304',
            },
            {
                label: 'Hoima',
                value: '403',
            },
            {
                label: 'Ibanda',
                value: '416',
            },
            {
                label: 'Iganga',
                value: '203',
            },
            {
                label: 'Isingiro',
                value: '417',
            },
            {
                label: 'Jinja',
                value: '204',
            },
            {
                label: 'Kaabong',
                value: '315',
            },
            {
                label: 'Kabale',
                value: '404',
            },
            {
                label: 'Kabarole',
                value: '405',
            },
            {
                label: 'Kaberamaido',
                value: '213',
            },
            {
                label: 'Kalangala',
                value: '101',
            },
            {
                label: 'Kaliro',
                value: '220',
            },
            {
                label: 'Kampala',
                value: '102',
            },
            {
                label: 'Kamuli',
                value: '205',
            },
            {
                label: 'Kamwenge',
                value: '413',
            },
            {
                label: 'Kanungu',
                value: '414',
            },
            {
                label: 'Kapchorwa',
                value: '206',
            },
            {
                label: 'Kasese',
                value: '406',
            },
            {
                label: 'Katakwi',
                value: '207',
            },
            {
                label: 'Kayunga',
                value: '112',
            },
            {
                label: 'Kibaale',
                value: '407',
            },
            {
                label: 'Kiboga',
                value: '103',
            },
            {
                label: 'Kiruhura',
                value: '418',
            },
            {
                label: 'Kisoro',
                value: '408',
            },
            {
                label: 'Kitgum',
                value: '305',
            },
            {
                label: 'Koboko',
                value: '316',
            },
            {
                label: 'Kotido',
                value: '306',
            },
            {
                label: 'Kumi',
                value: '208',
            },
            {
                label: 'Kyenjojo',
                value: '415',
            },
            {
                label: 'Lira',
                value: '307',
            },
            {
                label: 'Luwero',
                value: '104',
            },
            {
                label: 'Lyantonde',
                value: '116',
            },
            {
                label: 'Manafwa',
                value: '221',
            },
            {
                label: 'Maracha',
                value: '320',
            },
            {
                label: 'Masaka',
                value: '105',
            },
            {
                label: 'Masindi',
                value: '409',
            },
            {
                label: 'Mayuge',
                value: '214',
            },
            {
                label: 'Mbale',
                value: '209',
            },
            {
                label: 'Mbarara',
                value: '410',
            },
            {
                label: 'Mityana',
                value: '114',
            },
            {
                label: 'Moroto',
                value: '308',
            },
            {
                label: 'Moyo',
                value: '309',
            },
            {
                label: 'Mpigi',
                value: '106',
            },
            {
                label: 'Mubende',
                value: '107',
            },
            {
                label: 'Mukono',
                value: '108',
            },
            {
                label: 'Nakapiripirit',
                value: '311',
            },
            {
                label: 'Nakaseke',
                value: '115',
            },
            {
                label: 'Nakasongola',
                value: '109',
            },
            {
                label: 'Namutumba',
                value: '222',
            },
            {
                label: 'Nebbi',
                value: '310',
            },
            {
                label: 'Ntungamo',
                value: '411',
            },
            {
                label: 'Oyam',
                value: '321',
            },
            {
                label: 'Pader',
                value: '312',
            },
            {
                label: 'Pallisa',
                value: '210',
            },
            {
                label: 'Rakai',
                value: '110',
            },
            {
                label: 'Rukungiri',
                value: '412',
            },
            {
                label: 'Sembabule',
                value: '111',
            },
            {
                label: 'Sironko',
                value: '215',
            },
            {
                label: 'Soroti',
                value: '211',
            },
            {
                label: 'Tororo',
                value: '212',
            },
            {
                label: 'Wakiso',
                value: '113',
            },
            {
                label: 'Yumbe',
                value: '313',
            },
        ],
    },
    {
        label: 'Ukraine',
        phoneCode: '+380',
        value: 'UA',
        countryFlag: '🇺🇦',
        regions: [
            {
                label: 'Cherkasy',
                value: '71',
            },
            {
                label: 'Chernihiv',
                value: '74',
            },
            {
                label: 'Chernivtsi',
                value: '77',
            },
            {
                label: 'Dnipropetrovsk',
                value: '12',
            },
            {
                label: 'Donetsk',
                value: '14',
            },
            {
                label: 'Ivano-Frankivsk',
                value: '26',
            },
            {
                label: 'Kharkiv',
                value: '63',
            },
            {
                label: 'Kherson',
                value: '65',
            },
            {
                label: 'Khmelnytskyi',
                value: '68',
            },
            {
                label: 'Kiev',
                value: '32',
            },
            {
                label: 'Kirovohrad',
                value: '35',
            },
            {
                label: 'Luhansk',
                value: '09',
            },
            {
                label: 'Lviv',
                value: '46',
            },
            {
                label: 'Mykolaiv',
                value: '48',
            },
            {
                label: 'Odessa',
                value: '51',
            },
            {
                label: 'Poltava',
                value: '53',
            },
            {
                label: 'Rivne',
                value: '56',
            },
            {
                label: 'Sumy',
                value: '59',
            },
            {
                label: 'Ternopil',
                value: '61',
            },
            {
                label: 'Vinnytsia',
                value: '05',
            },
            {
                label: 'Volyn',
                value: '07',
            },
            {
                label: 'Zakarpattia',
                value: '21',
            },
            {
                label: 'Zaporizhia',
                value: '23',
            },
            {
                label: 'Zhytomyr',
                value: '18',
            },
            {
                label: 'Avtonomna Respublika Krym',
                value: '43',
            },
            {
                label: 'Kyïv',
                value: '30',
            },
            {
                label: 'Sevastopol',
                value: '40',
            },
        ],
    },
    {
        label: 'United Arab Emirates',
        phoneCode: '+971',
        value: 'AE',
        countryFlag: '🇦🇪',
        regions: [
            {
                label: 'Abu Dhabi',
                value: 'AZ',
            },
            {
                label: 'Ajman',
                value: 'AJ',
            },
            {
                label: 'Dubai',
                value: 'DU',
            },
            {
                label: 'Fujairah',
                value: 'FU',
            },
            {
                label: 'Ras al Khaimah',
                value: 'RK',
            },
            {
                label: 'Sharjah',
                value: 'SH',
            },
            {
                label: 'Umm Al Quwain',
                value: 'UQ',
            },
        ],
    },
    {
        label: 'United Kingdom',
        phoneCode: '+44',
        value: 'GB',
        countryFlag: '🇬🇧',
        regions: [
            {
                label: 'Aberdeen City',
                value: 'ABE',
            },
            {
                label: 'Aberdeenshire',
                value: 'ABD',
            },
            {
                label: 'Angus',
                value: 'ANS',
            },
            {
                label: 'Antrim and Newtownabbey',
                value: 'ANN',
            },
            {
                label: 'Ards and North Down',
                value: 'AND',
            },
            {
                label: 'Argyll and Bute',
                value: 'AGB',
            },
            {
                label: 'Armagh, Banbridge and Craigavon',
                value: 'ABC',
            },
            {
                label: 'Barking and Dagenham',
                value: 'BDG',
            },
            {
                label: 'Barnet',
                value: 'BNE',
            },
            {
                label: 'Barnsley',
                value: 'BNS',
            },
            {
                label: 'Bath and North East Somerset',
                value: 'BAS',
            },
            {
                label: 'Bedford',
                value: 'BDF',
            },
            {
                label: 'Belfast',
                value: 'BFS',
            },
            {
                label: 'Bexley',
                value: 'BEX',
            },
            {
                label: 'Birmingham',
                value: 'BIR',
            },
            {
                label: 'Blackburn with Darwen',
                value: 'BBD',
            },
            {
                label: 'Blackpool',
                value: 'BPL',
            },
            {
                label: 'Blaenau Gwent',
                value: 'BGW',
            },
            {
                label: 'Bolton',
                value: 'BOL',
            },
            {
                label: 'Bournemouth',
                value: 'BMH',
            },
            {
                label: 'Bracknell Forest',
                value: 'BRC',
            },
            {
                label: 'Bradford',
                value: 'BRD',
            },
            {
                label: 'Brent',
                value: 'BEN',
            },
            {
                label: 'Bridgend',
                value: 'BGE',
            },
            {
                label: 'Brighton and Hove',
                value: 'BNH',
            },
            {
                label: 'Bristol, City of',
                value: 'BST',
            },
            {
                label: 'Bromley',
                value: 'BRY',
            },
            {
                label: 'Buckinghamshire',
                value: 'BKM',
            },
            {
                label: 'Bury',
                value: 'BUR',
            },
            {
                label: 'Caerphilly',
                value: 'CAY',
            },
            {
                label: 'Calderdale',
                value: 'CLD',
            },
            {
                label: 'Cambridgeshire',
                value: 'CAM',
            },
            {
                label: 'Camden',
                value: 'CMD',
            },
            {
                label: 'Cardiff',
                value: 'CRF',
            },
            {
                label: 'Carmarthenshire',
                value: 'CMN',
            },
            {
                label: 'Causeway Coast and Glens',
                value: 'CCG',
            },
            {
                label: 'Central Bedfordshire',
                value: 'CBF',
            },
            {
                label: 'Ceredigion',
                value: 'CGN',
            },
            {
                label: 'Cheshire East',
                value: 'CHE',
            },
            {
                label: 'Cheshire West and Chester',
                value: 'CHW',
            },
            {
                label: 'Clackmannanshire',
                value: 'CLK',
            },
            {
                label: 'Conwy',
                value: 'CWY',
            },
            {
                label: 'Cornwall',
                value: 'CON',
            },
            {
                label: 'Coventry',
                value: 'COV',
            },
            {
                label: 'Croydon',
                value: 'CRY',
            },
            {
                label: 'Cumbria',
                value: 'CMA',
            },
            {
                label: 'Darlington',
                value: 'DAL',
            },
            {
                label: 'Denbighshire',
                value: 'DEN',
            },
            {
                label: 'Derby',
                value: 'DER',
            },
            {
                label: 'Derbyshire',
                value: 'DBY',
            },
            {
                label: 'Derry and Strabane',
                value: 'DRS',
            },
            {
                label: 'Devon',
                value: 'DEV',
            },
            {
                label: 'Doncaster',
                value: 'DNC',
            },
            {
                label: 'Dorset',
                value: 'DOR',
            },
            {
                label: 'Dudley',
                value: 'DUD',
            },
            {
                label: 'Dumfries and Galloway',
                value: 'DGY',
            },
            {
                label: 'Dundee City',
                value: 'DND',
            },
            {
                label: 'Durham County',
                value: 'DUR',
            },
            {
                label: 'Ealing',
                value: 'EAL',
            },
            {
                label: 'East Ayrshire',
                value: 'EAY',
            },
            {
                label: 'East Dunbartonshire',
                value: 'EDU',
            },
            {
                label: 'East Lothian',
                value: 'ELN',
            },
            {
                label: 'East Renfrewshire',
                value: 'ERW',
            },
            {
                label: 'East Riding of Yorkshire',
                value: 'ERY',
            },
            {
                label: 'East Sussex',
                value: 'ESX',
            },
            {
                label: 'Edinburgh, City of',
                value: 'EDH',
            },
            {
                label: 'Eilean Siar',
                value: 'ELS',
            },
            {
                label: 'Enfield',
                value: 'ENF',
            },
            {
                label: 'Essex',
                value: 'ESS',
            },
            {
                label: 'Falkirk',
                value: 'FAL',
            },
            {
                label: 'Fermanagh and Omagh',
                value: 'FMO',
            },
            {
                label: 'Fife',
                value: 'FIF',
            },
            {
                label: 'Flintshire',
                value: 'FLN',
            },
            {
                label: 'Gateshead',
                value: 'GAT',
            },
            {
                label: 'Glasgow City',
                value: 'GLG',
            },
            {
                label: 'Gloucestershire',
                value: 'GLS',
            },
            {
                label: 'Greenwich',
                value: 'GRE',
            },
            {
                label: 'Gwynedd',
                value: 'GWN',
            },
            {
                label: 'Hackney',
                value: 'HCK',
            },
            {
                label: 'Halton',
                value: 'HAL',
            },
            {
                label: 'Hammersmith and Fulham',
                value: 'HMF',
            },
            {
                label: 'Hampshire',
                value: 'HAM',
            },
            {
                label: 'Haringey',
                value: 'HRY',
            },
            {
                label: 'Harrow',
                value: 'HRW',
            },
            {
                label: 'Hartlepool',
                value: 'HPL',
            },
            {
                label: 'Havering',
                value: 'HAV',
            },
            {
                label: 'Herefordshire',
                value: 'HEF',
            },
            {
                label: 'Hertfordshire',
                value: 'HRT',
            },
            {
                label: 'Highland',
                value: 'HLD',
            },
            {
                label: 'Hillingdon',
                value: 'HIL',
            },
            {
                label: 'Hounslow',
                value: 'HNS',
            },
            {
                label: 'Inverclyde',
                value: 'IVC',
            },
            {
                label: 'Isle of Anglesey',
                value: 'AGY',
            },
            {
                label: 'Isle of Wight',
                value: 'IOW',
            },
            {
                label: 'Isles of Scilly',
                value: 'IOS',
            },
            {
                label: 'Islington',
                value: 'ISL',
            },
            {
                label: 'Kensington and Chelsea',
                value: 'KEC',
            },
            {
                label: 'Kent',
                value: 'KEN',
            },
            {
                label: 'Kingston upon Hull',
                value: 'KHL',
            },
            {
                label: 'Kingston upon Thames',
                value: 'KTT',
            },
            {
                label: 'Kirklees',
                value: 'KIR',
            },
            {
                label: 'Knowsley',
                value: 'KWL',
            },
            {
                label: 'Lambeth',
                value: 'LBH',
            },
            {
                label: 'Lancashire',
                value: 'LAN',
            },
            {
                label: 'Leeds',
                value: 'LDS',
            },
            {
                label: 'Leicester',
                value: 'LCE',
            },
            {
                label: 'Leicestershire',
                value: 'LEC',
            },
            {
                label: 'Lewisham',
                value: 'LEW',
            },
            {
                label: 'Lincolnshire',
                value: 'LIN',
            },
            {
                label: 'Lisburn and Castlereagh',
                value: 'LBC',
            },
            {
                label: 'Liverpool',
                value: 'LIV',
            },
            {
                label: 'London, City of',
                value: 'LND',
            },
            {
                label: 'Luton',
                value: 'LUT',
            },
            {
                label: 'Manchester',
                value: 'MAN',
            },
            {
                label: 'Medway',
                value: 'MDW',
            },
            {
                label: 'Merthyr Tydfil',
                value: 'MTY',
            },
            {
                label: 'Merton',
                value: 'MRT',
            },
            {
                label: 'Mid and East Antrim',
                value: 'MEA',
            },
            {
                label: 'Mid Ulster',
                value: 'MUL',
            },
            {
                label: 'Middlesbrough',
                value: 'MDB',
            },
            {
                label: 'Midlothian',
                value: 'MLN',
            },
            {
                label: 'Milton Keynes',
                value: 'MIK',
            },
            {
                label: 'Monmouthshire',
                value: 'MON',
            },
            {
                label: 'Moray',
                value: 'MRY',
            },
            {
                label: 'Neath Port Talbot',
                value: 'NTL',
            },
            {
                label: 'Newcastle upon Tyne',
                value: 'NET',
            },
            {
                label: 'Newham',
                value: 'NWM',
            },
            {
                label: 'Newport',
                value: 'NWP',
            },
            {
                label: 'Newry, Mourne and Down',
                value: 'NMD',
            },
            {
                label: 'Norfolk',
                value: 'NFK',
            },
            {
                label: 'North Ayrshire',
                value: 'NAY',
            },
            {
                label: 'North East Lincolnshire',
                value: 'NEL',
            },
            {
                label: 'North Lanarkshire',
                value: 'NLK',
            },
            {
                label: 'North Lincolnshire',
                value: 'NLN',
            },
            {
                label: 'North Somerset',
                value: 'NSM',
            },
            {
                label: 'North Tyneside',
                value: 'NTY',
            },
            {
                label: 'North Yorkshire',
                value: 'NYK',
            },
            {
                label: 'Northamptonshire',
                value: 'NTH',
            },
            {
                label: 'Northumberland',
                value: 'NBL',
            },
            {
                label: 'Nottingham',
                value: 'NGM',
            },
            {
                label: 'Nottinghamshire',
                value: 'NTT',
            },
            {
                label: 'Oldham',
                value: 'OLD',
            },
            {
                label: 'Orkney Islands',
                value: 'ORK',
            },
            {
                label: 'Oxfordshire',
                value: 'OXF',
            },
            {
                label: 'Pembrokeshire',
                value: 'PEM',
            },
            {
                label: 'Perth and Kinross',
                value: 'PKN',
            },
            {
                label: 'Peterborough',
                value: 'PTE',
            },
            {
                label: 'Plymouth',
                value: 'PLY',
            },
            {
                label: 'Poole',
                value: 'POL',
            },
            {
                label: 'Portsmouth',
                value: 'POR',
            },
            {
                label: 'Powys',
                value: 'POW',
            },
            {
                label: 'Reading',
                value: 'RDG',
            },
            {
                label: 'Redbridge',
                value: 'RDB',
            },
            {
                label: 'Redcar and Cleveland',
                value: 'RCC',
            },
            {
                label: 'Renfrewshire',
                value: 'RFW',
            },
            {
                label: 'Rhondda, Cynon, Taff',
                value: 'RCT',
            },
            {
                label: 'Richmond upon Thames',
                value: 'RIC',
            },
            {
                label: 'Rochdale',
                value: 'RCH',
            },
            {
                label: 'Rotherham',
                value: 'ROT',
            },
            {
                label: 'Rutland',
                value: 'RUT',
            },
            {
                label: 'St. Helens',
                value: 'SHN',
            },
            {
                label: 'Salford',
                value: 'SLF',
            },
            {
                label: 'Sandwell',
                value: 'SAW',
            },
            {
                label: 'Scottish Borders, The',
                value: 'SCB',
            },
            {
                label: 'Sefton',
                value: 'SFT',
            },
            {
                label: 'Sheffield',
                value: 'SHF',
            },
            {
                label: 'Shetland Islands',
                value: 'ZET',
            },
            {
                label: 'Shropshire',
                value: 'SHR',
            },
            {
                label: 'Slough',
                value: 'SLG',
            },
            {
                label: 'Solihull',
                value: 'SOL',
            },
            {
                label: 'Somerset',
                value: 'SOM',
            },
            {
                label: 'South Ayrshire',
                value: 'SAY',
            },
            {
                label: 'South Gloucestershire',
                value: 'SGC',
            },
            {
                label: 'South Lanarkshire',
                value: 'SLK',
            },
            {
                label: 'South Tyneside',
                value: 'STY',
            },
            {
                label: 'Southampton',
                value: 'STH',
            },
            {
                label: 'Southend-on-Sea',
                value: 'SOS',
            },
            {
                label: 'Southwark',
                value: 'SWK',
            },
            {
                label: 'Staffordshire',
                value: 'STS',
            },
            {
                label: 'Stirling',
                value: 'STG',
            },
            {
                label: 'Stockport',
                value: 'SKP',
            },
            {
                label: 'Stockton-on-Tees',
                value: 'STT',
            },
            {
                label: 'Stoke-on-Trent',
                value: 'STE',
            },
            {
                label: 'Suffolk',
                value: 'SFK',
            },
            {
                label: 'Sunderland',
                value: 'SND',
            },
            {
                label: 'Surrey',
                value: 'SRY',
            },
            {
                label: 'Sutton',
                value: 'STN',
            },
            {
                label: 'Swansea',
                value: 'SWA',
            },
            {
                label: 'Swindon',
                value: 'SWD',
            },
            {
                label: 'Tameside',
                value: 'TAM',
            },
            {
                label: 'Telford and Wrekin',
                value: 'TFW',
            },
            {
                label: 'Thurrock',
                value: 'THR',
            },
            {
                label: 'Torbay',
                value: 'TOB',
            },
            {
                label: 'Torfaen',
                value: 'TOF',
            },
            {
                label: 'Tower Hamlets',
                value: 'TWH',
            },
            {
                label: 'Trafford',
                value: 'TRF',
            },
            {
                label: 'Vale of Glamorgan, The',
                value: 'VGL',
            },
            {
                label: 'Wakefield',
                value: 'WKF',
            },
            {
                label: 'Walsall',
                value: 'WLL',
            },
            {
                label: 'Waltham Forest',
                value: 'WFT',
            },
            {
                label: 'Wandsworth',
                value: 'WND',
            },
            {
                label: 'Warrington',
                value: 'WRT',
            },
            {
                label: 'Warwickshire',
                value: 'WAR',
            },
            {
                label: 'West Berkshire',
                value: 'WBK',
            },
            {
                label: 'West Dunbartonshire',
                value: 'WDU',
            },
            {
                label: 'West Lothian',
                value: 'WLN',
            },
            {
                label: 'West Sussex',
                value: 'WSX',
            },
            {
                label: 'Westminster',
                value: 'WSM',
            },
            {
                label: 'Wigan',
                value: 'WGN',
            },
            {
                label: 'Wiltshire',
                value: 'WIL',
            },
            {
                label: 'Windsor and Maidenhead',
                value: 'WNM',
            },
            {
                label: 'Wirral',
                value: 'WRL',
            },
            {
                label: 'Wokingham',
                value: 'WOK',
            },
            {
                label: 'Wolverhampton',
                value: 'WLV',
            },
            {
                label: 'Worcestershire',
                value: 'WOR',
            },
            {
                label: 'Wrexham',
                value: 'WRX',
            },
            {
                label: 'York',
                value: 'YOR',
            },
        ],
    },
    {
        label: 'United States',
        phoneCode: '+1',
        value: 'US',
        countryFlag: '🇺🇸',
        regions: [
            {
                label: 'Alabama',
                value: 'AL',
            },
            {
                label: 'Alaska',
                value: 'AK',
            },
            {
                label: 'American Samoa',
                value: 'AS',
            },
            {
                label: 'Arizona',
                value: 'AZ',
            },
            {
                label: 'Arkansas',
                value: 'AR',
            },
            {
                label: 'California',
                value: 'CA',
            },
            {
                label: 'Colorado',
                value: 'CO',
            },
            {
                label: 'Connecticut',
                value: 'CT',
            },
            {
                label: 'Delaware',
                value: 'DE',
            },
            {
                label: 'District of Columbia',
                value: 'DC',
            },
            {
                label: 'Micronesia',
                value: 'FM',
            },
            {
                label: 'Florida',
                value: 'FL',
            },
            {
                label: 'Georgia',
                value: 'GA',
            },
            {
                label: 'Guam',
                value: 'GU',
            },
            {
                label: 'Hawaii',
                value: 'HI',
            },
            {
                label: 'Idaho',
                value: 'ID',
            },
            {
                label: 'Illinois',
                value: 'IL',
            },
            {
                label: 'Indiana',
                value: 'IN',
            },
            {
                label: 'Iowa',
                value: 'IA',
            },
            {
                label: 'Kansas',
                value: 'KS',
            },
            {
                label: 'Kentucky',
                value: 'KY',
            },
            {
                label: 'Louisiana',
                value: 'LA',
            },
            {
                label: 'Maine',
                value: 'ME',
            },
            {
                label: 'Marshall Islands',
                value: 'MH',
            },
            {
                label: 'Maryland',
                value: 'MD',
            },
            {
                label: 'Massachusetts',
                value: 'MA',
            },
            {
                label: 'Michigan',
                value: 'MI',
            },
            {
                label: 'Minnesota',
                value: 'MN',
            },
            {
                label: 'Mississippi',
                value: 'MS',
            },
            {
                label: 'Missouri',
                value: 'MO',
            },
            {
                label: 'Montana',
                value: 'MT',
            },
            {
                label: 'Nebraska',
                value: 'NE',
            },
            {
                label: 'Nevada',
                value: 'NV',
            },
            {
                label: 'New Hampshire',
                value: 'NH',
            },
            {
                label: 'New Jersey',
                value: 'NJ',
            },
            {
                label: 'New Mexico',
                value: 'NM',
            },
            {
                label: 'New York',
                value: 'NY',
            },
            {
                label: 'North Carolina',
                value: 'NC',
            },
            {
                label: 'North Dakota',
                value: 'ND',
            },
            {
                label: 'Northern Mariana Islands',
                value: 'MP',
            },
            {
                label: 'Ohio',
                value: 'OH',
            },
            {
                label: 'Oklahoma',
                value: 'OK',
            },
            {
                label: 'Oregon',
                value: 'OR',
            },
            {
                label: 'Palau',
                value: 'PW',
            },
            {
                label: 'Pennsylvania',
                value: 'PA',
            },
            {
                label: 'Puerto Rico',
                value: 'PR',
            },
            {
                label: 'Rhode Island',
                value: 'RI',
            },
            {
                label: 'South Carolina',
                value: 'SC',
            },
            {
                label: 'South Dakota',
                value: 'SD',
            },
            {
                label: 'Tennessee',
                value: 'TN',
            },
            {
                label: 'Texas',
                value: 'TX',
            },
            {
                label: 'Utah',
                value: 'UT',
            },
            {
                label: 'Vermont',
                value: 'VT',
            },
            {
                label: 'Virgin Islands',
                value: 'VI',
            },
            {
                label: 'Virginia',
                value: 'VA',
            },
            {
                label: 'Washington',
                value: 'WA',
            },
            {
                label: 'West Virginia',
                value: 'WV',
            },
            {
                label: 'Wisconsin',
                value: 'WI',
            },
            {
                label: 'Wyoming',
                value: 'WY',
            },
            {
                label: 'Armed Forces Americas',
                value: 'AA',
            },
            {
                label: 'Armed Forces Europe, Canada, Africa and Middle East',
                value: 'AE',
            },
            {
                label: 'Armed Forces Pacific',
                value: 'AP',
            },
        ],
    },
    {
        label: 'United States Minor Outlying Islands',
        phoneCode: '+1',
        value: 'UM',
        countryFlag: '🇺🇲',
        regions: [
            {
                label: 'Baker Island',
                value: '81',
            },
            {
                label: 'Howland Island',
                value: '84',
            },
            {
                label: 'Jarvis Island',
                value: '86',
            },
            {
                label: 'Johnston Atoll',
                value: '67',
            },
            {
                label: 'Kingman Reef',
                value: '89',
            },
            {
                label: 'Midway Islands',
                value: '71',
            },
            {
                label: 'Navassa Island',
                value: '76',
            },
            {
                label: 'Palmyra Atoll',
                value: '95',
            },
            {
                label: 'Wake Island',
                value: '79',
            },
            {
                label: 'Bajo Nuevo Bank',
                value: 'BN',
            },
            {
                label: 'Serranilla Bank',
                value: 'SB',
            },
        ],
    },
    {
        label: 'Uruguay',
        phoneCode: '+598',
        value: 'UY',
        countryFlag: '🇺🇾',
        regions: [
            {
                label: 'Artigas',
                value: 'AR',
            },
            {
                label: 'Canelones',
                value: 'CA',
            },
            {
                label: 'Cerro Largo',
                value: 'CL',
            },
            {
                label: 'Colonia',
                value: 'CO',
            },
            {
                label: 'Durazno',
                value: 'DU',
            },
            {
                label: 'Flores',
                value: 'FS',
            },
            {
                label: 'Florida',
                value: 'FD',
            },
            {
                label: 'Lavalleja',
                value: 'LA',
            },
            {
                label: 'Maldonado',
                value: 'MA',
            },
            {
                label: 'Montevideo',
                value: 'MO',
            },
            {
                label: 'Paysandú',
                value: 'PA',
            },
            {
                label: 'Río Negro',
                value: 'RN',
            },
            {
                label: 'Rivera',
                value: 'RV',
            },
            {
                label: 'Rocha',
                value: 'RO',
            },
            {
                label: 'Salto',
                value: 'SA',
            },
            {
                label: 'San José',
                value: 'SJ',
            },
            {
                label: 'Soriano',
                value: 'SO',
            },
            {
                label: 'Tacuarembó',
                value: 'TA',
            },
            {
                label: 'Treinta y Tres',
                value: 'TT',
            },
        ],
    },
    {
        label: 'Uzbekistan',
        phoneCode: '+998',
        value: 'UZ',
        countryFlag: '🇺🇿',
        regions: [
            {
                label: 'Toshkent shahri',
                value: 'TK',
            },
            {
                label: 'Andijon',
                value: 'AN',
            },
            {
                label: 'Buxoro',
                value: 'BU',
            },
            {
                label: 'Farg‘ona',
                value: 'FA',
            },
            {
                label: 'Jizzax',
                value: 'JI',
            },
            {
                label: 'Namangan',
                value: 'NG',
            },
            {
                label: 'Navoiy',
                value: 'NW',
            },
            {
                label: 'Qashqadaryo (Qarshi)',
                value: 'QA',
            },
            {
                label: 'Samarqand',
                value: 'SA',
            },
            {
                label: 'Sirdaryo (Guliston)',
                value: 'SI',
            },
            {
                label: 'Surxondaryo (Termiz)',
                value: 'SU',
            },
            {
                label: 'Toshkent wiloyati',
                value: 'TO',
            },
            {
                label: 'Xorazm (Urganch)',
                value: 'XO',
            },
            {
                label: 'Qoraqalpog‘iston Respublikasi (Nukus)',
                value: 'QR',
            },
        ],
    },
    {
        label: 'Vanuatu',
        phoneCode: '+678',
        value: 'VU',
        countryFlag: '🇻🇺',
        regions: [
            {
                label: 'Malampa',
                value: 'MAP',
            },
            {
                label: 'Pénama',
                value: 'PAM',
            },
            {
                label: 'Sanma',
                value: 'SAM',
            },
            {
                label: 'Shéfa',
                value: 'SEE',
            },
            {
                label: 'Taféa',
                value: 'TAE',
            },
            {
                label: 'Torba',
                value: 'TOB',
            },
        ],
    },
    {
        label: 'Venezuela, Bolivarian Republic of',
        phoneCode: '+58',
        value: 'VE',
        countryFlag: '🇻🇪',
        regions: [
            {
                label: 'Dependencias Federales',
                value: 'W',
            },
            {
                label: 'Distrito Federal',
                value: 'A',
            },
            {
                label: 'Amazonas',
                value: 'Z',
            },
            {
                label: 'Anzoátegui',
                value: 'B',
            },
            {
                label: 'Apure',
                value: 'C',
            },
            {
                label: 'Aragua',
                value: 'D',
            },
            {
                label: 'Barinas',
                value: 'E',
            },
            {
                label: 'Bolívar',
                value: 'F',
            },
            {
                label: 'Carabobo',
                value: 'G',
            },
            {
                label: 'Cojedes',
                value: 'H',
            },
            {
                label: 'Delta Amacuro',
                value: 'Y',
            },
            {
                label: 'Falcón',
                value: 'I',
            },
            {
                label: 'Guárico',
                value: 'J',
            },
            {
                label: 'Lara',
                value: 'K',
            },
            {
                label: 'Mérida',
                value: 'L',
            },
            {
                label: 'Miranda',
                value: 'M',
            },
            {
                label: 'Monagas',
                value: 'N',
            },
            {
                label: 'Nueva Esparta',
                value: 'O',
            },
            {
                label: 'Portuguesa',
                value: 'P',
            },
            {
                label: 'Sucre',
                value: 'R',
            },
            {
                label: 'Táchira',
                value: 'S',
            },
            {
                label: 'Trujillo',
                value: 'T',
            },
            {
                label: 'Vargas',
                value: 'X',
            },
            {
                label: 'Yaracuy',
                value: 'U',
            },
            {
                label: 'Zulia',
                value: 'V',
            },
        ],
    },
    {
        label: 'Vietnam',
        phoneCode: '+84',
        value: 'VN',
        countryFlag: '🇻🇳',
        regions: [
            {
                label: 'Đồng Nai',
                value: '39',
            },
            {
                label: 'Đồng Tháp',
                value: '45',
            },
            {
                label: 'Gia Lai',
                value: '30',
            },
            {
                label: 'Hà Giang',
                value: '03',
            },
            {
                label: 'Hà Nam',
                value: '63',
            },
            {
                label: 'Hà Tây',
                value: '15',
            },
            {
                label: 'Hà Tĩnh',
                value: '23',
            },
            {
                label: 'Hải Dương',
                value: '61',
            },
            {
                label: 'Hậu Giang',
                value: '73',
            },
            {
                label: 'Hòa Bình',
                value: '14',
            },
            {
                label: 'Hưng Yên',
                value: '66',
            },
            {
                label: 'Khánh Hòa',
                value: '34',
            },
            {
                label: 'Kiên Giang',
                value: '47',
            },
            {
                label: 'Kon Tum',
                value: '28',
            },
            {
                label: 'Lai Châu',
                value: '01',
            },
            {
                label: 'Lâm Đồng',
                value: '35',
            },
            {
                label: 'Lạng Sơn',
                value: '09',
            },
            {
                label: 'Lào Cai',
                value: '02',
            },
            {
                label: 'Long An',
                value: '41',
            },
            {
                label: 'Nam Định',
                value: '67',
            },
            {
                label: 'Nghệ An',
                value: '22',
            },
            {
                label: 'Ninh Bình',
                value: '18',
            },
            {
                label: 'Ninh Thuận',
                value: '36',
            },
            {
                label: 'Phú Thọ',
                value: '68',
            },
            {
                label: 'Phú Yên',
                value: '32',
            },
            {
                label: 'Quảng Bình',
                value: '24',
            },
            {
                label: 'Quảng Nam',
                value: '27',
            },
            {
                label: 'Quảng Ngãi',
                value: '29',
            },
            {
                label: 'Quảng Ninh',
                value: '13',
            },
            {
                label: 'Quảng Trị',
                value: '25',
            },
            {
                label: 'Sóc Trăng',
                value: '52',
            },
            {
                label: 'Sơn La',
                value: '05',
            },
            {
                label: 'Tây Ninh',
                value: '37',
            },
            {
                label: 'Thái Bình',
                value: '20',
            },
            {
                label: 'Thái Nguyên',
                value: '69',
            },
            {
                label: 'Thanh Hóa',
                value: '21',
            },
            {
                label: 'Thừa Thiên–Huế',
                value: '26',
            },
            {
                label: 'Tiền Giang',
                value: '46',
            },
            {
                label: 'Trà Vinh',
                value: '51',
            },
            {
                label: 'Tuyên Quang',
                value: '07',
            },
            {
                label: 'Vĩnh Long',
                value: '49',
            },
            {
                label: 'Vĩnh Phúc',
                value: '70',
            },
            {
                label: 'Yên Bái',
                value: '06',
            },
            {
                label: 'Cần Thơ',
                value: 'CT',
            },
            {
                label: 'Đà Nẵng',
                value: 'DN',
            },
            {
                label: 'Hà Nội',
                value: 'HN',
            },
            {
                label: 'Hải Phòng',
                value: 'HP',
            },
            {
                label: 'Hồ Chí Minh (Sài Gòn)',
                value: 'SG',
            },
        ],
    },
    {
        label: 'Virgin Islands, British',
        phoneCode: '+1284',
        value: 'VG',
        countryFlag: '🇻🇬',
        regions: [
            {
                label: 'Anegada',
                value: 'ANG',
            },
            {
                label: 'Jost Van Dyke',
                value: 'JVD',
            },
            {
                label: 'Tortola',
                value: 'TTA',
            },
            {
                label: 'Virgin Gorda',
                value: 'VGD',
            },
        ],
    },
    {
        label: 'Virgin Islands, U.S.',
        phoneCode: '+1340',
        value: 'VI',
        countryFlag: '🇻🇮',
        regions: [
            {
                label: 'St. Thomas',
                value: 'STH',
            },
            {
                label: 'St. John',
                value: 'SJO',
            },
            {
                label: 'St. Croix',
                value: 'SCR',
            },
        ],
    },
    {
        label: 'Wallis and Futuna',
        phoneCode: '+681',
        value: 'WF',
        countryFlag: '🇼🇫',
        regions: [
            {
                label: 'Alo',
                value: 'ALO',
            },
            {
                label: 'Sigave',
                value: 'SIG',
            },
            {
                label: 'Wallis',
                value: 'WAL',
            },
        ],
    },
    {
        label: 'Western Sahara',
        phoneCode: '+212',
        value: 'EH',
        countryFlag: '🇪🇭',
        regions: [
            {
                label: 'Es Smara',
                value: 'ESM',
            },
            {
                label: 'Boujdour',
                value: 'BOD',
            },
            {
                label: 'Laâyoune',
                value: 'LAA',
            },
            {
                label: 'Aousserd',
                value: 'AOU',
            },
            {
                label: 'Oued ed Dahab',
                value: 'OUD',
            },
        ],
    },
    {
        label: 'Yemen',
        phoneCode: '+967',
        value: 'YE',
        countryFlag: '🇾🇪',
        regions: [
            {
                label: 'Abyān',
                value: 'AB',
            },
            {
                label: '\'Adan',
                value: 'AD',
            },
            {
                label: 'Aḑ Ḑāli\'',
                value: 'DA',
            },
            {
                label: 'Al Bayḑā\'',
                value: 'BA',
            },
            {
                label: 'Al Ḩudaydah',
                value: 'HU',
            },
            {
                label: 'Al Jawf',
                value: 'JA',
            },
            {
                label: 'Al Mahrah',
                value: 'MR',
            },
            {
                label: 'Al Maḩwīt',
                value: 'MW',
            },
            {
                label: '\'Amrān',
                value: 'AM',
            },
            {
                label: 'Dhamār',
                value: 'DH',
            },
            {
                label: 'Ḩaḑramawt',
                value: 'HD',
            },
            {
                label: 'Ḩajjah',
                value: 'HJ',
            },
            {
                label: 'Ibb',
                value: 'IB',
            },
            {
                label: 'Laḩij',
                value: 'LA',
            },
            {
                label: 'Ma\'rib',
                value: 'MA',
            },
            {
                label: 'Raymah',
                value: 'RA',
            },
            {
                label: 'Şā‘dah',
                value: 'SD',
            },
            {
                label: 'Şan‘ā\'',
                value: 'SN',
            },
            {
                label: 'Shabwah',
                value: 'SH',
            },
            {
                label: 'Tā‘izz',
                value: 'TA',
            },
        ],
    },
    {
        label: 'Zambia',
        phoneCode: '+260',
        value: 'ZM',
        countryFlag: '🇿🇲',
        regions: [
            {
                label: 'Central',
                value: '02',
            },
            {
                label: 'Copperbelt',
                value: '08',
            },
            {
                label: 'Eastern',
                value: '03',
            },
            {
                label: 'Luapula',
                value: '04',
            },
            {
                label: 'Lusaka',
                value: '09',
            },
            {
                label: 'Northern',
                value: '05',
            },
            {
                label: 'North-Western',
                value: '06',
            },
            {
                label: 'Southern',
                value: '07',
            },
            {
                label: 'Western',
                value: '01',
            },
        ],
    },
    {
        label: 'Zimbabwe',
        phoneCode: '+263',
        value: 'ZW',
        countryFlag: '🇿🇼',
        regions: [
            {
                label: 'Bulawayo',
                value: 'BU',
            },
            {
                label: 'Harare',
                value: 'HA',
            },
            {
                label: 'Manicaland',
                value: 'MA',
            },
            {
                label: 'Mashonaland Central',
                value: 'MC',
            },
            {
                label: 'Mashonaland East',
                value: 'ME',
            },
            {
                label: 'Mashonaland West',
                value: 'MW',
            },
            {
                label: 'Masvingo',
                value: 'MV',
            },
            {
                label: 'Matabeleland North',
                value: 'MN',
            },
            {
                label: 'Matabeleland South',
                value: 'MS',
            },
            {
                label: 'Midlands',
                value: 'MI',
            },
        ],
    },
];
