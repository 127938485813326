import { NgClass } from '@angular/common';
import type { OnInit, Signal } from '@angular/core';
import { Component, computed, EventEmitter, HostListener, Input, Output } from '@angular/core';
import type { Observable } from 'rxjs';

import type { BadgeItemType, IconListType, Menu, MenuItemType, TooltipVisibility } from '@evc/web-components';
import {
  ActionService,
  BadgeButtonComponent,
  CloseOnClickOutsideDirective,
  DropdownMenuComponent,
  IconEnum,
  IsScrollableDirective,
  NavButtonComponent,
  SvgIconComponent,
  TooltipHoverDirective } from '@evc/web-components';

import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import type { Organization } from '../../core-client/organizations/organizations.type';
import type { OptionalProfile } from '../../core-client/user/user.service';
import { UserService } from '../../core-client/user/user.service';
import { SearchService } from '../../services/search/search.service';
import { StorageService } from '../../services/storage/storage.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- investigate CLOUD-816
@Component({
  selector: 'evc-leftbar',
  standalone: true,
  imports: [
    NgClass,
    SvgIconComponent,
    IsScrollableDirective,
    TooltipHoverDirective,
    NavButtonComponent,
    DropdownMenuComponent,
    CloseOnClickOutsideDirective,
    BadgeButtonComponent,
],
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss'],
})
export class LeftbarComponent implements OnInit {
  @Output() public readonly isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public leftbarConfig?: Menu[];
  @Input() public isOpen = true;
  @Input() public id = 'left-bar';

  public showOrganizationsMenu = false;
  public showTooltip: TooltipVisibility = 'auto';
  public profile: Signal<OptionalProfile>;

  constructor(
    private readonly _actionService: ActionService,
    private readonly _storageService: StorageService,
    private readonly _searchService: SearchService,
    private readonly _orgService: OrganizationsService,
    private readonly _userService: UserService,
  ) {
   this.profile = this._userService.profile;
  }

  @HostListener('transitionend', ['$event'])
  public toggleLeftBarFinished(event: TransitionEvent): void {
    if (event.propertyName === 'width') {
      this.setTooltipVisibility();
    }
  }

  public ngOnInit(): void {
    const storedIsOpen: string | null = this._storageService.getLocalValue(
      'evident.evc-topbar.leftbar-isOpen',
    );
    this.isOpen = storedIsOpen !== null ? storedIsOpen === 'true' : true;
    this.isOpenChange.emit(this.isOpen);
    this.setTooltipVisibility();
  }

  public setTooltipVisibility(): void {
    this.showTooltip = this.isOpen ? 'auto' : 'visible';
  }

  public getItemIcon(item: MenuItemType): IconListType {
    return item.icon || IconEnum.Folder;
  }

  public toggleLeftBar(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
    this._storageService.setLocalValue('evident.evc-topbar.leftbar-isOpen', String(this.isOpen));
  }

  public handleItemClick(itemClicked: MenuItemType): void {
    this._searchService.clearSearch();
    this._actionService.handleAction(itemClicked);
    this.leftbarConfig = this.leftbarConfig?.map(section => ({
      ...section,
      items: section.items?.map(item => ({
        ...item,
        selected: item.key === itemClicked.key,
      })),
    }));
  }

  public isTabDisabled(tabKey: string): boolean {
    const currentTab: MenuItemType | undefined = this.leftbarConfig?.map(section => section.items).flat().find(
      item => item?.key === tabKey,
    );

    return !!currentTab?.isDisabled;
  }

  public isItemSelected(item: MenuItemType): boolean {
    return item.selected ?? false;
  }

  public getStateIcon(): IconListType {
    return this.isOpen ? IconEnum.AngleLeft : IconEnum.AngleRight;
  }

  public getConfigLength(): number {
    return this.leftbarConfig?.length ?? 0;
  }

  public handleOrganizationBtnClick(): void {
    this.showOrganizationsMenu = !this.showOrganizationsMenu;
  }

  public getSelectedItem(item: MenuItemType[]): string | Observable<string> {
    return item.find(i => i.selected)?.text ?? item[0].text;
  }

  public getCurrentOrganization(organizations: Signal<Organization[]>): Signal<Organization | undefined> {
    return computed(() => organizations ? organizations().find(i => i.current) : this._orgService?.current());
  }

  public getOrganizationsMenuConfig(): Signal<BadgeItemType[]> {
    const badgeItems = this.leftbarConfig?.find(section => section.title === 'Organization')?.badgeItems;

    return badgeItems ? badgeItems as Signal<BadgeItemType[]> : this._orgService.entries as Signal<BadgeItemType[]>;
  }

  public handleHideOrgMenu(): void {
    this.showOrganizationsMenu = false;
  }

  public handleOrganizationClicked(clickedOrganization: BadgeItemType): void {
    if (this.leftbarConfig) {
      this.leftbarConfig.forEach(section => {
        if (section.title === 'Organization' && section.badgeItems) {
          section.badgeItems().forEach(organization => {
            organization.current = organization.id === clickedOrganization.id;
            this.handleHideOrgMenu();
          });
        }
      });
      this._orgService.setCurrent(clickedOrganization.id, true);
    }
  }
}
