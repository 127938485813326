import { NgClass } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import type { IconType, StatusIndicatorType } from '@evc/web-components';

import { CellComponent } from './cell/cell.component';

export type TableCell = {
  value: string;
  disabled?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  statusInfo?: StatusIndicatorType;
  style?: 'bold' | 'italic' | 'normal';
  click?: () => void;
}

export type TableRow = {
  [key: string]: TableCell;
}

export type TableRowArray = TableRow[];

@Component({
  standalone: true,
  selector: 'evc-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  imports: [NgClass, MatTableModule, MatSortModule, CellComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  @Output() readonly rowClicked: EventEmitter<TableRow> = new EventEmitter<TableRow>();

  @Input() displayedColumns: string[] = [];
  @Input() columnMapping: { [key: string ]: string } = {};
  @Input() data!: TableRowArray;
  @Input() clickableRows = false;
  @Input() sortingDataAccessor!: (item: TableRow, property: string) => string;

  dataSource = new MatTableDataSource(this.data);

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  onRowClick(row: TableRow): void {
    this.rowClicked.emit(row);
  }

  onCellClick(cell: TableCell): void {
    if (cell.click) {
      cell.click();
    }
  }
}
