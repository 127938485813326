export enum ENVIRONMENT {
  testing = 'testing',
  local = 'local',
  development = 'development',
  staging = 'staging',
  production = 'production'
}
export type Env = `${ENVIRONMENT}`

// locale = en-CA but international so keep `en`
export type AvailableLocales = 'en' | 'fr';
export type AvailableLanguages = 'en' | 'fr';
export type I18nEnv = {
  LANG?: AvailableLanguages
  LOCALES?: AvailableLocales[]
  LOCALES_STORAGE_KEY?: string
}

export type AuthEnv = {
  AUTH_CLIENT_ID?: string
  AUTH_FORCE_ORGANIZATION?: false | boolean
  AUTH_FAIL_ROUTE?: 'auth-fail' | string
  // enable logs in auth service
  DEBUG_AUTH_FLOW?: false | boolean
  // to disable auth only in one env - do not forget to remove route guard too
  DEBUG_DISABLE_AUTH?: false | boolean
}

export type ApiEnv = {
  // must end with /
  API_URI?: string
  // merge user profil with a mock
  DEBUG_MOCK_USER?: false | boolean
}

export type AppIdentityEnv = {
  APP_NAME?: string
  APP_TITLE?: string
  APP_LOGO?: string
}

type PlatformEnvGreenfield = {
  GREENFIELD?: boolean
  // enable logs in platform loading flow
  DEBUG_LOADING_FLOW?: false | boolean
  // by default menu apps only show admin when user is admin
  DEBUG_ALWAYS_SHOW_ADMIN_APP?: false | boolean
} & ApiEnv & AuthEnv

export type PlatformEnv = {
  ENV: Env
} & AppIdentityEnv
  & I18nEnv
  & PlatformEnvGreenfield
