/** App config
 * - define idp endpoints, tenants name etc for each environment
 * - then compute MSAL config from it
 *
 * @example app.config.ts
 * ```ts
 * import { environment } from '@env';
 * import { provideEnv, getAuthProviders } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideEnv(environment),
 *     ...getAuthProviders(),
 *   ],
 * };
 * ```
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import type {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import type { IPublicClientApplication } from '@azure/msal-browser';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import type { Maybe, ValueOf } from '@evc/web-components';
import { ENV_VARIABLES, objectMap } from '@evc/web-components';
import { getUrisConfig } from 'platform/services/config/config.default';
import type { ApplicationURIs, AuthAuthorities, AuthConfig, AuthPolicies, AuthRedirects } from 'platform/services/config/config.type';

import type { PlatformEnv } from '../../providers/env.type';

/* way to inject config from a callback to pass .env config : see getAuthProviders()*/
export const AUTH_CONFIG = new InjectionToken<string>('AUTH_CONFIG');

/** generate needed providers for MSAL - to be imported in app.config.ts */
export function getAuthProviders() { // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
  return [
    {
      provide: AUTH_CONFIG,
      useFactory: getAuthConfig,
      deps: [ENV_VARIABLES],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor, // you can set your own @see exemples/_auth.intercepror.ts,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}

/** compute clean config for auth based on some .env config
 * this one is special as it is use by authProviders
 * important to note that environment will not be parsed and defaulted before this
 */
export function getAuthConfig(environment:PlatformEnv):Maybe<AuthConfig> {
  const { GREENFIELD, ENV, AUTH_CLIENT_ID, AUTH_FORCE_ORGANIZATION } = environment ?? {};
  if (!GREENFIELD) return undefined;

  const env = ENV;
  const clientId = AUTH_CLIENT_ID;
  const forceOrganization = AUTH_FORCE_ORGANIZATION || false;
  const tenant = (() => ({
    local: 'evcpltidpdev',
    development: 'evcpltidpdev',
    testing: 'evcpltidpdev',
    staging: 'evcpltidpstg2',
    production: 'evidentconnect',
  }[env]))();

  if (!clientId || !tenant) return undefined;

  const policies:AuthPolicies = {
    signUpSignIn: 'b2c_1a_signup_signin',
    resetPassword: 'b2c_1a_passwordreset',
    editProfile: 'b2c_1a_profileedit',
  };

  const uris:ApplicationURIs = getUrisConfig(env);

  const redirects:AuthRedirects = {
    success: uris.root!,
    fail: uris.logout ?? uris.public!,
    organization: uris.organization!,
  };

  const AUTHORITY_DOMAIN = `${tenant}.b2clogin.com`;
  const AUTHORITY_URI = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/`/* + policyName */;

  return {
    clientId,
    tenant,
    policies,
    redirects,
    forceOrganization,
    scopes: [clientId],
    b2cPolicies: {
      names: policies,
      authorities: objectMap(policies, (policy: ValueOf<AuthAuthorities>) => `${AUTHORITY_URI}${policy}`),
      authorityDomain: AUTHORITY_DOMAIN,
    },
  };
}

function MSALInstanceFactory(environment:PlatformEnv, config:AuthConfig): IPublicClientApplication {
  const { ENV, GREENFIELD } = environment ?? {};
  if (!GREENFIELD) return {} as IPublicClientApplication;

  const { clientId, redirects, b2cPolicies } = config ?? {};

  const logLevel = ENV === 'production'
    ? LogLevel.Error
    : (ENV === 'staging' ? LogLevel.Warning : LogLevel.Verbose);

  return new PublicClientApplication({
    auth: {
      authority: b2cPolicies.authorities.signUpSignIn as string,
      knownAuthorities: [b2cPolicies.authorityDomain],
      clientId,
      redirectUri: redirects.success,
      postLogoutRedirectUri: redirects.logout,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel,
        piiLoggingEnabled: false,
        // // KEEP IN NEED - you may implement your logger logic here
        // loggerCallback:(_logLevel: LogLevel, message: string)=>{
        //   if (env === 'production') return;
        //   console.log('[msal]', message); // eslint-disable-line no-console
        // }
      },
    },
  });
}

function MSALInterceptorConfigFactory(environment:PlatformEnv, config:AuthConfig): MsalInterceptorConfiguration {
  const { API_URI, GREENFIELD } = environment ?? {};
  if (!GREENFIELD) return {} as MsalInterceptorConfiguration;

  const { scopes } = config ?? {};
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(API_URI!, scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

function MSALGuardConfigFactory(environment:PlatformEnv, config:AuthConfig): MsalGuardConfiguration {
  const { GREENFIELD } = environment ?? {};
  if (!GREENFIELD) return {} as MsalGuardConfiguration;

  const { scopes, b2cPolicies, redirects } = config ?? {};

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      authority: b2cPolicies.authorities.signUpSignIn as string,
      scopes: [...scopes],
    },
    loginFailedRoute: redirects.fail,
  };
}
export default getAuthProviders;
