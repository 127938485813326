import { MOCK_API_ORG_PAYLOAD } from '../../core-client/organizations/organizations.mock';
import type { UserConfig, UserProfile } from '../../core-client/user/user.type';
import type { ApplicationType } from '../../types/user-menu.type';

export const MOCK_APPLICATIONS:ApplicationType[] = [
  {
    code: 'drive',
    hasWebApplication: true,
    launchUrl: 'https://drive.olympus-ims-dev.com/drive',
    launchIconUrl: 'https://drive.olympus-ims-dev.com/drive',
    name: 'Drive',
    supportedFileMimeTypes: [],
    userHasActiveValidLicense: true,
    openWithUrl: 'https://drive.olympus-ims-dev.com/drive',
  },
  {
    code: 'device-manager',
    hasWebApplication: true,
    launchUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
    launchIconUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
    name: 'Device Manager',
    supportedFileMimeTypes: [],
    userHasActiveValidLicense: true,
    openWithUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
  },
];

export const MOCK_USER_CONFIG: UserConfig = {
  id: 'myUserId',
  displayName: 'Display Name',
  givenName: 'firstname',
  surname: 'lastname',
  preferredLanguage: 'en',
  role: 'admin',
  email: 'firstname.lastname@evidentscientific.com',
  color: 'red', // bg color > compute text light/dark
  // avatar?: string; // URI stay within user
  organizations: MOCK_API_ORG_PAYLOAD,
  applications: MOCK_APPLICATIONS,
  phone: '1234567890',
  address: undefined,
};

export const MOCK_USER_PROFILE: UserProfile = {
  id: 'myUserId',
  displayName: 'Display Name',
  role: 'admin',
  givenName: 'firstname',
  surname: 'lastname',
  email: 'firstname.lastname@evidentscientific.com',
  preferredLanguage: 'en',
  avatar: {
    type: 'initials',
    color: 'red',
    light: true,
    initials: 'FL',
  },
  phone: '1234567890',
  address: undefined,
};
