/** ReactiveFormService
 *
 * > documentation : apps/shared/doc/reactive-form.md
 *
 * - do not forget to bind the formGroup with the service
 * - listen submit from service - not from dom
 */
import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FormBuilder, FormControl, FormGroup, FormGroupDirective, NonNullableFormBuilder, ValidatorFn } from '@angular/forms';
import type { Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, Observable, retry, skip, Subject, takeUntil } from 'rxjs';

import type { Maybe } from '@evc/web-components';

import type { EvcValidator } from './types/validators.type';
import { EvcValidatorsService } from './validators/validator.service';

@Injectable()
export class EvcFormService<T extends Record<string, unknown>> {
  /** true once this form is submited
   * usefull to hide error messages until submited
   */
  submited = signal(false);
  get formGroup(): FormGroup {
    return this.#formGroupRef;
  }
  /**
   * IMPORTANT to pipe with retry()
   * or simply use this.onSubmit()
   *
   * this.#formService.onSubmit$
   *   .pipe( catchError((error, caught) => {
   *     return caught.pipe(retry()); // important or stop on error
   *   }) ).subscribe((data) => { });
   **/
  get onSubmit$(): Observable<T> {
    return this._onSubmit$.asObservable()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        map(({ next, error }) => {
          if (error) throw error;
          else return next!;
        }),
      );
  }
  onSubmit(onSuccess:(data:T)=>void, onError?:(error:Error)=>void): Subscription {
    return this.onSubmit$.pipe(
      catchError((error, caught) => {
        onError?.(error);

        return caught.pipe(retry());
      }),
    ).subscribe(onSuccess);
  }
  get onUpdate$(): Observable<T> {
    return this._onUpdate$.asObservable()
      .pipe(takeUntilDestroyed(this.#destroyRef));
  }
  onUpdate(callback:(data:T)=>void): Subscription {
    return this.onUpdate$.subscribe(callback);
  }

  #formGroupRef!: FormGroup;
  #validatorService = inject(EvcValidatorsService);

  private _onSubmit$ = new Subject<{next?:T, error?:Error}>();
  #ngSubmitSubscription?: Subscription;
  private _onUpdate$ = new Subject<T>();
  #ngUpdateSubscription?: Subscription;

  // because this service is tight to the form component - like to takeUntilDestroyed form vs service
  #serviceDestroyRef = inject(DestroyRef);
  #formDestroyRef:Maybe<DestroyRef> = undefined;
  get #destroyRef():DestroyRef {
    return this.#formDestroyRef ?? this.#serviceDestroyRef;
  }

  /** important - you MUST bind some infos with this service
   * will link form directive to optimized service observables (onSubmit$, onUpdate$)
   */
  bind(
    formGroup: FormGroup,
    formDirective: FormGroupDirective,
    destroyRef?: DestroyRef,
  ): void {
    this.#formGroupRef = formGroup;

    if (destroyRef) this.#formDestroyRef = destroyRef;

    const ngSubmit$ = (() => {
      if (destroyRef === undefined) return formDirective.ngSubmit;

      if (destroyRef instanceof Observable) {
        return formDirective.ngSubmit
          .pipe(takeUntil(destroyRef));
      }

      return formDirective.ngSubmit
        .pipe(takeUntilDestroyed(destroyRef));
    })();

    this.#ngSubmitSubscription = ngSubmit$.pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(this.#onSubmit.bind(this));

    this.#ngUpdateSubscription = formGroup.valueChanges.pipe(
      skip(1),
      distinctUntilChanged(),
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe((value:T) => {
      this.#onUpdate(value);
    });
  }

  controlFactory(
    formBuilder:FormBuilder|NonNullableFormBuilder,
    model:T,
  ):(
    key:keyof typeof model,
    validators?:(EvcValidator | ValidatorFn)[],
    required?:boolean,
  ) => FormControl {
    const validator = this.#validatorService;

    return function control(
      key:keyof typeof model,
      validators:(EvcValidator | ValidatorFn)[] = [],
      required=true,
    ) {
      const validatorsWithDefaults = [...validators];
      if (required) validatorsWithDefaults.push(validator.required());

      let value = model[key];
      if (typeof value === 'string') value = value ?? '';

      return formBuilder.control(value, validatorsWithDefaults);
    };
  }

  #onSubmit(): void {
    this.submited.set(true);
    if (!this.formGroup.valid) {
      return this._onSubmit$.next({ error: new Error(this.formGroup.status) } as unknown as T);
    }

    return this._onSubmit$.next({ next: this.formGroup.value } as unknown as T);
  }

  #onUpdate(value:T): void {
    this._onUpdate$.next(value);
  }
}
