import type { Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import type { BadgeItemType } from '@evc/web-components';
import {
  MenuItemComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

import { AuthService } from '../../core-client/auth/auth.service';
import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import { UserService } from '../../core-client/user/user.service';
import type { UserProfile } from '../../core-client/user/user.type';
import { PlatformConfigService } from '../../services/config/config.service';

@Component({
  selector: 'evc-user-menu',
  standalone: true,
  imports: [
    MenuItemComponent,
    SvgIconComponent,
    NavButtonComponent,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @Output() public readonly logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly login: EventEmitter<void> = new EventEmitter<void>();

  @Output() public readonly logoutClick: EventEmitter<void> = new EventEmitter<void>();

  public userProfile: Signal<UserProfile|undefined>;
  public organizations: Signal<BadgeItemType[]>;

  constructor(
    private readonly _userService: UserService,
    private readonly _organizationsService: OrganizationsService,
    private readonly _configService: PlatformConfigService,
    private readonly _authService: AuthService,
  ) {
    this.userProfile = this._userService.profile;
    this.organizations = _organizationsService.entries as Signal<BadgeItemType[]>;
  }

  public onLogin(): void {
    this.login.emit();
  }

  public onLogout(): void {
    this.logout.emit();
  }

  public onOrgClicked(organization: BadgeItemType): void {
    this._organizationsService.setCurrent(organization.id, true);
  }
  public getTimeOfDay(): string {
    return new Date().getHours() < 12 ? 'Good morning' : 'Good afternoon';
  }

  public openUserProfile(): void {
    this._userService.openProfile();
  }

  public addOrganization(): void {
    this._authService.redirectToCreateOrganization(document.location.href);
  }
}
