import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal } from '@angular/core';

import { ActionService } from '../../services/action/action.service';
import { Menu } from '../../types/menu.type';
import type { MenuItemType } from '../../types/menu-item.type';
import type { BadgeItemType } from '../../types/organization.type';
import { CollapsiblePanelComponent } from '../collapsible/collapsible-panel.component';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
  selector: 'evc-dropdown-menu',
  standalone: true,
  imports: [
    CollapsiblePanelComponent,
    MenuItemComponent,
  ],
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent {
  @Output() public readonly itemClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly organizationClicked: EventEmitter<BadgeItemType> = new EventEmitter<BadgeItemType>();

  @Input() public showDividers = false;
  @Input() public showOnlyBadgeItems = false;
  @Input() public showOnlyMenuItems = false;
  @Input() public showTitle = true;
  @Input() public menuConfig?: Menu;
  @Input() public orgMenuConfig?: Signal<BadgeItemType[]>;

  constructor(private _actionService: ActionService) {}

  public trackByKey(index: number, item: MenuItemType): string {
    return `${item.key}-${index}`;
  }

  public isItemSelected(item: MenuItemType): boolean {
    return item.selected ?? false;
  }

  public handleCollapsibleItemClick(
    item: MenuItemType,
    collapsibleItemClicked: MenuItemType,
  ): void {
    this._actionService.handleAction(collapsibleItemClicked);
    item.collapsible?.forEach(collapsibleItem => {
      collapsibleItem.selected = collapsibleItem.key === collapsibleItemClicked.key;
    });
  }

  public handleOrganizationClick(item: BadgeItemType): void {
    this.organizationClicked.emit(item);
  }

  public closeMenu(): void {
    this.itemClicked.emit();
  }
}
