import type { Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';

import type { BadgeItemType } from '@evc/web-components';
import {
  MenuItemComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';

import { AuthService } from '../../core-client/auth/auth.service';
import { OrganizationsService } from '../../core-client/organizations/organizations.service';
import { UserService } from '../../core-client/user/user.service';
import type { UserProfile } from '../../core-client/user/user.type';

@Component({
  selector: 'evc-user-menu',
  standalone: true,
  imports: [
    MenuItemComponent,
    SvgIconComponent,
    NavButtonComponent,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @Output() readonly logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly login: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly logoutClick: EventEmitter<void> = new EventEmitter<void>();

  MAX_ORGANIZATIONS_BEFORE_SCROLL = 3;

  userProfile: Signal<UserProfile|undefined>;
  organizations: Signal<BadgeItemType[]>;

  #userService: UserService = inject(UserService);
  #organizationsService: OrganizationsService = inject(OrganizationsService);
  #authService: AuthService = inject(AuthService);

  constructor() {
    this.userProfile = this.#userService.profile;
    this.organizations = this.#organizationsService.entries as Signal<BadgeItemType[]>;
  }

  onLogin(): void {
    this.login.emit();
  }

  onLogout(): void {
    this.logout.emit();
  }

  onOrgClicked(organization: BadgeItemType): void {
    this.#organizationsService.setCurrent(organization.id, true);
  }
  getTimeOfDay(): string {
    return new Date().getHours() < 12 ? 'Good morning' : 'Good afternoon';
  }

  openUserProfile(): void {
    this.#userService.openProfile();
  }

  addOrganization(): void {
    this.#authService.redirectToCreateOrganization(document.location.href);
  }
}
