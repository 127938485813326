import type { AfterViewInit, OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, ElementRef, inject, signal, ViewChild } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsrInfosComponent } from '@app/components/usr-infos/usr-infos.component';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { EvcValidatorsService, patterns } from '@shared/reactive-form/validators/validator.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import type { FormDataProfile, FormModelProfile } from '@shared/types/profile-form.type';

import type { ApiSaveUserPayload, OptionalProfile } from '@evc/platform';
import { UserService } from '@evc/platform';
import { FrameComponent, InputComponent, NavButtonComponent } from '@evc/web-components';

import { LANGUAGES } from '../mocks/user.mock';

@Component({
  standalone: true,
  selector: 'evc-home',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    FormsModule,
    InputComponent,
    FrameComponent,
    NavButtonComponent,
    UsrInfosComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class ProfilePageComponent implements OnInit, AfterViewInit {
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;
  @ViewChild('profileForm') profileFormElement!: ElementRef;

  public canSave = false;
  public hasErrors = false;
  public userProfile: Signal<OptionalProfile>;
  public newUserProfile : OptionalProfile;
  public languages = LANGUAGES;
  public form!: FormGroup<FormModelProfile>;
  public submited = this._formService.submited;
  public isEditing = signal(false);

  private _destroyRef = inject(DestroyRef);
  private _formBuilder = inject(FormBuilder);

  profileModel = signal<FormDataProfile>({
    givenName: '',
    surname: '',
    phone: '',
    address: undefined,
    preferredLanguage: 'En',
  });

  constructor(
    private readonly _userService: UserService,
    private readonly _formService: EvcFormService<FormDataProfile>,
    private readonly _validatorService: EvcValidatorsService,
  ) {
    this.userProfile = this._userService.profile;
    effect(() => {
      this.form.patchValue(this.userProfile() as FormDataProfile);
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    const control = this._formService.controlFactory(this._formBuilder.nonNullable, this.profileModel());

    this.form = this._formBuilder.group<FormModelProfile>({
      givenName: control('givenName', [
        this._validatorService.minLength(3),
        this._validatorService.maxLength(25),
        this._validatorService.pattern(patterns.alphanumericWithDash, 'noSpecialChars'),
      ]),
      surname: control('surname', [
        this._validatorService.minLength(3),
        this._validatorService.maxLength(25),
        this._validatorService.pattern(patterns.alphanumericWithDash, 'noSpecialChars'),
      ]),
      preferredLanguage: control('preferredLanguage'),
      phone: control('phone', [this._validatorService.phone()]),
    });

    this._formService.onUpdate(
      (value) => this.profileModel.update((profileModel) => ({ ...profileModel, ...value })),
    );

    this._formService.onSubmit(
      (value) => this.submitUserInfo(value),
    );
  }

  public ngAfterViewInit(): void {
    this._formService.bind(this.form, this.formDirective, this._destroyRef);
  }

  public submitUserInfo(value: FormDataProfile): void {
    const profile = this.userProfile();
    if (profile === undefined) return;

    const newUserProfile: ApiSaveUserPayload = {
      id: profile.id,
      preferredLanguage: value.preferredLanguage,
      givenName: value.givenName,
      surname: value.surname,
      phone: value.phone,
      address: value.address,
      displayName: profile.displayName ?? '',
    };

    this._userService.saveUser(newUserProfile)
    .then(() => {
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this._formService.submited.set(false);
      this.isEditing.update(() => false);
    })
    .catch((error) => {
      console.error(error); // TODO handle the error
    });
  }

  public submitForm(event: Event): void {
    event.preventDefault();
    if (this.form.value.address?.country === '') {
      this.form.value.address = undefined;
    }
    this.profileFormElement.nativeElement.dispatchEvent(new Event('submit'));
  }
}
