import type { Signal, WritableSignal } from '@angular/core';
import { inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

import type { AppIdentity, UserOrganization } from '@evc/platform';
import { OrganizationsService } from '@evc/platform';
import type { BadgeItemType, Menu } from '@evc/web-components';
import { BtnActionEnum } from '@evc/web-components';
import { I18nService } from '@shared/services/i18n/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class NavConfigService {
  #i18nService = inject(I18nService);
  #organizations!: WritableSignal<UserOrganization[]>;
  #leftbarConfig!: WritableSignal<Menu[]>;
  #appIdentity!: WritableSignal<AppIdentity>;
  #organizationService = inject(OrganizationsService);

  constructor(
    private readonly _router: Router,
  ) {
    this.initConfig();

    this._router.events
      .pipe(
        takeUntilDestroyed(),
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateSelectedMenuItem();
        }
      });
  }

  private initConfig(): void {
    this.#organizations = this.#organizationService.entries as WritableSignal<UserOrganization[]>;
    this.#leftbarConfig = signal<Menu[]>([
      {
        title: this.#i18nService.t$('navigation.settings'),
        items: [
          {
            text: this.#i18nService.t$('PAGE_PROFILE.title'),
            key: '/',
            icon: 'user',
            selected: false,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: () => this._router.navigateByUrl('/'),
            },
          },
          {
            text: this.#i18nService.t$('PAGE_SECURITY.title'),
            key: '/security-privacy',
            icon: 'lock-keyhole',
            selected: false,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: () => this._router.navigateByUrl('/security-privacy'),
            },
          },
        ],
      },
      {
        title: this.#i18nService.t$('navigation.organization'),
        badgeItems: this.#organizations as Signal<BadgeItemType[]>,
        items: [
          {
            text: this.#i18nService.t$('PAGE_APPLICATIONS.title'),
            key: '/applications',
            icon: 'choco-menu',
            selected: false,
            action: {
              type: BtnActionEnum.CALLBACK,
              callbackOrUrl: () => this._router.navigateByUrl('/applications'),
            },
          },
        ],
      },
    ]);

    this.#appIdentity = signal<AppIdentity>({
      title: this.#i18nService.t$('title'),
      logo: '../assets/icons/sprite/sprite.svg#circle-user',
      onTitleClick: () => this._router.navigateByUrl(''),
    });
  }

  public updateSelectedMenuItem(): void {
    if (!this.#leftbarConfig) return;

    const currentUrl = this._router.url;

    this.#leftbarConfig.update((config) =>
      config.map((section) => ({
        ...section,
        items: section.items?.map((item) => ({
          ...item,
          selected:
            item.key === currentUrl,
        })),
      })),
    );
  }

  public get leftbarConfig(): WritableSignal<Menu[]> {
    return this.#leftbarConfig;
  }

  public set leftbarConfig(value: Menu[]) {
    this.#leftbarConfig.set(value);
  }

  public get appIdentity(): WritableSignal<AppIdentity> {
    return this.#appIdentity;
  }
}
