import { HttpClient } from '@angular/common/http';
import type { AfterViewInit, OnInit, Signal, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Input, signal, untracked, ViewChildren } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import type { Observable } from 'rxjs';
import { catchError, from, of, shareReplay, switchMap } from 'rxjs';

import { LanguageService } from '@evc/platform';
import {
  InputComponent,
  Maybe,
} from '@evc/web-components';

import { EvcFormService } from '../../reactive-form/reactive-form.service';
import type { FormGroupWithControls } from '../../reactive-form/types/reactive-form.type';
import { EvcValidatorsService, patterns } from '../../reactive-form/validators/validator.service';
import { TranslatePipe } from '../../services/i18n/i18n.module';
import { I18nService } from '../../services/i18n/i18n.service';
import type { Country } from '../../types/country.type';
import type { FormDataAddress, FormModelAddress } from '../../types/profile-form.type';

export type StepAddressFormGroupWithControls = FormGroupWithControls<{
  address?: FormGroup<FormModelAddress>
}>
type StateConfig = {
  options: {
      value: string;
      label: string;
  }[];
  type: string;
}

@Component({
  standalone: true,
  selector: 'evc-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    FormsModule,
    InputComponent,
],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class AddressFormComponent<TFormGroupParent extends StepAddressFormGroupWithControls> implements OnInit, AfterViewInit {
  @ViewChildren(InputComponent) inputComponents!: InputComponent[];
  @Input({ required: true }) formGroupParent!:TFormGroupParent;
  @Input() currentAddress: Maybe<FormDataAddress> = undefined;
  @Input() parentComponentName = '';
  @Input() showError = false;

  #destroyRef = inject(DestroyRef);
  #httpClient = inject(HttpClient);
  #formBuilder = inject(FormBuilder);
  #formService = inject(EvcFormService<FormDataAddress>);
  #validatorService = inject(EvcValidatorsService);
  #languageService = inject(LanguageService);
  #i18nService = inject(I18nService);

  model = signal<FormDataAddress>({
    country: '',
    street: '',
    city: '',
    province: '',
    postalCode: '',
  });

  fields:string[] = [];
  formGroup!: FormGroup<FormModelAddress>;

  #currentCountryCode:WritableSignal<Maybe<string>> = signal(undefined);

  countries = toSignal<Country[]>(this.#loadCountriesPerLanguage$());

  states = computed<StateConfig>(() => this.#getCurrentCountryStates());

  #setProvinceOnCountryChangeEffect = effect(() => {
    const states = this.states();

    untracked(() => {
      this.#setCurrentStateProvinces(states);
    });
  });

  zipOrPostalCodeLabel = computed(() =>
    ['US', 'PH'].includes(this.#currentCountryCode()??'')
      ? 'zip'
      : 'postalCode',
  );

  get currentCountry():Signal<Country|undefined> {
    return computed(() => {
      const countryCode = this.#currentCountryCode();

      return this.countries()?.find(({ value }) => value === countryCode);
    });
  }

  /** may create it's own formGroup and inject to main parent form
   * if already exist resuse it to keep current state
   */
  ngOnInit(): void {
    this.#destroyRef.onDestroy(() => {
      this.#setProvinceOnCountryChangeEffect.destroy();
      this.formGroupParent.removeControl('address');
    });

    this.fields = Object.keys(this.model);

    const currentAddressControls = this.formGroupParent.controls.address;
    if (currentAddressControls) {
      this.formGroup = currentAddressControls;

      return;
    }

    this.formGroup = this.#createControls();
    this.formGroupParent.setControl('address', this.formGroup);

    if (this.currentAddress) {
      this.formGroup.patchValue(this.currentAddress);
    }

    this.formGroup.valueChanges.subscribe(() => {
      if (!this.formGroupParent.controls.address) {
        this.formGroupParent.setControl('address', this.formGroup);
      }
    });

    return;
  }

  ngAfterViewInit(): void {
    this.#syncCountryStates();
  }

  //

  resetAddress():void {
    if (this.currentAddress) {
      this.formGroupParent.setControl('address', this.formGroup);
      this.formGroup.patchValue(this.currentAddress);
    } else {
      this.formGroup.markAsPristine();
      this.formGroupParent.removeControl('address');
    }
  }

  removeAddress():void {
    this.formGroup.setValue({
      country: '',
      province: '',
      street: '',
      postalCode: '',
      city: '',
    });
    this.formGroupParent.markAsDirty();
    this.formGroupParent.removeControl('address');
  }

  #createControls(): FormGroup<FormModelAddress> {
    if (!this.model) {
      throw new Error('Model is required to create form controls');
    }
    const control = this.#formService.controlFactory(this.#formBuilder.nonNullable, this.model());

    return this.#formBuilder.group<FormModelAddress>({
      country: control('country'),
      province: control('province'),
      street: control('street', [this.#validatorService.pattern(patterns.alphanumericExtended)]),
      postalCode: control('postalCode', [this.#validatorService.zipCode()]),
      city: control('city', [this.#validatorService.pattern(patterns.alphanumericExtended)]),
    });
  }

  #syncCountryStates():void {
    const countryField = this.formGroup.get('country')!;
    const currentCountry = countryField.value;
    if (currentCountry) {
      this.#currentCountryCode.set(currentCountry);
    }

    countryField.valueChanges
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe(value => {
      this.#currentCountryCode.set(value ?? undefined);
      this.formGroup.get('province')!.setValue('');
    });
  }

  #getCurrentCountryStates():StateConfig {
    const country = this.currentCountry();

    let options = country?.regions ?? [];

    const type = (() => {
      if (country?.regionsType) return country.regionsType;
      if (options.length > 0) return 'unknown-states';

      return 'no-states';
    })();

    if (options.length <= 0) {
      options = [{ value: 'NA', label: this.#i18nService.t('form_address.no-states.placeholder') }];
    }

    return { options, type };
  }

  #loadCountriesPerLanguage$():Observable<Country[]> {
    return from(this.#languageService.lang$)
      .pipe(
        switchMap((lang) => from(
          this.#httpClient.get<Country[]>(`/assets/countries/${lang}.json`)
          .pipe(
            catchError(() => of([])),
          ),
        )),
        shareReplay(),
        takeUntilDestroyed(this.#destroyRef),
      );
  }

  #setCurrentStateProvinces(states:StateConfig):void {
    if (!this.formGroupParent.get('address')) return;
    const provinceControl = this.formGroup.get('province');
    if (!provinceControl) return;

    if (states.options.length > 1) {
      provinceControl.enable();
      provinceControl.markAsPristine();

      return;
    }
    const defaultValue = states.options[0].value;
    if (!provinceControl.getRawValue()) {
      provinceControl.setValue(defaultValue);
    }
    provinceControl.disable();
    provinceControl.markAsPristine();
  }
}
