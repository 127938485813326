<div class="header">
  <button class="header__title" (click)="handleTitleClick()">
    <evc-svg-icon size="logo" [svgPath]="appIdentity?.logo"></evc-svg-icon>
    <span>{{ (appIdentity?.title | optionalAsync)}}</span>
  </button>

  <div
    class="header__tabs-container"
    evcHorizontalWheelScroll
    evcIsScrollable
    [scrollPosition]="'bottom'"
  >
  @for(tab of topTabs; track trackByKey){
    <div class="header__top-tabs">
      @if(tab.action) {
        <button
          class="header__tab"
          [ngClass]="{ 'header__tab--selected': tab.selected }"
          [disabled]="isTabDisabled(tab.key)"
          (click)="handleTabClick(tab)"
        >
          <span class="header__tab-text" [ngClass]="{ 'header__tab-text--selected': tab.selected }">{{ tab.text }}</span>
        </button>
      }
    </div>
  }
  </div>
</div>
