import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { Avatar } from '../../types/avatar.type';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  standalone: true,
  selector: 'evc-badge-button',
  templateUrl: 'badge-button.component.html',
  styleUrls: ['./badge-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent],
})
export class BadgeButtonComponent {
  @Input() public currentText = '';
  @Input() public role = '';
  @Input() public avatar: Avatar | undefined = undefined;
}
