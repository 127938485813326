import type { CountryCode } from 'libphonenumber-js';

import type { Organization, UserConfig } from '@evc/platform';

export enum ProvinceEnum {
  QC = 'qc',
  ON = 'on',
  BC = 'bc',
  AB = 'ab',
  MB = 'mb',
  NB = 'nb',
  NL = 'nl',
  NS = 'ns',
  PE = 'pe',
  SK = 'sk',
  NT = 'nt',
  NU = 'nu',
  YT = 'yt',
}

export enum LanguageEnum {
  FR = 'Fr',
  EN = 'En',
}

export type CountryType = {
  value: CountryCode;
  label: string;
  regions: { value: ProvinceEnum; label: string }[];
};
export type ProvinceType = `${ProvinceEnum}`;
export type LanguageType = `${LanguageEnum}`;

export const PROVINCES = [
  { value: ProvinceEnum.QC, label: 'Quebec' },
  { value: ProvinceEnum.ON, label: 'Ontario' },
  { value: ProvinceEnum.BC, label: 'British Columbia' },
  { value: ProvinceEnum.AB, label: 'Alberta' },
  { value: ProvinceEnum.MB, label: 'Manitoba' },
  { value: ProvinceEnum.NB, label: 'New Brunswick' },
  { value: ProvinceEnum.NL, label: 'Newfoundland and Labrador' },
  { value: ProvinceEnum.NS, label: 'Nova Scotia' },
  { value: ProvinceEnum.PE, label: 'Prince Edward Island' },
  { value: ProvinceEnum.SK, label: 'Saskatchewan' },
  { value: ProvinceEnum.NT, label: 'Northwest Territories' },
  { value: ProvinceEnum.NU, label: 'Nunavut' },
  { value: ProvinceEnum.YT, label: 'Yukon' },
];

export const MOCKED_SINGLE_PROVINCE = [
  { value: ProvinceEnum.QC, label: 'Quebec' },
];

export const COUNTRIES: CountryType[] = [
  { value: 'FR', label: 'France', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'US', label: 'United States', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'CA', label: 'Canada', regions: PROVINCES },
  { value: 'GB', label: 'United Kingdom', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'DE', label: 'Germany', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'ES', label: 'Spain', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'IT', label: 'Italy', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'JP', label: 'Japan', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'CN', label: 'China', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'AU', label: 'Australia', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'BR', label: 'Brazil', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'RU', label: 'Russia', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'IN', label: 'India', regions: MOCKED_SINGLE_PROVINCE },
  { value: 'ZA', label: 'South Africa', regions: MOCKED_SINGLE_PROVINCE },
];

export const LANGUAGES = [
  { value: LanguageEnum.FR, label: 'Francais' },
  { value: LanguageEnum.EN, label: 'English' },
];

export const USER_MOCKED: UserConfig = {
  id: '1',
  email: 'bob.ross@email.com',
  roles: [{
    id: '0',
    name: 'admin',
    isReadOnly: false,
  }],
  givenName: 'Bob',
  surname: 'Ross',
  displayName: 'Bob Ross',
  emailVerified: true,
  phone: '8005557894',
  preferredLanguage: LanguageEnum.EN,
  address: {
    country: 'CA',
    street: '1234 Elm St',
    city: 'Springfield',
    province: ProvinceEnum.QC,
    postalCode: 'H3C1K5',
  },
};

export const ORG_MOCKED: Organization[] = [
  {
    name: 'Babylon',
    id: 'babylon',
    current: true,
    avatar: {
      type: 'initials',
      color: 'red',
      light: true,
      initials: 'B',
    },
  },
  {
    name: 'William Enterprises',
    id: 'william-enterprises',
    current: false,
    avatar: {
      type: 'initials',
      light: true,
      initials: 'WE',
    },
  },
  {
    name: 'Bob-Ross',
    id: 'bob-ross',
    current: false,
    avatar: {
      type: 'initials',
      light: true,
      initials: 'B',
    },
  },
];
