import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Input, signal, untracked, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';

import type { Address } from '@evc/platform';
import { UserService } from '@evc/platform';
import type {
  Avatar,
  IconListType,
  Maybe } from '@evc/web-components';
import {
  AvatarComponent,
  InputComponent,
  NavButtonComponent,
  SvgIconComponent,
} from '@evc/web-components';
import { AddressFormComponent } from '@shared/components/address-form/address-form.component';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import type { FormDataAddress, FormModelProfile } from '@shared/types/profile-form.type';

import { COUNTRIES } from '../../mocks/user.mock';

@Component({
  standalone: true,
  selector: 'evc-usr-infos',
  templateUrl: './usr-infos.component.html',
  styleUrls: ['./usr-infos.component.scss'],
  imports: [
    AvatarComponent,
    AddressFormComponent,
    ReactiveFormsModule,
    TranslatePipe,
    FormsModule,
    NavButtonComponent,
    SvgIconComponent,
    InputComponent,
],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvcFormService],
})
export class UsrInfosComponent implements OnInit {
  @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent<FormGroup<FormModelProfile>> | undefined;

  @Input({ required: true }) formGroupParent!: FormGroup<FormModelProfile>;

  #submited = signal(false);
  @Input({ required: true }) set submited(value:boolean) {
    this.#submited.set(value);
  }
  get submited():Signal<boolean> {
    return this.#submited.asReadonly();
  }

  #displayAddress = signal(false);
  @Input({ required: true }) set displayAddress(value:boolean) {
    this.#displayAddress.set(value);
  }
  get displayAddress():Signal<boolean> {
    return this.#displayAddress.asReadonly();
  }

  #userService = inject(UserService);
  #destroyRef = inject(DestroyRef);

  countries = COUNTRIES;
  addressValue = signal<Partial<Address> | undefined>(undefined);
  addressCTA = computed((): {
    label: string,
    icon: IconListType,
    type: 'error-tertiary' | 'tertiary',
    action: (event: Event) => void,
  } => {
    const addressValue = this.addressValue();
    const currentAddress = this.currentAddress();
    const isSameAddress = this.flattenAddress(addressValue) === this.flattenAddress(currentAddress);
    if (addressValue) {
      if (currentAddress && isSameAddress) {
        return { label: 'PAGE_PROFILE.personal_info.cta_address.remove', icon: 'xmark', type: 'error-tertiary', action: (event: Event) => this.removeAddress(event) };
      }

      return { label: 'PAGE_PROFILE.personal_info.cta_address.cancel', icon: 'xmark', type: 'error-tertiary', action: (event: Event) => this.resetUserAddress(event) };
    }

    if (currentAddress) {
      return { label: 'PAGE_PROFILE.personal_info.cta_address.undo', icon: 'arrow-rotate-left', type: 'tertiary', action: (event: Event) => this.resetUserAddress(event) };
    }

    return { label: 'PAGE_PROFILE.personal_info.cta_address.add', icon: 'plus', type: 'tertiary', action: (event: Event) => this.toggleAddress(event) };
  });

  userAvatar = computed<Maybe<Avatar>>(() => this.#userService.profile()?.avatar);
  showAdressError = signal(false);

  private syncSubmitToAddress = effect(():void => {
    if (this.submited() && this.addressValue()) {
      untracked(() => {
        this.showAdressError.set(true);
      });
    }
  });

  ngOnInit(): void {
    this.formGroupParent.valueChanges.pipe(
      takeUntilDestroyed(this.#destroyRef),
      // because of disabled fields
      map(() => this.formGroupParent.getRawValue()),
    ).subscribe((changes) => {
      this.addressValue.set(changes.address);
    });

    this.#destroyRef.onDestroy(() => {
      this.syncSubmitToAddress.destroy();
    });
  }

  get email(): Signal<string> {
    return computed(() => this.#userService.profile()?.email ?? '');
  }

  get currentAddress(): Signal<FormDataAddress|undefined> {
    return computed(() => this.#userService.profile()?.address ?? undefined);
  }

  toggleAddress(event: Event, force?:boolean): void {
    event.preventDefault();
    this.displayAddress = force === undefined ? !this.displayAddress() : force;
    if (!this.displayAddress()) {
      this.showAdressError.set(false);
    }
  }

  resetUserAddress(event: Event): void {
    event.preventDefault();
    this.addressFormComponent?.resetAddress();
      this.toggleAddress(event, !!this.currentAddress());
    this.formGroupParent.controls.address?.markAsPristine();
  }

  removeAddress(event: Event): void {
    event.preventDefault();
    this.toggleAddress(event, false);
    this.addressFormComponent?.removeAddress();
  }

  changeUserPicture(event: Event): void {
    event.preventDefault();
  }

  private flattenAddress({ country, province, street, postalCode, city }: Partial<Address> = {}): string {
    return [country,
      province,
      street,
      postalCode,
      city].join('');
  }
}
