<form class="home"
  name="profile"
  [formGroup]="form"
  #profileForm
>
  <evc-frame>
    <div frame-title class="evc-app-title">
      <span class="evc-page-title">{{'PAGE_PROFILE.title' | translate }}</span>
      <evc-button
        type="primary"
        [disabled]="!form.dirty"
        variant="semi"
        (click)="submitForm($event)"
      >
        {{ 'PAGE_PROFILE.form_submit' | translate }}
      </evc-button>
    </div>

    <div frame-content class="evc-page-container">
        <div class="evc-page-infos">
          <span class="evc-page-small-space evc-content-title">{{'PAGE_PROFILE.personal_info.title' | translate }}</span>
          <evc-usr-infos
            class="evc-page-main-elem "
            [formGroupParent]="form"
            [submited]="submited()"
            [displayAddress]="displayAddress()"
          >
          </evc-usr-infos>
          <div class="evc-page-small-space"></div>
        </div>

        <div class="evc-page-divider"></div>

        <div class="evc-page-infos">
          <span class="evc-page-small-space evc-content-title">{{'preferred_language.title' | translate }}</span>
          <div class="home__main-content">

            <evc-input
              class="evc-page-main-elem "
              label="{{'preferred_language.language' | translate }}"
              id="language"
              name="language"
              size="md"
              formControlName="preferredLanguage"
              type="select"
              [options]="languages"
              placeholder="{{'preferred_language.language_placeholder' | translate }}"
            >
            </evc-input>
            <div class="evc-page-mid-space"></div>
          </div>
          <div class="evc-page-small-space"></div>
        </div>
    </div>
  </evc-frame>
</form>
